import { useState, useEffect } from "react";
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";

export default function Nip() {
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const styles = {
    display: "flex",
    justifyContent: "space-between",
  };

  const buttonContainer = {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  };
  const buttonContainerPM = {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    marginTop: "16px",
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
    } else {
      window.location.href = "/login";
    }
  }, []);

  async function handleSave(docType) {
    setIsLoading(true);
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.nip,
      data: {
        type: docType,
        start: selectedStartDate,
        end: selectedEndDate,
      },
    });
    if (response) {
      console.log(response);
      let blob = new Blob([response], { type: "content-type text/csv" }),
        url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Auditoria_nip_${docType.toLowerCase()}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="main-content">
        <PageSection title="Auditoría Nip">
          <PageSection.Item size={1} length={3}>
            <div style={styles}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="start-date"
                  label="Fecha de principio"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="end-date"
                  label="Fecha de fin"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading}
                />
              </MuiPickersUtilsProvider>
            </div>
          </PageSection.Item>
          <PageSection.Item size={1} length={3}>
            <div style={buttonContainer}>
              <Button
                onClick={() => handleSave("BC")}
                style="primary"
                text="Guardar Auditoría BC"
                loading={isLoading}
                disabled={isLoading}
              />
              <Button
                onClick={() => handleSave("CC")}
                style="primary"
                text="Guardar Auditoría CC"
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
            <div style={buttonContainerPM}>
              <Button
                onClick={() => handleSave("BC_PM")}
                style="primary"
                text="Guardar Auditoría BC PM"
                loading={isLoading}
                disabled={isLoading}
              />
              <Button
                onClick={() => handleSave("CC_PM")}
                style="primary"
                text="Guardar Auditoría CC PM"
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </PageSection.Item>
        </PageSection>
      </div>
    </>
  );
}
