import React, { useState } from 'react';
import Tabs from '@bit/kiban-design-system.kiban.tabs';

import HistoricWorkfloo from "./HistoricWorkfloo";
import HistoricBuros from "./HistoricBuros";

export default function Historic () {
  const [tabSelected, setTabSelected] = useState(0);

  const handleChangeTab = (index) => {
    setTabSelected(index);
  }

  return (
    <div className="main-content">
      <Tabs
        indexSelected={tabSelected}
        onChange={handleChangeTab}
      >
        <Tabs.Item>Workfloo</Tabs.Item>
        <Tabs.Item>Burós</Tabs.Item>
      </Tabs>
      {tabSelected === 0 ?
        <HistoricWorkfloo/> : <HistoricBuros/>
      }
    </div>
  );
}
