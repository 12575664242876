import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import Alert from "@bit/kiban-design-system.kiban.alert";
import Select from "@bit/kiban-design-system.kiban.select-field";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import Table from "@bit/kiban-design-system.kiban.table";

import TextField from "@bit/kiban-design-system.kiban.text-field";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import Paths from "../../paths";
import { getMessageError } from "./HistoricErrors";
import "./Historic.css";

export default function HistoricBuros() {
  const limit = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historic, setHistoric] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  registerLocale("es", es);
  const history = useHistory();

  const getHistoricBuros = async (params) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.historicBuros}?limit=${limit + 1}&skip=${page * limit}${
        params.length > 0 ? `&${params.join("&")}` : ""
      }`,
    });
    if (data) {
      setHasNextPage(data.sicHistory.length > limit);
      setHistoric(data.sicHistory ? data.sicHistory : []);
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  const downloadHistoricBuros = async () => {
    const dataAppliedFilters = filters.filter((filter) =>
      appliedFilters.includes(filter.key)
    );
    const params = dataAppliedFilters.map((filter) => {
      return `${
        filter.key.includes("date")
          ? filter.key.replace("date_", "")
          : filter.key
      }=${
        filter.key.includes("date")
          ? moment(filter.value.toJSON()).format("YYYY-MM-DD")
          : filter.value
      }`;
    });
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.downloadHistoricBuros}?limit=100000&skip=0${
        params.length > 0 ? `&${params.join("&")}` : ""
      }`,
    });
    if (data) {
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "Historial.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(data);
      download.click();
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  // Start configuration table
  // Pagination table

  const goForward = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  const goBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const tablePagination = {
    hasNext: hasNextPage,
    hasPrevious: page > 0,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: () => setPage(0),
  };
  // end pagination table

  // build data table
  const rows =
    historic.length > 0
      ? historic
          .slice(0, limit)
          .map((historicItem, i) => [
            historicItem.SicId,
            moment.utc(historicItem.date).format("DD-MM-YYYY"),
            moment
              .utc(`${historicItem.date} ${historicItem.hours}`)
              .format("HH:mm"),
            historicItem.user,
            historicItem.type,
            historicItem.status,
            historicItem.origin,
            historicItem.names,
            historicItem.rfc,
            historicItem.folio,
          ])
      : [];

  const primaryAction = {
    label: "Exportar CSV",
    text: "Exportar CSV",
    onAction: downloadHistoricBuros,
    disabled: isLoading,
  };

  const tableHeaders = [
    "ID",
    "Fecha",
    "Hora",
    "Usuario",
    "Tipo",
    "Estatus",
    "Origen",
    "Nombres",
    "RFC",
    "Número de control/folio",
  ];
  // end data table
  // filters config
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [idSic, setIdSic] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondLastName, setSecondLastName] = useState("");
  const [rfc, setRfc] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [user, setUser] = useState("");
  const [catalogUsers, setCatalogUsers] = useState([]);

  const getUsers = async () => {
    setIsLoading(true);
    const [error, users] = await apiRequest({
      method: "get",
      url: apiRoutes.users,
    });

    if (users) {
      const options = users.users.map((userItem) => {
        return {
          content: userItem.login,
          value: userItem.userId,
        };
      });
      setCatalogUsers(options);
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getHistoricBuros(filterParams);
      getUsers();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleIdSic = (event) => {
    setIdSic(event.target.value);
  };

  const handleRemoveIdSic = () => {
    setIdSic("");
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleRemoveName = () => {
    setName("");
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleRemoveLastName = () => {
    setLastName("");
  };

  const handleSecondLastName = (event) => {
    setSecondLastName(event.target.value);
  };

  const handleRemoveSecondLastName = () => {
    setSecondLastName("");
  };

  const handleRfc = (event) => {
    setRfc(event.target.value);
  };

  const handleRemoveRfc = () => {
    setRfc("");
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleRemoveStartDate = () => {
    setStartDate(null);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleRemoveEndDate = () => {
    setEndDate(null);
  };

  const handleSelectUser = (selected) => {
    setUser(selected);
  };

  const handleRemoveUser = () => {
    setUser("");
  };

  const filters = [
    {
      key: "sicId",
      label: "Id Consulta SIC",
      value: idSic,
      filter: (
        <TextField
          placeholder="Id Consulta SIC"
          onChange={handleIdSic}
          value={idSic}
        />
      ),
      onRemove: handleRemoveIdSic,
    },
    {
      key: "nombres",
      label: "Nombres o Razón Social",
      value: name,
      filter: (
        <TextField
          placeholder="Nombres o Razón Social"
          onChange={handleName}
          value={name}
        />
      ),
      onRemove: handleRemoveName,
    },
    {
      key: "ApPaterno",
      label: "Apellido Paterno",
      value: lastName,
      filter: (
        <TextField
          placeholder="Apellido Paterno"
          onChange={handleLastName}
          value={lastName}
        />
      ),
      onRemove: handleRemoveLastName,
    },
    {
      key: "ApMaterno",
      label: "Apellido Materno",
      value: secondLastName,
      filter: (
        <TextField
          placeholder="Apellido Materno"
          onChange={handleSecondLastName}
          value={secondLastName}
        />
      ),
      onRemove: handleRemoveSecondLastName,
    },
    {
      key: "rfc",
      label: "RFC",
      value: rfc,
      filter: <TextField placeholder="RFC" onChange={handleRfc} value={rfc} />,
      onRemove: handleRemoveRfc,
    },
    {
      key: "date_from",
      label: "Fecha de principio",
      value: startDate,
      filter: (
        <DatePicker
          locale="es"
          className="input-date"
          selected={startDate}
          onChange={handleStartDate}
          placeholderText="Fecha de principio"
          dateFormat="dd/MM/yyyy"
        />
      ),
      onRemove: handleRemoveStartDate,
    },
    {
      key: "date_to",
      label: "Fecha de fin",
      value: endDate,
      filter: (
        <DatePicker
          locale="es"
          className="input-date"
          selected={endDate}
          onChange={handleEndDate}
          placeholderText="Fecha de fin"
          dateFormat="dd/MM/yyyy"
        />
      ),
      onRemove: handleRemoveEndDate,
    },
    {
      key: "userId",
      label: "Usuario",
      value: user,
      filter: (
        <Select
          placeholder={"Selecciona un usuario"}
          value={user}
          options={catalogUsers}
          onChange={handleSelectUser}
        />
      ),
      onRemove: handleRemoveUser,
    },
  ];

  const resetValues = (filtersReset) => {
    filtersReset.forEach((filter) => {
      filter.onRemove && filter.onRemove();
    });
  };

  const isEmpty = (value) => {
    return value === "" || value === null || value === undefined;
  };

  const onFilter = (selectedFilters) => {
    const resetFilters = filters.filter(
      (filter) => !selectedFilters.includes(filter.key)
    );
    resetValues(resetFilters);
    const filtersNoEmpty = filters
      .filter(
        (filter) =>
          selectedFilters.includes(filter.key) && !isEmpty(filter.value)
      )
      .map((filter) => filter.key);
    setAppliedFilters(filtersNoEmpty);
  };

  const listFilters = filters
    .filter((filter) => appliedFilters.includes(filter.key))
    .map((filter) => {
      const value = filter.key.includes("date")
        ? [
            filter.value.getDate(),
            filter.value.getMonth() + 1,
            filter.value.getUTCFullYear(),
          ].join("/")
        : filter.key === "userId"
        ? catalogUsers.find((item) => item.value === user).content
        : filter.value;
      return {
        key: filter.key,
        value: (
          <span>
            {filter.label}: <strong>{value}</strong>
          </span>
        ),
      };
    });

  // Apply filters
  useEffect(() => {
    const params = filters
      .filter((filter) => appliedFilters.includes(filter.key))
      .map((filter) => {
        return `${
          filter.key.includes("date")
            ? filter.key.replace("date_", "")
            : filter.key
        }=${
          filter.key.includes("date")
            ? moment(filter.value.toJSON()).format("YYYY-MM-DD")
            : encodeURIComponent(filter.value)
        }`;
      });
    getHistoricBuros(params);
    setFilterParams(params);
  }, [appliedFilters]);
  // end filters config

  useEffect(() => {
    getHistoricBuros(filterParams);
  }, [page]);

  const onSelectItem = (selected) => {
    const itemSelected = historic[selected];
    const path = Paths.DetailHistoricWorkfloo.replace(
      ":idHistoryWorkfloo",
      itemSelected.idUnykoo,
    )
    const url = `${window.location.origin}${path}`;
    window.open(url, "_blank");
  };

  // End configuration table

  return (
    <div className="main-content">
      {isLoading ? (
        <SkeletonTable columns={7} primaryAction pagination />
      ) : (
        <PageSection>
          <PageSection.Item size={1} length={1}>
            {error && (
              <Alert key={`alert-error-buros`} code="danger" text={error} />
            )}
            <Table
              headings={tableHeaders}
              rows={rows}
              primaryAction={primaryAction}
              pagination={historic.length > 0 ? tablePagination : null}
              filters={filters}
              appliedFilters={appliedFilters}
              onFilter={onFilter}
              onSelectItem={onSelectItem}
              listFilters={listFilters}
              labelExtraActionFilter={"Agregar filtro"}
              textEmpty={"No se encontraron resultados"}
              filterTitle="Filtrar"
              filterPrimaryActionText="Aplicar"
              filterSecondaryActionText="Cancelar"
              filterTagsLabel="Filtrado por:"
              filterTagsListEditButtonText="Editar filtro"
            />
          </PageSection.Item>
        </PageSection>
      )}
    </div>
  );
}
