import React, { useState } from "react";
import SortableList from "@bit/kiban-design-system.kiban.sortable-list";

import Header from "./PanelHeader";
import File from "./File";
import error_types from "./filesErrors";
import handleFileNameChange from "./onFileNameChange";
import "./FileList.css";

const FileList = ({
  files,
  onAddNew,
  onFileNameChange,
  onDeleteFile,
  onSelectFile,
  selectedFile,
  onChangeOrder,
}) => {
  const [filesErrors, setFilesErrors] = useState([]);

  const handleOnFileNameChange = (fileName, i) => {
    const { allFiles, errors } = handleFileNameChange({
      fileName,
      files,
      filesErrors,
      i,
    });
    onFileNameChange && onFileNameChange(allFiles);
    setFilesErrors(errors);
  };

  const handleRenderFile = (file, i) => {
    const handleDeleteFile = () => {
      let allFiles = [...files];
      allFiles.splice(i, 1);
      onDeleteFile && onDeleteFile(allFiles);
    };

    const error = filesErrors.find((error) => i === error.index);
    return (
      <File
        name={file.name_file}
        onClick={() => onSelectFile && onSelectFile(i)}
        onFileNameChange={(name) => handleOnFileNameChange(name, i)}
        onDeleteFile={handleDeleteFile}
        error={error}
        errorText={error ? error_types[error.type] : undefined}
        selected={selectedFile === i}
      />
    );
  };

  const handleChangeOrder = (files) => {
    onChangeOrder && onChangeOrder(files);
  };

  return (
    <div className="FileList-Container">
      <Header title="Javascript" onAddNew={onAddNew} />
      <div className="files-container">
        <SortableList
          items={files}
          renderItem={handleRenderFile}
          onChange={handleChangeOrder}
        />
      </div>
    </div>
  );
};

export default FileList;
