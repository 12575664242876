import React from "react";
import "./TableSms.css";

export default function TableSms({ title, headers, data, isTotal }) {
  return (
    <div className="container-table-data">
      <span className="table-title">{title}</span>
      <div className="table-data-container">
        <div className={`table-row${isTotal ? "--total" : ""}`}>
          {headers.map((header) => (
            <span className="cell--header">{header}</span>
          ))}
        </div>
        {data.map((item) => {
          return (
            <div className="table-row">
              <span className="cell">{item.send}</span>
              <span className="cell">{item.aproved}</span>
              <span className="cell">{item.fail}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
