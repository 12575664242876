import React from "react";
import Panel from "../../../../shared/Panel";

import VariablesSection from "./VariablesSection";
import ExampleSection from "./ExampleSection";

const LeftPanel = ({
  variables,
  onAddNewVariable,
  onEditVariable,
  example,
  onExampleChange,
}) => {
  return (
    <Panel className="left-panel">
      <VariablesSection
        variables={variables}
        onAddNew={onAddNewVariable}
        onEditVariable={onEditVariable}
      />
      <ExampleSection code={example} onCodeChange={onExampleChange} />
    </Panel>
  );
};

export default LeftPanel;
