import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useReducer,
} from "react";
import useAuthReducer from "./use-auth-reducer";
import Jwt from "../../utils/jwt";

const AuthContext = createContext({
  login() {},
  logout() {},
  isAuthenticated: null,
});

const initialState = {
  isLogged: false,
  token: null,
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(useAuthReducer, initialState);

  useEffect(() => {
    dispatch({ type: "LOGIN", token: localStorage.getItem("token") });
  }, []);

  const authContext = useMemo(
    () => ({
      login: (data) => {
        Jwt.setToken(data);
        dispatch({ type: "LOGIN", data });
      },
      logout: () => {
        localStorage.clear();
        Jwt.removeToken();
        dispatch({ type: "LOGOUT" });
      },
      isAuthenticated: state.token
        ? state.token
        : localStorage.getItem("token"),
    }),
    [state.token]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
