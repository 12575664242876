import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Button from "@bit/kiban-design-system.kiban.button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import Texts from "../../../utils/app-texts.json";

import "./AddCleanData.css";

const CONNECTOR_TYPE_NAME = "CAMARA_COMERCIO_BOGOTA";

const CleanData = () => {
  const { addAlert } = useAlert();
  const [name, setName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    service: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const [types, setTypes] = useState([]);
  const [services, setServices] = useState({});

  const history = useHistory();
  const routeParams = useParams();

  const getTypeServices = async () => {
    const [error, types] = await apiRequest({
      method: "GET",
      url: apiRoutes.getConnectorTypeServices.replace(
        ":connectorType",
        CONNECTOR_TYPE_NAME
      ),
    });
    if (types) {
      let parsedTypes = [];
      let parsedServices = {};
      types.forEach((type) => {
        parsedTypes.push({ key: type.type.const, label: type.type.langs.es });
        parsedServices[type.type.const] = [];
        type.services.forEach((service) => {
          parsedServices[type.type.const].push({
            label: service.langs.es,
            key: service.const,
          });
        });
      });
      setTypes(parsedTypes);
      setServices(parsedServices);
      if (routeParams.id) getConnector();
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.tecnicalError,
      });
    }
    if (!routeParams.id) setIsLoading(false);
  };

  const getConnector = async () => {
    const [error, connector] = await apiRequest({
      method: "get",
      url: apiRoutes.getEditConnector
        .replace("/:typeConnector", "")
        .replace(":id", routeParams.id),
    });
    if (connector) {
      setName(connector.name);
      setSelectedType(connector.type);
      setSelectedService(connector.service);
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };

  const saveConnector = async () => {
    let connector = {
      name,
      service: selectedService,
      type: selectedType,
    };
    if (routeParams.id) {
      connector.id = routeParams.id;
    }
    const [error, data] = await apiRequest({
      method: routeParams.id ? "put" : "post",
      data: connector,
      url: routeParams.id
        ? apiRoutes.getEditConnector
            .replace(":typeConnector", CONNECTOR_TYPE_NAME)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", CONNECTOR_TYPE_NAME),
    });
    if (data !== null) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
      if (!routeParams.id)
        history.replace(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTypeServices();
  }, []);

  const handleOnNamechange = (e) => {
    setName(e.target.value);
  };

  const handleChangeType = (type) => {
    setSelectedType(type);
  };

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  const validateForm = () => {
    let errors = {
      name: false,
      type: false,
      service: false,
    };
    if (!name) {
      errors.name = true;
    }
    if (!selectedType) {
      errors.type = true;
    }
    if (!selectedService) {
      errors.service = true;
    }
    setErrors(errors);
    return !errors.name && !errors.service && !errors.type;
  };

  const handleSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      saveConnector();
    }
  };
  const handleCancel = () => {
    history.push(
      Paths.Connectors.replace(":connectorType", routeParams.connectorType)
    );
  };

  const typesOptions = types.map((type) => ({
    content: type.label,
    value: type.key,
  }));
  const servicesTypes = services[selectedType]?.map((service) => ({
    content: service.label,
    value: service.key,
  }));

  const pageContent = (
    <PageSection title="Clean Data">
      <PageSection.Item
        title={Texts.connectors.fields.name}
        size={1}
        length={3}
      >
        <TextField
          type="text"
          value={name}
          onChange={handleOnNamechange}
          error={errors.name}
          errorText="Debes agregar un nombre"
        />
      </PageSection.Item>
      <PageSection.Item size={1} length={3} />
      <PageSection.Item size={1} length={3} />
      <PageSection.Item
        size={1}
        length={3}
        title={Texts.connectors.fields.connectorType}
      >
        <SelectField
          options={typesOptions}
          value={selectedType}
          onChange={handleChangeType}
          placeholder={Texts.connectors.fields.connectorTypePlaceholder}
          error={errors.type}
          errorText="Debes seleccionar un tipo"
        />
      </PageSection.Item>
      <PageSection.Item size={1} length={3} />
      <PageSection.Item size={1} length={3} />
      {selectedType && servicesTypes && servicesTypes.length > 0 && (
        <PageSection.Item
          size={1}
          length={3}
          title={Texts.connectors.fields.serviceType}
        >
          <SelectField
            options={servicesTypes}
            value={selectedService}
            onChange={handleServiceChange}
            placeholder={Texts.connectors.fields.serviceTypePlaceholder}
            error={errors.service}
            errorText="Debes seleccionar un servicio"
          />
        </PageSection.Item>
      )}
      <PageSection.Item size={1} length={1}>
        <div className="actions-container">
          <Button
            onClick={handleSave}
            text="Guardar"
            style="primary"
            disabled={isLoading}
          />
          <Button onClick={handleCancel} text="Cancelar" disabled={isLoading} />
        </div>
      </PageSection.Item>
    </PageSection>
  );

  return (
    <div className="main-content" id="AddCleanData">
      {isLoading ? <Skeleton /> : pageContent}
    </div>
  );
};

export default CleanData;
