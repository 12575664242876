import "./AddEditScore.css";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import TextArea from "@bit/kiban-design-system.kiban.textarea";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import Texts from "../../../utils/app-texts.json";
const SMS_MESSAGE_LENGTH = 140;

const AddEditScore = () => {
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [message, setMessage] = useState("");
  const [alertSource, setAlertSource] = useState("");
  const [notificationMethod, setNotificationMethod] = useState("");
  const [queryOperatorAsSource, setQueryOperatorSource] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getCatalogTypesServices = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectorTypeServices.replace(
        ":connector",
        routeParams.connectorType.toUpperCase()
      ),
    });
    if (!error) {
      setOptions(
        data?.map((opt) => {
          return {
            content:
              opt.type && opt.type.langs && opt.type.langs.es
                ? opt.type.langs.es
                : "",
            value: opt.type && opt.type.const ? opt.type.const : "",
          };
        })
      );
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const saveConnector = async () => {
    setIsLoading(true);
    const connector = { name };
    if (selected === "MASIVIAN") {
      connector.message = message;
      connector.alertOrigin = alertSource;
      connector.notificationMethod = notificationMethod;
      connector.queryOperatorAsSource = Boolean(queryOperatorAsSource);
    }
    const [error, data] = await apiRequest({
      method: isEdit ? "put" : "post",
      url: isEdit
        ? apiRoutes.editConnector
            .replace(":connector", selected)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", selected),
      data: connector,
    });
    if (!error) {
      addAlert({
        code: "success",
        message: AlertTexts.success,
        duration: 5000,
      });
      if (!isEdit) {
        setName(data.name);
        setSelected(data.type);
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDataConnector = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (!error) {
      setName(data.name);
      setSelected(data.type);
      setMessage(data.message || "");
      setAlertSource(data.alertOrigin || "");
      setNotificationMethod(data.notificationMethod || "");
      setQueryOperatorSource(data.queryOperatorAsSource ? 1 : 0);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCatalogTypesServices();
    setIsEdit(routeParams.hasOwnProperty("id"));
    if (routeParams.hasOwnProperty("id")) {
      getDataConnector();
    }
  }, []);

  const handleOnMessageChange = (e) => {
    if (e.target.value.length < SMS_MESSAGE_LENGTH + 1)
      setMessage(e.target.value);
  };

  return isLoading ? <Skeleton/> : (
    <div className="main-content" id="AddEditScore">
      <PageSection title="Score">
        <PageSection.Item size={1} length={3}>
          <TextField
            label={Texts.connectors.fields.name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={3}>
          <SelectField
            label={Texts.connectors.fields.connectorType}
            placeholder={Texts.connectors.fields.connectorTypePlaceholder}
            value={selected}
            onChange={(value) => {
              setSelected(value);
            }}
            options={options}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {selected === "MASIVIAN" && (
          <>
            <PageSection.Item size={1} length={3}>
              <TextArea
                label="Mensaje SMS"
                value={message}
                onChange={handleOnMessageChange}
                helperText="Si el workfloo contiene un formulario con un campo que tiene el id 123, poner {{FORM_123}} y se reemplazará el valor"
              />
              <p>
                Número de caracteres restantes:{" "}
                {SMS_MESSAGE_LENGTH - message.length}
              </p>
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <TextField
                label="Origen de alerta"
                value={alertSource}
                onChange={(e) => setAlertSource(e.target.value)}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <TextField
                label="Método de notificación"
                value={notificationMethod}
                onChange={(e) => setNotificationMethod(e.target.value)}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <label>Operadora de consultas como fuente</label>
              <div className="query-operator-field">
                <RadioButton
                  label="Sí"
                  name="queryOperatorAsSource"
                  value={1}
                  checked={queryOperatorAsSource ? true : false}
                  onChange={() => setQueryOperatorSource(1)}
                />
              </div>
              <div className="query-operator-field">
                <RadioButton
                  label="No"
                  name="queryOperatorAsSource"
                  value={0}
                  checked={!queryOperatorAsSource ? true : false}
                  onChange={() => setQueryOperatorSource(0)}
                />
              </div>
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button
              style="primary"
              text="Guardar"
              onClick={saveConnector}
              disabled={selected === "" || name === ""}
            />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => {
                history.push(
                  Paths.Connectors.replace(
                    ":connectorType",
                    routeParams.connectorType
                  )
                );
              }}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
};

export default AddEditScore;
