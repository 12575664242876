import axios from "axios";
import Jwt from "./../utils/jwt";

const envs = [
  {
    url: "alpha.workfloo.kiban.tech",
    api: "https://alpha.rest.unykoo.kiban.tech/v1/internal",
  },
  {
    url: "qa.workfloo.kiban.tech",
    api: "https://qa.rest.unykoo.kiban.tech/v1/internal",
  },
  {
    url: "test.workfloo.kiban.tech",
    api: "https://test.rest.unykoo.kiban.tech/v1/internal",
  },
  {
    url: "workfloo.kiban.tech",
    api: "https://prod.rest.unykoo.kiban.tech/v1/internal",
  },
  {
    url: "localhost",
    api: "https://qa.rest.unykoo.kiban.tech/v1/internal",
  },
  {
    url: "localhost.workfloo.kiban.tech",
    api: "http://localhost:8090/v1/internal",
  },
];

const getHostName = () => {
  const hostname = window.location.hostname;
  const currentEnv = envs.filter((env) => {
    return env.url === hostname;
  });

  return currentEnv[0]?.api || "";
};

export const API_BASE_URL = getHostName();

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

export const setApiToken = () => {
  api.defaults.headers.common["Authorization"] = `Bearer ${Jwt.token()}`;
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response?.status;
    if (
      status === 401  && !window.location.pathname.includes("/login") &&
      error.config.url !== "user/validate/bc"
    ) {
      localStorage.clear();
      window.location.replace("/login");
    }
    if (status === 403) {
      window.location.replace("/forbidden");
    }
    return Promise.reject(error);
  }
);

export const apiRequest = async ({ method, url, data, headers }) => {
  if (Jwt.getToken()) {
    api.defaults.headers.common["Authorization"] = `Bearer ${Jwt.getToken()}`;
  }

  let error = null;
  let response = null;
  try {
    response = await api[method.toLowerCase()](url, data, { headers });
    return [error, response ? response.data : response];
  } catch (e) {
    error = {
      error: e,
      message: e.response?.data.message,
      status: e.response?.status,
    };
    return [error, response];
  }
};
