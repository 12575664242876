import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import "./documents.css";
import Jwt from "./../../utils/jwt";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import { ReactComponent as Chevron } from "./../../assets/chevron-down.svg";
import { ReactComponent as Folder } from "./../../assets/Folder_Icon.svg";
import { ReactComponent as Drag } from "./../../assets/draggable.svg";
import { ReactComponent as Close } from "./../../assets/close.svg";
import { apiRequest, apiRoutes } from "../../services";

export default function Documents() {
  const history = useHistory();
  const routerParams = useParams();
  const headers = Jwt.getItem("all");
  const [document, setDocument] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [image, setImage] = useState(null);
  const [variables, setVariables] = useState([]);
  const [offsetX, setx] = useState(null);
  const [offsetY, sety] = useState(null);
  const [fieldsDragged, setFields] = useState([]);
  const [pvariables, setPersonalizedVariables] = useState([]);
  const [formVariables, setFormVariables] = useState([]);
  const [variablesDynamics, setVariablesDynamics] = useState([]);
  const [id, setIdElement] = useState(null);
  const [fieldId, setFieldId] = useState("");
  const [connector, setConnector] = useState("");
  const [type, setType] = useState("");
  const [name, setFieldName] = useState("");
  const imageRef = React.useRef(null);
  const [isOpen, setStatus] = useState([]);
  const [isOpenForm, setIsOpenForm] = useState([]);
  const [isOpenDynamic, setIsOpenDynamic] = useState([]);
  const [folderShowed, setFolderShowed] = useState("");
  const [formFieldsShowed, setformFieldsShowed] = useState(-1);
  const [variablesFieldsShowed, setVariablesFieldsShowed] = useState(-1);

  const PdfRef = useRef();

  // Here we get pdf vatriables on load and set them base on page you are in
  useEffect(() => {
    (async () => {
      const [error, document] = await apiRequest({
        method: "get",
        url: apiRoutes.pdfTemplate.replace(":id", routerParams.id),
      });
      if (document) {
        setDocument(document);
        const imageBase = document.imagesBase64[page];
        setImage(imageBase);
        setVariables(document.pdfTemplate.pages[page].variables);
        const newFields = document.pdfTemplate.pages.map((page) => {
          const variablesSave = page.variables
            ? page.variables.map((variable, i) => {
                return {
                  id: i,
                  x: variable.x,
                  y: variable.y,
                  fieldId: variable.fieldId,
                  connectorId: variable.connectorId,
                  fieldName: variable.fieldName,
                  connectorType: variable.connectorType,
                };
              })
            : [];
          return {
            variables: variablesSave,
          };
        });
        setFields(newFields);
      }
      if (error) {
        setError(error);
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    getPersonalizedVariables();
    getForm();
    getVariablesDynamics();
  }, []);

  function onDragOver(ev) {
    ev.preventDefault();
  }

  //function that triggers when a field starts moving from the menu to the image
  function onDragStartFromMenu(ev, field, connectorId, connectorType) {
    let id =
      fieldsDragged[page] && fieldsDragged[page].variables
        ? fieldsDragged[page].variables.length
        : 0;
    ev.dataTransfer.setData("id", id);
    const rect = ev.target.getBoundingClientRect();
    setx(ev.clientX - rect.x);
    sety(ev.clientY - rect.y);
    setIdElement(id);
    setType(connectorType);
    setFieldId(field.id.toString());
    setFieldName(field.name);
    setConnector(connectorId.toString());
  }

  //function that happens when field is drop within the image and calculate pixels to set them
  // here we verify is field already exist to update coordenates or if its a new field to add it to state
  function onDrop(ev) {
    let imageWidth = imageRef.current.width / 100;
    let imageHeight = imageRef.current.height / 100;
    const x = (ev.clientX - imageRef.current.x - 20) / imageWidth;
    const y = (ev.clientY - imageRef.current.y - 20) / imageHeight;
    const exist =
      fieldsDragged[page] &&
      fieldsDragged[page].variables &&
      fieldsDragged[page].variables.find((field) => {
        return field.id === id;
      });
    if (exist) {
      const newPosition = fieldsDragged[page].variables.map((fieldState) => {
        if (fieldState.id === id) {
          return {
            x,
            y,
            fieldId,
            id,
            fieldName: fieldState.fieldName,
            connectorId: connector,
            connectorType: type,
          };
        } else {
          return fieldState;
        }
      });
      let newPages = [...fieldsDragged];
      newPages[page].variables = newPosition;
      setFields(newPages);
    } else if (fieldsDragged[page]) {
      let newPages = [...fieldsDragged];
      newPages[page].variables = newPages[page].variables
        ? newPages[page].variables
        : [];
      newPages[page].variables[id] = {
        x,
        y,
        fieldId,
        id,
        fieldName: name,
        connectorId: connector,
        connectorType: type,
      };
      setFields(newPages);
    } else {
      let newPages = [...fieldsDragged];
      newPages[page] = {
        ...{
          variables: [
            {
              x,
              y,
              fieldId,
              id,
              fieldName: name,
              connectorId: connector,
              connectorType: type,
            },
          ],
        },
      };
      setFields(newPages);
    }
  }

  const getPersonalizedVariables = async () => {
    const [error, pvariables] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getConnectorsDocument}?typeConnector=VPR`,
    });
    if (pvariables) {
      setPersonalizedVariables(pvariables);
    }
    if (error) {
      setError(true);
    }
  };

  const getForm = async () => {
    const [error, formVariables] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getConnectorsDocument}?typeConnector=FORM`,
    });
    if (formVariables) {
      setFormVariables(formVariables);
    }
    if (error) {
      setError(true);
    }
  };

  const getVariablesDynamics = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getConnectorsDocument}?typeConnector=VP`,
    });
    if (data) {
      setVariablesDynamics(data);
    }
    if (error) {
      setError(true);
    }
  };

  const updateDocument = async () => {
    const [error, update] = await apiRequest({
      method: "put",
      url: apiRoutes.editPdfTemplate.replace(":id", routerParams.id),
      data: document,
      headers: {
        company_code: "demo",
        api_key: headers.sessionId,
      },
    });
    if (error) {
      setError(true);
    } else {
      history.push(`/connectors/documents`);
    }
  };

  function onSave() {
    const newDocument = Object.assign(document);
    fieldsDragged.map((pageWithFields, index) => {
      let variables = pageWithFields.variables.length
        ? pageWithFields.variables
        : null;
      newDocument.pdfTemplate.pages[index].variables = variables;
    });
    delete newDocument.imagesBase64;
    setDocument(newDocument);
    updateDocument();
  }

  function onCancel() {
    history.push(`/connectors/documents/edit/${routerParams.id}`);
  }

  function onChangePage(image, i) {
    setPage(i);
    setImage(image);
  }

  function removeField(ev, fieldToRemove) {
    let filterFields = fieldsDragged[page].variables.filter((field) => {
      return field.id !== fieldToRemove.id;
    });
    let newFields = filterFields.map((field, index) => {
      return { ...field, id: index };
    });
    let newPages = [...fieldsDragged];
    newPages[page] = { ...{ variables: [...newFields] } };
    setFields(newPages);
  }

  useEffect(() => {}, [fieldsDragged]);

  const handleShowFields = (selected) => {
    setVariablesFieldsShowed(-1);
    if (formFieldsShowed !== selected) {
      setformFieldsShowed(selected);
    } else {
      setformFieldsShowed(-1);
    }
  };

  const handleFormShowFields = (selected) => {
    if (!!isOpenForm.length) {
      let newStatus = [...isOpenForm];
      if (isOpenForm[selected] === true) {
        newStatus[selected] = false;
        setIsOpenForm(newStatus);
      } else {
        newStatus[selected] = true;
        setIsOpenForm(newStatus);
      }
    } else {
      setIsOpenForm([true]);
    }
  };

  const handleDynamicShowFields = (selected) => {
    if (!!isOpenDynamic.length) {
      let newStatus = [...isOpenDynamic];
      if (isOpenDynamic[selected] === true) {
        newStatus[selected] = false;
        setIsOpenDynamic(newStatus);
      } else {
        newStatus[selected] = true;
        setIsOpenDynamic(newStatus);
      }
    } else {
      setIsOpenDynamic([true]);
    }
  };

  const handleVariablesShowFields = (selected) => {
    if (!!isOpen.length) {
      let newStatus = [...isOpen];
      if (isOpen[selected] === true) {
        newStatus[selected] = false;
        setStatus(newStatus);
      } else {
        newStatus[selected] = true;
        setStatus(newStatus);
      }
    } else {
      setStatus([true]);
    }
  };

  const handleFieldListClick = (selected) => {
    setVariablesFieldsShowed(-1);
    setformFieldsShowed(-1);
    if (folderShowed !== selected) {
      setFolderShowed(selected);
    } else {
      setFolderShowed("");
    }
  };

  //function that triggers when a field starts moving within the image
  let pointerInBoxX = 0;
  let pointerInBoxY = 0;
  function onDragStart(ev) {
    ev.target.closest(".field-image").classList.add("dragging");
    pointerInBoxX =
      ev.pageX - ev.target.closest(".field-image").getBoundingClientRect().x;
    pointerInBoxY =
      ev.pageY - ev.target.closest(".field-image").getBoundingClientRect().y;
  }

  const onDragEnd = (e) => {
    if (PdfRef.current.querySelector(".dragging")) {
      const coordsX = parseFloat(
        PdfRef.current.querySelector(".dragging").style.left
      );
      const coordsY = parseFloat(
        PdfRef.current.querySelector(".dragging").style.top
      );
      let variables = [...fieldsDragged];
      let itemIndex = Array.from(
        PdfRef.current.querySelectorAll(".field-image")
      ).findIndex((element) => element.classList.contains("dragging"));
      // let itemIndex = variables[page].variables.findIndex(
      //   (item) => item.id === variables[page].variables[index].id
      // );
      variables[page].variables[itemIndex].x = coordsX;
      variables[page].variables[itemIndex].y = coordsY;
      setFields(variables);
      PdfRef.current.querySelectorAll(".field-image").forEach((item) => {
        item.classList.remove("dragging");
      });
      pointerInBoxX = 0;
    }
  };

  // const onDrag = (e) => {
  //   if (e.target.closest(".field-image").classList.contains("dragging")) {
  //     const posInPdfX = e.pageX - PdfRef.current.getBoundingClientRect().x;
  //     const posInPdfY = e.pageY - PdfRef.current.getBoundingClientRect().y;
  //     // console.log(posInPdfX, pointerInBoxX);
  //     e.target.closest(".field-image").style.left = `${
  //       posInPdfX - pointerInBoxX
  //     }px`;
  //     e.target.closest(".field-image").style.top = `${
  //       posInPdfY - pointerInBoxY
  //     }px`;
  //   }
  // };
  const onDrag = (e) => {
    if (PdfRef.current && PdfRef.current.querySelector(".dragging")) {
      const posInPdfX = e.pageX - PdfRef.current.getBoundingClientRect().x;
      const posInPdfY = e.pageY - PdfRef.current.getBoundingClientRect().y;

      const itemPosX =
        ((posInPdfX - pointerInBoxX) * 100) /
        PdfRef.current.getBoundingClientRect().width;
      const itemPosY =
        ((posInPdfY - pointerInBoxY) * 100) /
        PdfRef.current.getBoundingClientRect().height;
      const itemWidth =
        (PdfRef.current.querySelector(".dragging").getBoundingClientRect()
          .width *
          100) /
        PdfRef.current.getBoundingClientRect().width;
      const itemHeight =
        (PdfRef.current.querySelector(".dragging").getBoundingClientRect()
          .height *
          100) /
        PdfRef.current.getBoundingClientRect().height;
      if (
        itemPosX >= 0 &&
        itemPosX + itemWidth <= 100 &&
        itemPosY >= 0 &&
        itemPosY + itemHeight <= 100 &&
        e.pageX >= PdfRef.current.getBoundingClientRect().x &&
        e.pageY >= PdfRef.current.getBoundingClientRect().y
      ) {
        PdfRef.current.querySelector(".dragging").style.left = `${itemPosX}%`;
        PdfRef.current.querySelector(".dragging").style.top = `${itemPosY}%`;
      } else {
        onDragEnd();
      }
    }
  };

  return (
    <div
      className="documents-content"
      onMouseUp={(e) => onDragEnd(e)}
      onMouseMove={onDrag}
    >
      <div className="side-menu-documents">
        <div className="logo-menu-container">
          <h3>Documentos</h3>
          <hr></hr>
        </div>
        <div className="variable-container">
          <ul className="variable-list">
            <li
              className={`field-list${
                folderShowed === "variables" ? " open" : ""
              }`}
            >
              <div
                className="label-content header"
                onClick={() => handleFieldListClick("variables")}
              >
                <Folder />
                <span className="label">Variables Personalizadas</span>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
                  <path
                    d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                    strokeWidth="1.75"
                  />
                </svg>
              </div>
              <div className="fields-list-container">
                {!!pvariables.length &&
                  pvariables.map((variable, i) => {
                    return (
                      <div
                        className="fields-content-container"
                        key={`fields-container-pvariables-item-${i}`}
                      >
                        <div
                          className={`label-content ${
                            !!isOpen.length && isOpen[i] === true ? "open" : ""
                          }`}
                          onClick={() => handleVariablesShowFields(i)}
                        >
                          <span className="label">{variable.name}</span>{" "}
                          <svg
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                          >
                            <path
                              d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                              strokeWidth="1.75"
                            />
                          </svg>
                        </div>
                        <div
                          className={`fields-container ${
                            !!isOpen.length && isOpen[i] === true ? "open" : ""
                          }`}
                        >
                          {variable.fields.map((field, i) => {
                            return (
                              <div
                                className="field-item-container"
                                key={`field-item-container-1-${field.id}-${i}`}
                              >
                                <span
                                  className="field"
                                  draggable
                                  onDragStart={(e) =>
                                    onDragStartFromMenu(
                                      e,
                                      field,
                                      variable.id,
                                      variable.connectorType
                                    )
                                  }
                                >
                                  {`${field.name}`}
                                  <Drag />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </li>
            <li
              className={`field-list${
                folderShowed === "dynamics" ? " open" : ""
              }`}
            >
              <div
                className="label-content header"
                onClick={() => handleFieldListClick("dynamics")}
              >
                <Folder />
                <span className="label">Variables Dinámicas</span>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
                  <path
                    d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                    strokeWidth="1.75"
                  />
                </svg>
              </div>
              <div className="fields-list-container">
                {!!variablesDynamics.length &&
                  variablesDynamics.map((variable, i) => {
                    return (
                      <div
                        className="fields-content-container"
                        key={`fields-content-container-item-variablesDynamics-${i}`}
                      >
                        <div
                          className={`label-content ${
                            !!isOpenDynamic.length && isOpenDynamic[i] === true
                              ? "open"
                              : ""
                          }`}
                          onClick={() => handleDynamicShowFields(i)}
                        >
                          <span className="label">{variable.name}</span>{" "}
                          <svg
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                          >
                            <path
                              d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                              strokeWidth="1.75"
                            />
                          </svg>
                        </div>
                        <div
                          className={`fields-container ${
                            !!isOpenDynamic.length && isOpenDynamic[i] === true
                              ? "open"
                              : ""
                          }`}
                        >
                          {variable.fields.map((field, i) => {
                            return (
                              <div
                                className="field-item-container"
                                key={`field-item-container-2-${field.id}-${i}`}
                              >
                                <span
                                  className="field"
                                  draggable
                                  onDragStart={(e) =>
                                    onDragStartFromMenu(
                                      e,
                                      field,
                                      variable.id,
                                      variable.connectorType
                                    )
                                  }
                                >
                                  {`${field.name}`}
                                  <Drag />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </li>
            <li
              className={`field-list${folderShowed === "forms" ? " open" : ""}`}
            >
              <div
                className="label-content header"
                onClick={() => handleFieldListClick("forms")}
              >
                <Folder />
                <span className="label">Formularios</span>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none">
                  <path
                    d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                    strokeWidth="1.75"
                  />
                </svg>
              </div>
              <div className="fields-list-container">
                {!!formVariables.length &&
                  formVariables.map((variable, i) => {
                    return (
                      <div
                        className="fields-content-container"
                        key={`fields-content-container-formVariables-${i}`}
                      >
                        <div
                          className={`label-content ${
                            !!isOpenForm.length && isOpenForm[i] === true
                              ? "open"
                              : ""
                          }`}
                          onClick={() => handleFormShowFields(i)}
                        >
                          <span className="label">{variable.name}</span>{" "}
                          <svg
                            width="10"
                            height="7"
                            viewBox="0 0 10 7"
                            fill="none"
                          >
                            <path
                              d="M8.98129 1.01904L8.45215 1.51904L4.97727 4.99392L1.5024 1.51904L0.99353 1.01904"
                              strokeWidth="1.75"
                            />
                          </svg>
                        </div>
                        <div
                          className={`fields-container ${
                            !!isOpenForm.length && isOpenForm[i] === true
                              ? "open"
                              : ""
                          }`}
                        >
                          {variable.fields &&
                            variable.fields.map((field, j) => {
                              return (
                                <div
                                  className="field-item-container"
                                  key={`field-item-container-3-${field.id}-${i}-${j}`}
                                >
                                  <span
                                    className="field"
                                    draggable
                                    onDragStart={(e) =>
                                      onDragStartFromMenu(
                                        e,
                                        field,
                                        variable.id,
                                        variable.connectorType
                                      )
                                    }
                                  >
                                    {`${field.name}`}
                                    <Drag />
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </li>
          </ul>
          <ButtonGroup className="save-button">
            <Button
              style="secondary"
              text="Cancelar"
              onClick={onCancel}
            ></Button>
            <Button style="primary" text="Guardar" onClick={onSave}></Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="image-content">
        {document.imagesBase64 && (
          <div style={{ position: "relative" }} ref={PdfRef}>
            <img
              ref={imageRef}
              onDrop={(e) => onDrop(e)}
              onDragOver={(e) => onDragOver(e)}
              className="pdf-image"
              alt="pdf-img"
              src={`data:image/jpeg;base64,${image}`}
            ></img>
            {!!fieldsDragged.length &&
              fieldsDragged[page] &&
              fieldsDragged[page].variables?.map((field, i) => {
                return (
                  <div
                    className="field-image"
                    style={{
                      position: `absolute`,
                      top: `${field.y}%`,
                      left: `${field.x}%`,
                    }}
                    key={`field-image-${i}`}
                  >
                    <div className="field-header">
                      <span
                        className="drag-trigger action"
                        onMouseDown={onDragStart}
                        // onMouseMove={onDrag}
                      >
                        <Drag />
                      </span>
                      <span
                        className="delete-button action"
                        onClick={(e) => removeField(e, field)}
                      >
                        <Close key={`${field.id}-4-${Date.now() * 4}`} />
                      </span>
                    </div>
                    <span className="field-label">{field.fieldName}</span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className="images-content">
        {document.imagesBase64 &&
          document.imagesBase64.map((image, i) => {
            return (
              <>
                <img
                  className="image-list"
                  onClick={() => onChangePage(image, i)}
                  alt="pdf-img"
                  src={`data:image/jpeg;base64,${image}`}
                  key={`image-list-${i}`}
                ></img>
                <div className="page-number">{i + 1}</div>
              </>
            );
          })}
      </div>
    </div>
  );
}
