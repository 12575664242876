import { apiRequest, apiRoutes } from "../../../../services";

const saveVariables = async ({
  files,
  variables,
  name,
  example,
  routeParams,
}) => {
  const js_files = files.map((file) => ({
    ...file,
    data: btoa(unescape(encodeURIComponent(file.data))),
  }));
  const projectVariables = variables.map((variable) => ({
    ...variable,
    id: parseInt(variable.id),
  }));

  const payload = {
    name,
    example: btoa(unescape(encodeURIComponent(example))),
    js_files,
    variables: projectVariables,
  };

  if (routeParams.id) payload.id = parseInt(routeParams.id);

  let results = [];
  let requestError = null;
  let projectId = null;

  const [error, data] = await apiRequest({
    data: payload,
    method: routeParams.id ? "put" : "post",
    url: apiRoutes.editConnector.replace(":connector", "VP").replace(":id", routeParams.id),
  });
  if (data) {
    if (data.idConnector) {
      projectId = data.idConnector;
    }
    if (data.results) {
      results = Object.keys(data.results).map((id) => {
        const variable = variables.find(
          (variable) => parseInt(id) === parseInt(variable.id)
        );
        return variable !== undefined
          ? `${variable.name}: ${data.results[parseInt(id)]}`
          : "Error: Variable not found";
      });
    }
  }
  if (error) {
    if (error.error.response.data.js_error) {
      results = [error.error.response.data.js_error];
    } else if (error.error.response.data.error) {
      requestError = error.error.response.data.error;
    } else {
      results = ["Error: Ha ocurrido un error inesperado."];
    }
  }
  return { requestError, results, idConnector: projectId };
};

export default saveVariables;
