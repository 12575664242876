import React from "react";

import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Button from "@bit/kiban-design-system.kiban.button";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";

const ConfigBuroMX = ({
  dataConfig,
  handleOnChangeConfig,
  errorFields,
  saveConfig,
}) => {
  return (
    <PageSection>
      {dataConfig.BuroCredito && (
        <>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Buró de Crédito</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="buro-fisica"
                  value={true}
                  checked={dataConfig.BuroCredito.PersonaFisica.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "BuroCredito",
                      "PersonaFisica",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="buro-fisica"
                  value={false}
                  checked={!dataConfig.BuroCredito.PersonaFisica.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "BuroCredito",
                      "PersonaFisica",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">
                Activar Buró de Crédito Personas Morales
              </Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="buro-moral"
                  value={true}
                  checked={dataConfig.BuroCredito.PersonaMoral.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "BuroCredito",
                      "PersonaMoral",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="buro-moral"
                  value={false}
                  checked={!dataConfig.BuroCredito.PersonaMoral.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "BuroCredito",
                      "PersonaMoral",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            {dataConfig.BuroCredito.PersonaMoral.Activated && (
              <TextField
                error={errorFields.buroCredito?.PersonaMoral?.CodigoInstitucion}
                errorText={
                  !dataConfig.BuroCredito.PersonaMoral.CodigoInstitucion ? 
                    "El campo no debe ir vacio"
                  : "El código debe ser de 4 dígitos"
                }
                label="Código Institución"
                value={dataConfig.BuroCredito.PersonaMoral.CodigoInstitucion}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "BuroCredito",
                    "PersonaMoral",
                    "CodigoInstitucion",
                    e.target.value
                  )
                }
              />
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Círculo de Crédito</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="circulo-fisica"
                  value={true}
                  checked={dataConfig.CirculoCredito.PersonaFisica.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "PersonaFisica",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="circulo-fisica"
                  value={false}
                  checked={!dataConfig.CirculoCredito.PersonaFisica.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "PersonaFisica",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            {dataConfig.CirculoCredito.PersonaFisica.Activated && (
              <TextField
                type="number"
                error={errorFields.circuloCredito?.PersonaFisica?.ClaveOtorgante}
                errorText={
                  !dataConfig.CirculoCredito.PersonaFisica.ClaveOtorgante ?
                    "El campo no debe ir vacio" :
                    "La clave debe tener de 6 a 10 dígitos"
                }
                label="Clave Otorgante"
                value={dataConfig.CirculoCredito.PersonaFisica.ClaveOtorgante}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "PersonaFisica",
                    "ClaveOtorgante",
                    e.target.value
                  )
                }
              />
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">
                Activar Círculo de Crédito Personas Morales
              </Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="circulo-moral"
                  value={true}
                  checked={dataConfig.CirculoCredito.PersonaMoral.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "PersonaMoral",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="circulo-moral"
                  value={false}
                  checked={!dataConfig.CirculoCredito.PersonaMoral.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "PersonaMoral",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            {dataConfig.CirculoCredito.PersonaMoral.Activated && (
              <TextField
                type="number"
                error={errorFields.circuloCredito?.PersonaMoral?.CodigoInstitucion}
                errorText={
                  !dataConfig.CirculoCredito.PersonaMoral.CodigoInstitucion ?
                    "El campo no debe ir vacio" :
                    "El código debe ser de 4 dígitos"
                }
                label="Código institución"
                value={dataConfig.CirculoCredito.PersonaMoral.CodigoInstitucion}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "PersonaMoral",
                    "CodigoInstitucion",
                    e.target.value
                  )
                }
              />
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Círculo de Crédito PLD Check</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="circulo-pld"
                  value={true}
                  checked={dataConfig.CirculoCredito.Pld.ActivatedCCPldCheck}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "Pld",
                      "ActivatedCCPldCheck",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="circulo-pld"
                  value={false}
                  checked={!dataConfig.CirculoCredito.Pld.ActivatedCCPldCheck}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "Pld",
                      "ActivatedCCPldCheck",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Círculo de Crédito LAE</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="circulo-lae"
                  value={true}
                  checked={dataConfig.CirculoCredito.Lae.ActivatedCCLae}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "Lae",
                      "ActivatedCCLae",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="circulo-lae"
                  value={false}
                  checked={!dataConfig.CirculoCredito.Lae.ActivatedCCLae}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "CirculoCredito",
                      "Lae",
                      "ActivatedCCLae",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
          </PageSection.Item>
          <PageSection.Item size={1} length={2}/>
          {(dataConfig.CirculoCredito.Pld.ActivatedCCPldCheck || dataConfig.CirculoCredito.Lae.ActivatedCCLae) && (
            <div>
              <TextField
                error={errorFields.circuloCredito?.Pld?.ConsumerKey}
                errorText={"El campo no debe ir vacio"}
                label="Llave del cliente (Consumer key)"
                type="password"
                value={dataConfig.CirculoCredito?.Pld.ConsumerKey}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "Pld",
                    "ConsumerKey",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.circuloCredito?.Pld?.User}
                errorText={"El campo no debe ir vacio"}
                label="User"
                type="text"
                value={dataConfig.CirculoCredito.Pld.User}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "Pld",
                    "User",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.circuloCredito?.Pld?.Password}
                errorText={"El campo no debe ir vacio"}
                label="Contraseña"
                type="password"
                value={dataConfig.CirculoCredito.Pld.Password}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "Pld",
                    "Password",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.circuloCredito?.Pld?.Signature}
                errorText={"El campo no debe ir vacio"}
                label="Firma llave privada (x-signature)"
                type="password"
                value={dataConfig.CirculoCredito.Pld.Signature}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "CirculoCredito",
                    "Pld",
                    "Signature",
                    e.target.value
                  )
                }
              />
            </div>
          )}
          <PageSection.Item size={1} length={2} />
        </>
      )}
      <PageSection.Item size={1} length={1}>
        <Button onClick={saveConfig} style="primary" text="Guardar" />
      </PageSection.Item>
    </PageSection>
  );
};

export default ConfigBuroMX;
