import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AnnotatedLayout from "@bit/kiban-design-system.kiban.annotated-layout";
import Button from "@bit/kiban-design-system.kiban.button";
import Checkbox from "@bit/kiban-design-system.kiban.checkbox";
import LoadingBar from "@bit/kiban-design-system.kiban.loading-bar";
import NipField from "@bit/kiban-design-system.kiban.nip";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import StepsBar from "@bit/kiban-design-system.kiban.steps-bar";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../services";
import AlertTexts from "../../utils/alerts-texts";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";

import "./Rainao.css";

const Nip = ({
  currentStep,
  getStatusRainao,
  steps,
  sentMean,
  razonSocial,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorNip, setErrorNip] = useState(false);
  const [accept, setAccept] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(-1);
  const [isLastStep, setIsLastStep] = useState(false);
  const params = useParams();
  const { addAlert } = useAlert();
  const messagesSteps = {
    WAITING_FIRST_VERIFICATION: [
      "Ingrese el NIP (4 caracteres) obtenido via correo electrónico a la dirección {{sentMean}} para validar su cuenta",
    ],
    WAITING_SECOND_VERIFICATION: [
      "Ingrese el NIP (4 caracteres) obtenido via mensaje al número {{sentMean}} para validar su cuenta",
      "Por este conducto autorizo expresamente a {{razonSocial}} para que por conducto de sus funcionarios facultados lleve a cabo investigaciones, sobre mi comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente.",
      "Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que  {{razonSocial}}  hará de tal información y de que ésta podrá realizar consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos relación jurídica.",
      "Autorizo a Nkonect SA de CV para que solicite a Trans Unión de México, S. A. S. I. C. mi Reporte de Crédito Especial (RCE) a fin de conocer la condición en que se encuentra mi historial crediticio con Entidades Financieras y/o Empresas Comerciales, de acuerdo con lo establecido en el artículo 40 de la Ley para Regular a las Sociedades de Información Crediticia. Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará. Bajo protesta de decir verdad manifiesto que los datos que menciono en esta solicitud son verídicos.",
    ],
  };

  useEffect(() => {
    let interval = null;
    if (isLastStep && loadingPercentage >= 0) {
      const increment = loadingPercentage + 5;
      if (increment < 100) {
        interval = setInterval(() => {
          setLoadingPercentage(increment);
        }, 1000);
      } else if (loadingPercentage < 100) {
        setLoadingPercentage(100);
      }
    }
    return () => clearInterval(interval);
  }, [isLastStep, loadingPercentage]);

  const updateStatus = async (code) => {
    setIsLoading(true);
    let dataSend = {
      idUnykoo: parseInt(params.id),
    };
    if (code) {
      dataSend = { ...dataSend, nip: code.join("") };
    }
    setIsLastStep(currentStep === "WAITING_SECOND_VERIFICATION");
    setLoadingPercentage(0);
    const response = await apiRequest({
      method: "patch",
      url: apiRoutes.updateStatusRainao.replace(
        "{step}",
        currentStep.replace("WAITING_", "")
      ),
      data: dataSend,
    });
    if (!response[0]) {
      if (currentStep === "WAITING_SECOND_VERIFICATION") {
        const cryptId = Jwt.signToken({ id: params.id });
        window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
      } else {
        getStatusRainao();
      }
    } else {
      if (currentStep.includes("VERIFICATION")) {
        setErrorNip(true);
      } else {
        addAlert({
          code: "danger",
          message: AlertTexts.tecnicalError,
          duration: 5000,
        });
      }
    }
    if (currentStep !== "WAITING_SECOND_VERIFICATION" || response[0]) {
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    setErrorNip(false);
    const response = await apiRequest({
      method: "patch",
      url: apiRoutes.updateStatusRainao.replace(
        "{step}",
        "RESEND_VERIFICATION"
      ),
      data: { idUnykoo: parseInt(params.id) },
    });
    addAlert({
      duration: 5000,
      code: !response[0] ? "success" : "danger",
      message: !response[0]
        ? "Se envío correctamente el código"
        : "No se pudo reenviar el código, intenta otra vez",
    });
    getStatusRainao();
  };

  const onChangeTerms = () => {
    setAccept(!accept);
  };

  const views = {
    WAITING_ACCEPT_TERMS_AND_CONDITIONS: {
      title: "Términos y Condiciones",
      component: (
        <div className="createAccount">
          <Checkbox
            label={
              <p>
                Acepto{" "}
                <a href={"https://www.rainao.com/"} target="_blank">
                  términos y condiciones
                </a>
                {", "}
                el uso de medios electrónicos de autenticación, como Número de
                Identificación Personal (NIP) y creo mi cuenta.
              </p>
            }
            checked={accept}
            onChange={onChangeTerms}
          />
          <Button
            disabled={!accept}
            style="primary"
            text="Crear Cuenta"
            onClick={() => updateStatus()}
          />
        </div>
      ),
    },
    WAITING_FIRST_VERIFICATION: {
      title: "Primera verificación",
      component: (
        <div className="validateAccount">
          <NipField
            error={errorNip}
            messageError={"El código es inválido"}
            primaryAction={{
              label: "Validar Nip",
              action: (code) => updateStatus(code),
            }}
            secondaryAction={{
              label: "Reenviar NIP",
              action: () => resendCode(),
            }}
          />
        </div>
      ),
    },
    WAITING_SECOND_VERIFICATION: {
      title: "Segunda verificación",
      component: (
        <NipField
          error={errorNip}
          messageError={"El código es inválido"}
          primaryAction={{
            label: "Validar Nip",
            action: (code) => updateStatus(code),
          }}
          secondaryAction={{
            label: "Reenviar NIP",
            action: () => resendCode(),
          }}
        />
      ),
    },
  };

  return isLoading ? (
    isLastStep ? (
      <LoadingBar isFixed={false} size={loadingPercentage} />
    ) : (
      <Skeleton />
    )
  ) : (
    <div className="main-content" id="AuthNip">
      <Title>Autenticar cuenta</Title>
      {steps && <StepsBar steps={steps} />}
      <AnnotatedLayout
        title={views[currentStep].title}
        description={
          messagesSteps.hasOwnProperty(currentStep)
            ? messagesSteps[currentStep].map((msg) =>
                msg
                  .replace("{{razonSocial}}", razonSocial)
                  .replace("{{sentMean}}", sentMean)
              )
            : []
        }
      >
        {views[currentStep].component}
      </AnnotatedLayout>
    </div>
  );
};

export default Nip;
