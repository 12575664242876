import React from "react";
import { useParams } from "react-router-dom";

import AddEditDocument from "./Documents/AddEditDocument";
import AddEditCustomVariables from "../CustomVariables/AddEditCustomVariables";
import CleanData from "./CleanData/AddEditCleanData";
import AddEditScore from "./Scores/AddEditScore";
import API from "./API/API";
import Payments from "./Payments/AddEditSTP";
import AddEditKyc from "./Kyc/AddEditKyc";
import AddEditDigitalSign from "./DigitalSign/AddEditDigitalSign";
import AddEditOpenBanking from "./OpenBanking/AddEditOpenBanking";
import AddEditMessaging from "./Messaging/AddEditMessaging";
import AddEditBuro from "./Buro/AddEditBuro";
import AddEditForms from "./Forms/AddEditForm";
import AddEditCreditPolicies from "./CreditPolicies/AddEditCreditPolicies";
import AddEditNip from "./NIP/AddEditNip";
import AddEditRainao from "./Rainao/AddEditRainao";
import AddEditTimer from "./Timer/AddEditTimer";

const EditConnectors = () => {
  const routeParams = useParams();
  switch (routeParams.connectorType) {
    case "documents":
      return <AddEditDocument />;
    case "vp":
      return <AddEditCustomVariables />;
    case "api":
      return <API />;
    case "cleandata":
      return <CleanData />;
    case "score":
      return <AddEditScore />;
    case "payments":
      return <Payments />;
    case "identity":
      return <AddEditKyc />;
    case "digitalsign":
      return <AddEditDigitalSign />;
    case "openbanking":
      return <AddEditOpenBanking />;
    case "messaging":
      return <AddEditMessaging />;
    case "buro":
      return <AddEditBuro />;
    case "forms":
      return <AddEditForms />;
    case "credit-policies":
      return <AddEditCreditPolicies />;
    case "nip":
      return <AddEditNip />;
    case "rainao":
      return <AddEditRainao />;
    case "timer":
      return <AddEditTimer />;
    default:
      return <></>;
  }
};

export default EditConnectors;
