import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import alertsTexts from "../../../utils/alerts-texts";

import GenericNIP from "./GenericNIP";
import SIC from "./SIC";
import {
  parseOptions,
  getTypeServices,
  getMessagingConnectors,
  saveConnector,
  isValidForm,
  getConnector,
  getNipMeansCatalog,
} from "./utils";

import Paths from "../../../paths";

const AddEditNip = () => {
  const [_, setTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [messagingTypes, setMessagingTypes] = useState([]);
  const [connectors, setConnectors] = useState({});
  const [name, setName] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [firstVerificationData, setFirstVerificationData] = useState({});
  const [secondVerificationData, setSecondVerificationData] = useState({});
  const [sicData, setSicData] = useState({});
  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    service: false,
    nipGeneric: {
      firstVerification: {
        mean: false,
        meanConnector: false,
        meanBackUp: {
          mean: false,
          meanConnector: false,
          attempts: false,
        },
      },
      secondVerification: {
        mean: false,
        meanConnector: false,
        meanBackUp: {
          mean: false,
          meanConnector: false,
          attempts: false,
        },
      },
    },
    sic: {
      urlTermsConditions: false,
      messagingSicConnector: false,
      emailSicConnector: false,
      personType: false,
    },
  });

  const { addAlert } = useAlert();
  const history = useHistory();
  const routeParams = useParams();

  const getTypesAndServices = async (connectorType) => {
    const { error, types } = await getTypeServices({
      connectorType,
    });
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    } else {
      const { parsedTypes, parsedServices } = parseOptions(types);
      if (connectorType === "NIP") {
        setTypes(parsedTypes);
        setServices(parsedServices);
      }
    }
  };

  const getMeans = async () => {
    const { error, meansCatalog } = await getNipMeansCatalog();
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    } else {
      setMessagingTypes(meansCatalog);
    }
  };

  const handleSave = async () => {
    const connectorData = {
      name,
      service: selectedService,
    };
    if (selectedService === "NIP_SIC") {
      connectorData.nipSic = sicData;
    }
    if (selectedService === "NIP_GEN") {
      connectorData.nipGeneric = {
        firstVerification: {
          ...firstVerificationData,
          hasBackUp: firstVerificationData.hasBackUp || false,
        },
        secondVerification: {
          ...secondVerificationData,
          hasBackUp: secondVerificationData.hasBackUp || false,
        },
      };
    }
    const res = await saveConnector(
      connectorData,
      routeParams.id || null,
      (error) => {
        addAlert({
          code: "danger",
          message: error.message || alertsTexts.unexpectedError,
          duration: 5000,
        });
      }
    );
    setIsLoading(false);
    if (res !== null) {
      addAlert({
        code: "success",
        message: alertsTexts.success,
        duration: 5000,
      });
      if (!routeParams.id)
        history.push(
          Paths.EditConnectors.replace(":connectorType", "nip").replace(
            ":id",
            res.id
          )
        );
    }
  };

  const getConnectorData = async () => {
    const data = await getConnector(routeParams.id, (error) => {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    });
    if (data) {
      setName(data.name);
      setSelectedService(data.service);
      if (data.service === "NIP_SIC") {
        setSicData(data.nipSic);
      }
      if (data.service === "NIP_GEN") {
        setFirstVerificationData(data.nipGeneric.firstVerification);
        setSecondVerificationData(data.nipGeneric.secondVerification);
      }
    }
  };

  const getMessagingConnectorsCatalog = async () => {
    const { error, connectors } = await getMessagingConnectors();
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    } else {
      setConnectors(connectors);
    }
  };

  const getAllData = async () => {
    await Promise.all([
      getTypesAndServices("NIP"),
      getMeans(),
      getMessagingConnectorsCatalog(),
    ]);
    if (routeParams.id) {
      await getConnectorData();
    }
    setIsPreloading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (selectedService === "NIP_SIC") {
      setFirstVerificationData({});
      setSecondVerificationData({});
    }
  }, [selectedService]);

  const pageActions = [
    {
      text: "Guardar",
      loading: isLoading,
      disabled: isLoading || isPreloading,
      onAction: () => {
        if (
          isValidForm({
            errors,
            name,
            selectedService,
            firstVerificationData,
            secondVerificationData,
            sicData,
            onValidate: setErrors,
          })
        ) {
          setIsLoading(true);
          handleSave();
        }
      },
    },
    {
      text: "Cancelar",
      disabled: isLoading || isPreloading,
      onAction: () =>
        history.push(Paths.Connectors.replace(":connectorType", "nip")),
    },
  ];

  return (
    <div className="main-content" id="NIPConnector">
      {isPreloading ? (
        <Skeleton />
      ) : (
        <>
          <PageSection
            title="NIP"
            primaryAction={pageActions[0]}
            secondaryActions={[pageActions[1]]}
          >
            <PageSection.Item title="Nombre" size={1} length={3}>
              <TextField
                placeholder="Nombra tu NIP"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errors.name}
                errorText="El nombre es requerido"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item title="Tipo" size={1} length={3}>
              <SelectField
                placeholder="Escoge un tipo de NIP"
                value={selectedService}
                onChange={setSelectedService}
                options={services.NIP}
                error={errors.service}
                errorText="Debes elegir un tipo de NIP"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {selectedService === "NIP_SIC" && (
              <SIC
                data={sicData}
                onChange={setSicData}
                connectorsCatalog={connectors}
                errors={errors.sic}
                isLoading={isLoading}
              />
            )}
          </PageSection>
          {selectedService === "NIP_GEN" && (
            <GenericNIP
              messagingCatalog={messagingTypes}
              connectorsCatalog={connectors}
              firstVerificationData={firstVerificationData}
              secondVerificationData={secondVerificationData}
              onChangteFirstVerification={setFirstVerificationData}
              onChangteSecondVerification={setSecondVerificationData}
              firstVerificationErrors={errors.nipGeneric.firstVerification}
              secondVerificationErrors={errors.nipGeneric.secondVerification}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddEditNip;
