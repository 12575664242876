import React, { useState } from "react";

import Button from "@bit/kiban-design-system.kiban.button";
import Popover from "@bit/kiban-design-system.kiban.popover";
import ActionList from "@bit/kiban-design-system.kiban.action-list";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import { MoreVerticalIcon } from "@bit/kiban-design-system.kiban.icons";

const HeaderActions = ({ primaryAction, secondaryActions }) => {
  const [openSecondaryActions, setOpenSecondaryActions] = useState(false);

  const toggleSecondaryActionsPopover = () => {
    setOpenSecondaryActions(!openSecondaryActions);
  };

  const secondaryActionsActivator = (
    <IconButton
      icon={MoreVerticalIcon}
      style="secondary"
      onClick={toggleSecondaryActionsPopover}
    />
  );
  return (
    <div className="Panel_Actions_Container">
      {secondaryActions && (
        <Popover
          activator={secondaryActionsActivator}
          active={openSecondaryActions}
          position="right"
          onClose={toggleSecondaryActionsPopover}
        >
          {
            <ActionList
              onActionAnyItem={toggleSecondaryActionsPopover}
              items={secondaryActions}
            />
          }
        </Popover>
      )}
      {primaryAction && (
        <Button
          text={primaryAction.text}
          icon={primaryAction.icon}
          style="light"
          onClick={primaryAction.onAction}
          disabled={primaryAction.disabled}
          loading={primaryAction.loading}
        />
      )}
    </div>
  );
};

export default HeaderActions;
