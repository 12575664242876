import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Button from "@bit/kiban-design-system.kiban.button";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import AlertTexts from "../../../utils/alerts-texts";
import { apiRequest, apiRoutes } from "../../../services";
import connectorTypes from "../connectorsTypes";

import { STP, SPEI } from "./Config";

const Config = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataConfig, setDataConfig] = useState(null);
  const [errors, setErrors] = useState({
    Stp: {
      NombreEmpresa: false,
      NombreOrdenante: false,
      CuentaOrdenante: false,
      Rfc: false,
    },
    Spei: {
      NombreOrdenante: false,
      CuentaOrdenante: false,
    },
  });
  const [isStp, setIsStp] = useState(false);

  const routeParams = useParams();
  const { addAlert } = useAlert();

  const showAlert = (code, text) => {
    addAlert({
      code,
      message: text,
      duration: 5000,
    });
  };

  const saveConfig = async () => {
    const savedData = {
      paymentsRequest: {
        Spei: {
          Activate: dataConfig.Spei.Activate,
          NombreOrdenante: dataConfig.Spei.NombreOrdenante,
          CuentaOrdenante: dataConfig.Spei.CuentaOrdenante,
        },
        Stp: {
          ...dataConfig.Stp,
        },
      },
    };
    delete savedData.paymentsRequest.Stp.CargosPendientes;
    delete savedData.paymentsRequest.Stp.Saldo;
    const [error, _] = await apiRequest({
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        connectorTypes[routeParams.connectorType]
      ),
      data: savedData,
    });
    if (!error) {
      showAlert("success", AlertTexts.success);
    } else {
      showAlert("error", error.message || AlertTexts.unexpectedError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const [err, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getConfigConnector.replace(
          ":typeConnector",
          connectorTypes[routeParams.connectorType]
        ),
      });
      if (data && data.paymentsResponse) {
        if (!data?.paymentsResponse?.Stp?.HorarioContratado) {
          data.paymentsResponse.Stp.HorarioContratado = "basico";
        }
        setDataConfig(data?.paymentsResponse);
        if (data?.paymentsResponse?.Stp?.Activate) setIsStp(true);
      }
      if (err) {
        showAlert("error", err.message || AlertTexts.unexpectedError);
      }
      setIsLoading(false);
    })();
  }, []);

  const handleOnChange = (module, key, value) => {
    setDataConfig({
      ...dataConfig,
      [module]: {
        ...dataConfig[module],
        [key]: value,
      },
    });
  };

  const handleOnSave = () => {
    setIsLoading(true);
    if (isFormValid()) {
      saveConfig();
    } else {
      setTimeout(() => setIsLoading(false), 500);
    }
  };

  const isFormValid = () => {
    let formErrors = {
      Stp: {
        NombreEmpresa: false,
        NombreOrdenante: false,
        CuentaOrdenante: false,
        Rfc: false,
      },
      Spei: {
        NombreOrdenante: false,
        CuentaOrdenante: false,
      },
    };
    if (dataConfig.Stp.Activate) {
      if (!dataConfig.Stp.NombreEmpresa) {
        formErrors.Stp.NombreEmpresa = true;
      }
      if (!dataConfig.Stp.NombreOrdenante) {
        formErrors.Stp.NombreOrdenante = true;
      }
      if (!dataConfig.Stp.CuentaOrdenante) {
        formErrors.Stp.CuentaOrdenante = true;
      }
      if (!dataConfig.Stp.Rfc) {
        formErrors.Stp.Rfc = true;
      }
    }
    if (dataConfig.Spei.Activate) {
      if (!dataConfig.Spei.NombreOrdenante) {
        formErrors.Spei.NombreOrdenante = true;
      }
      if (!dataConfig.Spei.CuentaOrdenante) {
        formErrors.Spei.CuentaOrdenante = true;
      }
    }
    setErrors(formErrors);
    return (
      !formErrors.Stp.NombreEmpresa &&
      !formErrors.Stp.NombreOrdenante &&
      !formErrors.Stp.CuentaOrdenante &&
      !formErrors.Stp.Rfc &&
      !formErrors.Spei.NombreOrdenante &&
      !formErrors.Spei.CuentaOrdenante
    );
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    dataConfig && (
      <>
        {isStp && (
          <STP
            data={dataConfig?.Stp}
            errors={errors.Stp}
            onChange={(key, value) => handleOnChange("Stp", key, value)}
          />
        )}
        <SPEI
          data={dataConfig?.Spei}
          errors={errors.Spei}
          onChange={(key, value) => handleOnChange("Spei", key, value)}
        />
        <PageSection>
          <PageSection.Item size={1} length={1}>
            <div style={{ textAlign: "center" }}>
              <Button text="Guardar" onClick={handleOnSave} style={`primary`} />
            </div>
          </PageSection.Item>
        </PageSection>
      </>
    )
  );
};

export default Config;
