import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";

const STP = ({ data, onChange, errors }) => {
  return (
    <>
      <PageSection title="Configuración STP">
        <PageSection.Item title="Activo" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                name="Activate"
                checked={data?.Activate}
                onChange={() => onChange("Activate", true)}
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={0}
                name="Activate"
                checked={!data?.Activate}
                onChange={() => onChange("Activate", false)}
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {data?.Activate && (
          <>
            <PageSection.Item size={1} length={3} title="Nombre empresa *">
              <TextField
                value={data?.NombreEmpresa}
                onChange={(e) => onChange("NombreEmpresa", e.target.value)}
                error={errors.NombreEmpresa}
                errorText="Este campo es requerido"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} title="Nombre ordenante *">
              <TextField
                value={data?.NombreOrdenante}
                onChange={(e) => onChange("NombreOrdenante", e.target.value)}
                error={errors.NombreOrdenante}
                errorText="Este campo es requerido"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item size={1} length={3} title="Cuenta ordenante *">
              <TextField
                value={data?.CuentaOrdenante}
                onChange={(e) => onChange("CuentaOrdenante", e.target.value)}
                error={errors.CuentaOrdenante}
                errorText="Este campo es requerido"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} title="RFC *">
              <TextField
                value={data?.Rfc}
                onChange={(e) => onChange("Rfc", e.target.value)}
                error={errors.Rfc}
                errorText="Este campo es requerido"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item
              title="Horario contratado STP"
              size={1}
              length={1}
            >
              <Grid>
                <Grid.Item size="contentWidth">
                  <RadioButton
                    label="24/24"
                    value={1}
                    name="HorarioContratado"
                    checked={data?.HorarioContratado === "full24"}
                    onChange={() => onChange("HorarioContratado", "full24")}
                  />
                </Grid.Item>
                <Grid.Item size="contentWidth">
                  <RadioButton
                    label="6am a 11pm"
                    value={0}
                    name="HorarioContratado"
                    checked={data?.HorarioContratado === "basico"}
                    onChange={() => onChange("HorarioContratado", "basico")}
                  />
                </Grid.Item>
              </Grid>
            </PageSection.Item>
          </>
        )}
      </PageSection>
      {data?.Activate && (
        <>
          <PageSection title="Frecuencia y tiempo de reintento - Obtención CEP">
            <PageSection.Item
              title="Frecuencia (en minutos)"
              size={1}
              length={3}
            >
              <TextField
                value={data?.ConsultaCepFrequencyIntentsInMinutes || ""}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    /^[0-9\b]+$/.test(e.target.value)
                  ) {
                    onChange(
                      "ConsultaCepFrequencyIntentsInMinutes",
                      parseInt(e.target.value)
                    );
                  }
                }}
              />
            </PageSection.Item>
            <PageSection.Item title="Tiempo (en minutos)" size={1} length={3}>
              <TextField
                value={data?.ConsultaCepTotalWaitInMinutes || ""}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    /^[0-9\b]+$/.test(e.target.value)
                  ) {
                    onChange(
                      "ConsultaCepTotalWaitInMinutes",
                      parseInt(e.target.value)
                    );
                  }
                }}
              />
            </PageSection.Item>
          </PageSection>
          <PageSection title="Dispersión - Prohibir envio SPEI de 5:45pm a 6:15pm">
            <PageSection.Item>
              <Grid>
                <Grid.Item size="contentWidth">
                  <RadioButton
                    label="Sí"
                    value={1}
                    name="BlockHours6Pm"
                    checked={data?.BlockHours6Pm}
                    onChange={() => onChange("BlockHours6Pm", true)}
                  />
                </Grid.Item>
                <Grid.Item size="contentWidth">
                  <RadioButton
                    label="No"
                    value={0}
                    name="BlockHours6Pm"
                    checked={!data?.BlockHours6Pm}
                    onChange={() => onChange("BlockHours6Pm", false)}
                  />
                </Grid.Item>
              </Grid>
            </PageSection.Item>
          </PageSection>
          <PageSection title="Consultar el saldo de su cuenta STP">
            <PageSection.Item title="Saldo" size={1} length={3}>
              <TextField value={data?.Saldo || ""} />
            </PageSection.Item>
            <PageSection.Item title="Cargos Pendientes" size={1} length={3}>
              <TextField value={data?.CargosPendientes || ""} />
            </PageSection.Item>
          </PageSection>
        </>
      )}
    </>
  );
};

export default STP;
