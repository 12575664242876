import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
// start design-system
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
// end design-system
import { Input, Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import { validateEmail } from "../../utils/validators";

import { SnackBar as SnackBarStyles } from "../../styles";

import "./Company.css";

export default function Company() {
  const [dataCompany, setDataCompany] = useState(null);
  const [confirmInputs, setConfirmInputs] = useState({
    confirmEmailFacturas: {
      error: false,
      helperText: "",
      value: "",
    },
    confirmEmailAdmin: {
      error: false,
      helperText: "",
      value: "",
    },
  });
  const [errorInputs, setErrorInputs] = useState(null);
  const [errorApi, setErrorApi] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [showNotice, setShowNotice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const requiredInputs = [
    "nombreComercial",
    "rfc",
    "calle",
    "numeroExterior",
    "ciudad",
    "municipio",
    "departamento",
    "emailFacturas",
    "emailAdmin",
  ];

  const snackBarClasses = SnackBarStyles();

  const getDataCompany = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.configuration,
    });
    setErrorApi(error);
    if (!error) {
      setDataCompany(data);
      let updateConfirmInputs = { ...confirmInputs };
      updateConfirmInputs.confirmEmailFacturas.value = data.emailFacturas;
      updateConfirmInputs.confirmEmailAdmin.value = data.emailAdmin;
      const objErrors = {};
      Object.keys(data).forEach((key) => {
        objErrors[key] = { error: false, helperText: "" };
      });
      setErrorInputs(objErrors);
      setConfirmInputs(updateConfirmInputs);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getDataCompany();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleInputConfirm = (event) => {
    let updateData = { ...confirmInputs };
    updateData[event.target.id].value = event.target.value;
    // Validation
    let key = event.target.id.replace("confirm", "");
    key = key[0].toLowerCase() + key.substr(1);
    updateData[event.target.id].error = dataCompany[key] !== event.target.value;
    updateData[event.target.id].helperText =
      dataCompany[key] !== event.target.value ? "El correo debe coincidir" : "";
    setConfirmInputs(updateData);
  };

  const handleChangeInput = (event) => {
    let updateData = { ...dataCompany };
    let updateConfirmData = { ...confirmInputs };
    updateData[event.target.id] = event.target.value;
    setDataCompany(updateData);
    // validation required
    let updateErrorInputs = { ...errorInputs };
    const errorRequired =
      requiredInputs.includes(event.target.id) && event.target.value === "";
    updateErrorInputs[event.target.id].error = errorRequired;
    updateErrorInputs[event.target.id].helperText = errorRequired
      ? "El campo es requerido"
      : "";
    setErrorInputs(updateErrorInputs);
    // validation with inputs confirm
    if (event.target.id.includes("email")) {
      // validation format email
      const emailValid = validateEmail(event.target.value);
      updateErrorInputs[event.target.id].error = !emailValid;
      updateErrorInputs[event.target.id].helperText = !emailValid
        ? "Debe ingresar un email válido"
        : "";
      const key =
        "confirm" +
        event.target.id[0].toUpperCase() +
        event.target.id.substr(1);
      updateConfirmData[key].error =
        confirmInputs[key].value !== event.target.value;
      updateConfirmData[key].helperText =
        confirmInputs[key].value !== event.target.value
          ? "El correo debe coincidir"
          : "";
      setConfirmInputs(updateConfirmData);
    }
  };

  const handleLoadLogo = (event) => {
    let updateData = { ...dataCompany };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      updateData.logo = reader.result.split(",")[1];
      setDataCompany(updateData);
    };
  };

  const hasAnyError = () => {
    let error = false;
    errorInputs &&
      Object.keys(errorInputs).forEach((key) => {
        error = !!errorInputs[key].error || error;
      });
    Object.keys(confirmInputs).forEach((key) => {
      error = !!confirmInputs[key].error || error;
    });
    return error;
  };

  async function handleSaveData() {
    setIsLoading(true);
    if (!hasAnyError()) {
      let putDataCompany = { ...dataCompany };
      delete putDataCompany.logoUrl;
      const response = await apiRequest({
        method: "put",
        url: apiRoutes.configuration,
        data: dataCompany,
      });
      setShowNotice(true);
      if (!response[0]) {
        getDataCompany();
        setErrorSave(null);
      } else {
        setErrorSave(response[0]);
        setIsLoading(false);
      }
    } else {
      setShowNotice(true);
      setIsLoading(false);
    }
  }

  const handleChangeSofom = (value) => {
    setDataCompany({ ...dataCompany, sofom: value });
  };

  const propsButtonSave = {
    disabled: isLoading,
    text: "Guardar",
    onAction: () => handleSaveData(),
  };

  return errorApi ? (
    <Redirect to="/login" />
  ) : isLoading && !dataCompany ? (
    <Skeleton size="large" />
  ) : (
    <>
      <div className="main-content" id="CompanyConfig">
        <Snackbar
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowNotice(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className={snackBarClasses.success}
          open={showNotice}
          autoHideDuration={6000}
          message={
            !hasAnyError()
              ? !errorSave
                ? "Los cambios han sido guardados"
                : "Ocurrió un error inesperado, verifica los campos e intenta de nuevo"
              : "Verifica los campos con errores"
          }
          onClose={() => setShowNotice(false)}
        ></Snackbar>
        <Title type="displayLarge">Empresa</Title>
        <PageSection title="Datos fiscales" primaryAction={propsButtonSave}>
          {/* Row */}
          <PageSection.Item title="Razon Social" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.razonSocial.error : false}
              helperText={errorInputs ? errorInputs.razonSocial.helperText : ""}
              disabled={isLoading}
              id="razonSocial"
              value={dataCompany ? dataCompany.razonSocial : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="Nombre comercial" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.nombreComercial.error : false}
              helperText={
                errorInputs ? errorInputs.nombreComercial.helperText : ""
              }
              disabled={isLoading}
              id="nombreComercial"
              value={dataCompany ? dataCompany.nombreComercial : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          {/* Row */}
          <PageSection.Item
            title={Jwt.getItem("country") === "MX" ? "RFC" : "NIT"}
            size={1}
            length={3}
          >
            <TextField
              error={errorInputs ? errorInputs.rfc.error : false}
              helperText={errorInputs ? errorInputs.rfc.helperText : ""}
              disabled={isLoading}
              id="rfc"
              value={dataCompany ? dataCompany.rfc : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          <PageSection.Item title="" size={1} length={3} />
          {/* Row */}
          <PageSection.Item title="Dirección" size={0} length={3}>
            <TextField
              error={errorInputs ? errorInputs.calle.error : false}
              helperText={errorInputs ? errorInputs.calle.helperText : ""}
              disabled={isLoading}
              id="calle"
              value={dataCompany ? dataCompany.calle : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="Número exterior" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.numeroExterior.error : false}
              helperText={
                errorInputs ? errorInputs.numeroExterior.helperText : ""
              }
              disabled={isLoading}
              id="numeroExterior"
              value={dataCompany ? dataCompany.numeroExterior : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          {/* Row */}
          <PageSection.Item title="Número interior" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.numeroInterior.error : false}
              helperText={
                errorInputs ? errorInputs.numeroInterior.helperText : ""
              }
              disabled={isLoading}
              id="numeroInterior"
              value={dataCompany ? dataCompany.numeroInterior : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="Ciudad" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.ciudad.error : false}
              helperText={errorInputs ? errorInputs.ciudad.helperText : ""}
              disabled={isLoading}
              id="ciudad"
              value={dataCompany ? dataCompany.ciudad : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          {/* Row */}
          <PageSection.Item title="Municipio" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.municipio.error : false}
              helperText={errorInputs ? errorInputs.municipio.helperText : ""}
              disabled={isLoading}
              id="municipio"
              value={dataCompany ? dataCompany.municipio : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="Departamento" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.departamento.error : false}
              helperText={
                errorInputs ? errorInputs.departamento.helperText : ""
              }
              disabled={isLoading}
              id="departamento"
              value={dataCompany ? dataCompany.departamento : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
        </PageSection>
        <PageSection title="Correos electrónicos">
          {/* Row */}
          <PageSection.Item title="Envío de facturas" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.emailFacturas.error : false}
              helperText={
                errorInputs ? errorInputs.emailFacturas.helperText : ""
              }
              disabled={isLoading}
              id="emailFacturas"
              value={dataCompany ? dataCompany.emailFacturas : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item
            title="Confirmar correo electrónico"
            size={1}
            length={3}
          >
            <TextField
              error={confirmInputs.confirmEmailFacturas.error}
              helperText={confirmInputs.confirmEmailFacturas.helperText}
              disabled={isLoading}
              id="confirmEmailFacturas"
              value={confirmInputs.confirmEmailFacturas.value}
              onChange={(event) => handleInputConfirm(event)}
            />
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          {/* Row */}
          <PageSection.Item title="Administrador principal" size={1} length={3}>
            <TextField
              error={errorInputs ? errorInputs.emailAdmin.error : false}
              helperText={errorInputs ? errorInputs.emailAdmin.helperText : ""}
              disabled={isLoading}
              id="emailAdmin"
              value={dataCompany ? dataCompany.emailAdmin : ""}
              onChange={(event) => handleChangeInput(event)}
            />
          </PageSection.Item>
          <PageSection.Item
            title="Confirmar correo electrónico"
            size={1}
            length={3}
          >
            <TextField
              error={confirmInputs.confirmEmailAdmin.error}
              helperText={confirmInputs.confirmEmailAdmin.helperText}
              disabled={isLoading}
              id="confirmEmailAdmin"
              value={confirmInputs.confirmEmailAdmin.value}
              onChange={(event) => handleInputConfirm(event)}
            />
          </PageSection.Item>
        </PageSection>
        <PageSection title="Personalización">
          {/* Row */}
          <PageSection.Item
            title="Es su logotipo (máximo 500kb)"
            size={1}
            length={3}
          >
            <Input
              disabled={isLoading}
              id="logo"
              type="file"
              onChange={(event) => handleLoadLogo(event)}
            />
          </PageSection.Item>
          <PageSection.Item
            title="¿Su institución es una SOFOM?"
            size={1}
            length={3}
          >
            <div className="custom-control custom-radio-control">
              <RadioButton
                name="sofom"
                value="Y"
                label="Sí"
                checked={dataCompany.sofom === "Y"}
                onChange={() => handleChangeSofom("Y")}
              />
              <RadioButton
                name="sofom"
                value="N"
                label="No"
                checked={dataCompany.sofom === "N"}
                onChange={() => handleChangeSofom("N")}
              />
            </div>
          </PageSection.Item>
          <PageSection.Item title="" size={1} length={3} />
          <PageSection.Item
            title="Vista previa de su logotipo"
            size={1}
            length={3}
          >
            <img
              src={`${dataCompany ? dataCompany.logoUrl : ""}`}
              alt="logo company"
              width="80px"
            />
          </PageSection.Item>
        </PageSection>
      </div>
    </>
  );
}
