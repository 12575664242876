import Jwt from "./jwt";

const envs = [
  {
    url: "alpha.workfloo.kiban.tech",
    api: "https://alpha.unykoo.com/workfloo",
  },
  {
    url: "test.workfloo.kiban.tech",
    api: "https://test.unykoo.com/workfloo",
  },
  {
    url: "qa.workfloo.kiban.tech",
    api: "https://qa.unykoo.com/workfloo",
  },
  {
    url: "workfloo.kiban.tech",
    api: "https://www.unykoo.com/workfloo",
  },
  {
    url: "localhost",
    api: "https://qa.unykoo.com/workfloo",
  },
  {
    url: "localhost.workfloo.kiban.tech",
    api: "http://localhost:8080/workfloo",
  },
];

const getHostName = () => {
  const hostname = window.location.hostname;
  const currentEnv = envs.filter((env) => {
    return env.url === hostname;
  });

  return currentEnv[0]?.api || "";
};

function sideMenu() {
  const alphaLink = getHostName();
  const token = Jwt.getToken();

  let menuAdmin = [
    {
      name: "Iniciar Workfloo",
      link: "/workfloo",
      // link: `${alphaLink}/auth/consulta/select_workfloo.xhtml?token=${token}`,
    },
    {
      name: "Histórico",
      link: "/historic",
    },
  ];
  const subMenuMarketPlace = [
    {
      name: "Burós",
      link: "/connectors/buro",
    },
    {
      name: "Clean Data",
      link: "/connectors/cleandata",
      // link: `${alphaLink}/auth/cfdi/cfdi.xhtml?token=${token}`,
    },
    {
      name: "Core Bancario",
      link: "/connectors/bankingcore",
    },
    {
      name: "Casos ficticios",
      link: "/casos-ficticios",
    },
    {
      name: "Firma Electrónica",
      link: "/connectors/digitalsign",
    },
    {
      name: "KYC",
      link: "/connectors/identity",
    },
    {
      name: "Mensajería",
      link: "/connectors/messaging",
      // link: `${alphaLink}/auth/messaging/messagingConnector.xhtml?token=${token}`,
    },
    {
      name: "Open Banking",
      link: "/connectors/openbanking",
      // link: `${alphaLink}/auth/openBanking/openBankingConnector.xhtml?token=${token}`,
    },
    {
      name: "Pagos",
      link: `/connectors/payments`,
    },
    {
      name: "Scores",
      link: `/connectors/score`,
    },
  ];
  const endMenu = [
    {
      name: "Configuraciones",
      link: "#",
      subMenu: [
        {
          name: "API",
          link: `/api`,
        },
        {
          name: "Empresa",
          link: "/company",
        },
        {
          name: "Usuarios",
          link: `/configuration/users`,
        },
      ],
    },
    {
      name: "Auditoría",
      link: "#",
      subMenu: [
        {
          name: "Autorizaciones",
          link: `/auditoria/authorization`,
        },
        {
          name: "Auditoría NIP",
          link: `/auditoria/nip`,
        },
      ],
    },
    {
      name: "Documentacion",
      link: `/documentation`,
    },
  ];
  if (getHostName() !== "https://www.unykoo.com/workfloo") {
    endMenu[0].subMenu.splice(2, 0, {
      name: "Sincronización",
      link: "/configuration/synchronization",
      // link: `${alphaLink}/auth/configuraciones/env_sync_ultima.xhtml?token=${token}`,
    });
  }

  if (getHostName() !== "https://www.unykoo.com/workfloo") {
    // if (getHostName() !== "https://alpha.unykoo.com/workfloo") { //  Only for test in localhost
    menuAdmin.push({
      name: "Workfloos",
      link: `/workfloos`,
    });
    menuAdmin.push({
      name: "Conectores",
      link: "#",
      subMenu: [
        {
          name: "API",
          link: `/connectors/api`,
        },
        {
          name: "Árboles de decisión",
          link: `/connectors/credit-policies`,
        },
        {
          name: "Documentos",
          link: `/connectors/documents`,
        },
        {
          name: "Formularios",
          link: `/connectors/forms`,
        },
        {
          name: "NIP",
          link: `/connectors/nip`,
        },
        {
          name: "Variables personalizadas",
          link: `/variables-personalizadas`,
        },
        {
          name: "Variables dinámicas",
          link: `/connectors/vp`,
        },
        {
          name: "Timer",
          link: `/connectors/timer`,
        },
      ].filter(Boolean),
    });
    menuAdmin.push({
      name: "Marketplace",
      subMenu: subMenuMarketPlace,
      link: "#",
    });
  }

  menuAdmin = menuAdmin.concat(endMenu);

  const user = [
    {
      name: "Iniciar Workfloo",
      link: `${alphaLink}/auth/consulta/select_workfloo.xhtml?token=${token}`,
    },
    {
      name: "Histórico",
      link: "/historic",
    },
    {
      name: "Documentacion",
      link: `/documentation`,
    },
  ];

  let options = [];
  if (Jwt.getItem("role") === "admin" || Jwt.getItem("role") === "superadmin") {
    options = menuAdmin;
  } else {
    options = user;
  }
  return options;
}

export { getHostName, sideMenu, envs };
