import React, { useEffect, useState, useRef } from "react";
// start design-system
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import "../ConfigurationPage.css";

import ConfigDigitalSignMX from "./ConfigDigitalSignMX";
import ConfigDigitalSignCO from "./ConfigDigitalSignCO";

export default function ConfigurationDigitalSign() {
  const [loading, setLoading] = useState(true);
  const [dataConfig, setDataConfig] = useState(null);
  const [dataKey, setDataKey] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const { addAlert } = useAlert();
  const fieldsOnlyRead = [
    "Activated",
    "InternalToken",
    "WebhookUrl",
    "WebhookUser",
    "WebhookPassword",
    "Expiration",
    "Host",
  ];

  const urlWebhookReference = useRef();
  const userWebhookReference = useRef();
  const passwordWebhookReference = useRef();

  const getConfigDigitalSign = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "DIGITAL_SIGN"
      ),
    });
    if (!err) {
      const responseDataConfig =
        data.digitalSignMXResponse || data.digitalSignCOResponse;
      if (responseDataConfig) {
        const dataKey = Object.keys(data)[0];
        setDataConfig(responseDataConfig);
        setDataKey(dataKey);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigDigitalSign();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig);
    let updateConfig = { ...dataConfig };
    keys.forEach((key) => {
      if (!updateConfig[key].Activated) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !fieldsOnlyRead.includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          updateConfig[key][keyInternal] = "";
        });
      }
    });
    return { digitalSignRequest: { ...updateConfig } };
  };

  const updateConfigDigital = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "DIGITAL_SIGN"
      ),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const handleOnChangeConfig = (key, attr, value) => {
    const updateConfig = { ...dataConfig };
    const updateObj = { ...updateConfig[key] };
    updateObj[attr] = value;
    updateConfig[key] = updateObj;
    const updateErrors = errorFields.includes(`${key}-${attr}`)
      ? errorFields.filter((keyError) => keyError !== `${key}-${attr}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    const keys = Object.keys(dataConfig);
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (updateConfig[key].Activated) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !fieldsOnlyRead.includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          if (updateConfig[key][keyInternal] === "") {
            objErrors.push(`${key}-${keyInternal}`);
          }
          if (key === "Deceval" && (keyInternal === "CodigoDepositante" || keyInternal === "IdClaseDefinicionDocumento")) {
            const regex = /^\d+$/;
            if (!regex.test(updateConfig[key][keyInternal])) {
              objErrors.push(`${key}-${keyInternal}`);
            }
          }
        });
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigDigital();
    }
  };

  const renderForm = () => {
    switch (dataKey) {
      case "digitalSignCOResponse":
        return (
          <ConfigDigitalSignCO
            errorFields={errorFields}
            onSave={saveConfig}
            data={dataConfig}
            onChange={handleOnChangeConfig}
          />
        );
      default:
        return (
          <ConfigDigitalSignMX
            dataConfig={dataConfig}
            errorFields={errorFields}
            handleOnChangeConfig={handleOnChangeConfig}
            saveConfig={saveConfig}
            refs={{
              passwordWebhookReference,
              userWebhookReference,
              urlWebhookReference,
            }}
          />
        );
    }
  };

  return loading ? (
    <Skeleton />
  ) : dataConfig && (
    <div className="main-content">{renderForm()}</div>
  );
}
