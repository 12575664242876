import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";

const MatiWidget = () => {
  const [loadSrcMati, setLoadSrcMati] = useState(false);
  const [dataWorkfloo, setDataWorkfloo] = useState(null);

  const routeParams = useParams();
  const { addAlert } = useAlert();

  const getDataMati = async () => {
    const [err, res] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getMati}?idWorkfloo=${routeParams.id}`,
    });
    if (res) {
      setDataWorkfloo(res);
    }
    if (err) {
      addAlert({
        code: "danger",
        message: err.message
          ? err.message
          : "Ocurrio un error inesperado, vuelve a intentar más tarde",
        duration: 5000,
      });
    }
  };

  const updateStatusMati = async () => {
    const response = await apiRequest({
      method: "post",
      url: `${apiRoutes.postMati}?idWorkfloo=${routeParams.id}`,
      data: { status: "CAPTURED" },
    });
    if (!response[0]) {
      const cryptId = Jwt.signToken({ id: routeParams.id });
      window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
    } else {
      addAlert({
        code: "danger",
        message: response[0].message
          ? response[0].message
          : "Ocurrio un error inesperado, vuelve a intentar más tarde",
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    getDataMati();
  }, []);

  useEffect(() => {
    if (dataWorkfloo !== null) {
      const matiScript = document.createElement("script");
      matiScript.src = "https://web-button.mati.io/button.js";
      document.body.appendChild(matiScript);
      setLoadSrcMati(true);
    }
  }, [dataWorkfloo]);

  useEffect(() => {
    if (loadSrcMati) {
      const matiButton = document.getElementsByTagName("mati-button")[0];
      matiButton.addEventListener("mati:loaded", () => {
        console.log("cargando boton");
      });

      matiButton.addEventListener("mati:userFinishedSdk", ({ detail }) => {
        updateStatusMati();
      });

      matiButton.addEventListener("mati:exitedSdk", () => {
        console.log("Se salió del flujo Mati");
      });
    }
  }, [loadSrcMati]);

  return dataWorkfloo !== null ? (
    <div className="main-content">
      <PageSection title="Mati Widget">
        <mati-button
          id="matiButtonId"
          clientid={dataWorkfloo && dataWorkfloo.clientId}
          flowId={dataWorkfloo && dataWorkfloo.flowId}
          metadata={`{"idUnykoo":${routeParams.id}}`}
        />
      </PageSection>
    </div>
  ) : (
    <Skeleton />
  );
};

export default MatiWidget;
