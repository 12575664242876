import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  success: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "#2196F3",
      borderColor: "#2196F3",
      color: "#ffffff",
    },
  },
});
