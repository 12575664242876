export default [
  {
    content: "Numérico",
    value: "NUMERIC",
  },
  {
    content: "Alfanumérico",
    value: "ALPHANUMERIC",
  },
  {
    content: "Lista",
    value: "LIST",
  },
  {
    content: "Fecha",
    value: "DATE",
  },
];
