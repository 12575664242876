import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";

const ConfigScoreMX = ({
  isEmailageActive,
  emailageSandboxUserEmail,
  emailageSandboxAccountToken,
  emailageSandboxSecretKey,
  emailageProductionUserEmail,
  emailageProductionAccountToken,
  emailageProductionSecretKey,
  isMasivianActive,
  masivianUser,
  masivianPassword,
  onChange,
  onSave,
  errors,
}) => {
  console.log(errors);
  const handleOnChange = (config, value) => {
    onChange && onChange(config, value);
  };

  return (
    <>
      <PageSection title="Emailage">
        <PageSection.Item title="Activar Emailage" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                name="ActivateEmailage"
                checked={isEmailageActive}
                onChange={() => handleOnChange("Emailage", { Activate: true })}
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={0}
                name="ActivateEmailage"
                checked={!isEmailageActive}
                onChange={() => handleOnChange("Emailage", { Activate: false })}
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {isEmailageActive && (
          <>
            <PageSection.Item title="Configuración Sandbox" size={1} length={3}>
              <Grid>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Email del usuario *"
                    value={emailageSandboxUserEmail}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        SandboxConfig: {
                          UserEmail: e.target.value,
                          AccountToken: emailageSandboxAccountToken,
                          AccountSecret: emailageSandboxSecretKey,
                        },
                      })
                    }
                    error={errors.emailage.sandboxUserEmail}
                    errorText="Este campo debe estar completo"
                  />
                </Grid.Item>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Token de la cuenta *"
                    type="password"
                    value={emailageSandboxAccountToken}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        SandboxConfig: {
                          UserEmail: emailageSandboxUserEmail,
                          AccountToken: e.target.value,
                          AccountSecret: emailageSandboxSecretKey,
                        },
                      })
                    }
                    error={errors.emailage.sandboxAccountToken}
                    errorText="Este campo debe estar completo"
                  />
                </Grid.Item>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Llave secreta *"
                    type="password"
                    value={emailageSandboxSecretKey}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        SandboxConfig: {
                          UserEmail: emailageSandboxUserEmail,
                          AccountToken: emailageSandboxAccountToken,
                          AccountSecret: e.target.value,
                        },
                      })
                    }
                    error={errors.emailage.sandboxSecretKey}
                    errorText="Este campo debe estar completo"
                  />
                </Grid.Item>
              </Grid>
            </PageSection.Item>
            <PageSection.Item
              title="Configuración Producción"
              size={1}
              length={3}
            >
              <Grid>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Email del usuario"
                    value={emailageProductionUserEmail}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        ProdConfig: {
                          UserEmail: e.target.value,
                          AccountToken: emailageProductionAccountToken,
                          AccountSecret: emailageProductionSecretKey,
                        },
                      })
                    }
                  />
                </Grid.Item>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Token de la cuenta"
                    type="password"
                    value={emailageProductionAccountToken}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        ProdConfig: {
                          UserEmail: emailageProductionUserEmail,
                          AccountToken: e.target.value,
                          AccountSecret: emailageProductionSecretKey,
                        },
                      })
                    }
                  />
                </Grid.Item>
                <Grid.Item size="fullWidth">
                  <TextField
                    label="Llave secreta"
                    type="password"
                    value={emailageProductionSecretKey}
                    onChange={(e) =>
                      handleOnChange("Emailage", {
                        ProdConfig: {
                          UserEmail: emailageProductionUserEmail,
                          AccountToken: emailageProductionAccountToken,
                          AccountSecret: e.target.value,
                        },
                      })
                    }
                  />
                </Grid.Item>
              </Grid>
            </PageSection.Item>
          </>
        )}
      </PageSection>
      <PageSection title="Masivian">
        <PageSection.Item title="Activar Masivian" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                name="ActivateMasivian"
                checked={isMasivianActive}
                onChange={() => handleOnChange("Masivian", { Activate: true })}
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={0}
                name="ActivateMasivian"
                checked={!isMasivianActive}
                onChange={() => handleOnChange("Masivian", { Activate: false })}
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {isMasivianActive && (
          <PageSection.Item title="Configuración Masivian" size={1} length={3}>
            <Grid>
              <Grid.Item size="fullWidth">
                <TextField
                  label="Usuario *"
                  value={masivianUser}
                  onChange={(e) =>
                    handleOnChange("Masivian", {
                      Activate: isMasivianActive,
                      User: e.target.value,
                      Password: masivianPassword,
                    })
                  }
                  error={errors.masivian?.user}
                  errorText="Este campo debe estar completo"
                />
              </Grid.Item>
              <Grid.Item size="fullWidth">
                <TextField
                  label="Contraseña *"
                  type="password"
                  value={masivianPassword}
                  onChange={(e) =>
                    handleOnChange("Masivian", {
                      Activate: isMasivianActive,
                      User: masivianUser,
                      Password: e.target.value,
                    })
                  }
                  error={errors.masivian?.password}
                  errorText="Este campo debe estar completo"
                />
              </Grid.Item>
            </Grid>
          </PageSection.Item>
        )}
      </PageSection>
      <PageSection.Item>
        <Button text="Guardar" style={`primary`} onClick={onSave} />
      </PageSection.Item>
    </>
  );
};

export default ConfigScoreMX;
