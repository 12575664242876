import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import Table from "@bit/kiban-design-system.kiban.table";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import Tabs from "@bit/kiban-design-system.kiban.tabs";
import { PlusIcon, AlertIcon } from "@bit/kiban-design-system.kiban.icons";
import { COLOR_DANGER_BASE } from "@bit/kiban-design-system.styles.design-tokens-es6";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { isValidURL } from "../../utils/validators";
import AlertTexts from "../../utils/alerts-texts";

import connectorsProps from "./connectorsProps";
import connectorsTypes from "./connectorsTypes";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";

import ConnectorDropdown from "./ConnectorDropdown";
import useTablePagination from "../../utils/useTablePagination";
import Paths from "../../paths";
import useSearch from "../../utils/useTableSearch";

const Connectors = () => {
  const limit = 10;
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [connectors, setConnectors] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [totalConnectors, setTotalConnectors] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const [search, setSearch] = useState("");
  const { addAlert } = useAlert();

  const { filteredData } = useSearch({ data: connectors, search });

  const handleChangeTab = (index) => {
    setTabSelected(index);
  };

  const history = useHistory();

  const routerParams = useParams();
  const {
    from,
    goBack,
    goForward,
    hasNext,
    hasPrevious,
    page,
    skipBack,
    skipForward,
    to,
  } = useTablePagination({
    initialPage: 1,
    rowsPerPage: limit,
    totalItems: totalConnectors,
  });

  useEffect(() => {
    setTotalConnectors(filteredData.length);
  }, [filteredData]);

  const getConnectors = async () => {
    const [error, connectors] = await apiRequest({
      method: "get",
      url: apiRoutes.connectors.replace(
        "{type}",
        connectorsTypes[routerParams.connectorType]
      ),
    });
    if (connectors) {
      setConnectors(connectors);
    }
    if (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      setIsLoading(true);
      setTabSelected(0);
      getConnectors();
    } else {
      window.location.href = "/login";
    }
  }, [routerParams.connectorType]);

  const connectorsActions = async (id, type) => {
    const [error, data] = await apiRequest({
      method: type === "DUPLICATE" ? "post" : "put",
      url:
        type === "DUPLICATE"
          ? routerParams.connectorType !== "vp"
            ? `${apiRoutes.duplicateConnector.replace(
                ":id",
                id
              )}?typeConnector=${connectorsTypes[routerParams.connectorType]}`
            : apiRoutes.duplicateVPConnector.replace(":id", id)
          : `${apiRoutes.deleteConnector.replace(":id", id)}?typeConnector=${
              connectorsTypes[routerParams.connectorType]
            }`,
    });
    if (data !== null) {
      if (type === "DUPLICATE") {
        let allConnectors = [...connectors];
        getConnectors();
      } else {
        let itemIndex = connectors.findIndex(
          (connector) => connector.id === id
        );
        let allConnectors = [...connectors];
        allConnectors.splice(itemIndex, 1);
        setConnectors(allConnectors);
      }
    }
    if (error) {
      setError(error);
    }
  };

  const onSelectConnector = (selected) => {
    const itemSelected = filteredData[limit * (page - 1) + selected];
    if (isValidURL(connectorsProps[routerParams.connectorType].link)) {
      const cryptId = Jwt.signToken({ id: itemSelected.id.toString() });
      window.location.href = connectorsProps[
        routerParams.connectorType
      ].editLink
        .replace("{token}", Jwt.getToken())
        .replace("{id}", cryptId);
    } else {
      if (!itemSelected.allowedToEdit) {
        addAlert({
          duration: 5000,
          code: "danger",
          message: AlertTexts.forbidden,
        });
      } else {
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routerParams.connectorType
          ).replace(":id", itemSelected.id)
        );
      }
    }
  };

  const tablePagination = {
    current: from,
    results: to,
    total: totalConnectors,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: skipBack,
    onSkipForward: skipForward,
  };

  const headings = ["Nombre", "Fecha de creación", "Fecha de modificación", ""];
  if (routerParams.connectorType === "forms") {
    headings.unshift("Id");
  }

  const onDuplicateConnector = (index) => {
    connectorsActions(connectors[limit * (page - 1) + index].id, "DUPLICATE");
  };

  const onDeleteConnector = (index) => {
    setItemToDelete(limit * (page - 1) + index);
    setOpenAlert(true);
  };

  const rows = filteredData
    .slice(limit * (page - 1), limit * page)
    .map((connector, i) => {
      const data = [
        connector.name,
        connector.created && connector.created !== ""
          ? moment(connector.created).format("DD-MM-YYYY HH:mm")
          : "",
        connector.modified && connector.modified !== ""
          ? moment(connector.modified).format("DD-MM-YYYY HH:mm")
          : "",
        <ConnectorDropdown
          index={i}
          onDuplicate={onDuplicateConnector}
          onDelete={onDeleteConnector}
        />,
      ];
      if (routerParams.connectorType === "forms") {
        data.unshift(connector.id);
      }
      return data;
    });

  const alertPrimaryAction = {
    text: "Sí, eliminar",
    onAction: (e) => {
      connectorsActions(connectors[itemToDelete].id, "DELETE");
      closeAlert();
    },
  };

  const alertCancelAction = {
    text: "No, cancelar",
    onAction: (e) => {
      closeAlert();
    },
  };

  const closeAlert = () => {
    setOpenAlert(false);
    setItemToDelete(-1);
  };

  const onAddNew = () => {
    if (isValidURL(connectorsProps[routerParams.connectorType].link)) {
      window.location.href = connectorsProps[
        routerParams.connectorType
      ].link.replace("{token}", Jwt.getToken());
    } else {
      history.push(
        Paths.AddConnectors.replace(
          ":connectorType",
          routerParams.connectorType
        )
      );
    }
  };

  const tablePrimaryAction = {
    icon: PlusIcon,
    label: connectorsProps[routerParams.connectorType].primaryAction,
    text: connectorsProps[routerParams.connectorType].primaryAction,
    onAction: onAddNew,
  };

  const viewConnector = (
    <>
      {error && (
        <PageSection.Item size={1} length={1}>
          <p
            style={{
              color: COLOR_DANGER_BASE,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AlertIcon />
            <span style={{ marginLeft: "10px" }}>
              {error.message ? error.message : "Hubo un error inesperado"}
            </span>
          </p>
        </PageSection.Item>
      )}
      {!isLoading ? (
        connectors.length > 0 ? (
          <Table
            primaryAction={tablePrimaryAction}
            title={connectorsProps[routerParams.connectorType].title}
            headings={headings}
            rows={rows}
            onSelectItem={onSelectConnector}
            pagination={tablePagination}
            onSearch={setSearch}
            search={search}
            searchTitle="Buscar"
          />
        ) : (
          <PageSection
            title={connectorsProps[routerParams.connectorType].title}
            primaryAction={tablePrimaryAction}
          >
            <PageSection.Item>
              <p>{connectorsProps[routerParams.connectorType].emptyState}</p>
            </PageSection.Item>
          </PageSection>
        )
      ) : (
        <SkeletonTable headers title primaryAction pagination />
      )}
      <ModalScreen
        open={openAlert}
        title={connectorsProps[routerParams.connectorType].deleteAlertTitle}
        primaryAction={alertPrimaryAction}
        secondaryActions={[alertCancelAction]}
        onClose={closeAlert}
      >
        {connectorsProps[routerParams.connectorType].deleteAlertDescription}
      </ModalScreen>
    </>
  );

  const stylesTabs = {
    "margin-top": `${tabSelected === 0 ? "20px" : "0"}`,
  };

  return (
    <div className="main-content">
      {connectorsProps[routerParams.connectorType].tabs &&
        connectorsProps[routerParams.connectorType].tabs.length > 0 && (
          <Tabs indexSelected={tabSelected} onChange={handleChangeTab}>
            {connectorsProps[routerParams.connectorType].tabs.map((tab) => (
              <Tabs.Item>{tab}</Tabs.Item>
            ))}
          </Tabs>
        )}
      {connectorsProps[routerParams.connectorType].tabs &&
      connectorsProps[routerParams.connectorType].tabs.length > 0 ? (
        <div style={stylesTabs}>
          {tabSelected === 0
            ? viewConnector
            : React.cloneElement(
                connectorsProps[routerParams.connectorType].pagesTabs[
                  tabSelected - 1
                ]
              )}
        </div>
      ) : (
        viewConnector
      )}
    </div>
  );
};

export default Connectors;
