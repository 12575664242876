import React, { useEffect, useState } from "react";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";

import { apiRequest, apiRoutes } from "../../../services";
import alertsTexts from "../../../utils/alerts-texts";
import Jwt from "../../../utils/jwt";
import { getHostName } from "../../../utils/menuRights";

const StartWorkfloo = () => {
  const { addAlert } = useAlert();
  const [catalogWorkfloos, setCatalogWorkfloos] = useState([]);
  const [workfloo, setWorkfloo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getWorkfloos = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.workfloos,
    });
    if (!error) {
      const options = data.map((workflooItem) => {
        return {
          content: workflooItem.name,
          value: workflooItem.id,
        };
      });
      setCatalogWorkfloos(options);
    }
  };

  const startWorkfloo = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.startWorkfloo,
      data: {
        id: workfloo,
      },
    });
    if (!error) {
      const cryptId = Jwt.signToken({ id: data.workflooId });
      setIsLoading(false);
      setWorkfloo(null);
      window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
    } else {
      setIsLoading(false);
      addAlert({
        code: "danger",
        message: alertsTexts.unexpectedError,
        duration: 5000,
      });
    }
  };

  const handleSelectWorkfloo = (item) => {
    setWorkfloo(item);
  };

  const handleStarWorkfloo = () => {
    startWorkfloo();
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getWorkfloos();
    } else {
      window.location.href = "/login";
    }
  }, []);

  return (
    <div className="main-content">
      <PageSection title="Iniciar un Workfloo">
        <PageSection.Item title="Selecciona un Workfloo" size={1} length={3}>
          <SelectField
            placeholder={"Selecciona un workfloo"}
            value={workfloo}
            options={catalogWorkfloos}
            onChange={handleSelectWorkfloo}
            disabled={isLoading}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={3}>
          <Button
            style="primary"
            text="Continuar"
            disabled={!workfloo || isLoading}
            loading={isLoading}
            onClick={handleStarWorkfloo}
          />
        </PageSection.Item>
      </PageSection>
    </div>
  );
};

export default StartWorkfloo;
