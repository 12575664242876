import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AnnotatedLayout from "@bit/kiban-design-system.kiban.annotated-layout";
import Button from "@bit/kiban-design-system.kiban.button";
import Checkbox from "@bit/kiban-design-system.kiban.checkbox";
import NipField from "@bit/kiban-design-system.kiban.nip";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import StepsBar from "@bit/kiban-design-system.kiban.steps-bar";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import AlertTexts from "../../utils/alerts-texts";
import { getHostName } from "../../utils/menuRights";

import "./AuthNip.css";

const AuthNipSic = () => {
  const [currentView, setCurrentView] = useState("createAccount");
  const [description, setDescription] = useState("");
  const [accept, setAccept] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [errorNip, setErrorNip] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [steps, setSteps] = useState([
    {
      name: "Términos y Condiciones",
      status: true,
      isFullBar: false,
    },
    {
      name: "Ingrese NIP",
      status: false,
      isFullBar: false,
    },
    {
      name: "Autorización",
      status: false,
      isFullBar: false,
    },
  ]);
  const params = useParams();
  const messagesSteps = {
    validateAccount: {
      phone: [
        "Ingrese el NIP (4 caracteres) obtenido via mensaje al número {{phone}} para validar su cuenta",
      ],
      email: [
        "Ingrese el NIP (4 caracteres) obtenido via correo electrónico a la dirección {{email}} para validar su cuenta",
      ],
    },
    authorization: {
      razonSocial: [
        "Por este conducto autorizo expresamente a {{razonSocial}} para que por conducto de sus funcionarios facultados lleve a cabo investigaciones, sobre mi comportamiento crediticio en las Sociedades de Información Crediticia que estime conveniente.",
        "Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que  {{razonSocial}}  hará de tal información y de que ésta podrá realizar consultas periódicas de mi historial crediticio, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos relación jurídica.",
      ],
    },
  };

  const { addAlert } = useAlert();

  const getStepAuth = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.authSicNip}?idUnykoo=${params.id}`,
    });
    if (!error) {
      const keyStep = Object.keys(data)[0];
      setCurrentView(keyStep);
      if (keyStep !== "createAccount") {
        const value = data[keyStep];
        const keyValue = Object.keys(value)[0];
        const messages = messagesSteps[keyStep][keyValue];
        setDescription(
          messages.map((message) => {
            return message.replace(`{{${keyValue}}}`, value[keyValue]);
          })
        );
      } else {
        setLink(
          data[keyStep].hasOwnProperty("termsAndConditionsLink")
            ? data[keyStep].termsAndConditionsLink
            : ""
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const nextStep = async (bodyRequest) => {
    setIsLoading(true);
    setErrorNip(false);
    setStepError(false);
    const response = await apiRequest({
      method: "post",
      url: `${apiRoutes.authSicNip}/${
        currentView === "authorization" ? "authorize" : currentView
      }`,
      data: bodyRequest,
    });
    if (!response[0]) {
      if (currentView !== "authorization") {
        getStepAuth();
      } else {
        const cryptId = Jwt.signToken({ id: params.id });
        window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.tecnicalError,
        duration: 5000,
      });
      setStepError(true);
      if (response[0].message === "NIP inválido") {
        setErrorNip(true);
      }
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    setErrorNip(false);
    const response = await apiRequest({
      method: "post",
      url: `${apiRoutes.authSicNip}/resend`,
      data: { idUnykoo: parseInt(params.id) },
    });
    getStepAuth();
    addAlert({
      duration: 5000,
      code: !response[0] ? "success" : "danger",
      message: !response[0]
        ? "Se envío correctamente el código"
        : "No se pudo reenviar el código, intenta otra vez",
    });
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getStepAuth();
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (currentView) {
      const updateSteps = [...steps];
      const index =
        currentView === "validateAccount"
          ? 1
          : currentView === "authorization"
          ? 2
          : 0;
      for (let i = 0; i <= index; i++) {
        updateSteps[i] = {
          ...updateSteps[i],
          status: true,
        };
      }
      setSteps(updateSteps);
    }
  }, [currentView]);

  const onChangeTerms = () => {
    setAccept(!accept);
  };

  const saveChanges = (code) => {
    const bodyRequest = {
      idUnykoo: parseInt(params.id),
    };
    if (code) {
      bodyRequest["nip"] = code.join("");
    }
    nextStep(bodyRequest);
  };

  const views = {
    createAccount: {
      title: "Términos y Condiciones",
      component: (
        <div className="createAccount">
          <Checkbox
            label={
              <p>
                Acepto{" "}
                <a href={link} target="_blank">
                  términos y condiciones
                </a>
                {", "}
                el uso de medios electrónicos de autenticación, como Número de
                Identificación Personal (NIP) y creo mi cuenta.
              </p>
            }
            checked={accept}
            onChange={onChangeTerms}
          />
          <Button
            disabled={stepError || !accept}
            style="primary"
            text="Crear Cuenta"
            onClick={() => saveChanges()}
          />
        </div>
      ),
    },
    validateAccount: {
      title: "Ingrese el NIP",
      component: (
        <div className="validateAccount">
          <NipField
            error={errorNip}
            messageError={"El código es inválido"}
            primaryAction={{
              label: "Validar Cuenta",
              action: (code) => saveChanges(code),
            }}
            secondaryAction={{
              label: "Reenviar NIP",
              action: () => resendCode(),
            }}
          />
        </div>
      ),
    },
    authorization: {
      title: "Autorización",
      component: (
        <NipField
          error={errorNip}
          messageError={"El código es inválido"}
          primaryAction={{
            label: "Autorizar",
            action: (code) => saveChanges(code),
          }}
        />
      ),
    },
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content" id="AuthNip">
      <Title>Autenticar cuenta</Title>
      <StepsBar steps={steps} />
      <AnnotatedLayout
        title={views[currentView].title}
        description={description}
      >
        {views[currentView].component}
      </AnnotatedLayout>
    </div>
  );
};

export default AuthNipSic;
