import React, { useEffect } from "react";
import Jwt from "../../utils/jwt";
import { useHistory } from "react-router-dom";
import "./Documentation.css";

export default function Documentation() {
  const history = useHistory();

  useEffect(() => {
    if (!Jwt.validateToken()) {
      history.push("/login");
    }
  });

  return (
    <>
      <div className="main-content">
        <div className="page-content">
          <h1>API Rest Documentación</h1>
          <a href="https://api.es.unykoo.kiban.mx/">
            Dar un clic para acceder a la documentación
          </a>
          <h1>Manual de usuario</h1>
          <a href="https://help.kiban.com/category/workfloo-legacy-kiban">
            Dar un clic para acceder a la documentación
          </a>
        </div>
      </div>
    </>
  );
}
