import React, { useState } from "react";
import "./Login.css";
import { apiRoutes, apiRequest } from "./../../services";
import Nip from '@bit/kiban-design-system.kiban.nip';
import Tag from '@bit/kiban-design-system.kiban.tag';
import Title from "@bit/kiban-design-system.kiban.title";

export default function VerifyCode({
  sendVerifyEmail,
  sendVerifyCode,
  email,
  error,
}) {

  return (
    <div className="verifyCode__container">
      <Title type="displaySmall">Introduce tu código</Title>
      <div className="verifyCode__label">
        <label className="verifyCode__label--notice">Hemos enviado tu código a:</label>
        <br/>
        <Tag>{email}</Tag>
      </div>
      <Nip
        primaryAction={{
          label: "Acceder",
          action: (code) => sendVerifyCode(code.join("")),
        }}
        secondaryAction={{
          label: "Reenviar Código",
          action: () => sendVerifyEmail(email),
        }}
        error={error}
        messageError="El código es incorrecto. Por favor verificalo y vuelve a intentarlo"
      />
    </div>
  );
}
