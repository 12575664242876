import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import { apiRequest, apiRoutes } from "../../../services";
import ConfigScoreMX from "./ConfigScoreMX";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import connectorsTypes from "../connectorsTypes";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

const Scores = () => {
  const [dataConfig, setDataConfig] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { addAlert } = useAlert();

  const routeParams = useParams();
  const getConfig = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        connectorsTypes[routeParams.connectorType]
      ),
    });
    if (data && data.scoreResponse) {
      setDataConfig(data.scoreResponse);
    }
    if (err) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };
  const updateScore = async () => {
    const [err, data] = await apiRequest({
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        connectorsTypes[routeParams.connectorType]
      ),
      data: { scoreRequest: dataConfig },
    });
    if (err) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfig();
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (!dataConfig?.Emailage?.Activate) {
      setErrors({ ...errors, emailage: {} });
    }
  }, [dataConfig]);

  const handleOnConfigChange = (config, value) => {
    let configData = { ...dataConfig };
    configData[config] = { ...configData[config], ...value };
    setDataConfig(configData);
  };

  const handleOnSave = () => {
    setIsLoading(true);
    if (isFormValid()) {
      updateScore();
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  };

  const isFormValid = () => {
    let errors = { emailage: {}, masivian: {} };
    if (dataConfig?.Emailage?.Activate) {
      if (!dataConfig?.Emailage?.SandboxConfig?.UserEmail) {
        errors["emailage"]["sandboxUserEmail"] = true;
      }
      if (!dataConfig?.Emailage?.SandboxConfig?.AccountToken) {
        errors["emailage"]["sandboxAccountToken"] = true;
      }
      if (!dataConfig?.Emailage?.SandboxConfig?.AccountSecret) {
        errors["emailage"]["sandboxSecretKey"] = true;
      }
    }
    if (dataConfig?.Masivian?.Activate) {
      if (!dataConfig?.Masivian?.User) {
        errors["masivian"]["user"] = true;
      }
      if (!dataConfig?.Masivian?.Password) {
        errors["masivian"]["password"] = true;
      }
    }
    setErrors(errors);
    return (
      !errors.emailage.sandboxUserEmail &&
      !errors.emailage.sandboxAccountToken &&
      !errors.emailage.sandboxSecretKey &&
      !errors.masivian.user &&
      !errors.masivian.password
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : dataConfig && (
        <ConfigScoreMX
          isEmailageActive={dataConfig?.Emailage?.Activate}
          emailageSandboxUserEmail={
            dataConfig?.Emailage?.SandboxConfig?.UserEmail
          }
          emailageSandboxAccountToken={
            dataConfig?.Emailage?.SandboxConfig?.AccountToken
          }
          emailageSandboxSecretKey={
            dataConfig?.Emailage?.SandboxConfig?.AccountSecret
          }
          emailageProductionUserEmail={
            dataConfig?.Emailage?.ProdConfig?.UserEmail
          }
          emailageProductionAccountToken={
            dataConfig?.Emailage?.ProdConfig?.AccountToken
          }
          emailageProductionSecretKey={
            dataConfig?.Emailage?.ProdConfig?.AccountSecret
          }
          isMasivianActive={dataConfig?.Masivian?.Activate}
          masivianUser={dataConfig?.Masivian?.User}
          masivianPassword={dataConfig?.Masivian?.Password}
          onChange={handleOnConfigChange}
          onSave={handleOnSave}
          errors={errors}
        />
      )}
    </>
  );
};

export default Scores;
