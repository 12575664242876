import { useEffect, useState } from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import YMDInput from "@bit/kiban-design-system.kiban.ymd-input";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { useHistory, useParams } from "react-router-dom";
import Paths from "../../../paths";
import { apiRequest, apiRoutes } from "../../../services";
import alertsTexts from "../../../utils/alerts-texts";

const modifyLabels = {
  0: "Días",
  1: "Horas",
  2: "Minutos",
};

const AddEditTimer = () => {
  const history = useHistory();
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const [name, setName] = useState("");
  const [isPreloading, setIsPreloading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTimer, setDataTimer] = useState({
    year: 0,
    month: 0,
    day: 0,
  });

  const saveConnector = async () => {
    setIsLoading(true);
    const { year, month, day } = dataTimer;
    const [error, data] = await apiRequest({
      method: routeParams.id ? "put" : "post",
      url: routeParams.id
        ? apiRoutes.editConnector
            .replace(":connector", "TIMER")
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", "TIMER"),
      data: { name, days: year, hours: month, minutes: day },
    });
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
      setIsLoading(false);
    }
    if (data !== null) {
      addAlert({
        code: "success",
        message: alertsTexts.success,
        duration: 5000,
      });
      setIsLoading(false);
      if (!routeParams.id) {
        history.push(
          Paths.EditConnectors.replace(":connectorType", "timer").replace(
            ":id",
            data.id
          )
        );
      }
    }
  };

  const getConnector = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (data) {
      setName(data.name);
      const { days, hours, minutes } = data;
      setDataTimer({ year: days, month: hours, day: minutes });
    }
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsPreloading(false);
  };

  const handleOnChangeTimer = (value) => {
    setDataTimer(value);
  };

  const pageActions = [
    {
      text: "Guardar",
      loading: isLoading,
      disabled: false,
      onAction: saveConnector,
    },
    {
      text: "Cancelar",
      disabled: isLoading,
      onAction: () =>
        history.push(Paths.Connectors.replace(":connectorType", "timer")),
    },
  ];

  useEffect(() => {
    if (routeParams.id) getConnector();
    else setIsPreloading(false);
  }, []);

  useEffect(() => {
    if (!isPreloading) {
      const labels = Array.from(
        document.getElementsByClassName("YMDInput-Section-label")
      );
      labels.forEach((label, index) => {
        label.innerText = modifyLabels[index];
      });
    }
  }, [isPreloading]);

  return (
    <div className="main-content" id="TIMERConnector">
      {isPreloading ? (
        <Skeleton />
      ) : (
        <PageSection
          title="TIMER"
          primaryAction={pageActions[0]}
          secondaryActions={[pageActions[1]]}
        >
          <PageSection.Item size={1} length={3}>
            <TextField
              placeholder="Nombra tu timer"
              value={name}
              onChange={(e) => setName(e.target.value)}
              errorText="El nombre es requerido"
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3}>
            <YMDInput
              onChangeFn={(value) => handleOnChangeTimer(value)}
              value={dataTimer}
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
        </PageSection>
      )}
    </div>
  );
};

export default AddEditTimer;
