import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import Title from "@bit/kiban-design-system.kiban.title";
import Button from "@bit/kiban-design-system.kiban.button";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import YMDInput from '@bit/kiban-design-system.kiban.ymd-input';

import "./AddEditOpenBanking.styles.css";

const FintocForm = ({
  fields,
  type,
  serviceType,
  onFieldChange,
  formValues,
  widget,
  widgetTest,
}) => {
  const { addAlert } = useAlert();
  return (
    <>
      {fields &&
        fields[type] &&
        fields[type][serviceType] &&
        fields[type][serviceType].map((field) => (
          <>
            <PageSection.Item size={1} length={3} title={field.content}>
              {field.type === "select" ? (
                <SelectField
                  label={field.label}
                  placeholder={`Selecciona un elemento para ${field.label}`}
                  options={field.options}
                  onChange={(value) => onFieldChange(field.name, value)}
                  value={formValues[field.name]}
                />
              ) : (
                <>
                  <YMDInput
                    onChangeFn={(value)=> onFieldChange(field.name, value)}
                    value={formValues[field.name]}
                    label={field.label}
                  />
                </>
              )}
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        ))}
      {widget && (
        <>
          <PageSection.Item size={1} length={3}>
            <div className="Fintoc--Widget--Header">
              <Title type="Heading">Widget Producción</Title>
              <Button
                text="Copiar"
                onClick={() =>
                  navigator.clipboard
                    .writeText(widget)
                    .then(() => {
                      addAlert({
                        code: "success",
                        message: "Widget copiado al portapapeles",
                        duration: 5000,
                      });
                    })
                }
              />
            </div>
            <p className="widget--helper">
              El contenido del widget cambiará dinámicamente según se actualice
              la información del conector.
            </p>
            <AceEditor
              name="ob_fintoc_widget"
              mode="javascript"
              fontSize={12}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={false}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              width="100%"
              height="200px"
              readOnly
              value={widget}
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
        </>
      )}
      {widgetTest && (
        <>
          <PageSection.Item size={1} length={3}>
            <div className="Fintoc--Widget--Header">
              <Title type="Heading">Widget Sandbox</Title>
              <Button
                text="Copiar"
                onClick={() =>
                  navigator.clipboard
                    .writeText(widgetTest)
                    .then(() => {
                      addAlert({
                        code: "success",
                        message: "Widget copiado al portapapeles",
                        duration: 5000,
                      });
                    })
                }
              />
            </div>
            <p className="widget--helper">
              El contenido del widget cambiará dinámicamente según se actualice
              la información del conector.
            </p>
            <AceEditor
              name="ob_fintoc_widgetTest"
              mode="javascript"
              fontSize={12}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={false}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              width="100%"
              height="200px"
              readOnly
              value={widgetTest}
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
        </>
      )}
    </>
  );
};

export default FintocForm;
