import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";

const ConfigBuroCO = ({ data, onChange, onSave, errorFields }) => {
  const handleOnChange = (values) => {
    onChange &&
      onChange({
        ...data,
        ...values,
      });
  };

  return (
    <>
      <PageSection title="TransUnion">
        <PageSection.Item title="Activar TransUnion" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                checked={data.Transunion?.Activated}
                name="TransUnionActivated"
                onChange={() =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      Activated: true,
                    },
                  })
                }
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={1}
                checked={!data.Transunion?.Activated}
                name="TransUnionActivated"
                onChange={() =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      Activated: false,
                    },
                  })
                }
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {data.Transunion.Activated && (
          <>
            <PageSection.Item title="Id Cifin" size={1} length={3}>
              <TextField
                value={data.Transunion?.IdCifin}
                onChange={(e) =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      IdCifin: e.target.value,
                    },
                  })
                }
                error={errorFields.TransUnion?.IdCifin}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item title="Contraseña Cifin" size={1} length={3}>
              <TextField
                type="password"
                value={data.Transunion?.PasswordCifin}
                onChange={(e) =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      PasswordCifin: e.target.value,
                    },
                  })
                }
                error={errorFields.TransUnion?.PasswordCifin}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item title="Sandbox - Id Cifin" size={1} length={3}>
              <TextField
                value={data.Transunion?.IdCifinSandbox}
                onChange={(e) =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      IdCifinSandbox: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item
              title="Sandbox - Contraseña Cifin"
              size={1}
              length={3}
            >
              <TextField
                type="password"
                value={data.Transunion?.PasswordCifinSandbox}
                onChange={(e) =>
                  handleOnChange({
                    Transunion: {
                      ...data.Transunion,
                      PasswordCifinSandbox: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
          </>
        )}
      </PageSection>
      <PageSection title="Experian">
        <PageSection.Item title="Activar Experian" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                checked={data.Experian?.Activated}
                name="ExperianActivated"
                onChange={() =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      Activated: true,
                    },
                  })
                }
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={1}
                checked={!data.Experian?.Activated}
                name="ExperianActivated"
                onChange={() =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      Activated: false,
                    },
                  })
                }
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {data.Experian.Activated && (
          <>
            <PageSection.Item title="Usuario" size={1} length={3}>
              <TextField
                value={data.Experian?.User}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      User: e.target.value,
                    },
                  })
                }
                error={errorFields.Experian?.User}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item title="Contraseña" size={1} length={3}>
              <TextField
                type="password"
                value={data.Experian?.Password}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      Password: e.target.value,
                    },
                  })
                }
                error={errorFields.Experian?.Password}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item title="Usuario Acierta" size={1} length={3}>
              <TextField
                value={data.Experian?.UserAcierta}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      UserAcierta: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item title="Contraseña Acierta" size={1} length={3}>
              <TextField
                type="password"
                value={data.Experian?.PasswordAcierta}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      PasswordAcierta: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item title="Usuario sandbox" size={1} length={3}>
              <TextField
                value={data.Experian?.SandboxUser}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      SandboxUser: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item title="Contraseña Sandbox" size={1} length={3}>
              <TextField
                type="password"
                value={data.Experian?.SandboxPassword}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      SandboxPassword: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item
              title="Usuario Acierta Sandbox"
              size={1}
              length={3}
            >
              <TextField
                value={data.Experian?.SandboxUserAcierta}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      SandboxUserAcierta: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item
              title="Contraseña Acierta Sandbox"
              size={1}
              length={3}
            >
              <TextField
                type="password"
                value={data.Experian?.SandboxPasswordAcierta}
                onChange={(e) =>
                  handleOnChange({
                    Experian: {
                      ...data.Experian,
                      SandboxPasswordAcierta: e.target.value,
                    },
                  })
                }
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
          </>
        )}
      </PageSection>
      <div style={{ textAlign: "center" }}>
        <Button text="Guardar" onClick={onSave} style={`primary`} />
      </div>
    </>
  );
};

export default ConfigBuroCO;
