const useAuthReducer = (prevState, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...prevState,
          isLoggedIn: true,
          token: action.token,
        };
      case "LOGOUT":
        return {
          ...prevState,
          isLoggedIn: false,
          token: null,
        };
      default:
        return {
          ...prevState,
        };
    }
  };
  
  export default useAuthReducer;
  