import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Button from "@bit/kiban-design-system.kiban.button";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import alertsTexts from "../../../utils/alerts-texts";

import PredefinedFieldsModal from "./PredefinedFieldsModal";
import CustomFieldModal from "./CustomFieldModal";
import Form from "./Form";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import {
  pagePrimaryAction,
  pageCancelAction,
  getPredefinedFieldsTypes,
  getPredefinedFields,
  getSectionTypes,
  saveForm,
  getForm,
} from "./utils";
import Paths from "../../../paths";

import "./AddEditForm.css";

const AddEditForm = () => {
  const [openPredefinedFieldsModal, setOpenPredefinedFieldsModal] =
    useState(false); // Boolean to open the predefined fields modal
  const [openCustomFieldModal, setOpenCustomFieldModal] = useState(false); // Boolean to open the custom fields modal
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false); // Boolean to open the delete confirmation modal
  const [isLoading, setIsLoading] = useState(false); // Boolean to show the loading animation
  const [predefinedFieldsTypes, setPredefinedFieldsType] = useState([]); // Array of predefined fields types
  const [sectionTypes, setSectionTypes] = useState([]); // Array of section types
  const [name, setName] = useState(""); // String to store the form name
  const [form, setForm] = useState([]); // Array of sections
  const [errors, setErrors] = useState({
    name: false,
  }); // Object to store the errors
  const [fieldTypes, setFieldTypes] = useState([]); // Array of field types
  const [fieldToEdit, setFieldToEdit] = useState(null); // Object to store the field to edit
  const [fieldToDelete, setFieldtoDelete] = useState(null); // Ref to store the field to delete

  const routeParams = useParams();
  const history = useHistory();
  const { addAlert } = useAlert();

  /**
   * Function to get the predefined fields types and section types from the API
   */
  const getAllTypes = async () => {
    setIsLoading(true); // Show the loading animation
    const [types, sections] = await Promise.all([
      // Get the predefined fields types and section types
      getPredefinedFieldsTypes({
        onError: (error) => {
          addAlert({
            code: "danger",
            message: error.message || alertsTexts.unexpectedError,
            duration: 5000,
          });
        },
      }),
      getSectionTypes({
        onError: (error) => {
          addAlert({
            code: "danger",
            message: error.message || alertsTexts.unexpectedError,
            duration: 5000,
          });
        },
      }),
    ]);
    setPredefinedFieldsType(types);
    setSectionTypes(sections);
    setIsLoading(false);
  };

  /**
   * Function to get the form from the API
   */
  const getFormData = async () => {
    const form = await getForm({
      id: routeParams.id,
      onError: (error) => {
        addAlert({
          code: "danger",
          message: error.message || alertsTexts.unexpectedError,
          duration: 5000,
        });
      },
    });
    setName(form.name);
    if (form.sections) setForm(form.sections);
  };

  useEffect(() => {
    getAllTypes(); // Get the predefined fields types and section types
    if (routeParams.id) {
      // If the form id is defined
      getFormData(); // Get the form data
    }
  }, []);

  /**
   * UseEffect hook to set the fieldTypes when form state changes
   */
  useEffect(() => {
    const types = [
      {
        value: null,
        content: "Selecciona un campo",
      },
    ];
    form.forEach((section) => {
      if (section.fields)
        section.fields.forEach((field) => {
          if (
            field.type === "LIST" ||
            field.type === "RADIO" ||
            field.type === "CATALOG" ||
            field.type === "CASO_FICTICIO"
          )
            types.push({
              value: field.id,
              content: field.name,
            });
        });
    });
    setFieldTypes(types);
  }, [form]);

  /**
   *  Function to open or close the predefined fields modal
   */
  const togglePredefinedFieldsModal = () =>
    setOpenPredefinedFieldsModal(!openPredefinedFieldsModal);

  /**
   * Function to open or close the custom fields modal
   */
  const toggleCustomFieldModal = () => {
    if (openCustomFieldModal) setFieldToEdit(null); // Reset the field to edit if the modal is closed
    setOpenCustomFieldModal(!openCustomFieldModal);
  };

  /**
   *  Event handler to select a predefined field type
   * @param {String} type  The predefined field type
   */
  const handleSelectPredefinedFields = async (type) => {
    setIsLoading(true);
    const predefinedFields = await getPredefinedFields({
      form,
      type,
      onError: (error) =>
        addAlert({
          code: "danger",
          message: error.message || alertsTexts.unexpectedError,
          duration: 5000,
        }),
    });
    setForm(predefinedFields || []);
    togglePredefinedFieldsModal();
    setIsLoading(false);
  };

  /**
   *  Event handler to update form fields and sections when DnD event is triggered
   * @param {Object} form The form object
   */
  const handleSort = (form) => {
    setForm(form);
  };

  /**
   *  Event handler to open the custom field modal to edit a field
   *  Set the field to edit in the ref
   * @param {String} sectionId The section id
   * @param {String} fieldId  The field id
   */
  const handleEditAnyField = (sectionId, fieldId) => {
    setFieldToEdit({ section: sectionId, field: fieldId });
    toggleCustomFieldModal();
  };

  /**
   *  Event handler to save the form
   */
  const handleSave = async () => {
    if (name) {
      // If the form name is defined
      const data = await saveForm({
        form: {
          name,
          sections: form,
        },
        id: routeParams.id ? routeParams.id : null, // If the form id is defined, it's an update
        onError: (error) =>
          addAlert({
            code: "danger",
            message:
              error.message || error.status === 409
                ? alertsTexts.nameAlreadyExist
                : alertsTexts.unexpectedError,
            duration: 5000,
          }),
      });
      if (data !== null && data !== undefined) {
        // If the form was saved
        addAlert({
          code: "success",
          message: "Formulario guardado correctamente",
          duration: 5000,
        });
        if (!routeParams.id) {
          // If the form was created
          history.push(Paths.EditForm.replace(":id", data.id)); // Redirect to the form edit page
        }
      }
    } else {
      // If the form name is not defined
      setErrors({
        ...errors,
        name: true,
      });
    }
  };

  /**
   *  Event handler to add a new field or update an existing one
   * @param {Object} field The field object with the new data
   * @param {String} sectionId  The section id
   * @param {String} fieldId  The field id
   */
  const handleApplyCustomField = (field, sectionId, fieldId) => {
    const newForm = form.map((section) => ({
      ...section,
      fields: section.fields.map((f) => ({ ...f })),
    }));
    if (!fieldId && !sectionId) {
      // If the field is new
      const sectionIndex = newForm.findIndex(
        (s) => s.section === field.section
      );
      if (sectionIndex > -1) {
        newForm[sectionIndex].fields.push(field);
      } else {
        newForm.push({
          section: field.section,
          fields: [field],
        });
      }
    }
    if (sectionId && fieldId) {
      // If the field is updated
      const section = newForm.findIndex((s) => s.section === sectionId);
      const fieldIndex = newForm[section].fields.findIndex(
        (f) => f.id === fieldId
      );
      newForm[section].fields.splice(fieldIndex, 1);
      if (sectionId === field.section) {
        // If the section is the same
        newForm[section].fields.splice(fieldIndex, 0, field);
      } else {
        // If the section is different
        const newSectionIndex = newForm.findIndex(
          (s) => s.section === field.section
        );
        if (newSectionIndex > -1) {
          // If the section already exists
          newForm[newSectionIndex].fields.push(field);
        } else {
          // If the section doesn't exist
          newForm.push({
            section: field.section,
            fields: [field],
          });
        }
      }
      if (!newForm[section].fields.length) {
        // If the section is empty
        newForm.splice(section, 1);
      }
    }
    toggleCustomFieldModal();
    setForm(newForm);
  };

  /**
   *  Event handler to open the delete confirmation modal
   * @param {Objet} field  The field to be deleted
   */
  const handleDeleteField = (field) => {
    setOpenDeleteConfirmationModal(true); // Open the delete confirmation modal
    setFieldtoDelete(field); // Set the field to delete in the ref
    toggleCustomFieldModal(); // Close the custom field modal
  };

  /**
   * Event handler to delete a field
   */
  const handleDeleteFieldConfirm = () => {
    const newForm = form.map((section) => ({
      ...section,
      fields: section.fields.map((f) => ({ ...f })),
    })); // Copy the form
    const section = newForm.findIndex(
      (s) => s.section === fieldToDelete.section
    ); // Find the section index
    const fieldIndex = newForm[section].fields.findIndex(
      (f) => f.id === fieldToDelete.id
    ); // Find the field index
    newForm[section].fields.splice(fieldIndex, 1); // Remove the field
    if (!newForm[section].fields.length) {
      // If the section is empty
      newForm.splice(section, 1); // Remove the section
    }
    setOpenDeleteConfirmationModal(false);
    setForm(newForm);
  };

  /**
   * Event handler to close the delete confirmation modal
   */
  const handleCancelDeleteField = () => {
    setFieldtoDelete(null);
    setOpenDeleteConfirmationModal(false);
  };

  const handleCancel = () => {
    history.push(Paths.Connectors.replace(":connectorType", "forms"));
  };

  return (
    <>
      <div className="main-content" id="AddEditForm">
        <PageSection
          title="Formulario"
          primaryAction={pagePrimaryAction(handleSave)}
          secondaryActions={[pageCancelAction(handleCancel)]}
        >
          <PageSection.Item size={1} length={3}>
            <TextField
              label="Nombre"
              placeholder="Nombre del formulario"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={errors.name}
              errorText="El nombre es requerido"
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={1}>
            <div className="add-element-actions-container">
              <Button
                text="Campo(s) predefinido(s)"
                style="primary"
                onClick={togglePredefinedFieldsModal}
              />
              <Button
                text="Campo personalizado"
                style="primary"
                onClick={toggleCustomFieldModal}
              />
            </div>
          </PageSection.Item>
          <Form
            form={form}
            sectionTypes={sectionTypes}
            onSort={handleSort}
            onClickEditButtonField={handleEditAnyField}
            onChange={setForm}
          />
        </PageSection>
      </div>
      <PredefinedFieldsModal
        open={openPredefinedFieldsModal}
        types={predefinedFieldsTypes}
        onClose={togglePredefinedFieldsModal}
        onApply={handleSelectPredefinedFields}
        isLoading={isLoading}
      />
      <DeleteConfirmationModal
        open={openDeleteConfirmationModal}
        onCancelDelete={handleCancelDeleteField}
        onConfrmDelete={handleDeleteFieldConfirm}
      />
      <CustomFieldModal
        open={openCustomFieldModal}
        sectionTypes={sectionTypes}
        onClose={toggleCustomFieldModal}
        onApply={handleApplyCustomField}
        onDelete={handleDeleteField}
        formTypes={fieldTypes}
        fieldToEdit={
          fieldToEdit
            ? form
                .find((s) => s.section === fieldToEdit.section)
                .fields.find(
                  (f) => (f.langs ? f.langs.es : f.id) === fieldToEdit.field
                )
            : undefined
        }
      />
    </>
  );
};

export default AddEditForm;
