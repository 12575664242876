import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
// end design-system
import { apiRequest, apiRoutes } from "../../services";
import "./Api.css";
import Jwt from "../../utils/jwt";

export default function Api() {
  const [clientApiKey, setClientApiKey] = useState(null);
  const [companyCode, setCompanyCode] = useState(null);
  const [errorApi, setErrorApi] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiKeyReference = useRef();
  const companyCodeReference = useRef();

  useEffect(() => {
    async function getdataApi() {
      const [error, data] = await apiRequest({
        method: "get",
        url: apiRoutes.api,
      });
      setErrorApi(error);
      if (!error) {
        setClientApiKey(data.clientApiKey);
        setCompanyCode(data.companyCode);
      }
      setIsLoading(false);
    }

    if (Jwt.validateToken()) {
      getdataApi();
    } else {
      window.location.href = "/login";
    }
  }, []);

  return errorApi ? (
    <Redirect to="/login" />
  ) : isLoading ? (
    <Skeleton />
  ) : (
    <>
      <div className="main-content">
        <PageSection title="API KEY">
          <PageSection.Item size={1} length={2}>
            <TextField
              connectedRight={
                <Button
                  onClick={() => {
                    apiKeyReference.current.focus();
                    apiKeyReference.current.select();
                    navigator.clipboard.writeText(clientApiKey);
                  }}
                  style="primary"
                  text="Copiar"
                />
              }
              readOnly={true}
              id="clientApiKey"
              prefix="API Key"
              reference={apiKeyReference}
              value={clientApiKey ? clientApiKey : ""}
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={2} />
          <PageSection.Item size={1} length={2}>
            <TextField
              connectedRight={
                <Button
                  onClick={() => {
                    companyCodeReference.current.focus();
                    companyCodeReference.current.select();
                    navigator.clipboard.writeText(companyCode);
                  }}
                  style="primary"
                  text="Copiar"
                />
              }
              id="companyCode"
              prefix="Company code"
              readOnly={true}
              reference={companyCodeReference}
              value={companyCode ? companyCode : ""}
            />
          </PageSection.Item>
        </PageSection>
      </div>
    </>
  );
}
