import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  root: {
    padding: "2px 2px 1px !important",
    fontSize: "14px",
  },
  readOnly: {
    backgroundColor: "#D3D3D3",
  },
  underline: {
    "&:before": {
      borderBottomColor: "transparent",
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomColor: "transparent",
    },
    "&:after": {
      opacity: 0,
      transform: "scaleX(1)",
      borderBottomColor: "#3e464c",
      transitionProperty: "opacity",
    },
    "&.Mui-focused:not($disabled):not($error):after": {
      opacity: 1,
    },
  },
});
