import { apiRequest, apiRoutes } from "../../../../../services";

/**
 *  This function is used to normalize the data coming from the API to a format
 *  that is easier to use in the select fields in the app.
 * @param {StepsTypesServices} dataTypes Payload from the server
 * @returns {Object} Normalized data that contains the types, services, sections and fields.
 */

export const normalizeDataTypes = (dataTypes) => {
  let types = []; // Types array
  let services = {}; // Services object
  let sections = {}; // Sections object
  let fields = {}; // Fields object
  let catalogs = {}; // Catalogs object
  let catalogsNames = {};
  dataTypes.forEach((type) => {
    // Loop through the types
    types.push({
      // Push the type to the types array
      content: type.type.langs.es, // Type name
      value: type.type.const, // Type value
    });
    if (type.services) {
      // If the type has services
      type.services.forEach((service) => {
        // Loop through the services
        if (service.const) {
          // If the service has a const
          if (!services[type.type.const]) {
            // If the services object doesn't have the type const
            services[type.type.const] = []; // Create the type const in the services object
          }
          services[type.type.const].push({
            // Push the service to the services object
            content: service.langs.es, // Service name
            value: service.const, // Service value
          });
        }
        if (service.sections) {
          // If the service has sections
          service.sections.forEach((section) => {
            // Loop through the sections
            if (!sections[type.type.const]) {
              // If the sections object doesn't have the type const
              sections[type.type.const] = {}; // Create the type const in the sections object
            }
            if (service.const) {
              // If the service has a const
              if (!sections[type.type.const][service.const]) {
                // If the sections object doesn't have the service const
                sections[type.type.const][service.const] = []; // Create the service const in the sections object
              }
              sections[type.type.const][service.const].push({
                // Push the section to the sections object
                content: section.label, // Section name
                value: section.pathToSection, // Section value
                label: section.pathToSection,
              });
            } else {
              // If the service doesn't have a const (set to default)
              if (!sections[type.type.const]["default"]) {
                // If the sections object doesn't have the default key
                sections[type.type.const]["default"] = []; // Create the default key in the sections object
              }
              sections[type.type.const]["default"].push({
                // Push the section to the sections object
                content: section.label, // Section name
                value: section.pathToSection, // Section value
                label: section.pathToSection,
              });
            }
            if (section.fields) {
              // If the section has fields
              section.fields.forEach((field) => {
                // Loop through the fields
                if (!fields[type.type.const]) {
                  // If the fields object doesn't have the type const
                  fields[type.type.const] = {}; // Create the type const in the fields object
                }
                let serviceConst = service.const || "default"; // If service has const, use it, otherwise use default
                if (!fields[type.type.const][serviceConst]) {
                  // If the fields object doesn't have the service const
                  fields[type.type.const][serviceConst] = {}; // Create the service const in the fields object
                }
                if (
                  !fields[type.type.const][serviceConst][section.pathToSection] // If the fields object doesn't have the section value
                ) {
                  fields[type.type.const][serviceConst][section.pathToSection] =
                    []; // Create the section value in the fields object
                }
                fields[type.type.const][serviceConst][section.pathToSection] // Push the field to the fields object
                  .push({
                    content: field.label, // Field name
                    value: field.pathToField, // Field value
                    type: field.type, // Field type
                  });
                if (field.catalog) {
                  catalogs = buildCataLogs(
                    field.catalog,
                    type,
                    serviceConst,
                    section,
                    field.pathToField,
                    catalogs
                  );
                }
                if (field.catalogName) {
                  catalogsNames = buildCataLogs(
                    null,
                    type,
                    serviceConst,
                    section,
                    field.pathToField,
                    catalogsNames,
                    field.catalogName
                  );
                }
              });
            }
          });
        }
      });
    }
  });
  return {
    types,
    services,
    sections,
    fields,
    catalogs,
    catalogsNames,
  };
};

const buildCataLogs = (
  fieldCatalog,
  type,
  serviceConst,
  section,
  pathToField,
  currentCatalogs,
  catalogName
) => {
  let catalogs = { ...currentCatalogs };
  // If the field has catalogs
  if (fieldCatalog)
    fieldCatalog.forEach((catalog) => {
      // Loop through the catalogs
      if (!catalogs[type.type.const]) {
        // If the catalogs object doesn't have the type const
        catalogs[type.type.const] = {}; // Create the type const in the catalogs object
      }
      if (!catalogs[type.type.const][serviceConst]) {
        // If the catalogs object doesn't have the service const
        catalogs[type.type.const][serviceConst] = {}; // Create the service const in the catalogs object
      }
      if (
        !catalogs[type.type.const][serviceConst][section.pathToSection] // If the catalogs object doesn't have the section value
      ) {
        catalogs[type.type.const][serviceConst][section.pathToSection] = {}; // Create the section value in the catalogs object
      }
      if (
        !catalogs[type.type.const][serviceConst][section.pathToSection][ // If the catalogs object doesn't have the field value
          pathToField
        ]
      ) {
        catalogs[type.type.const][serviceConst][section.pathToSection][
          pathToField
        ] = []; // Create the field value in the catalogs object
      }
      catalogs[type.type.const][serviceConst][section.pathToSection][
        pathToField
      ].push({
        // Push the catalog to the catalogs object
        content: catalog.value,
        value: catalog.key,
      });
    });
  if (catalogName) {
    if (!catalogs[type.type.const]) {
      // If the catalogs object doesn't have the type const
      catalogs[type.type.const] = {}; // Create the type const in the catalogs object
    }
    if (!catalogs[type.type.const][serviceConst]) {
      // If the catalogs object doesn't have the service const
      catalogs[type.type.const][serviceConst] = {}; // Create the service const in the catalogs object
    }
    if (
      !catalogs[type.type.const][serviceConst][section.pathToSection] // If the catalogs object doesn't have the section value
    ) {
      catalogs[type.type.const][serviceConst][section.pathToSection] = {}; // Create the section value in the catalogs object
    }
    if (
      !catalogs[type.type.const][serviceConst][section.pathToSection][ // If the catalogs object doesn't have the field value
        pathToField
      ]
    ) {
      catalogs[type.type.const][serviceConst][section.pathToSection][
        pathToField
      ] = catalogName; // Create the field value in the catalogs object
    }
  }
  return catalogs;
};

export const getCatalog = async (catalogName, onError) => {
  const [error, catalog] = await apiRequest({
    method: "GET",
    url: apiRoutes.getCatalog.replace(":catalogName", catalogName),
  });
  if (error) {
    onError(error);
    return;
  }
  return catalog;
};
