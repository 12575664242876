import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Alert from "@bit/kiban-design-system.kiban.alert";
import Button from "@bit/kiban-design-system.kiban.button";
import { SaveIcon } from "@bit/kiban-design-system.kiban.icons";

import "./AddEditCustomVariables.css";

import LeftPanel from "./AddEditCustomVariablesComponents/LeftPanel";
import CenterPanel from "./AddEditCustomVariablesComponents/CenterPanel";
import RightPanel from "./AddEditCustomVariablesComponents/RightPanel";
import AddVariableModalScreen from "./AddEditCustomVariablesComponents/AddVariableModalScreen";
import addNewFile from "./AddEditCustomVariablesComponents/RightPanel/addNewFile";
import {
  saveProject,
  getVPR,
} from "./AddEditCustomVariablesComponents/requests";
import isFormValid from "./AddEditCustomVariablesComponents/isFormValid";
import Paths from "../../paths";
import areThereAnyChanges from "./AddEditCustomVariablesComponents/areThereAnyChanges";

import { apiRoutes, apiRequest } from "../../services";

const AddEditCustomVariable = () => {
  const routeParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const routeResults = location.search
    ? atob(new URLSearchParams(location.search).get("results"))
    : "";

  const [name, setName] = useState("");
  const [variables, setVariables] = useState([]);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(0);
  const [example, setExample] = useState("");
  const [openVariableModal, setOpenVariableModal] = useState(false);
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState({});
  const [showChangesAlert, setShowChangesAlert] = useState(false);

  useEffect(() => {
    if (
      areThereAnyChanges({
        name,
        example,
        files,
        variables,
        draft,
      })
    ) {
      setShowChangesAlert(true);
    } else {
      setShowChangesAlert(false);
    }
  }, [name, variables, files, example, draft]);

  useEffect(() => {
    if (results.length > 0 && results[0].includes("Error:")) {
      setShowChangesAlert(true);
    }
  }, [results]);

  const newConnector = async () => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.addConnector.replace(":typeConnector", "VP"),
    });
    if (data) {
      history.replace(
        `${Paths.EditConnectors.replace(
          ":connectorType",
          routeParams.connectorType
        ).replace(":id", data.id)}`
      );
    }
    if (error) {
      setErrors([
        ...errors,
        { type: "request", message: error.error.response.data.error },
      ]);
    }
  };

  useEffect(() => {
    const getConnector = async () => {
      const { requestErrors, draft, connector } = await getVPR({
        id: routeParams.id,
        errors,
      });
      setErrors(requestErrors);
      setDraft(draft);
      setName(connector.name);
      setFiles(connector.files);
      setExample(connector.example);
      setVariables(connector.variables);
    };
    if (!routeParams.id) {
      newConnector();
    } else {
      setIsLoading(true);
      getConnector();
    }
  }, [routeParams]);

  useEffect(() => {
    if (location.search) {
      setResults([routeResults]);
    }
  }, [location.search]);

  const toggleModal = () => {
    setOpenVariableModal(!openVariableModal);
  };

  const handleOnApplyVariables = (variables) => {
    setVariables(variables);
  };

  const handleDeleteVariable = (variable) => {
    let allVariables = [...variables];
    allVariables.splice(variable, 1);
    setVariables(allVariables);
  };

  const handleAddNewFile = () => {
    let allFiles = files.map((file) => ({ ...file }));
    setFiles(addNewFile(allFiles));
  };

  const handleOnDeleteFile = (files) => {
    if (!files.length) {
      let allFiles = files.map((file) => ({ ...file }));
      setFiles(addNewFile(allFiles));
    } else {
      setFiles(files);
    }
  };

  const handleSelectFile = (selected) => {
    setFile(selected);
  };

  const handleCodeChange = (code) => {
    let allFiles = files.map((file) => ({ ...file }));
    allFiles[file].data = code;
    setFiles(allFiles);
  };

  const handleOnSave = async () => {
    const { formErrors, isValid } = isFormValid({
      example,
      name,
      variables,
      files,
    });
    if (showChangesAlert) {
      setShowChangesAlert(false);
    }
    if (isValid) {
      const { requestError, results, idConnector } = await saveProject({
        files,
        example,
        name,
        routeParams,
        variables,
      });
      if (results) {
        setDraft({
          name,
          example,
          files,
          variables,
        });
        setResults(results);
      }
      if (requestError)
        setErrors([...errors, { type: "request", message: requestError }]);
    }
    setErrors(formErrors);
    setIsLoading(false);
  };

  const handleChangeOrder = (files) => {
    setFiles(files);
  };

  return (
    <>
      <Grid className="grid-main-container" noGap alignItems="flex-start">
        <Grid.Item size={12}>
          <Grid noGap alignItems="center" className="head">
            <Grid.Item size="content-width">
              <div className="name-field">
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nombre del conector..."
                />
              </div>
            </Grid.Item>
            <Grid.Item size="auto">
              <div className="alert-container">
                {errors.length > 0 && (
                  <Alert
                    code="danger"
                    text={errors.map((error) => error.message).join(", ")}
                  />
                )}
                {showChangesAlert && !errors.length && (
                  <Alert code="info" text="Cambios no guardados" />
                )}
              </div>
            </Grid.Item>
            <Grid.Item size="content-width">
              <Button
                text="Guardar y ejecutar"
                style="primary"
                icon={SaveIcon}
                onClick={() => {
                  setIsLoading(true);
                  handleOnSave();
                }}
              />
            </Grid.Item>
          </Grid>
        </Grid.Item>
        <Grid.Item size="content-width">
          <LeftPanel
            variables={variables}
            onAddNewVariable={toggleModal}
            onDeleteVariable={handleDeleteVariable}
            onEditVariable={toggleModal}
            example={example}
            onExampleChange={setExample}
          />
        </Grid.Item>
        <Grid.Item>
          <CenterPanel
            file={files[file]}
            onCodeChange={handleCodeChange}
            output={results.length > 0 ? results.join("\n") : ""}
            isLoading={isLoading}
          />
        </Grid.Item>
      </Grid>
      <RightPanel
        files={files}
        onAddNew={handleAddNewFile}
        onFileNameChange={setFiles}
        onDeleteFile={handleOnDeleteFile}
        onSelectFile={handleSelectFile}
        selectedFile={file}
        onChangeOrder={handleChangeOrder}
      />
      <AddVariableModalScreen
        open={openVariableModal}
        onClose={toggleModal}
        onApply={handleOnApplyVariables}
        variables={variables}
      />
    </>
  );
};

export default AddEditCustomVariable;
