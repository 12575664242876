import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { useParams, useHistory } from "react-router-dom";

import Alert from "@bit/kiban-design-system.kiban.alert";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import InputFile from "@bit/kiban-design-system.kiban.input-file";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Panel from "../../shared/Panel";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Texts from "../../utils/app-texts.json";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import Paths from "../../paths";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";

export default function AddEditCasosFicticios() {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [typesServices, setTypesServices] = useState([]);
  const [optionsTypes, setOptionsTypes] = useState([]);
  const [typeSelected, setTypeSelected] = useState(null);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [optionsServices, setOptionsServices] = useState([]);
  const [casoFicticio, setCasoFicticio] = useState(null);
  const [name, setName] = useState("");
  const [json, setJson] = useState("");
  const [fileJson, setFileJson] = useState(null);
  const [errorsFields, setErrorFields] = useState({
    name: false,
    type: false,
    service: false,
  });

  const history = useHistory();
  const routerParams = useParams();
  const isEdit = routerParams.hasOwnProperty("id");

  const getTypesServices = async () => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getCatalogTypesServices,
    });
    if (!err) {
      setTypesServices(data);
      const options = data.map((item) => {
        return {
          content: item.type.langs.es,
          value: item.type.const,
        };
      });
      setOptionsTypes(options);
    } else {
      setStatus({
        code: "danger",
        msg: "Ocurrio un error, intenta de nuevo",
      });
    }
    setIsLoading(false);
  };

  const createCasoFicticio = async () => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      data: {
        name,
        type: typeSelected.value,
        service: optionsServices ? serviceSelected.value : "",
      },
      method: "post",
      url: apiRoutes.casosFicticios,
    });
    if (!err) {
      setStatus({
        code: "success",
        msg: "Se creó correctamente el caso ficticio",
      });
      setCasoFicticio(data);
      setJson(b64ToJson(data.text));
      history.push(Paths.EditCasosFicticios.replace(":id", data.id));
    } else {
      const msg =
        err.status && err.status === 409
          ? "Este caso ficticio ya existe, intenta de nuevo"
          : "Ocurrio un error, intenta de nuevo";
      setStatus({
        code: "danger",
        msg: msg,
      });
    }
    setIsLoading(false);
  };

  const updateCasoFicticio = async () => {
    setIsLoading(true);
    const dataJson = fileJson
      ? fileJson
      : btoa(unescape(encodeURIComponent(json)));
    const newCasoFicticio = {
      ...casoFicticio,
      name,
      text: dataJson,
    };
    const [err, data] = await apiRequest({
      data: newCasoFicticio,
      method: "put",
      url: `${apiRoutes.casosFicticios}?id=${routerParams.id}`,
    });
    if (!err) {
      getCasoFicticioById();
      setStatus({
        code: "success",
        msg: "Se actualizó correctamente el caso ficticio",
      });
    } else {
      setStatus({
        code: "danger",
        msg: "Ocurrio un error, intenta de nuevo",
      });
    }
    setIsLoading(false);
  };

  const getCasoFicticioById = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getCasoFicticioById}?id=${routerParams.id}`,
    });
    if (!err) {
      setCasoFicticio(data);
      setName(data.name);
      setJson(b64ToJson(data.text));
    } else {
      setStatus({
        code: "danger",
        msg: "Ocurrio un error, intenta de nuevo",
      });
    }
  };

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  useEffect(() => {
    if (Jwt.validateToken()) {
      getTypesServices();
      if (isEdit) {
        getCasoFicticioById();
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (typeSelected && !isEdit) {
      const typeServiceSelected = typesServices.find(
        (item) => item.type.const === typeSelected.value
      );
      const services = typeServiceSelected.services
        ? typeServiceSelected.services.map((service) => {
            return {
              content: service.langs.es,
              value: service.const,
            };
          })
        : null;
      setOptionsServices(services);
    }
  }, [typeSelected]);

  useEffect(() => {
    if (casoFicticio && typesServices.length > 0) {
      const typeServiceSelected = typesServices.find(
        (item) => item.type.const === casoFicticio.type
      );
      const serviceSelected = typeServiceSelected.services
        ? typeServiceSelected.services.find(
            (item) => item.const === casoFicticio.service
          )
        : null;
      setTypeSelected({
        text: typeServiceSelected.type.langs.es,
        value: typeServiceSelected.type.const,
      });
      if (serviceSelected) {
        setServiceSelected({
          text: serviceSelected.langs.es,
          value: serviceSelected.const,
        });
      }
    }
  }, [casoFicticio, typesServices]);

  const handleOnChangeOptionsTypes = (value) => {
    const typeServiceSelected = typesServices.find(
      (item) => item.type.const === value
    );
    setTypeSelected({
      text: typeServiceSelected.type.langs.es,
      value: typeServiceSelected.type.const,
    });
    setServiceSelected(null);
  };

  const handleOnChangeOptionsServices = (value) => {
    const serviceSelected = typesServices
      .find((item) => item.type.const === typeSelected.value)
      .services.find((item) => item.const === value);
    setServiceSelected({
      text: serviceSelected.langs.es,
      value: serviceSelected.const,
    });
  };

  const onSave = () => {
    const errorService = isEdit ? false : optionsServices ? !serviceSelected: false ;
    const updateErrors = {
      name: name === "",
      type: !typeSelected,
      service: errorService,
    };
    setErrorFields(updateErrors);
    if (name !== "" && typeSelected && !errorService) {
      if (isEdit) {
        updateCasoFicticio();
      } else {
        createCasoFicticio();
      }
    }
  };

  const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const b64ToJson = (strB64) => {
    try {
      return JSON.stringify(JSON.parse(b64DecodeUnicode(strB64)), undefined, 2);
    } catch {
      return "";
    }
  };

  const handleUploadFile = (data) => {
    setFileJson(data.length > 0 ? data[0].content : null);
  };

  const downloadJson = () => {
    let download = document.createElement("a");
    download.textContent = "download";
    download.download = `${name}.json`;
    download.href = "data:text/json;charset=utf-8," + json;
    download.click();
    setStatus({
      code: "success",
      msg: "Se ha descargado el archivo JSON",
    });
  };

  const copyJson = () => {
    navigator.clipboard.writeText(json);
    setStatus({
      code: "success",
      msg: "El JSON se copió a tu portapapeles",
    });
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      {status && (
        <Alert
          code={status.code}
          key={"alert-success-casosFicticios"}
          text={status.msg}
        />
      )}
      <PageSection title={`${isEdit ? "Editar" : "Nuevo"} caso ficticio`}>
        <PageSection.Item size={1} length={3}>
          <TextField
            error={errorsFields.name}
            errorText="El campo no puede estar vacio"
            label={Texts.connectors.fields.name}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <SelectField
            error={errorsFields.type}
            errorText="Debe seleccionar una opción"
            label={Texts.connectors.fields.connectorType}
            disabled={isEdit}
            placeholder={
              isEdit
                ? typeSelected && typeSelected.text
                : Texts.connectors.fields.connectorTypePlaceholder
            }
            onChange={handleOnChangeOptionsTypes}
            value={typeSelected && typeSelected.value}
            options={isEdit ? [] : optionsTypes}
          />
          {optionsServices && (
            <SelectField
              error={errorsFields.service}
              errorText="Debe seleccionar una opción"
              label={Texts.connectors.fields.serviceType}
              disabled={isEdit}
              placeholder={
                isEdit
                  ? serviceSelected && serviceSelected.text
                  : Texts.connectors.fields.serviceTypePlaceholder
              }
              onChange={handleOnChangeOptionsServices}
              value={serviceSelected && serviceSelected.value}
              options={isEdit ? [] : optionsServices}
            />
          )}
          {isEdit && (
            <>
              <Title type="heading">Subir archivo</Title>
              <InputFile
                encodeBase64={true}
                handleChange={(data) => handleUploadFile(data)}
                label={"Agrega tu archivo txt/json/xml/intl"}
                extensionsFiles={[
                  "txt",
                  "TXT",
                  "json",
                  "JSON",
                  "xml",
                  "XML",
                  "intl",
                  "INTL",
                ]}
              />
            </>
          )}
        </PageSection.Item>
        {isEdit ? (
          <PageSection.Item size={2} length={3}>
            <Panel.Section title="JSON">
              <AceEditor
                mode="javascript"
                name="vp_editor_example"
                fontSize={12}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={false}
                setOptions={{
                  enableBasicAutocompletion: false,
                  enableLiveAutocompletion: false,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                  scrollPastEnd: true,
                }}
                width="100%"
                value={json}
                onChange={setJson}
              />
            </Panel.Section>
          </PageSection.Item>
        ) : (
          <PageSection.Item size={2} length={3} />
        )}
        {isEdit && (
          <>
            <PageSection.Item size={1} length={3} />
            <PageSection.Item size={1} length={3} />
            <PageSection.Item size={1} length={3}>
              <ButtonGroup>
                <Button style="light" text="Descargar" onClick={downloadJson} />
                <Button style="light" text="Copiar" onClick={copyJson} />
              </ButtonGroup>
            </PageSection.Item>
          </>
        )}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button style="primary" text="Guardar" onClick={onSave} />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => history.push(Paths.CasosFicticios)}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
}
