import { apiRequest, apiRoutes } from "../../../../services";
import b64DecodeUnicode from "../../../../utils/b64DecodeUnicode";

const getVPR = async ({ id, errors }) => {
  let connector = {};
  let draft = {};
  let requestErrors = [];
  const [error, data] = await apiRequest({
    method: "get",
    url: `${apiRoutes.getConnector.replace(":id", id)}`,
  });
  if (data) {
    connector.name = data.name;
    draft.name = data.name;
    connector.example = b64DecodeUnicode(data.example);
    draft.example = b64DecodeUnicode(data.example);
    const allFiles = data.js_files.map((file) => {
      return {
        ...file,
        data: b64DecodeUnicode(file.data),
      };
    });
    connector.files = allFiles;
    connector.variables = data.variables;
    draft.files = allFiles;
    draft.variables = data.variables;
  }
  if (error) {
    requestErrors = [
      ...errors,
      { type: "request", message: error.error.response.data.error },
    ];
  }
  return { requestErrors, draft, connector };
};

export default getVPR;
