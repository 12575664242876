import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AuthNipSic from "./AuthNipSic";
import AuthNipGeneric from "./AuthNipGeneric";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import AlertTexts from "../../utils/alerts-texts";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";

import "./AuthNip.css";

const AuthNip = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataNip, setDataNip] = useState(null);
  const [steps, setSteps] = useState([
    {
      name: "Primera verificación",
      status: false,
      isFullBar: false,
    },
  ]);
  const params = useParams();
  const { addAlert } = useAlert();

  const getStatusNip = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.statusNip}?idWorkfloo=${params.id}`,
    });
    if (data) {
      setDataNip(data);
      const updateSteps = [...steps];
      if (data.totalSteps > 2 && steps.length < 2) {
        updateSteps.push({
          name: "Segunda verificación",
          status: false,
          isFullBar: false,
        });
        setSteps(updateSteps);
      }
    }
    if (error) {
      addAlert({
        code: "danger",
        message: AlertTexts.tecnicalError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getStatusNip();
    } else {
      window.location.href = "/login";
    }
  }, []);

  return isLoading || !dataNip ? (
    <Skeleton />
  ) : dataNip.nipType === "SIC" ? (
    <AuthNipSic />
  ) : (
    <AuthNipGeneric
      dataNip={dataNip}
      steps={steps}
      setSteps={setSteps}
      getStatusNip={getStatusNip}
    />
  );
};

export default AuthNip;
