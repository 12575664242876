const getMessageError = (status, messageError) => {
  switch (status) {
    case 500:
      return "Ocurrio un error inesperado en el servidor";
    case 400:
      return !messageError ? "Ocurrio un error inesperado" : messageError;
    case 403:
      return "Token inválido";
    case 404:
      return "No se encontró la ruta, favor de verificar";
    default:
      return "Ocurrio un error inesperado";
  }
}

export {
  getMessageError,
}