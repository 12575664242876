import React from "react";

import HeaderActions from "./HeaderActions";

const Section = ({
  children,
  title,
  primaryAction,
  secondaryActions,
  subdued,
}) => {
  return (
    <div className={`Panel_Section_Container${subdued ? " subdued" : ""}`}>
      {(title || primaryAction) && (
        <div className="Panel_Section_Header">
          <h2 className="Panel_Title">{title}</h2>
          {(primaryAction || secondaryActions) && (
            <HeaderActions
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default Section;
