import React, { useEffect, useState, useRef } from "react";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";

export default function ConfigurationCleanData() {
  const [loading, setLoading] = useState(true);
  const [dataConfig, setDataConfig] = useState({});
  const [errorFields, setErrorFields] = useState([]);
  const { addAlert } = useAlert();

  const getConfigBuro = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "CAMARA_COMERCIO_BOGOTA"
      ),
    });
    if (!err) {
      const responseDataConfig = data.hasOwnProperty("cleanDataCOResponse")
        ? data.cleanDataCOResponse.CamaraComercioBogota
        : null;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigBuro();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig);
    let updateConfig = { ...dataConfig };
    setDataConfig(updateConfig);
    return {
      cleanDataRequest: {
        CamaraComercioBogota: { ...updateConfig },
      },
    };
  };

  const updateConfigBuro = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "CAMARA_COMERCIO_BOGOTA"
      ),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const handleOnChangeConfig = (key, value) => {
    const updateConfig = { ...dataConfig };
    updateConfig[key] = value;
    const updateErrors = errorFields.includes(`${key}`)
      ? errorFields.filter((keyError) => keyError !== `${key}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    const notRequired = ["Activate", "UserSandbox", "PasswordSandbox"];
    const keys = Object.keys(dataConfig).filter(
      (key) => !notRequired.includes(key)
    );
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (dataConfig.Activate && updateConfig[key] === "") {
        objErrors.push(key);
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigBuro();
    }
  };

  return loading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      {dataConfig.hasOwnProperty("Activate") && (
        <PageSection>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Cámara Comercio Bogotá</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="camara-comercio"
                  value={true}
                  checked={dataConfig.Activate}
                  onChange={(checked, value) =>
                    handleOnChangeConfig("Activate", value === "true")
                  }
                />
                <RadioButton
                  label="No"
                  name="camara-comercio"
                  value={false}
                  checked={!dataConfig.Activate}
                  onChange={(checked, value) =>
                    handleOnChangeConfig("Activate", value === "true")
                  }
                />
              </div>
            </div>
            {dataConfig.Activate && (
              <>
                <TextField
                  error={errorFields.includes("User")}
                  errorText={"El campo no debe ir vacio"}
                  label="Usuario"
                  value={dataConfig.User}
                  onChange={(e) => handleOnChangeConfig("User", e.target.value)}
                />
                <TextField
                  error={errorFields.includes("Password")}
                  errorText={"El campo no debe ir vacio"}
                  label="Password"
                  type="password"
                  value={dataConfig.Password}
                  onChange={(e) =>
                    handleOnChangeConfig("Password", e.target.value)
                  }
                />
                <TextField
                  label="Usuario Sandbox"
                  value={dataConfig.UserSandbox}
                  onChange={(e) =>
                    handleOnChangeConfig("UserSandbox", e.target.value)
                  }
                />
                <TextField
                  label="Password Sandbox"
                  type="password"
                  value={dataConfig.PasswordSandbox}
                  onChange={(e) =>
                    handleOnChangeConfig("PasswordSandbox", e.target.value)
                  }
                />
                <TextField
                  error={errorFields.includes("OfflineHoursExpiration")}
                  errorText={"El campo no debe ir vacio"}
                  label="Solicitud offline expiracion(horas)"
                  value={dataConfig.OfflineHoursExpiration}
                  onChange={(e) =>
                    handleOnChangeConfig(
                      "OfflineHoursExpiration",
                      e.target.value
                    )
                  }
                />
              </>
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={1}>
            <Button onClick={saveConfig} style="primary" text="Guardar" />
          </PageSection.Item>
        </PageSection>
      )}
    </div>
  );
}
