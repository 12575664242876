import { apiRequest, apiRoutes } from "../../../../services";

const getMessagingConnectors = async () => {
  let connectors = null;
  let error = null;
  const [err, res] = await apiRequest({
    method: "get",
    url: `${apiRoutes.connectors.replace("{type}", "MESSAGING")}&default=true`,
  });
  if (res) {
    connectors = {};
    res.forEach((connector) => {
      if (!connectors[connector.type]) {
        connectors[connector.type] = [];
      }
      connectors[connector.type].push({
        content: connector.name,
        value: connector.id,
      });
    });
  }
  if (err) error = err;
  return {
    connectors,
    error,
  };
};

export default getMessagingConnectors;
