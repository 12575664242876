import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Texts from "../../../utils/app-texts.json";

const ArusForm = ({ data, onChange }) => {
  return (
    <>
      <PageSection.Item size={1} length={3}>
        <TextField
          label={Texts.connectors.fields.url}
          placeholder="https://example.com"
          value={data.url}
          onChange={(e) => onChange("url", e.target.value)}
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
    </>
  );
};

export default ArusForm;
