import { apiRequest, apiRoutes } from "../../../../../services";

export const getStepTypesServices = async ({ onError }) => {
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getStepTypesServices,
  });
  if (data) {
    return data;
  }
  if (error) {
    onError && onError(error);
  }
};
