import React from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import "./Body.css";

import b64DecodeUnicode from "../../../utils/b64DecodeUnicode";

const Body = ({ onChange, code }) => {
  return (
    <PageSection title="Body">
      <AceEditor
        name="api-connector-editor"
        mode="javascript"
        fontSize={12}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={true}
        onChange={onChange}
        value={code ? b64DecodeUnicode(code) : ``}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          cursorStyle: "smooth",
          scrollPastEnd: true,
        }}
        width="100%"
      />
    </PageSection>
  );
};

export default Body;
