import React from "react";
import Panel from "../../../../shared/Panel";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";

const OutputSection = ({ output }) => {
  return (
    <Panel title="Resultados" className="OutputSection">
      <AceEditor
        name="api_output_section"
        mode="javascript"
        fontSize={12}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={false}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          scrollPastEnd: true,
        }}
        width="100%"
        readOnly
        value={output}
      />
    </Panel>
  );
};

export default OutputSection;
