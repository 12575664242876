import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  root: {
    fontSize: "14px",
    height: "30px",
    padding: "0 14px",
    textTransform: "none",
    overflow: "hidden",
    boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%)",
    "& .MuiButton-label": {
      fontFamily: '"Roboto","Helvetica Neue",sans-serif !important',
      fontWeight: "400",
    },
  },
  primary: {
    backgroundColor: "rgb(44, 191, 85) !important",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "rgb(78, 201, 112) !important",
    },
    "&:focus": {
      backgroundColor: "#556068 !important",
    },
  },
  fab: {
    padding: 0,
    width: "32px",
    height: "32px",
    minHeight: "0",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  primaryAction: {
    marginTop: "-10px",
    marginBottom: "20px",
  },
  endPage: {
    marginTop: "10px",
  }
});
