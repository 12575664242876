import React, { useRef, useEffect } from "react";

import "./InformativeTextField.css";

const InformativeTextField = ({ label, previewLabel, value, onChange }) => {
  const fieldRef = useRef();
  const previewRef = useRef();

  /**
   * UseEffect used to set default value
   */
  useEffect(() => {
    if (value) fieldRef.current.innerText = value;
  }, []);

  /**
   * Event handler to handle the field value change
   */
  const handleChange = () => {
    const content = fieldRef.current.innerHTML;
    onChange && onChange(content);
  };

  /**
   *  Function to decode the HTML entities
   * @param {String} value
   */
  const decodeHTML = (value) => {
    let textArea = document.createElement("textarea"); // Create a <textarea> element
    textArea.innerHTML = value; // Set its value to the encoded string
    return textArea.value; // Output the value of the <textarea> element
  };

  return (
    <div className="InformativeTextField-Container">
      {label && <label>{label}</label>}
      <div
        className="InformativeTextField"
        tabIndex="1"
        contentEditable
        ref={fieldRef}
        onInput={handleChange}
      ></div>
      <div className="preview-container">
        {previewLabel && (
          <div className="preview-label-header">{previewLabel}</div>
        )}
        <div
          ref={previewRef}
          className="preview-content"
          dangerouslySetInnerHTML={{
            __html: decodeHTML(value),
          }}
        ></div>
      </div>
    </div>
  );
};

export default InformativeTextField;
