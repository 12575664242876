import jwt_decode from "jwt-decode";

import CryptoJS from "crypto-js";

const KEY_SECRET = process.env.REACT_APP_SECRET_KEY_JWT || "";
class Jwt {
  /*
    return value token save in localStorage
  */
  static getToken() {
    return localStorage.getItem("token");
  }

  /*
    save value token
    if exists and is the same don't save it.
  */
  static setToken(token) {
    if (token !== null && this.getToken() !== token) {
      localStorage.setItem("token", token);
    }
  }

  /*
    return value of key.
    if key is all return all values.
    if header is true return values headers.
    @Param key: {all, sessionId, login, role, companyCode}
    @Param headers: {true, false}
  */
  static getItem(key, header = false) {
    if (this.getToken()) {
      const data = jwt_decode(this.getToken(), { header: header });
      return key === "all" ? data : data[key];
    }
    return null;
  }

  /*
    Remove item token in localStorage
  */
  static removeToken() {
    localStorage.clear();
  }

  /*
    Encode to base64
    @Param: source {data to encode}
  */
  static base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);
    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, "");
    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");
    return encodedSource;
  }

  /*
    Validate if token save in localStorage didn't change for any user.
  */
  static validateToken() {
    const token = this.getToken();
    if (token === null) {
      return false;
    }
    const data = CryptoJS.enc.Utf8.parse(JSON.stringify(this.getItem("all")));
    const headers = CryptoJS.enc.Utf8.parse(
      JSON.stringify(this.getItem("all", true))
    );
    const b64Data = this.base64url(data);
    const b64Headers = this.base64url(headers);
    const tkn = this.base64url(
      CryptoJS.HmacSHA256(b64Headers + "." + b64Data, KEY_SECRET)
    );
    if (tkn !== token.split(".")[2]) {
      this.removeToken();
    }
    return tkn === token.split(".")[2];
  }

  /*
    Sign new JWT
    @Param: payload {object to encode}
  */
  static signToken(payload) {
    const data = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
    const headers = CryptoJS.enc.Utf8.parse(
      JSON.stringify({ alg: "HS256", typ: "JWT" })
    );
    const b64Data = this.base64url(data);
    const b64Headers = this.base64url(headers);

    const token = b64Headers + "." + b64Data;

    let signature = CryptoJS.HmacSHA256(token, KEY_SECRET);
    signature = this.base64url(signature);
    return token + "." + signature;
  }
}

export default Jwt;
