import React from "react";
import DynamicTable from "@bit/kiban-design-system.kiban.dynamic-table";
import PageSection from "@bit/kiban-design-system.layout.page-section";

const HeaderQueryParams = ({
  title,
  onRemove,
  onAdd,
  sectionKey,
  onChange,
  data,
}) => {
  const headers = ["Nombre", "Valor"];
  return (
    <PageSection title={title}>
      <PageSection.Item size={1} length={1}>
        <DynamicTable
          headings={headers}
          rows={data || []}
          onAddRow={() => onAdd && onAdd(sectionKey)}
          onRemoveRow={(item) => onRemove && onRemove(sectionKey, item)}
          onHandleChange={(value, row, item) =>
            onChange && onChange(sectionKey, value, row, item)
          }
        />
      </PageSection.Item>
    </PageSection>
  );
};

export default HeaderQueryParams;
