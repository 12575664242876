const Paths = {
  Api: "/api",
  Base: "/",
  Login: "/login",
  Documentation: "/documentation",
  Home: "/home",
  Users: "/configuration/users",
  AddUser: "/user/add",
  EditUser: "/user/:id",
  Company: "/company",
  Nip: "/auditoria/nip",
  pdfDocument: "/connectors/:id/pdfTemplate",
  Authorization: "/auditoria/authorization",
  Workfloos: "/workfloos",
  Connectors: "/connectors/:connectorType",
  AddConnectors: "/connectors/:connectorType/add",
  EditConnectors: "/connectors/:connectorType/edit/:id",
  Synchronization: "/configuration/synchronization",
  Historic: "/historic",
  Variables: "/variables-personalizadas",
  CasosFicticios: "/casos-ficticios",
  AddCasosFicticios: "/casos-ficticios/add",
  EditCasosFicticios: "/casos-ficticios/edit/:id",
  AuthNipSic: "/workfloo/sic-auth-nip/:id",
  AuthNip: "/workfloo/nip/:id",
  AddWorkfloo: "/workfloos/add",
  EditWorkfloo: "/workfloos/edit/:id",
  FintocWidget: "/workfloo/fintoc/:id",
  EditForm: "/connectors/forms/edit/:id",
  DetailHistoricWorkfloo: "/historic/:idHistoryWorkfloo",
  RecoveryPassword: "/recovery-password",
  ResetPassword: "/resetPassword",
  StartWorkfloo: "/workfloo",
  Forbidden: "/forbidden",
  Mati: "/workfloo/mati/:id",
  MiFiel: "/workfloo/mifiel/:id",
  Rainao: "/workfloo/rainao/:id",
};

export default Paths;
