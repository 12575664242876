import { apiRequest, apiRoutes } from "../../../services";
import alertsTexts from "../../../utils/alerts-texts";
import sortCatalog from "../../../utils/sort-catalogs";
/**
 *  Make a request to the API to create a new Connector
 * @param {HistoryLocationState} history React Router history
 * @param {Function} addAlert Alert provider add alert function
 */
export const createConnector = async ({ history, addAlert, onCreate }) => {
  const [error, data] = await apiRequest({
    url: apiRoutes.addConnector.replace(":typeConnector", "POLICY"),
    method: "POST",
    data: {
      name: "Nuevo árbol de decisión",
      steps: [],
      messages: [],
      nodes: [],
    },
  });
  if (data) {
    history.replace(`/connectors/credit-policies/edit/${data.id}`);
    onCreate(data);
  }
  if (error) {
    let message = alertsTexts.unexpectedError;
    if (error.status === 409) {
      message = alertsTexts.nameAlreadyExist;
    }
    addAlert({
      code: "danger",
      message: message,
      duration: 5000,
    });
    history.replace(`/connectors/credit-policies`);
  }
};

/**
 *  Make a request to the API to get connector by id
 * @param {HistoryLocationState} history React Router history
 *  @param {Function} addAlert Alert provider add alert function
 * @param {Number | String} id Connector id
 * @returns  {Promise} Promise with the connector data
 */
export const getConnector = async ({ history, addAlert, id }) => {
  const [error, data] = await apiRequest({
    method: "GET",
    url: apiRoutes.getConnector.replace(":id", id),
  });
  if (data) {
    return data;
  }
  if (error) {
    addAlert({
      code: "danger",
      message: alertsTexts.unexpectedError,
    });
    history.replace(`/connectors/credit-policies`);
  }
};

/**
 *  Returns a new node added
 * @param {Object} node Node to add
 * @param {Object} selectedItem Information from step
 * @param {Array} steps Used to get the selected step name
 * @param {Array} messages Used to get the selected message name
 * @returns  {Object} New node added
 */
export const getLastCreatedNode = ({
  node,
  selectedItem,
  steps,
  messages,
}) => ({
  ...node,
  type: selectedItem.type,
  itemId: selectedItem.id,
  title:
    selectedItem.type === "STEP"
      ? steps.find((step) => step.id === selectedItem.id).name
      : messages.find((message) => message.id === selectedItem.id).name,
  sourceEndpoints:
    selectedItem.type === "STEP"
      ? [
          {
            id: "true-ep",
            position: "BottomLeft",
            label: {
              label: "Verdadero",
              location: 0.5,
            },
            children: [],
          },
          {
            id: "false-ep",
            position: "BottomRight",
            label: {
              label: "Falso",
              location: 0.5,
            },
            children: [],
          },
        ]
      : [],
});

/**
 *  Make a request to the API to update a connector
 * @param {String} name Connector name
 * @param {Array} steps Steps of the connector
 * @param {Array} messages Messages of the connector
 * @param {Array} nodes Nodes of the connector
 * @param {Function} onSuccess Success callback
 * @param {Function} onError Error callback
 * @param {String} method Request method
 *  @parama {String} id Connector id
 */
export const saveConnector = async ({
  name,
  auxName,
  nodes,
  onSuccess,
  onError,
  method,
  id,
}) => {
  const connectorNodes = nodes.map((node) => {
    // Loop through all nodes to parse them to the API nodes format
    let nd = {
      id: parseInt(node.id.split("-")[1]),
      root: false,
      stepId: node.itemId,
      posX: typeof node.posX === "number" ? `${node.posX}px` : node.posX,
      posY: typeof node.posX === "number" ? `${node.posY}px` : node.posY,
    };
    if (node.sourceEndpoints && node.sourceEndpoints.length > 0) {
      nd.childTrueNodeId = parseInt(
        node.sourceEndpoints[0].children[0].split("-")[1]
      );
      nd.childFalseNodeId = parseInt(
        node.sourceEndpoints[1].children[0].split("-")[1]
      );
    }
    return nd;
  });

  let connector = {
    name: name,
    nodes: connectorNodes,
  };

  // if (name !== auxName) {
  //   connector.name = name;
  // }

  const [error, data] = await apiRequest({
    method: method,
    url: `${apiRoutes.addConnector.replace(":typeConnector", "POLICY")}/${id}`,
    data: connector,
  });
  if (data !== null) {
    onSuccess();
  }
  if (error) {
    onError(error);
  }
};

/**
 *  Make a request to the API to duplicate a Step
 * @param {String} id Step id
 * @param {String} connectorId Connector id
 * @param {Function} onSuccess On success callback
 * @param {Function} onError On error callback
 */
export const duplicateStep = async ({
  id,
  connectorId,
  onSuccess,
  onError,
}) => {
  const [error, data] = await apiRequest({
    method: "put",
    url: `${apiRoutes.addConnector.replace(
      ":typeConnector",
      "POLICY"
    )}/step/copy?connectorId=${connectorId}&stepId=${id}`,
  });
  if (data) {
    onSuccess(data);
  }
  if (error) {
    onError();
  }
};

export const deleteStep = async ({ id, connectorId, onSuccess, onError }) => {
  const [error, data] = await apiRequest({
    method: "delete",
    url: `${apiRoutes.addConnector.replace(
      ":typeConnector",
      "POLICY"
    )}/step?connectorId=${connectorId}&stepId=${id}`,
  });
  if (data) {
    onSuccess(data);
  }
  if (error) {
    onError();
  }
};

/**
 *  Used to parse nodes from API format to the FlowChart component format
 * @param {Array} nodes Nodes in API format
 * @param {Array} steps Steps from API
 * @param {Array} messages Messages from API
 * @returns {Array} Nodes in FlowChart component format
 */
export const parseNodes = ({ nodes, steps, messages }) => {
  return nodes.map((node) => {
    // Loop through all nodes to parse them to the FlowChart component format
    const nd = {
      id: `Element-${node.id}`,
      itemId: node.stepId,
      root: node.root,
      type: node.childTrueNodeId || node.childFalseNodeId ? "STEP" : "MESSAGE", // If the node has children, it's a step
      posX: node.posX,
      posY: node.posY,
      targetConnectionsLimit: -1,
      sourceConnectionsLimit: -1,
      title:
        node.childTrueNodeId || node.childFalseNodeId
          ? steps.find((step) => step.id === node.stepId).name
          : messages.find((message) => message.id === node.stepId).name, // If the node has children then assign the step name
      sourceEndpoints:
        node.childTrueNodeId || node.childFalseNodeId
          ? [
              {
                id: `true-ep`,
                position: "BottomLeft",
                label: {
                  label: "Verdadero",
                  location: 0.5,
                },
                children: [`Element-${node.childTrueNodeId}`],
              },
              {
                id: `false-ep`,
                position: "BottomRight",
                label: {
                  label: "Falso",
                  location: 0.5,
                },
                children: [`Element-${node.childFalseNodeId}`],
              },
            ]
          : [],
    };
    return nd;
  });
};
