import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import OptionField from "@bit/kiban-design-system.kiban.option-field";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import Textarea from "@bit/kiban-design-system.kiban.textarea";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import Texts from "../../../utils/app-texts.json";

const AddEditMessaging = () => {
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const history = useHistory();

  // SMS
  const [optionsPhones, setOptionsPhones] = useState([]);
  const [phone, setPhone] = useState("");
  const [background, setBackground] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState("");
  const [maxCharacters, setMaxCharacteres] = useState(140);
  // SENDGRID
  const [destType, setDestType] = useState("FIELD");
  const [field, setField] = useState("");
  const [optionsFields, setOptionsFields] = useState([]);
  const [optionsPictures, setOptionsPictures] = useState(null);
  const [optionsPicturesFilter, setOptionsPicturesFilter] = useState(null);
  const [selectedItemsPicture, setSelectedItemsPicture] = useState([]);
  const [documents, setDocuments] = useState(null);
  const [documentsFilter, setDocumentsFilter] = useState(null);
  const [selectedItemsDocument, setSelectedItemsDocument] = useState([]);
  const [destFixed, setDestFixed] = useState("");
  const [destTypes, setDestTypes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState();
  const [sender, setSender] = useState("");
  const [attachmentsEdit, setAttachmentsEdit] = useState([]);
  // GENERAL
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getCatalogTypesServices = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectorTypeServices.replace(
        ":connector",
        routeParams.connectorType.toUpperCase()
      ),
    });
    if (!error) {
      setOptions(
        data.map((opt) => {
          if (opt.type.const === "SENDGRID") {
            setDestTypes(opt.destTypes);
            if (opt.templates) {
              setTemplates(
                opt.templates.map((temp) => {
                  return {
                    content: temp.name,
                    value: `${temp.id}:${temp.name}`,
                  };
                })
              );
            }
          }
          return {
            content:
              opt.type && opt.type.langs && opt.type.langs.es
                ? opt.type.langs.es
                : "",
            value: opt.type && opt.type.const ? opt.type.const : "",
          };
        })
      );
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDocuments = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectors.replace("{type}", "DOCUMENT"),
    });
    let documentsApi = [];
    if (!error) {
      documentsApi = data.map((document) => {
        return {
          label: document.name,
          value: `${document.connectorType}:${document.id}`,
        };
      });
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setDocuments(documentsApi);
    setIsLoading(false);
  };

  const getForm = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getConnectorsDocument}?typeConnector=FORM`,
    });
    let pictures = [];
    if (!error) {
      let options = [];
      let fields = [];
      data.map((form) => {
        const isPhone =
          form.fields && form.fields.find((field) => field.type === "PHONE");
        const isEmail =
          form.fields && form.fields.find((field) => field.type === "EMAIL");
        const isPicture =
          form.fields && form.fields.find((field) => field.type === "PICTURE");
        if (isPhone) {
          options.push({
            section: true,
            content: form.name,
          });
          form.fields.forEach((field) => {
            if (field.type === "PHONE") {
              options.push({
                section: false,
                content: field.name,
                value: `${form.id}:${field.id}`,
              });
            }
          });
        }
        if (isEmail) {
          fields.push({
            section: true,
            content: form.name,
          });
          form.fields.forEach((field) => {
            if (field.type === "EMAIL") {
              fields.push({
                section: false,
                content: field.name,
                value: `${form.id}:${field.id}`,
              });
            }
          });
        }
        if (isPicture) {
          pictures.push({
            section: true,
            label: form.name,
          });
          form.fields.forEach((field) => {
            if (field.type === "PICTURE") {
              pictures.push({
                label: field.name,
                value: `${form.connectorType}:${form.id}:${field.id}`,
              });
            }
          });
        }
      });
      setOptionsFields(fields);
      setOptionsPhones(options);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setOptionsPictures(pictures);
    setIsLoading(false);
  };

  const saveConnector = async () => {
    setIsLoading(true);
    let bodyRequest = { name };
    if (selected === "QUIUBAS") {
      bodyRequest = {
        ...bodyRequest,
        background,
        defaultMessage,
      };
      if (background) {
        bodyRequest["dest"] = phone;
      }
    }
    if (selected === "TWILIO") {
      bodyRequest = {
        ...bodyRequest,
        defaultMessage,
      };
    }
    if (selected === "SENDGRID") {
      const [templateId, templateAlias] = template.split(":");
      let attachments = [];
      selectedItemsPicture.forEach((selected) => {
        const [connectorType, connectorId, fieldId] = selected.split(":");
        attachments.push({
          connectorType,
          connectorId,
          fieldId,
        });
      });
      selectedItemsDocument.forEach((selected) => {
        const [connectorType, connectorId] = selected.split(":");
        attachments.push({
          connectorType,
          connectorId,
        });
      });
      bodyRequest = {
        ...bodyRequest,
        sender,
        destType,
        templateId,
        templateAlias,
        attachments,
      };
      if (destType === "FIXED") {
        bodyRequest = {
          ...bodyRequest,
          destFixed,
        };
      }
      if (destType === "FIELD") {
        const [formConnectorId, formFieldId] = field.split(":");
        bodyRequest = {
          ...bodyRequest,
          formConnectorId,
          formFieldId,
        };
      }
    }
    const [error, data] = await apiRequest({
      method: isEdit ? "put" : "post",
      url: isEdit
        ? apiRoutes.editConnector
            .replace(":connector", selected)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", selected),
      data: bodyRequest,
    });
    if (!error) {
      addAlert({
        code: "success",
        message: AlertTexts.success,
        duration: 5000,
      });
      if (!isEdit) {
        setName(data.name);
        setSelected(data.type);
        setIsEdit(true);
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDataConnector = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (!error) {
      setName(data.name);
      setSelected(data.type);
      setDefaultMessage(
        data.hasOwnProperty("defaultMessage") ? data.defaultMessage : ""
      );
      setMaxCharacteres(
        data.defaultMessage ? 140 - data.defaultMessage.length : 140
      );
      if (data.type === "QUIUBAS") {
        setBackground(data.background);
        setDefaultMessage(data.defaultMessage);
        if (data.background) {
          setPhone(data.dest);
        }
      }
      if (data.type === "SENDGRID") {
        setTemplate(`${data.templateId}:${data.templateAlias}`);
        setAttachmentsEdit(data.attachments ? data.attachments : []);
        setSender(data.sender);
        setDestType(data.destType);
        if (data.destType === "FIXED") {
          setDestFixed(data.destFixed);
        }
        if (data.destType === "FIELD") {
          setField(`${data.formConnectorId}:${data.formFieldId}`);
        }
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const searchPictures = (search) => {
    if (search !== "") {
      const options = optionsPictures.filter(
        (opt) => opt.label.includes(search) || opt.section
      );
      setOptionsPicturesFilter(options);
    } else {
      setOptionsPicturesFilter(optionsPictures);
    }
  };

  const handleOnRemoveSelection = (value) => {
    const allSelectedItems = selectedItemsPicture.filter(
      (item) => item !== value
    );
    setSelectedItemsPicture(allSelectedItems);
  };

  const searchDocuments = (search) => {
    if (search !== "") {
      const options = documents.filter((opt) => opt.label.includes(search));
      setDocumentsFilter(options);
    } else {
      setDocumentsFilter(documents);
    }
  };

  const handleOnRemoveSelectionDocument = (value) => {
    const allSelectedItems = selectedItemsDocument.filter(
      (item) => item !== value
    );
    setSelectedItemsDocument(allSelectedItems);
  };

  useEffect(() => {
    getCatalogTypesServices();
    setIsEdit(routeParams.hasOwnProperty("id"));
    getForm();
    getDocuments();
    if (routeParams.hasOwnProperty("id")) {
      getDataConnector();
    }
  }, []);

  useEffect(() => {
    if (optionsPictures && documents) {
      setOptionsPicturesFilter(optionsPictures);
      setDocumentsFilter(documents);
    }
  }, [optionsPictures, documents]);

  useEffect(() => {
    if (
      optionsPicturesFilter &&
      documentsFilter &&
      attachmentsEdit.length > 0
    ) {
      let attachmentsPictures = [];
      let attachmentsDocuments = [];
      attachmentsEdit.forEach((item) => {
        if (item.connectorType === "FORM") {
          attachmentsPictures.push(
            `${item.connectorType}:${item.connectorId}:${item.fieldId}`
          );
        }
        if (item.connectorType === "DOCUMENT") {
          attachmentsDocuments.push(
            `${item.connectorType}:${item.connectorId}`
          );
        }
      });
      setSelectedItemsPicture(attachmentsPictures);
      setSelectedItemsDocument(attachmentsDocuments);
      setAttachmentsEdit([]);
    }
  }, [optionsPicturesFilter, documentsFilter, attachmentsEdit]);

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      <PageSection title="Mensajería">
        <PageSection.Item size={1} length={3}>
          <TextField
            label="Nombre"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={3}>
          <SelectField
            label={Texts.connectors.fields.connectorType}
            placeholder={Texts.connectors.fields.connectorTypePlaceholder}
            value={selected}
            onChange={(value) => {
              setSelected(value);
              setDefaultMessage("");
            }}
            options={options}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {selected === "QUIUBAS" && (
          <>
            <PageSection.Item title="Proceso automático" size={1} length={3}>
              <RadioButton
                label="Si"
                name="automatic"
                value="yes"
                checked={background}
                onChange={(_checked, value) => setBackground(value === "yes")}
              />
              <RadioButton
                label="No"
                name="automatic"
                value="no"
                checked={!background}
                onChange={(_checked, value) => setBackground(value === "yes")}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {background && (
              <>
                <PageSection.Item size={1} length={3}>
                  <SelectField
                    label="Número de teléfono"
                    placeholder="--Campo formulario--"
                    value={phone}
                    onChange={(value) => {
                      setPhone(value);
                    }}
                    options={optionsPhones}
                  />
                </PageSection.Item>
                <PageSection.Item size={2} length={3} />
              </>
            )}
            <PageSection.Item size={1} length={3}>
              <Textarea
                label="Mensaje por defecto"
                helperText={`Los datos que se reemplazarán en el mensaje son {{nombres}}, {{paterno}}, {{materno}} y {{nip}} en caso de ser NIP. P.ej.
                Hola {{nombres}}, este es tu NIP para generar la consulta {{nip}}.Número de caracteres restantes: ${maxCharacters}`}
                onChange={(event) => {
                  setDefaultMessage(event.target.value);
                  setMaxCharacteres(140 - event.target.value.length);
                }}
                maxLength={140}
                value={defaultMessage}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {selected === "SENDGRID" && (
          <>
            <PageSection.Item title="Destinatario" size={1} length={3}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {destTypes.map((optionRadio) => {
                  return (
                    <RadioButton
                      label={optionRadio.langs.es}
                      name="destType"
                      value={optionRadio.const}
                      checked={destType === optionRadio.const}
                      onChange={(_checked, value) => setDestType(value)}
                    />
                  );
                })}
              </div>
            </PageSection.Item>
            {destType === "FIELD" && (
              <>
                <PageSection.Item size={1} length={3}>
                  <SelectField
                    placeholder="--Seleccionar--"
                    value={field}
                    onChange={(value) => {
                      setField(value);
                    }}
                    options={optionsFields}
                  />
                </PageSection.Item>
                <PageSection.Item size={1} length={3}></PageSection.Item>
              </>
            )}
            {destType === "FIXED" && (
              <>
                <PageSection.Item size={1} length={3}>
                  <TextField
                    value={destFixed}
                    onChange={(e) => {
                      setDestFixed(e.target.value);
                    }}
                  />
                </PageSection.Item>
                <PageSection.Item size={1} length={3}></PageSection.Item>
              </>
            )}
            {(destType === "NIP" || destType === "") && (
              <PageSection.Item size={2} length={3} />
            )}
            <PageSection.Item size={1} length={3}>
              <TextField
                label="De"
                value={sender}
                onChange={(e) => {
                  setSender(e.target.value);
                }}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <SelectField
                label="Templates Sendgrid"
                placeholder="--Elegir--"
                value={template}
                onChange={(value) => {
                  setTemplate(value);
                }}
                options={templates}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item
              title="Adjuntar documentos de Formularios"
              size={1}
              length={3}
            >
              <OptionField
                name="Adjuntar documentos de Formularios"
                items={optionsPicturesFilter}
                searchPlaceHolder="Busca algún documento"
                selectedItems={selectedItemsPicture}
                onSearchChange={searchPictures}
                onChange={(selected) => setSelectedItemsPicture(selected)}
                onRemoveSelection={handleOnRemoveSelection}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item
              title="Adjuntar de conector Documentos"
              size={1}
              length={3}
            >
              <OptionField
                name="Adjuntar de conector Documentos"
                items={documentsFilter}
                searchPlaceHolder="Busca algún documento"
                selectedItems={selectedItemsDocument}
                onSearchChange={searchDocuments}
                onChange={(selected) => setSelectedItemsDocument(selected)}
                onRemoveSelection={handleOnRemoveSelectionDocument}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <p>
              La siguiente lista de valores se pueden utilizar en su template.
            </p>
            <ul>
              <li>
                {
                  "{{workfloo_url}} : Enlace a la página de resumen del workfloo, de la cual forma parte el correo electrónico."
                }
              </li>
              <li>{"{{workfloo_id}} : Id del workfloo."}</li>
              <li>
                {
                  "{{form_123}} : Si el workfloo contiene un formulario con un campo que tiene el id 123, se reemplaza con el valor del campo."
                }
              </li>
              <li>
                {
                  "{{form2_123}} : Para un formulario específico con id 2 y su campo con id 123."
                }
              </li>
            </ul>
          </>
        )}
        {selected === "TWILIO" && (
          <>
            <PageSection.Item size={1} length={3}>
              <Textarea
                label="Mensaje por defecto"
                helperText={
                  "El mensaje debe concidir con el template creado en Twilio, los datos se pueden reemplazar con datos de formularios o de VPRs a través de su ID.P.ej. Hola {{FORM:7}}, este es tu código de acceso {{VPR:1}}"
                }
                onChange={(event) => setDefaultMessage(event.target.value)}
                value={defaultMessage}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button
              style="primary"
              text="Guardar"
              onClick={saveConnector}
              disabled={selected === "" || name === ""}
            />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => {
                history.push(
                  Paths.Connectors.replace(
                    ":connectorType",
                    routeParams.connectorType.toLowerCase()
                  )
                );
              }}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
};

export default AddEditMessaging;
