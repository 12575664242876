import { isValidFileName } from "../../../../utils/validators";

export default ({ files, fileName, filesErrors, i }) => {
  const repeated = files.filter(
    (file, index) => index !== i && file.name_file.trim() === fileName.trim()
  );
  let allFiles = [...files];
  let errors = [...filesErrors];
  let error = errors.findIndex((error) => error.index === i);
  if (error === -1) {
    errors.push({ index: i });
    error = errors.length - 1;
  }
  if (!fileName) {
    errors[error].type = "EMPTY_NAME";
  } else if (!isValidFileName(fileName)) {
    errors[error].type = "INVALID_NAME";
  } else if (repeated.length) {
    errors[error].type = "REPEATED_NAME";
  } else {
    errors.splice(error, 1);
  }
  allFiles[i].name_file = fileName;
  return { allFiles, errors };
};
