import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";

const ConfigDataSignCo = ({ data, errorFields, onChange, onSave }) => {
  const handleOnChange = (key, attr, value) => {
    onChange && onChange(key, attr, value);
  };

  return (
    <PageSection>
      <PageSection.Item title="Activar Lleida" size={1} length={1}>
        <Grid>
          <Grid.Item size="contentWidth">
            <RadioButton
              label="Sí"
              value={1}
              onChange={() => handleOnChange("Lleida", "Activated", true)}
              checked={data.Lleida?.Activated}
            />
          </Grid.Item>
          <Grid.Item size="contentWidth">
            <RadioButton
              label="No"
              value={0}
              onChange={() => handleOnChange("Lleida", "Activated", false)}
              checked={!data.Lleida?.Activated}
            />
          </Grid.Item>
        </Grid>
      </PageSection.Item>
      {data.Lleida?.Activated && (
        <>
          <PageSection.Item size={1} length={3} title="Usuario Lleida">
            <TextField
              error={errorFields.includes("Lleida-LleidaUser")}
              errorText={"El campo no debe ir vacio"}
              value={data.Lleida?.LleidaUser}
              onChange={(e) =>
                handleOnChange("Lleida", "LleidaUser", e.target.value)
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3} title="Contraseña Lleida">
            <TextField
              error={errorFields.includes("Lleida-LleidaPassword")}
              errorText={"El campo no debe ir vacio"}
              type={"password"}
              value={data.Lleida?.LleidaPassword}
              onChange={(e) =>
                handleOnChange("Lleida", "LleidaPassword", e.target.value)
              }
            />
          </PageSection.Item>
        </>
      )}
      <PageSection.Item title="Deceval" size={1} length={1}>
        <Grid>
          <Grid.Item size="contentWidth">
            <RadioButton
              label="Sí"
              value={1}
              onChange={() => handleOnChange("Deceval", "Activated", true)}
              checked={data.Deceval?.Activated}
            />
          </Grid.Item>
          <Grid.Item size="contentWidth">
            <RadioButton
              label="No"
              value={0}
              onChange={() => handleOnChange("Deceval", "Activated", false)}
              checked={!data.Deceval?.Activated}
            />
          </Grid.Item>
        </Grid>
      </PageSection.Item>
      {data.Deceval?.Activated && (
        <>
          <PageSection.Item size={1} length={3} title="Código Depositante">
            <TextField
              error={errorFields.includes("Deceval-CodigoDepositante")}
              errorText={
                data.Deceval?.CodigoDepositante !== ""
                  ? "Debes ingresar solo números"
                  : "El campo no debe ir vacio"
              }
              value={data.Deceval?.CodigoDepositante}
              onChange={(e) =>
                handleOnChange("Deceval", "CodigoDepositante", e.target.value)
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item
            size={1}
            length={3}
            title="Id Clase Definición Documento"
          >
            <TextField
              error={errorFields.includes("Deceval-IdClaseDefinicionDocumento")}
              errorText={
                data.Deceval?.IdClaseDefinicionDocumento !== ""
                  ? "Debes ingresar solo números"
                  : "El campo no debe ir vacio"
              }
              value={data.Deceval?.IdClaseDefinicionDocumento}
              onChange={(e) =>
                handleOnChange(
                  "Deceval",
                  "IdClaseDefinicionDocumento",
                  e.target.value
                )
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3} title="Nit Emisor">
            <TextField
              error={errorFields.includes("Deceval-NitEmisor")}
              errorText={"El campo no debe ir vacio"}
              value={data.Deceval?.NitEmisor}
              onChange={(e) =>
                handleOnChange("Deceval", "NitEmisor", e.target.value)
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3} title="Usuario">
            <TextField
              error={errorFields.includes("Deceval-User")}
              errorText={"El campo no debe ir vacio"}
              value={data.Deceval?.User}
              onChange={(e) =>
                handleOnChange("Deceval", "User", e.target.value)
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item
            size={1}
            length={3}
            title="Código Depositante Sandbox"
          >
            <TextField
              error={errorFields.includes("Deceval-SandboxCodigoDepositante")}
              errorText="Debes ingresar solo números"
              value={data.Deceval?.SandboxCodigoDepositante}
              onChange={(e) =>
                handleOnChange(
                  "Deceval",
                  "SandboxCodigoDepositante",
                  e.target.value
                )
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item
            size={1}
            length={3}
            title="Id Clase Definición Documento Sandbox"
          >
            <TextField
              error={errorFields.includes(
                "Deceval-SandboxIdClaseDefinicionDocumento"
              )}
              errorText="Debes ingresar solo números"
              value={data.Deceval?.SandboxIdClaseDefinicionDocumento}
              onChange={(e) =>
                handleOnChange(
                  "Deceval",
                  "SandboxIdClaseDefinicionDocumento",
                  e.target.value
                )
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3} title="Nit Emisor Sandbox">
            <TextField
              error={errorFields.includes("Deceval-SandboxNitEmisor")}
              errorText={"El campo no debe ir vacio"}
              value={data.Deceval?.SandboxNitEmisor}
              onChange={(e) =>
                handleOnChange("Deceval", "SandboxNitEmisor", e.target.value)
              }
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3} title="Usuario Sandbox">
            <TextField
              error={errorFields.includes("Deceval-SandboxUser")}
              errorText={"El campo no debe ir vacio"}
              value={data.Deceval?.SandboxUser}
              onChange={(e) =>
                handleOnChange("Deceval", "SandboxUser", e.target.value)
              }
            />
          </PageSection.Item>
        </>
      )}
      <PageSection.Item size={1} length={1}>
        <div style={{ textAlign: "center" }}>
          <Button text="Guardar" onClick={onSave} style={`primary`} />
        </div>
      </PageSection.Item>
    </PageSection>
  );
};

export default ConfigDataSignCo;
