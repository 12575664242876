import React, { useEffect, useState } from "react";
import { PlusIcon } from "@bit/kiban-design-system.kiban.icons";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";

import Variable from "./VariableForm";
import error_types from "./variablesErrors";
import onVariableChange from "./onVariableChange";

import { apiRequest, apiRoutes } from "../../../../services";

const AddVariableModalScreen = ({ open, variables, onClose, onApply }) => {
  const [modalVariables, setModalVariables] = useState([]);
  const [variableErrors, setVariableErrors] = useState([]);
  const [applyIsDisabled, setApplyIsDisabled] = useState(true);

  useEffect(() => {
    if (
      !modalVariables.length ||
      variableErrors.length ||
      modalVariables.filter((variable) => !variable.name || !variable.vartype)
        .length
    ) {
      setApplyIsDisabled(true);
    } else {
      setApplyIsDisabled(false);
    }
  }, [modalVariables, variableErrors]);

  useEffect(() => {
    setModalVariables(
      variables ? variables.map((variable) => ({ ...variable })) : []
    );
  }, [variables]);

  const handleOnAddVariable = async () => {
    const [error, data] = await apiRequest({
      method: "put",
      url: apiRoutes.vprId,
    });
    if (!error) {
      let variables = [...modalVariables];
      variables.push({
        name: "",
        vartype: "",
        id: data.idVariable,
      });
      setModalVariables(variables);
    }
  };

  const handleOnVariableChange = (name, type, i) => {
    const { variables, errors } = onVariableChange({
      modalVariables,
      variableErrors,
      name,
      type,
      i,
    });
    setVariableErrors(errors);
    setModalVariables(variables);
  };

  const handleClose = () => {
    if (JSON.stringify(modalVariables) !== JSON.stringify(variables)) {
      const resultVariables = variables
        ? variables.map((variable) => ({ ...variable }))
        : [];
      setModalVariables(resultVariables);
    }
    onClose && onClose();
  };

  const handleOnApply = () => {
    onApply && onApply(modalVariables);
    onClose && onClose();
  };

  const handleOnRemoveVariable = (item) => {
    let modalVariablesSet = [...modalVariables];
    modalVariablesSet.splice(item, 1);
    setModalVariables(modalVariablesSet);
  };

  const primaryAction = {
    text: "Aplicar",
    disabled: applyIsDisabled,
    onAction: handleOnApply,
  };

  const secondaryActions = [{ text: "Cancelar", onAction: handleClose }];

  const extraAction = {
    text: "Añadir",
    icon: PlusIcon,
    onAction: handleOnAddVariable,
  };

  return (
    <ModalScreen
      open={open}
      title="Añadir Variable"
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      extraAction={extraAction}
      onClose={handleClose}
    >
      {modalVariables !== undefined &&
        modalVariables.length > 0 &&
        modalVariables.map((variable, i) => {
          const error = variableErrors.find((error) => i === error.index);
          return (
            <div
              key={`vps-modal-screen-variable-${i}`}
              className="ModalScreen_Variable--Container"
            >
              <Variable
                id={variable.id}
                name={variable.name}
                type={variable.vartype}
                onChange={(name, type) => handleOnVariableChange(name, type, i)}
                nameError={error ? error_types[error.type] : undefined}
                onRemove={() => handleOnRemoveVariable(i)}
              />
            </div>
          );
        })}
    </ModalScreen>
  );
};

export default AddVariableModalScreen;
