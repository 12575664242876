const routes = {
  api: "/company/clientApiInfo",
  authorization: "/auditoriaAutenticacion",
  configuration: "/company/configuraciones",
  login: "/login",
  logout: "/logout",
  users: "/users",
  groups: "/groups",
  nip: "/auditoriaNIP",
  workfloos: "/workfloosDefinition",
  pdfTemplate: "/connector/DOCUMENT/:id/pdfTemplate",
  editPdfTemplate: "/connector/DOCUMENT/:id/pdfTemplate",
  getConnectorsDocument: "/connector",
  legacyVariables: "/connector/variables/legacy",
  duplicateWorkfloos: "/workfloosDefinition/:id/copy",
  connectors: "/connector?typeConnector={type}",
  duplicateConnector: "/connector/:id/copy",
  deleteConnector: "/connector/:id",
  getEditConnector: "/connector/:typeConnector/:id",
  addConnector: "/connector/:typeConnector",
  historicWorkfloo: "/workfloo/history",
  historicBuros: "/sic/history",
  downloadHistoricWorkfloo: "/workfloo/history/csv",
  downloadHistoricBuros: "/sic/history/csv",
  vprId: "/connector/VP/id",
  getPlans: "/company/plans",
  updatePlans: "company/plans/:typePlan",
  getConfigConnector: "/connectors/config/:typeConnector",
  getSmsData: "/invoice/sms",
  newVPConnector: "/connector/variable/new",
  duplicateVPConnector: "/connector/VP/:id/copy",
  downloadJsonWorkfloo: "/workfloo/history/:id",
  casosFicticios: "/casosficticios",
  getCasoFicticioById: "/casoficticio",
  duplicateCasoFicticio: "/casoficticio/copy",
  getCatalogTypesServices: "/casosficticios/typeServices",
  synchronization: "/startSynchronization",
  getLogActivity: "/logActivity/SYNC",
  getCatalogsApi: "/connector/API/catalogs",
  authSicNip: "/workfloo/sicAuthNip",
  getConnectorTypeServices: "/connector/:connectorType/typeServices",
  connectorTypeServices: "/connector/:connector/typeServices",
  getConnector: "/connector/:id",
  editConnector: "/connector/:connector/:id",
  getApiExample: "/connector/API/example",
  addWorkfloo: "/workfloosDefinition",
  workfloo: "/workfloosDefinition/:id",
  getWorkflooAvailableTypes: "/workfloosDefinition/availableTypes",
  getPredeterminedTypes: "/FORM/setsActivated",
  addForm: "/connector/FORM",
  editForm: "/connector/FORM/:id",
  getPredefinedFields: "/FORM/predefinedFields/:id",
  getCustomFields: "/FORM/custom/:id",
  getPredefinedTypes: "/FORM/setsActivated",
  getSections: "/FORM/field/sections",
  getCustomFieldId: "/FORM/custom/fieldId",
  workflooFintoc: "/workfloo/fintoc?idUnykoo={idUnykoo}",
  detailHistoricWorkfloo: "/workfloo/history/:id",
  editUser: "/user/:id",
  addUser: "/user",
  validateBC: "user/validate/bc",
  validateEmail: "/user/validate/email",
  validateCode: "/user/validate/code",
  addMessage: "/connector/POLICY/:id/step/message",
  editMessage: "connector/POLICY/:idStep/step/message/:idMessage",
  getStepTypesServices: "/connector/POLICY/step/typeServices",
  createPolicyStep: "/connector/POLICY/:id/step",
  editPolicyStep: "/connector/POLICY/:id/step/:stepId",
  getPolicyUniversesCatalog: "/connector/POLICY/:id/step/universes",
  getCatalog: "/catalog/:catalogName",
  recoveryPassword: "user/resetPassword/sendEmailLink",
  resetPassword: "user/resetPassword",
  cancelWorkfloo: "workfloo/cancel",
  workflooHistoric: "workfloo/:id/jsonviewer",
  startWorkfloo: "workfloo",
  downloadFileJsonWorkfloo: "workfloo/:id/file",
  statusNip: "workfloo/authNip/statusNip",
  authGenericNip: "/workfloo/authNip/sendNip",
  confirmationAuthNip: "/workfloo/authNip/confirmationNip",
  getMiFielWidgetData: "/workfloo/mifiel/widget?idWorkfloo={workflooId}",
  setMiFielWidgetStatus:
    "/workfloo/mifiel/widget/status?idWorkfloo={workflooId}",
  getMati: "/workfloo/mati/widget",
  postMati: "/workfloo/mati/status",
  statusRainao: "/workfloo/rainao/status",
  updateStatusRainao: "/workfloo/rainao/{step}",
};

export default routes;
