import React, { useState } from "react";
import Grid from "@bit/kiban-design-system.layout.grid";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import { EditIcon } from "@bit/kiban-design-system.kiban.icons";

const Variable = ({ index, name, type, onEdit }) => {
  const activator = (
    <IconButton icon={EditIcon} style="secondary" onClick={onEdit} />
  );
  return (
    <Grid alignItems="center" noGap className="variable-grid">
      <Grid.Item size="content-width">{index}</Grid.Item>
      <Grid.Item size="auto">{name}</Grid.Item>
      <Grid.Item size="auto">{type}</Grid.Item>
      <Grid.Item size="content-width">{activator}</Grid.Item>
    </Grid>
  );
};

export default Variable;
