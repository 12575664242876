import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";

const ConfigChile = ({ dataConfig, onChange, errors }) => {
  return (
    <>
      <PageSection.Item size={1} length={2}>
        <div className="rows">
          <Title type="heading">Activar Fintoc:</Title>
          <div className="container-inputs">
            <RadioButton
              label="Si"
              name="fintoc"
              value={true}
              checked={dataConfig?.Fintoc?.ActivateFintoc}
              onChange={(checked, value) =>
                onChange("Fintoc", "ActivateFintoc", value === "true")
              }
            />
            <RadioButton
              label="No"
              name="fintoc"
              value={false}
              checked={!dataConfig?.Fintoc?.ActivateFintoc}
              onChange={(checked, value) =>
                onChange("Fintoc", "ActivateFintoc", value === "true")
              }
            />
          </div>
        </div>
        {dataConfig?.Fintoc?.ActivateFintoc && (
          <div>
            <TextField
              label="Public Key Producción"
              name="publicKey"
              value={dataConfig?.Fintoc?.FintocPublicKey}
              onChange={(e) => {
                onChange("Fintoc", "FintocPublicKey", e.target.value);
              }}
              error={
                errors &&
                errors.length > 0 &&
                errors.includes("Fintoc-FintocPublicKey")
              }
              errorText="Este campo es requerido"
            />
            <TextField
              label="Secret Key Producción"
              name="secretKey"
              value={dataConfig?.Fintoc?.FintocSecretKey}
              onChange={(e) => {
                onChange("Fintoc", "FintocSecretKey", e.target.value);
              }}
              error={
                errors &&
                errors.length > 0 &&
                errors.includes("Fintoc-FintocSecretKey")
              }
              errorText="Este campo es requerido"
            />
            <TextField
              label="Public Key Sandbox"
              name="publicKey"
              value={dataConfig?.Fintoc?.FintocPublicKeySandBox}
              onChange={(e) => {
                onChange("Fintoc", "FintocPublicKeySandBox", e.target.value);
              }}
              error={
                errors &&
                errors.length > 0 &&
                errors.includes("Fintoc-FintocPublicKeySandBox")
              }
              errorText="Este campo es requerido"
            />
            <TextField
              label="Secret Key Sandbox"
              name="secretKey"
              value={dataConfig?.Fintoc?.FintocSecretKeySandBox}
              onChange={(e) => {
                onChange("Fintoc", "FintocSecretKeySandBox", e.target.value);
              }}
              error={
                errors &&
                errors.length > 0 &&
                errors.includes("Fintoc-FintocSecretKeySandBox")
              }
              errorText="Este campo es requerido"
            />
          </div>
        )}
      </PageSection.Item>
    </>
  );
};

export default ConfigChile;
