import React, { useEffect, useState, useRef } from "react";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import "../ConfigurationPage.css";

export default function ConfigurationKyc() {
  const [dataConfig, setDataConfig] = useState({});
  const [errorFields, setErrorFields] = useState([]);
  const [loading, setLoading] = useState(true);

  const urlWebhookReference = useRef();
  const secretWebhookReference = useRef();
  const { addAlert } = useAlert();

  const getConfigKyc = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "IDENTIDAD"),
    });
    if (!err) {
      const responseDataConfig = data.hasOwnProperty("kycMXResponse")
        ? data.kycMXResponse
        : data.hasOwnProperty("kycCOResponse")
        ? data.kycCOResponse
        : data.hasOwnProperty("kycCLResponse")
        ? data.kycCLResponse
        : null;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig);
    let updateConfig = { ...dataConfig };
    keys.forEach((key) => {
      if (!updateConfig[key].Activate) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !["Activate", "WebhookUrl", "WebhookSecret"].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          if (keyInternal !== "HoursExpiration") {
            updateConfig[key][keyInternal] = "";
          }
        });
      }
    });
    setDataConfig(updateConfig);
    return { kycRequest: { ...updateConfig } };
  };

  const updateConfigKyc = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "IDENTIDAD"),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        codde: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigKyc();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleOnChangeConfig = (key, attr, value) => {
    const updateConfig = { ...dataConfig };
    const updateObj = { ...updateConfig[key] };
    updateObj[attr] = value;
    updateConfig[key] = updateObj;
    const updateErrors = errorFields.includes(`${key}-${attr}`)
      ? errorFields.filter((keyError) => keyError !== `${key}-${attr}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    const keys = Object.keys(dataConfig);
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (updateConfig[key].Activate) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !["Activate", "WebhookUrl", "WebhookSecret"].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          if (updateConfig[key][keyInternal] === "") {
            objErrors.push(`${key}-${keyInternal}`);
          }
        });
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigKyc();
    }
  };

  return loading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      <PageSection>
        {dataConfig.BuroDigitalId && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Buró de Identidad Digital:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="identidad-digital"
                    value={true}
                    checked={dataConfig.BuroDigitalId.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "BuroDigitalId",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="identidad-digital"
                    value={false}
                    checked={!dataConfig.BuroDigitalId.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "BuroDigitalId",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.BuroDigitalId.Activate && (
                <>
                  <TextField
                    error={errorFields.includes("BuroDigitalId-User")}
                    errorText={"El campo no debe ir vacío"}
                    label="Usuario"
                    value={dataConfig.BuroDigitalId.User}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "BuroDigitalId",
                        "User",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("BuroDigitalId-Password")}
                    errorText={"El campo no debe ir vacío"}
                    label="Contraseña"
                    type="password"
                    value={dataConfig.BuroDigitalId.Password}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "BuroDigitalId",
                        "Password",
                        e.target.value
                      )
                    }
                  />
                </>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Cumplo360 && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Cumplo 360:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="cumplo-360"
                    value={true}
                    checked={dataConfig.Cumplo360.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Cumplo360",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="cumplo-360"
                    value={false}
                    checked={!dataConfig.Cumplo360.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Cumplo360",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.Cumplo360.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("Cumplo360-User")}
                    errorText={"El campo no debe ir vacío"}
                    label="Usuario"
                    value={dataConfig.Cumplo360.User}
                    onChange={(e) =>
                      handleOnChangeConfig("Cumplo360", "User", e.target.value)
                    }
                  />
                  <TextField
                    error={errorFields.includes("Cumplo360-Password")}
                    errorText={"El campo no debe ir vacío"}
                    label="Contraseña"
                    type="password"
                    value={dataConfig.Cumplo360.Password}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Cumplo360",
                        "Password",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("Cumplo360-HoursExpiration")}
                    errorText={"El campo no debe ir vacío"}
                    label="Horas de expiración"
                    type="number"
                    value={dataConfig.Cumplo360.HoursExpiration}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Cumplo360",
                        "HoursExpiration",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Mati && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Mati:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="mati"
                    value={true}
                    checked={dataConfig.Mati.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig("Mati", "Activate", value === "true")
                    }
                  />
                  <RadioButton
                    label="No"
                    name="mati"
                    value={false}
                    checked={!dataConfig.Mati.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig("Mati", "Activate", value === "true")
                    }
                  />
                </div>
              </div>
              {dataConfig.Mati.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("Mati-ClientId")}
                    errorText={"El campo no debe ir vacío"}
                    label="ID de cliente"
                    value={dataConfig.Mati.ClientId}
                    onChange={(e) =>
                      handleOnChangeConfig("Mati", "ClientId", e.target.value)
                    }
                  />
                  <TextField
                    error={errorFields.includes("Mati-ClientSecretKey")}
                    errorText={"El campo no debe ir vacío"}
                    label="Llave secreta del cliente"
                    type="password"
                    value={dataConfig.Mati.ClientSecretKey}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Mati",
                        "ClientSecretKey",
                        e.target.value
                      )
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
            {dataConfig.Mati.Activate && (
              <PageSection.Item size={1} length={1}>
                <Title type="heading">
                  Estos datos se encuentran en su{" "}
                  <a target="_blank" href="https://dashboard.getmati.com/">
                    Dashboard de Mati
                  </a>{" "}
                  en la pestaña Integración de los SDKs sección 1. Mati
                  Documentación
                </Title>
                <TextField
                  connectedRight={
                    <Button
                      onClick={() => {
                        urlWebhookReference.current.focus();
                        urlWebhookReference.current.select();
                        navigator.clipboard.writeText(
                          dataConfig.Mati.WebhookUrl
                        );
                      }}
                      style="primary"
                      text="Copiar"
                    />
                  }
                  readOnly={true}
                  id="urlWebhook"
                  prefix="La URL de su webhook"
                  reference={urlWebhookReference}
                  value={dataConfig.Mati.WebhookUrl}
                />
                <TextField
                  connectedRight={
                    <Button
                      onClick={() => {
                        secretWebhookReference.current.focus();
                        secretWebhookReference.current.select();
                        navigator.clipboard.writeText(
                          dataConfig.Mati.WebhookSecret
                        );
                      }}
                      style="primary"
                      text="Copiar"
                    />
                  }
                  readOnly={true}
                  id="secretWebhook"
                  prefix="Webhook secret"
                  reference={secretWebhookReference}
                  value={dataConfig.Mati.WebhookSecret}
                />
                <Title type="heading">
                  Estos datos se tienen que copiar en su{" "}
                  <a target="_blank" href="https://dashboard.getmati.com/">
                    Dashboard de Mati
                  </a>{" "}
                  en la pestaña Integración de los SDKs sección 3. Recibir datos
                  de verificación de usuarios botón Configurar nuevo webhook
                </Title>
              </PageSection.Item>
            )}
          </>
        )}
        {dataConfig.QuienEsQuien && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Quien es quien:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="quien"
                    value={true}
                    checked={dataConfig.QuienEsQuien.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "QuienEsQuien",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="quien"
                    value={false}
                    checked={!dataConfig.QuienEsQuien.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "QuienEsQuien",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.QuienEsQuien.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("QuienEsQuien-User")}
                    errorText={"El campo no debe ir vacío"}
                    label="ID de cliente"
                    value={dataConfig.QuienEsQuien.User}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "QuienEsQuien",
                        "User",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("QuienEsQuien-Password")}
                    errorText={"El campo no debe ir vacío"}
                    label="Llave secreta del cliente"
                    type="password"
                    value={dataConfig.QuienEsQuien.Password}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "QuienEsQuien",
                        "Password",
                        e.target.value
                      )
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.ExpedienteAzul && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Expediente azul:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="expediente"
                    value={true}
                    checked={dataConfig.ExpedienteAzul.Activate}
                    onChange={(_checked, value) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="expediente"
                    value={false}
                    checked={!dataConfig.ExpedienteAzul.Activate}
                    onChange={(_checked, value) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.ExpedienteAzul.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("ExpedienteAzul-ApiDomain")}
                    errorText={"El campo no debe ir vacío"}
                    label="Api Domain"
                    value={dataConfig.ExpedienteAzul.ApiDomain}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "ApiDomain",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("ExpedienteAzul-CustomerId")}
                    errorText={"El campo no debe ir vacío"}
                    label="Id Cliente"
                    value={dataConfig.ExpedienteAzul.CustomerId}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "CustomerId",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("ExpedienteAzul-TokenKey")}
                    errorText={"El campo no debe ir vacío"}
                    label="TokenKey"
                    value={dataConfig.ExpedienteAzul.TokenKey}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "TokenKey",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes(
                      "ExpedienteAzul-CustomerIdToken"
                    )}
                    errorText={"El campo no debe ir vacío"}
                    label="Id Token de Cliente"
                    value={dataConfig.ExpedienteAzul.CustomerIdToken}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "ExpedienteAzul",
                        "CustomerIdToken",
                        e.target.value
                      )
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        <PageSection.Item size={1} length={1}>
          <Button onClick={saveConfig} style="primary" text="Guardar" />
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
