import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import Paths from "../../../paths";
import { apiRequest, apiRoutes } from "../../../services";
import alertTexts from "../../../utils/alerts-texts";
import connectorsTypes from "../connectorsTypes";

const AddEditRainao = () => {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPreloading, setIsPreloading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const routeParams = useParams();
  const { addAlert } = useAlert();

  const createAlert = (message, code) => {
    addAlert({
      message,
      code,
      duration: 5000,
    });
  };

  const getConnector = async () => {
    setIsPreloading(true);
    const [error, response] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (error) {
      setError(true);
      setIsPreloading(false);
      return;
    }
    setName(response.name);
    setIsPreloading(false);
  };

  const saveConnector = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: !routeParams.id ? "POST" : "PUT",
      url: !routeParams.id
        ? apiRoutes.addConnector.replace(
            ":typeConnector",
            connectorsTypes[routeParams.connectorType]
          )
        : apiRoutes.editConnector
            .replace(":connector", connectorsTypes[routeParams.connectorType])
            .replace(":id", routeParams.id),
      data: {
        name,
      },
    });
    if (data !== null) {
      createAlert(alertTexts.success, "success");
      if (!routeParams.id) {
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
      }
    }
    if (error) {
      createAlert(
        error.message ||
          (error.status === 409 && alertTexts.nameAlreadyExist) ||
          alertTexts.unexpectedError,
        "danger"
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (routeParams.id) getConnector();
  }, []);

  const validateForm = () => {
    setError(name.length === 0);
    return name.length > 0;
  };

  const pageActions = [
    {
      text: "Guardar",
      disabled: isLoading,
      loading: isLoading,
      onAction: () => {
        if (validateForm()) saveConnector();
      },
    },
    {
      text: "Cancelar",
      disabled: isLoading,
      onAction: () =>
        history.push(Paths.Connectors.replace(":connectorType", "rainao")),
    },
  ];

  return isPreloading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      <PageSection
        title="Rainao"
        primaryAction={pageActions[0]}
        secondaryActions={[pageActions[1]]}
      >
        <PageSection.Item size={1} length={3} title="Nombre">
          <TextField
            placeholder="Nombra tu conector Rainao"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isLoading}
            error={error}
            errorText="El nombre es requerido"
          />
        </PageSection.Item>
      </PageSection>
    </div>
  );
};

export default AddEditRainao;
