import React, { useState } from "react";
import Badges from "@bit/kiban-design-system.kiban.badges";
import Button from "@bit/kiban-design-system.kiban.button";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import Tabs from "@bit/kiban-design-system.kiban.tabs";
import Tag from "@bit/kiban-design-system.kiban.tag";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import {
  SuccessIcon,
  ErrorIcon,
  CircleFillIcon,
} from "@bit/kiban-design-system.kiban.icons";

export default function Header({
  dataWorkfloo,
  downloadJson,
  handleCancel,
  handleContinue,
  indexTab,
  setIndexTab,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState("");
  const [errorConfirm, setErrorConfirm] = useState(false);

  const handleClickCancel = () => {
    setOpenModal(true);
  };

  const primaryAction = {
    text: "Confirmar",
    onAction: (e) => {
      if (confirmCancel === "cancelar") {
        handleCancel();
        setErrorConfirm(false);
        setOpenModal(false);
      } else {
        setErrorConfirm(true);
      }
    },
  };

  const handleOnCloseModal = () => {
    setErrorConfirm(false);
    setOpenModal(false);
    setConfirmCancel("");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 6);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const hours = date.getHours() < 9 ? `0${date.getHours()}` : date.getHours();
    const minutes =
      date.getMinutes() < 9 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds =
      date.getSeconds() < 9 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${day}/${month}/${date.getFullYear()} ${hours}:${minutes}:${seconds}`;
  };

  const getIconStatus = () => {
    switch (dataWorkfloo.status) {
      case "SUCCESS":
        return <SuccessIcon />;
      case "ERROR":
        return <ErrorIcon />;
      case "ERROR_TECNICO":
        return <ErrorIcon />;
      case "PROGRESS":
        return <CircleFillIcon color={"#0047FF"} />;
      default:
        return <CircleFillIcon color={"#808692"} />;
    }
  };

  const status = dataWorkfloo.status.includes("ERROR")
    ? "danger"
    : dataWorkfloo.status.toLowerCase();

  const label = `${dataWorkfloo.status.charAt(0)}${dataWorkfloo.status
    .slice(1)
    .toLowerCase()}`;
  return (
    <div className="historic__header">
      <div className="historic__header-containter">
        <div className="historic__header-labels-container">
          <div className="historic__header--title">
            Workfloo #{dataWorkfloo.workflooId}
          </div>
          <Title type="displayLarge">{dataWorkfloo.workflooName}</Title>
          <div className="historic__header-details">
            <div className="historic__header-details--item">
              <div className="historic__header-details--label">Creado por:</div>
              <Tag>{dataWorkfloo.createdBy}</Tag>
            </div>
            {!dataWorkfloo.hasOwnProperty("canceled") &&
              dataWorkfloo?.canceled === "" && (
                <div className="historic__header-details--item">
                  Fecha de creación {formatDate(dataWorkfloo.created)}
                </div>
              )}
            {dataWorkfloo?.canceled && dataWorkfloo.canceled !== "" && (
              <div className="historic__header-details--item">
                Cancelado por: <Tag>{dataWorkfloo.canceledBy}</Tag>
              </div>
            )}
            {dataWorkfloo?.canceled && dataWorkfloo.canceled !== "" && (
              <div className="historic__header-details--item">
                Fecha de cancelacion {formatDate(dataWorkfloo.canceled)}
              </div>
            )}
          </div>
        </div>
        <div className="historic__header-status">
          <Badges
            label={label}
            state={status}
            type="state"
            icon={getIconStatus()}
          />
        </div>
      </div>
      <div className="historic__header-actions-container">
        <Tabs indexSelected={indexTab} onChange={(index) => setIndexTab(index)}>
          <Tabs.Item>Paso</Tabs.Item>
          <Tabs.Item>Detalles</Tabs.Item>
        </Tabs>
        <ModalScreen
          open={openModal}
          title={"Workfloo"}
          primaryAction={primaryAction}
          onClose={handleOnCloseModal}
        >
          {
            "Esta acción cancelará el workfloo, escribe 'cancelar' para confirmar."
          }
          <TextField
            value={confirmCancel}
            onChange={(e) => setConfirmCancel(e.target.value)}
            error={errorConfirm}
            errorText="Verifica que hayas escrito bien la palabra cancelar."
          />
        </ModalScreen>
        <div className="historic__header-buttons-container">
          <Button
            style="light"
            text="Descargar json"
            onClick={() => downloadJson()}
          />
          <Button
            disabled={dataWorkfloo.status !== "PROGRESS"}
            style="secondary"
            text="Cancelar"
            onClick={() => handleClickCancel()}
          />
          <Button
            disabled={dataWorkfloo.status !== "PROGRESS"}
            style="primary"
            text="Continuar"
            onClick={() => handleContinue()}
          />
        </div>
      </div>
    </div>
  );
}
