import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AnnotatedLayout from "@bit/kiban-design-system.kiban.annotated-layout";
import NipField from "@bit/kiban-design-system.kiban.nip";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import StepsBar from "@bit/kiban-design-system.kiban.steps-bar";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import AlertTexts from "../../utils/alerts-texts";
import { getHostName } from "../../utils/menuRights";

import "./AuthNip.css";

const AuthNipGeneric = ({ dataNip, getStatusNip, steps, setSteps }) => {
  const [currentView, setCurrentView] = useState("firstVerification");
  const [isLoading, setIsLoading] = useState(false);
  const [errorNip, setErrorNip] = useState(false);
  const params = useParams();
  const { addAlert } = useAlert();
  const messagesSteps = {
    sms: [
      "Ingrese el NIP (4 caracteres) obtenido via mensaje al número {{sentMean}} para validar su cuenta",
    ],
    whatsapp: [
      "Ingrese el NIP (4 caracteres) obtenido via whatsapp al número {{sentMean}} para validar su cuenta",
    ],
    email: [
      "Ingrese el NIP (4 caracteres) obtenido via correo electrónico a la dirección {{sentMean}} para validar su cuenta",
    ],
  };

  useEffect(() => {
    if (dataNip) {
      if (dataNip.currentStep > 1) {
        setCurrentView("secondVerification");
      }
      if (dataNip.totalSteps > 2) {
        const updateSteps = steps.map((step, index) => {
          return {
            ...step,
            status:
              index === 0
                ? dataNip.currentStep > index
                : dataNip.currentStep >= 2,
          };
        });
        setSteps(updateSteps);
      } else {
        setSteps(null);
      }
    }
  }, [dataNip]);

  const validateCode = async (code) => {
    setIsLoading(true);
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.confirmationAuthNip,
      data: {
        idUnykoo: parseInt(params.id),
        nip: code.join(""),
      },
    });
    if (!response[0]) {
      if (dataNip.currentStep + 1 === dataNip.totalSteps) {
        const cryptId = Jwt.signToken({ id: params.id });
        window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
      } else {
        getStatusNip();
      }
    } else {
      if (response[0].message === "NIP inválido") {
        setErrorNip(true);
      } else {
        addAlert({
          code: "danger",
          message: AlertTexts.tecnicalError,
          duration: 5000,
        });
      }
    }
    setIsLoading(false);
  };

  const resendCode = async () => {
    setErrorNip(false);
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.authGenericNip,
      data: { idUnykoo: parseInt(params.id) },
    });
    addAlert({
      duration: 5000,
      code: !response[0] ? "success" : "danger",
      message: !response[0]
        ? "Se envío correctamente el código"
        : "No se pudo reenviar el código, intenta otra vez",
    });
    getStatusNip();
  };

  const views = {
    firstVerification: {
      title: steps ? "Primera verificación" : "Ingresa Nip",
      component: (
        <div className="validateAccount">
          <NipField
            error={errorNip}
            messageError={"El código es inválido"}
            primaryAction={{
              label: "Validar Nip",
              action: (code) => validateCode(code),
            }}
            secondaryAction={{
              label: "Reenviar NIP",
              action: () => resendCode(),
            }}
          />
        </div>
      ),
    },
    secondVerification: {
      title: "Segunda verificación",
      component: (
        <NipField
          error={errorNip}
          messageError={"El código es inválido"}
          primaryAction={{
            label: "Validar Nip",
            action: (code) => validateCode(code),
          }}
          secondaryAction={{
            label: "Reenviar NIP",
            action: () => resendCode(),
          }}
        />
      ),
    },
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content" id="AuthNip">
      <Title>Autenticar cuenta</Title>
      {steps && <StepsBar steps={steps} />}
      <AnnotatedLayout
        title={views[currentView].title}
        description={messagesSteps[dataNip.sentMethod.toLowerCase()].map(
          (msg) => msg.replace("{{sentMean}}", dataNip.sentMean)
        )}
      >
        {views[currentView].component}
      </AnnotatedLayout>
    </div>
  );
};

export default AuthNipGeneric;
