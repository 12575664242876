import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Flag } from "@material-ui/icons";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import BoxList from "@bit/kiban-design-system.kiban.list-box";
import FlowChart from "@bit/kiban-design-system.kiban.flow-chart";
import WorkflooNode from "@bit/kiban-design-system.kiban.workfloo-node";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import Panel from "../../../shared/Panel";
import { apiRequest, apiRoutes } from "../../../services";
import Paths from "../../../paths";
import AlertTexts from "../../../utils/alerts-texts";

import {
  boxOptions,
  useConnector,
  generateID,
  policyNodes,
  nodesIcons,
  getConnector,
  getBoxOpotions,
} from "./utils";

import "./AddEditWorkfloo.css";

const AddEditWorkfloo = () => {
  const [name, setName] = useState(""); // Workfloo name
  const [workflooNodes, setWorkfloosNodes] = useState([
    {
      title: `Inicio`,
      id: generateID(),
      posX: 0,
      posY: 0,
      isRoot: true,
      children: [],
    },
  ]); // Workfloo nodes
  const [lastCreatedNode, setLastCreatedNode] = useState(null); // New node created
  const [connectorType, setConnectorType] = useState(""); // Selected connector type
  const [selectedNode, setSelectedNode] = useState(null); // Selected node to be edited
  const [openModal, setOpenModal] = useState(false); // Connector select modal screen
  const [selectedConnector, setSelectedConnector] = useState(""); // Connector selected to create workfloo node
  const [errors, setErrors] = useState({
    name: false,
  });
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isAnyConnectorSelected, setIsAnyConnectorSelected] = useState(false); // Connector selected state
  const [bxOptions, setBxOptions] = useState([]); // Box options

  const { connectors, loading, error } = useConnector(connectorType); // Hook to get al connectors by type
  const history = useHistory();
  const routeParams = useParams();
  const { addAlert } = useAlert();

  const saveWorkfloo = async () => {
    const workfloo = {
      name,
      nodes: workflooNodes.map((node) => {
        let dataNode = {
          id: parseInt(node.id.split("Element-")[1]),
          connectorType: node.isRoot ? "ROOT" : node.connectorType,
          root: node.isRoot || false,
          posX:
            typeof node.posX === "string" && node.posX.includes("px")
              ? `${node.posX}`
              : `${node.posX}px`,
          posY:
            typeof node.posY === "string" && node.posY.includes("px")
              ? `${node.posY}`
              : `${node.posY}px`,
          children: node.children
            ? node.children.map((nd) => nd.split("Element-")[1])
            : [],
          name: node.title,
        };
        if (node.idConnector) {
          dataNode.idConnector = node.idConnector;
        }
        if (node.idMessage) {
          dataNode.idMessage = node.idMessage;
        }
        return dataNode;
      }),
    };
    if (routeParams.id) {
      workfloo.id = parseInt(routeParams.id);
    }
    const [error, data] = await apiRequest({
      method: !routeParams.id ? "post" : "put",
      url: !routeParams.id
        ? apiRoutes.addWorkfloo
        : apiRoutes.workfloo.replace(":id", routeParams.id),
      data: workfloo,
    });
    if (data !== null) {
      addAlert({
        code: "success",
        message: AlertTexts.success,
      });
      if (!routeParams.id)
        history.replace(Paths.EditWorkfloo.replace(":id", data.id));
    }
    if (error) {
      let message = AlertTexts.unexpectedError;
      if (error.status === 409) {
        message = AlertTexts.nameAlreadyExist;
      }
      addAlert({
        code: "danger",
        message: message,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getWorkfloo = async () => {
    const [error, workfloo] = await apiRequest({
      method: "get",
      url: apiRoutes.workfloo.replace(":id", routeParams.id),
    });
    if (workfloo) {
      setName(workfloo.name);
      const workflooNodes = workfloo.nodes.map((node) => ({
        ...node,
        id: `Element-${node.id}`,
        isRoot: node.root,
        title: node.root ? "Inicio" : node.name,
        children: node.children
          ? node.children.map((nd) => `Element-${nd}`)
          : [],
      }));

      setWorkfloosNodes(workflooNodes);
    }
    if (error) {
      addAlert({
        error: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };

  /**
   *  useEffect hook used to control actions when modal is closed:
   *    - Resets last node created state
   *    - Resets connector type state
   *    - Resets selected connector state
   */
  useEffect(() => {
    if (!openModal) {
      setLastCreatedNode(null);
      setConnectorType("");
      setSelectedConnector("");
      setSelectedNode(null);
    }
  }, [openModal]);

  useEffect(() => {
    if (routeParams.id) {
      setIsLoading(true);
      getWorkfloo();
    }
    (async () => {
      const { options, error } = await getBoxOpotions();
      if (options.length) {
        setBxOptions(options);
      }
      if (error) {
        addAlert({
          code: "danger",
          message: AlertTexts.unexpectedError,
        });
      }
      setIsLoading(false);
    })();
  }, []);

  /**
   * This function is used delete a node from workflooNodes state
   * @function handleDeleteNode
   * @param {string} id workflooNodes element id
   */
  const handleDeleteNode = (id) => {
    /**
     *  Filters all workflooNodes if node id is different by id param
     */
    const node = workflooNodes.find((node) => node.id === id);
    let allNodes = workflooNodes.map((node) => ({ ...node }));
    if (node.connectorType === "POLICY") {
      allNodes = allNodes.filter((nd) => !node.children.includes(nd.id));
    }
    allNodes.forEach((nd) => {
      if (nd.children && nd.children.includes(node.id)) {
        nd.children = nd.children.filter((child) => child !== node.id);
      }
    });
    allNodes = allNodes.filter((nd) => nd.id !== node.id);
    setWorkfloosNodes(allNodes);
  };

  /**
   * This function is used into FlowChart renderNode prop
   * @function renderNode
   * @param {Object} node workflooNodes item
   */
  const renderNode = (node) => {
    let isPolicyChild = false;
    /**
     * Check if node is a child of a policy node
     */
    if (node.connectorType === "MESSAGE")
      workflooNodes.forEach((nd) => {
        if (nd.connectorType === "POLICY" && nd.children.includes(node.id)) {
          isPolicyChild = true;
        }
      });
    return (
      <WorkflooNode
        key={node.id}
        title={node.title}
        isStartNode={node.isRoot || false}
        icon={
          node.isRoot
            ? Flag
            : nodesIcons.find((icon) => icon.value === node.connectorType)
                ?.icon || undefined
        }
        onDelete={
          node.parent || isPolicyChild || node.isRoot
            ? undefined
            : () => handleDeleteNode(node.id)
        }
        onEdit={
          node.parent || isPolicyChild || node.isRoot
            ? undefined
            : () => {
                setConnectorType(node.connectorType);
                setSelectedConnector(node.idConnector);
                setSelectedNode(node.id);
                toggleModal();
              }
        }
      />
    );
  };

  /**
   * This function is used to get connector value when is dragged
   * @function handleGetItemDragged
   * @param {string} value connector type
   */
  const handleGetItemDragged = (value) => {
    setConnectorType(value);
  };

  /**
   * This function is used to set primary values for a new node
   * when connector is dropped into FlowChart canvas
   * @function handleOnDropOver
   * @param {Object} e onDropEvent
   */
  const handleOnDropOver = (e) => {
    const node = {
      posX: e.clientX - e.currentTarget.getBoundingClientRect().left - 44.5, // left position calculated by cursor position and canvas position
      posY: e.clientY - e.currentTarget.getBoundingClientRect().top - 44.5, // top position calculated by cursor position and canvas position
      id: generateID(), // unique id
      children: [], // children nodes
    };
    setLastCreatedNode(node);
    toggleModal();
  };

  const handleSelectConnector = () => {
    if (selectedNode) {
      let allNodes = workflooNodes.map((nd) => ({ ...nd }));
      const node = workflooNodes.find((node) => node.id === selectedNode);
      if (node.connectorType === "POLICY") {
        if (node.children.length > 0) {
          allNodes = allNodes.filter((nd) =>
            node.children.includes(nd.id) ? false : true
          );
        }
      }
      let lastCreatedNode = {
        posX: node.posX,
        posY: node.posY,
        id: generateID(),
        children: [],
      };
      allNodes.forEach((nd) => {
        if (nd.children.includes(selectedNode)) {
          nd.children[nd.children.indexOf(selectedNode)] = lastCreatedNode.id;
        }
      });
      allNodes = allNodes.filter((nd) => nd.id !== selectedNode);
      setWorkfloosNodes(allNodes);
      setLastCreatedNode(lastCreatedNode);
    }
    setIsAnyConnectorSelected(true);
    toggleModal();
  };

  useEffect(() => {
    if (isAnyConnectorSelected) {
      (async () => {
        if (lastCreatedNode && selectedConnector && connectorType) {
          let allNodes = workflooNodes.map((nd) => ({ ...nd }));
          const connector = connectors.find(
            (cn) => cn.id === selectedConnector
          );
          let newNode = {
            ...lastCreatedNode,
            idConnector: selectedConnector,
            title: connector.name,
            connectorType,
          };
          if (connector.connectorType === "POLICY") {
            const policyConnector = await getConnector({
              id: connector.id,
              onError: () =>
                addAlert({
                  code: "danger",
                  message: AlertTexts.unexpectedError,
                  duration: 5000,
                }),
            });
            const newNodes = policyNodes({
              connector: policyConnector,
              connectorType,
              parentNode: newNode,
            });
            newNode.children = newNodes.map((nd) => nd.id);
            allNodes.push(...newNodes);
          }
          allNodes.push(newNode);
          setSelectedNode(null);
          setLastCreatedNode(null);
          setConnectorType("");
          setSelectedConnector("");
          setIsAnyConnectorSelected(false);
          setWorkfloosNodes(allNodes);
        }
      })();
    }
  }, [
    lastCreatedNode,
    selectedConnector,
    selectedNode,
    connectorType,
    isAnyConnectorSelected,
  ]);

  /**
   * This function is used to update a node if its state changes
   * @function handleNodeChange
   * @param {Object} node workflooNodes item
   */
  const handleNodeChange = (node, props) => {
    let allNodes = workflooNodes.map((node) => ({ ...node }));
    let nodeIndex = allNodes.findIndex((nd) => nd.id === node);

    allNodes[nodeIndex] = { ...allNodes[nodeIndex], ...props };
    if (!selectedNode) {
      setWorkfloosNodes(allNodes);
    }
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const isFormValid = () => {
    let errorsArray = { ...errors };
    if (!name) {
      errorsArray.name = true;
    } else {
      errorsArray.name = false;
    }
    setErrors(errorsArray);
    return !errorsArray.name;
  };

  const handleSave = () => {
    if (isFormValid()) {
      saveWorkfloo();
    }
  };

  const pageActions = [
    {
      text: "Guardar",
      onAction: handleSave,
    },
    {
      text: "Cancelar",
      onAction: () => history.replace(Paths.Workfloos),
    },
  ];

  const modalActions = [
    {
      text: "Aplicar",
      onAction: handleSelectConnector,
      disabled: !selectedConnector ? true : false,
    },
    {
      text: "Cancelar",
      onAction: () => toggleModal(),
    },
  ];

  const optValue =
    connectorType ||
    workflooNodes.find((node) => node.id === selectedNode)?.connectorType;
  const selectedOption = boxOptions.find((opt) => opt.value === optValue);

  const modalOptions =
    connectors && connectors.length > 0
      ? connectors.map((connector) => ({
          content: connector.name,
          value: connector.id,
        }))
      : [];

  const handleChangeSelector = (value) => {
    setSelectedConnector(value);
  };

  const handleConnectionsChange = (sourceId, targetId) => {
    let allNodes = workflooNodes.map((node) => {
      return { ...node };
    });
    let sourceNodeIndex = allNodes.findIndex((nd) => nd.id === sourceId);
    if (!allNodes[sourceNodeIndex].children.includes(targetId))
      if (sourceNodeIndex > -1) {
        if (
          allNodes[sourceNodeIndex].children &&
          allNodes[sourceNodeIndex].connectorType === "POLICY"
        ) {
          allNodes[sourceNodeIndex].children = [
            ...allNodes[sourceNodeIndex].children,
            targetId,
          ];
        } else {
          allNodes[sourceNodeIndex].children = [targetId];
        }
        setWorkfloosNodes(allNodes);
      }
  };

  const handleDeleteConnection = (sourceId, targetId) => {
    let allNodes = workflooNodes.map((node) => ({ ...node }));
    let sourceNodeIndex = allNodes.findIndex((nd) => nd.id === sourceId);
    allNodes[sourceNodeIndex].children.splice(
      allNodes[sourceNodeIndex].children.indexOf(targetId),
      1
    );
    setWorkfloosNodes(allNodes);
  };

  return isLoading || !bxOptions.length ? (
    <Skeleton />
  ) : (
    <>
      <div className="main-content" id="AddEditWorkfloo">
        <PageSection
          title="Workfloo"
          primaryAction={pageActions[0]}
          secondaryActions={[pageActions[1]]}
        >
          <PageSection.Item title="Nombre" size={1} length={3}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={errors.name}
              errorText="Este campo no debe estar vacío"
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={1}>
            <div className="Connectors-Panels-Container">
              <Panel className="Connectors--Panel">
                <Panel.Section>
                  <BoxList
                    options={bxOptions.map((opt) => ({
                      ...opt,
                      icon: React.createElement(
                        nodesIcons.find((icon) => icon.value === opt.value).icon
                      ),
                    }))}
                    title="Conectores"
                    getItemDraggable={handleGetItemDragged}
                  />
                </Panel.Section>
              </Panel>
              <Panel className="FlowChart--Panel">
                <Panel.Section>
                  <FlowChart
                    graphHeight={800}
                    graphId="workfloo-flow-chart"
                    nodes={workflooNodes}
                    nodeClassName="workfloo-flow-chart-node"
                    renderNode={renderNode}
                    onNodeChange={handleNodeChange}
                    onConnectionsChange={handleConnectionsChange}
                    onDeleteConnection={handleDeleteConnection}
                    onDropOver={handleOnDropOver}
                  />
                </Panel.Section>
              </Panel>
            </div>
          </PageSection.Item>
        </PageSection>
      </div>
      <ModalScreen
        title={selectedOption ? selectedOption.label : ""}
        open={openModal}
        primaryAction={modalActions[0]}
        secondaryActions={[modalActions[1]]}
        onClose={toggleModal}
      >
        <SelectField
          options={modalOptions}
          value={selectedConnector}
          onChange={handleChangeSelector}
          label="Seleccionar"
          placeholder="Selecciona un conector"
        />
      </ModalScreen>
    </>
  );
};

export default AddEditWorkfloo;
