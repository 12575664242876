import { apiRequest, apiRoutes } from "../../../../services";

const getTypeServices = async ({ connectorType }) => {
  let types = null;
  let error = null;
  const [err, res] = await apiRequest({
    method: "get",
    url: apiRoutes.getConnectorTypeServices.replace(
      ":connectorType",
      connectorType
    ),
  });
  if (res) types = res;
  if (err) error = err;
  return {
    types,
    error,
  };
};

export default getTypeServices;
