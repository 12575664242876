import React, { useEffect, useState } from "react";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Checkbox from "@bit/kiban-design-system.kiban.checkbox";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { fieldTypes, getFieldId, getFieldOptions } from "../utils";

import InformativeTextField from "./InformativeTextField";

import "./CustomFieldModal.css";

const CustomFieldModal = ({
  open,
  sectionTypes,
  onApply,
  onClose,
  formTypes,
  fieldToEdit,
  onDelete,
}) => {
  const [fieldType, setFieldType] = useState(""); // Field type selected
  const [fieldName, setFieldName] = useState(""); // Field name
  const [fieldSection, setFieldSection] = useState(""); // Field section
  const [minChar, setMinChar] = useState(null); // Field min length
  const [maxChar, setMaxChar] = useState(null); // Field max length
  const [regexp, setRegexp] = useState(""); // Field pattern
  const [msj, setMsj] = useState(""); // Field pattern message
  const [minVal, setMinVal] = useState(null); // Field min value
  const [maxVal, setMaxVal] = useState(null); // Field max value
  const [valuesList, setValuesList] = useState(""); // Field values list
  const [informativeText, setInformativeText] = useState(""); // Field informative text
  const [isRequired, setIsRequired] = useState(false); // Field required
  const [toSign, setToSign] = useState(false); // Field to sign
  const [conditionShowFormFieldId, setConditionShowFormFieldId] =
    useState(null); // Field condition show form field id
  const [conditionShowFieldValue, setConditionShowFieldValue] = useState(""); // Field condition show field value
  const [defaultValue, setDefaultValue] = useState(""); // Field default value
  const [errors, setErrors] = useState({
    name: false,
    valuesList: false,
  }); // Field errors

  const { addAlert } = useAlert();

  /**
   *  UseEffect to set all field values when is trying to edit a field
   */
  useEffect(() => {
    if (fieldToEdit) {
      setFieldType(fieldToEdit.type);
      setFieldName(fieldToEdit.name);
      setFieldSection(fieldToEdit.section);
      setMinChar(fieldToEdit.minLength);
      setMaxChar(fieldToEdit.maxLength);
      setRegexp(fieldToEdit.pattern);
      setMsj(fieldToEdit.patternMessage);
      setMinVal(fieldToEdit.minVal);
      setMaxVal(fieldToEdit.maxVal);
      setValuesList(fieldToEdit.listOptions);
      setInformativeText(fieldToEdit.informativeText);
      setIsRequired(fieldToEdit.required);
      setToSign(fieldToEdit.fileToSign);
      setConditionShowFormFieldId(fieldToEdit.conditionShowFormFieldId);
      setConditionShowFieldValue(fieldToEdit.conditionShowFieldValue);
      setDefaultValue(fieldToEdit.defaultValue);
    }
  }, [fieldToEdit]);

  /**
   *  UseEffect used to set field type, name and section as default if is trying to create a new field
   */
  useEffect(() => {
    if (open && !fieldToEdit) {
      setFieldType(fieldTypes[0].value);
      setFieldName("");
      setFieldSection(sectionTypes[0].value);
    }
    if (!open) {
      setFieldType("");
      setFieldName("");
      setFieldSection("");
      setMinChar(null);
      setMaxChar(null);
      setRegexp("");
      setMsj("");
      setMinVal(null);
      setMaxVal(null);
      setValuesList("");
      setInformativeText("");
      setIsRequired(false);
      setToSign(false);
      setConditionShowFormFieldId("");
      setConditionShowFieldValue("");
      setDefaultValue("");
      setErrors({
        name: false,
        valuesList: false,
      });
    }
  }, [open]);

  /**
   *  Event handler to save the field
   */
  const handleApply = async () => {
    const field = {
      ...fieldToEdit,
      type: fieldType,
      name: fieldName,
      section: fieldSection,
      minLength: minChar,
      maxLength: maxChar,
      pattern: regexp,
      patternMessage: msj,
      minVal,
      maxVal,
      listOptions: valuesList,
      informativeText,
      required: isRequired,
      conditionShowFormFieldId,
      conditionShowFieldValue,
      fileToSign: toSign,
      defaultValue,
    };
    let fieldId = null;
    if (!fieldToEdit)
      // If is creating a new field
      fieldId = await getFieldId({
        onError: (error) => {
          addAlert({
            type: "danger",
            text: error.message,
            duration: 5000,
          });
        },
      });
    if (fieldId && !fieldToEdit) {
      // If is creating a new field
      field.id = fieldId.toString();
      onApply(field);
    }
    if (fieldToEdit) {
      // If is editing a field
      field.id = fieldToEdit.id.toString();
      onApply(field, fieldToEdit.section, fieldToEdit.id);
    }
  };

  /**
   * Event handler to close the modal and reset all field values
   */
  const handleClose = () => {
    onClose && onClose();
  };

  /**
   *  Function to validate the required fields
   */
  const validateField = () => {
    let fieldErrors = { ...errors };
    if (!fieldName) {
      fieldErrors.name = true;
    } else {
      fieldErrors.name = false;
    }
    if ((fieldType === "LIST" || fieldType === "RADIO") && !valuesList) {
      fieldErrors.valuesList = true;
    } else {
      fieldErrors.valuesList = false;
    }
    setErrors(fieldErrors);
    return !fieldErrors.name && !fieldErrors.valuesList;
  };

  const handleDeleteField = () => {
    onDelete && onDelete(fieldToEdit);
  };

  const primaryAction = {
    text: "Aplicar",
    onAction: () => {
      if (validateField()) {
        handleApply();
      }
    },
  };
  const cancelAction = {
    text: "Cancelar",
    onAction: handleClose,
  };
  const deleteAction = {
    text: "Eliminar",
    onAction: handleDeleteField,
  };

  return (
    <ModalScreen
      open={open}
      title="Datos del campo"
      primaryAction={primaryAction}
      secondaryActions={[cancelAction]}
      extraAction={fieldToEdit ? deleteAction : undefined}
      onClose={handleClose}
    >
      <div className="CustomFieldModalContent">
        <div className="fields-set">
          <SelectField
            label="Tipo"
            options={fieldTypes}
            placeholder="Selecciona un tipo de campo"
            value={fieldType}
            onChange={setFieldType}
            disabled={(fieldToEdit && fieldToEdit.requirements) || false}
          />
          <TextField
            label="Nombre"
            placeholder="Nombre del campo"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
            error={errors.name}
            errorText="El nombre del campo es requerido"
          />
          <SelectField
            label="Sección"
            options={sectionTypes}
            placeholder="Selecciona una sección"
            value={fieldSection}
            onChange={setFieldSection}
          />
        </div>
        {((fieldToEdit && !fieldToEdit.requirements) || !fieldToEdit) &&
          (fieldType === "ALPHABETIC" ||
            fieldType === "ALPHANUMERIC" ||
            fieldType === "TEXT") && (
            <>
              <div className="fields-set">
                <TextField
                  label="Mínimo caracteres"
                  placeholder="Mínimo caracteres"
                  type="number"
                  value={minChar}
                  onChange={(e) => setMinChar(parseInt(e.target.value))}
                />
                <TextField
                  label="Máximo caracteres"
                  placeholder="Máximo caracteres"
                  type="number"
                  value={maxChar}
                  onChange={(e) => setMaxChar(parseInt(e.target.value))}
                />
              </div>
              {fieldType === "TEXT" && (
                <div className="fields-set">
                  <TextField
                    label="Expresión regular"
                    placeholder="Expresión regular"
                    value={regexp}
                    onChange={(e) => setRegexp(e.target.value)}
                  />
                  <TextField
                    label="Mensaje cuando no se cumple"
                    placeholder="Mensaje cuando no se cumple"
                    value={msj}
                    onChange={(e) => setMsj(e.target.value)}
                  />
                </div>
              )}
            </>
          )}
        {((fieldToEdit && !fieldToEdit.requirements) || !fieldToEdit) &&
          fieldType === "NUMERIC" && (
            <div className="fields-set">
              <TextField
                label="Valor Máximo"
                placeholder="Máximo"
                type="number"
                value={maxVal}
                onChange={(e) => setMaxVal(parseInt(e.target.value))}
              />
              <TextField
                label="Valor Mínimo"
                placeholder="Mínimo"
                type="number"
                value={minVal}
                onChange={(e) => setMinVal(parseInt(e.target.value))}
              />
            </div>
          )}
        {((fieldToEdit && !fieldToEdit.requirements) || !fieldToEdit) &&
          (fieldType === "RADIO" || fieldType === "LIST") && (
            <div className="fields-set">
              <TextField
                label="Lista de valores"
                placeholder="Ej. Opción 1;Opción 2;Opción 3"
                value={valuesList}
                onChange={(e) => setValuesList(e.target.value)}
                error={errors.valuesList}
                errorText="La lista de valores es requerida"
              />
            </div>
          )}
        {/* {fieldType === "LIST" && (
          <div className="fields-set">
            <TextField
              label="Valores"
              placeholder="Valores"
              value={valuesList}
              onChange={(e) => setValuesList(e.target.value)}

            />
          </div>
        )} */}
        <div className="fields-set">
          <SelectField
            label="Mostrar si campo"
            placeholder="Selecciona un campo"
            options={
              fieldToEdit
                ? formTypes.filter((ft) => ft.value !== fieldToEdit.id)
                : formTypes
            }
            value={conditionShowFormFieldId}
            onChange={setConditionShowFormFieldId}
          />
          <TextField
            label="Tiene el valor"
            placeholder="Valor del campo"
            value={conditionShowFieldValue}
            onChange={(e) => setConditionShowFieldValue(e.target.value)}
          />
        </div>
        {((fieldToEdit && !fieldToEdit.requirements) || !fieldToEdit) &&
          fieldType === "INFORMATIVE_TEXT" && (
            <div className="field-set">
              <InformativeTextField
                label="Texto informativo (Puede contener HTML)"
                previewLabel="Vista previa"
                value={informativeText}
                onChange={setInformativeText}
              />
            </div>
          )}
        {fieldType !== "INFORMATIVE_TEXT" && (
          <div className="fields-set">
            <div className="checkbox-field">
              <label>Requerido</label>
              <Checkbox
                onChange={() => {
                  setIsRequired(!isRequired);
                }}
                checked={isRequired}
              />
            </div>
          </div>
        )}
        {((fieldToEdit && !fieldToEdit.requirements) || !fieldToEdit) &&
          fieldType === "PICTURE" && (
            <div className="fields-set">
              <div className="checkbox-field">
                <label>A firmar</label>
                <Checkbox
                  onChange={() => {
                    setToSign(!toSign);
                  }}
                  checked={toSign}
                />
              </div>
            </div>
          )}
        {fieldType !== "PICTURE" && (
          <div className="fields-set">
            {fieldType === "LIST" ||
            fieldType === "RADIO" ||
            fieldType === "CATALOG" ||
            fieldType === "CASO_FICTICIO" ? (
              <SelectField
                label="Valor por defecto"
                placeholder="Selecciona un valor"
                options={getFieldOptions(
                  (fieldToEdit &&
                    (fieldToEdit.catalogs || fieldToEdit.casosFicticios)) ||
                    (valuesList && valuesList.split(";"))
                )}
                value={defaultValue}
                onChange={setDefaultValue}
              />
            ) : (
              <TextField
                label="Valor por defecto"
                placeholder="Valor por defecto"
                value={defaultValue}
                onChange={(e) => setDefaultValue(e.target.value)}
              />
            )}
          </div>
        )}
      </div>
    </ModalScreen>
  );
};

export default CustomFieldModal;
