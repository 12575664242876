import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";

const PFPMForm = ({
  termsAndConditionsUrl,
  onChangeTermsConditionsUrl,
  termsAndConditionsUrlError,
  selectedSMSType,
  onChangeSelectedSMSType,
  messagingTypes,
  smsTypeError,
  selectedEmailType,
  onChangeEmailType,
  emailTypeError,
}) => {
  return (
    <>
      {termsAndConditionsUrl && (
        <>
          <PageSection.Item size={2} length={3} />
          <PageSection.Item size={1} length={3}>
            <TextField
              value={termsAndConditionsUrl}
              placeholder="https://enter.your.url"
              label="Términos y condiciones (URL)"
              onChange={(e) => onChangeTermsConditionsUrl(e.target.value)}
              error={termsAndConditionsUrlError || false}
              errorText="Debes completar este campo"
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
        </>
      )}
      {selectedSMSType && (
        <>
          <PageSection.Item size={1} length={3}>
            <SelectField
              value={selectedSMSType}
              label="NIP Sms conector"
              placeholder="Selecciona un NIP vía SMS/Whatsapp"
              helperText="{{nombres}} {{paterno}} {{materno}} {{nip}} se reemplazan con los datos del cliente"
              onChange={onChangeSelectedSMSType}
              options={messagingTypes.sms || []}
              error={smsTypeError || false}
              errorText="Debes seleccionar un elemento"
            />
          </PageSection.Item>
          <PageSection.Item size={2} length={3} />
        </>
      )}
      {selectedEmailType && (
        <PageSection.Item size={1} length={3}>
          <SelectField
            value={selectedEmailType}
            label="NIP Email conector"
            placeholder="Selecciona un NIP vía email"
            helperText="{{nombres}} {{paterno}} {{materno}} {{nip}} se reemplazan con los datos del cliente"
            onChange={onChangeEmailType}
            options={messagingTypes.email || []}
            error={emailTypeError || false}
            errorText="Debes seleccionar un elemento"
          />
        </PageSection.Item>
      )}
    </>
  );
};

export default PFPMForm;
