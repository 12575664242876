import React, { Fragment, useEffect, useState } from "react";
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import AlertTexts from "../../../utils/alerts-texts";
import { apiRequest, apiRoutes } from "../../../services";

const FormLegalario = ({
  radioSelected,
  templatePdfId,
  setTemplatePdfId,
  addAlert,
  dataFormTemplate,
  setDataFormTemplate,
  setRadioSelected,
  mappingTemplate,
  formTemplate,
  setFormTemplate,
}) => {
  const [indexDeleteForm, setIndexDeleteForm] = useState(null);
  const [dataUpdate, setDataUpdate] = useState(null);
  const [optionsField, setOptionsField] = useState({
    VPR: null,
    FORM: null,
    VP: null,
  });
  const optionsMapping = [
    {
      content: "Formulario",
      value: "FORM",
    },
    {
      content: "Variable Personalizada",
      value: "VPR",
    },
    {
      content: "VP Dinámicas",
      value: "VP",
    },
  ];

  const getFieldsOptions = async (type) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getConnectorsDocument}?typeConnector=${type}`,
    });
    if (!error) {
      let options = new Array();
      data.forEach((item) => {
        options.push({
          section: true,
          content: item.name,
        });
        item.fields.forEach((field) => {
          options.push({
            content: field.name,
            value: `${item.id}&${field.name}&${field.id}`,
          });
        });
      });
      const updateOptionsField = { ...optionsField };
      updateOptionsField[type] = options;
      setOptionsField(updateOptionsField);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
  };

  const generateFormTemplate = (index, data) => {
    return [
      <Fragment key={`form-template-${index}`}>
        <PageSection.Item size={1} length={4}>
          <TextField
            label="Identificador Legalario"
            value={data[index].idLegalario}
            onChange={(e) =>
              setDataUpdate({
                index,
                key: "idLegalario",
                value: e.target.value,
              })
            }
          />
        </PageSection.Item>
        <PageSection.Item size={1} length={4}>
          <SelectField
            label="Mapear"
            placeholder="Selecciona una opción"
            value={data[index].mappingSelected}
            onChange={(value) =>
              setDataUpdate({
                index,
                key: "mappingSelected",
                value: value,
              })
            }
            options={optionsMapping}
          />
        </PageSection.Item>
        <PageSection.Item size={1} length={4}>
          <SelectField
            label="Campo"
            placeholder="Seleccionar campo"
            value={data[index].fieldMapping}
            onChange={(value) =>
              setDataUpdate({
                index,
                key: "fieldMapping",
                value: value,
              })
            }
            options={data[index].optionsFields}
          />
        </PageSection.Item>
        <PageSection.Item size={1} length={4}>
          <div style={{ paddingTop: "35px" }}>
            <Button
              style="primary"
              text="Borrar"
              onClick={() => setIndexDeleteForm(index)}
            />
          </div>
        </PageSection.Item>
      </Fragment>,
    ];
  };

  const addFormTemplate = () => {
    const updateFormTemplate = [...formTemplate];
    const updateDataFormTemplate = [...dataFormTemplate];
    updateDataFormTemplate.push({
      idLegalario: "",
      mappingSelected: "",
      fieldMapping: "",
      optionsFields: [],
    });
    const index = updateFormTemplate.length;
    updateFormTemplate.push(
      generateFormTemplate(index, updateDataFormTemplate)
    );
    setDataFormTemplate(updateDataFormTemplate);
    setFormTemplate(updateFormTemplate);
  };

  const handleOnRadioChange = (key, value) => {
    const updateRadioSelected = { ...radioSelected };
    updateRadioSelected[key] = value === "true";
    setRadioSelected(updateRadioSelected);
  };

  const handleOnChangeFormTemplate = ({ index, key, value }) => {
    const updateDataFormTemplate = [...dataFormTemplate];
    updateDataFormTemplate[index][key] = value;
    if (key === "mappingSelected") {
      updateDataFormTemplate[index]["optionsFields"] = optionsField[value];
    }
    const updateFormTemplate = formTemplate.map((_form, _index) => {
      return generateFormTemplate(_index, updateDataFormTemplate);
    });
    setDataFormTemplate(updateDataFormTemplate);
    setFormTemplate(updateFormTemplate);
    setDataUpdate(null);
  };

  const deleteFormTemplate = (index) => {
    const updateDataFormTemplate = dataFormTemplate
      .slice(0, index)
      .concat(dataFormTemplate.slice(index + 1, dataFormTemplate.length + 1));
    let updateFormTemplate = [];
    formTemplate.forEach((_form, _index) => {
      if (_index < formTemplate.length - 1) {
        updateFormTemplate.push(
          generateFormTemplate(_index, updateDataFormTemplate)
        );
      }
    });
    setIndexDeleteForm(null);
    setFormTemplate(updateFormTemplate);
    setDataFormTemplate(updateDataFormTemplate);
  };

  useEffect(() => {
    const key = Object.keys(optionsField).find((k) => optionsField[k] === null);
    if (key) {
      getFieldsOptions(key);
    }
  }, [optionsField]);

  useEffect(() => {
    if (dataUpdate !== null) {
      handleOnChangeFormTemplate(dataUpdate);
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (indexDeleteForm !== null) {
      deleteFormTemplate(indexDeleteForm);
    }
  }, [indexDeleteForm]);

  useEffect(() => {
    const key = Object.keys(optionsField).find((k) => optionsField[k] === null);
    if (mappingTemplate && key === undefined) {
      const updateDataFormTemplate = mappingTemplate.map((map) => {
        return {
          idLegalario: map.legalarioId,
          mappingSelected: map.connectorType,
          fieldMapping: `${map.connectorId}&${map.fieldName}&${map.fieldId}`,
          optionsFields: optionsField[map.connectorType],
        };
      });
      const forms = updateDataFormTemplate.map((_data, index) =>
        generateFormTemplate(index, updateDataFormTemplate)
      );
      setDataFormTemplate(updateDataFormTemplate);
      setFormTemplate(forms);
    }
  }, [optionsField, mappingTemplate]);

  const StylesRadioButton = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  return (
    <>
      <PageSection.Item title="Firma del usuario requerida" size={1} length={4}>
        <div style={StylesRadioButton}>
          <RadioButton
            label="Si"
            name="userRequiredSignature"
            value={true}
            checked={radioSelected["userRequiredSignature"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("userRequiredSignature", value)
            }
          />
          <RadioButton
            label="No"
            name="userRequiredSignature"
            value={false}
            checked={!radioSelected["userRequiredSignature"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("userRequiredSignature", value)
            }
          />
        </div>
      </PageSection.Item>
      <PageSection.Item size={3} length={4} />
      <PageSection.Item title="Firmas secuenciales" size={1} length={4}>
        <div style={StylesRadioButton}>
          <RadioButton
            label="Si"
            name="workflow"
            value={true}
            checked={radioSelected["workflow"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("workflow", value)
            }
          />
          <RadioButton
            label="No"
            name="workflow"
            value={false}
            checked={!radioSelected["workflow"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("workflow", value)
            }
          />
        </div>
      </PageSection.Item>
      <PageSection.Item size={3} length={4} />
      <PageSection.Item title="Enviar por WhatsApp" size={1} length={4}>
        <div style={StylesRadioButton}>
          <RadioButton
            label="Si"
            name="whatsApp"
            value={true}
            checked={radioSelected["whatsApp"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("whatsApp", value)
            }
          />
          <RadioButton
            label="No"
            name="whatsApp"
            value={false}
            checked={!radioSelected["whatsApp"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("whatsApp", value)
            }
          />
        </div>
      </PageSection.Item>
      <PageSection.Item size={3} length={4} />
      <PageSection.Item
        title="Crear documento desde plantilla"
        size={1}
        length={4}
      >
        <div style={StylesRadioButton}>
          <RadioButton
            label="Si"
            name="fillDocument"
            value={true}
            checked={radioSelected["fillDocument"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("fillDocument", value)
            }
          />
          <RadioButton
            label="No"
            name="fillDocument"
            value={false}
            checked={!radioSelected["fillDocument"]}
            onChange={(_checked, value) =>
              handleOnRadioChange("fillDocument", value)
            }
          />
        </div>
      </PageSection.Item>
      <PageSection.Item size={3} length={4} />
      {radioSelected["fillDocument"] && (
        <>
          <PageSection.Item size={1} length={2}>
            <TextField
              label="Plantilla Id"
              value={templatePdfId}
              onChange={(e) => {
                setTemplatePdfId(e.target.value);
              }}
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={2} />
          {formTemplate.map((form) => form)}
          <PageSection.Item size={1} length={2}>
            <div style={{ paddingTop: "35px" }}>
              <Button
                onClick={addFormTemplate}
                style="light"
                text="Agregar dato para plantilla"
              />
            </div>
          </PageSection.Item>
        </>
      )}
    </>
  );
};

export default FormLegalario;
