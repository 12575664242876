import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// start design-system
import Alert from "@bit/kiban-design-system.kiban.alert";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Button from "@bit/kiban-design-system.kiban.button";
import InputGroup from "@bit/kiban-design-system.kiban.input-group";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import TextField from "@bit/kiban-design-system.kiban.text-field";
// end design-system
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import { validateEmail, validatePassword } from "../../utils/validators";
import alertsTexts from "../../utils/alerts-texts";
import { SnackBar as SnackBarStyles } from "../../styles";
import Paths from "../../paths";
import "./AddEditUser.css";

export default function AddEditUser() {
  const { addAlert } = useAlert();
  const routerParams = useParams();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorFields, setErrorFields] = useState(null);
  const [userData, setUserData] = useState({ admin: false });
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordConfirmErrorMessage, setPasswordConfirmErrorMessage] =
    useState("");
  const [msgValidatePassword, setMsgValidatePassword] = useState({});
  const [errorApi, setErrorApi] = useState(null);
  const [showNotice, setShowNotice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlertValidatePassword, setShowAlertValidatePassword] =
    useState(false);
  const [typeValidatePassword, setTypeValidatePassword] = useState("");

  const snackBarClasses = SnackBarStyles();

  /** Get the current account country */
  const accountCountry = Jwt.getItem("country");

  const getUserData = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.editUser.replace(":id", routerParams.id),
    });
    setErrorApi(error);
    if (!error) {
      setUserData(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      if (routerParams.id) {
        getUserData();
      }
      setIsLoading(false);
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleOnChangeUser = (key, value) => {
    const updateUser = { ...userData };
    updateUser[key] = value;
    setUserData(updateUser);
    if (key.includes("BC")) {
      let updateErrorFields = { ...errorFields };
      const keys = key.includes("BC_PM")
        ? ["userBC_PM", "claveBC_PM"]
        : ["userBC", "claveBC"];
      keys.forEach((k) => (updateErrorFields[k] = false));
      setErrorFields(updateErrorFields);
      let updateMsgValidatePassword = { ...msgValidatePassword };
      updateMsgValidatePassword[key.includes("BC_PM") ? "PM" : "PF"] = "";
      setMsgValidatePassword(updateMsgValidatePassword);
    }
  };

  const updateUser = () => {
    setIsLoading(true);
    if (formValid()) {
      setErrorMessage(true);
      handleSaveData();
    } else {
      setIsLoading(false);
    }
  };

  const validateBC = async (type, user, password) => {
    let body = {
      password: password,
      serviceType: type,
      user: user,
    };
    const [error] = await apiRequest({
      method: "post",
      url: apiRoutes.validateBC,
      data: body,
    });
    setTypeValidatePassword(type);
    if (!error) {
      setShowAlertValidatePassword(true);
      setTimeout(() => setShowAlertValidatePassword(false), 5000);
    } else {
      let updateMsgValidatePassword = { ...msgValidatePassword };
      updateMsgValidatePassword[type] =
        error.status === 401
          ? alertsTexts.invalidCredentials
          : alertsTexts.unexpectedError;
      setMsgValidatePassword(updateMsgValidatePassword);
      let updateErrorFields = { ...errorFields };
      updateErrorFields[`userBC${type === "PM" ? `_${type}` : ""}`] = true;
      updateErrorFields[`claveBC${type === "PM" ? `_${type}` : ""}`] = true;
      setErrorFields(updateErrorFields);
    }
    setIsLoading(false);
  };

  const formValid = () => {
    let errors = {};
    if (!userData.primerNombre) {
      errors["primerNombre"] = true;
    }
    if (!userData.apellidoPaterno) {
      errors["apellidoPaterno"] = true;
    }
    if (!userData.email) {
      errors["email"] = true;
      setEmailErrorMessage("El campo no puede estar vacio");
    }
    if (userData.email && !validateEmail(userData.email)) {
      errors["email"] = true;
      setEmailErrorMessage("Debe ingresar un email válido");
    }
    if (!routerParams.id || userData.password || userData.confirmPassword) {
      if (!userData.password) {
        errors["password"] = true;
        setPasswordErrorMessage("El campo no puede estar vacio");
      }
      if (!userData.confirmPassword) {
        errors["confirmPassword"] = true;
        setPasswordConfirmErrorMessage("El campo no puede estar vacio");
      }
      if (userData.confirmPassword !== userData.password) {
        errors["password"] = true;
        errors["confirmPassword"] = true;
        setPasswordErrorMessage("El campo contraseña no coincide");
        setPasswordConfirmErrorMessage("El campo contraseña no coincide");
      }
      if (userData.password && !validatePassword(userData.password)) {
        errors["password"] = true;
        setPasswordErrorMessage("Debe ingresar un password válido");
      }
      if (
        userData.confirmPassword &&
        !validatePassword(userData.confirmPassword)
      ) {
        errors["confirmPassword"] = true;
        setPasswordConfirmErrorMessage("Debe ingresar un password válido");
      }
    }
    if (userData.userBC && !userData.claveBC) {
      errors["claveBC"] = true;
    }
    if (!userData.userBC && userData.claveBC) {
      errors["userBC"] = true;
    }
    if (userData.userBC_PM && !userData.claveBC_PM) {
      errors["claveBC_PM"] = true;
    }
    if (!userData.userBC_PM && userData.claveBC_PM) {
      errors["userBC_PM"] = true;
    }
    if (userData.userCC && !userData.claveCC) {
      errors["claveCC"] = true;
    }
    if (!userData.userCC && userData.claveCC) {
      errors["userCC"] = true;
    }
    if (userData.userCC_PM && !userData.claveCC_PM) {
      errors["claveCC_PM"] = true;
    }
    if (userData.userCC_PM && !userData.claveCC_PM) {
      errors["userCC_PM"] = true;
    }
    setErrorFields(errors);
    return (
      !errors.primerNombre &&
      !errors.apellidoPaterno &&
      !errors.email &&
      !errors.confirPassword &&
      !errors.password &&
      !errors.userBC &&
      !errors.claveBC &&
      !errors.userBC_PM &&
      !errors.claveBC_PM &&
      !errors.userCC &&
      !errors.claveCC &&
      !errors.claveCC_PM &&
      !errors.userCC_PM
    );
  };

  const handleSaveData = async () => {
    if (routerParams.id) {
      const response = await apiRequest({
        method: "put",
        url: apiRoutes.editUser.replace(":id", routerParams.id),
        data: userData,
      });
      if (!response[0]) {
        addAlert({
          code: "success",
          message: alertsTexts.success,
          duration: 5000,
        });
      } else {
        if (response[0].status === 409) {
          setErrorFields({ ...errorFields, email: true });
          setEmailErrorMessage(alertsTexts.emailAlreadyExist);
        } else {
          addAlert({
            code: "danger",
            message: alertsTexts.unexpectedError,
            duration: 5000,
          });
        }
      }
      setIsLoading(false);
    } else {
      createUser();
    }
  };

  const createUser = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.addUser,
      data: userData,
    });
    if (!error) {
      history.push(Paths.EditUser.replace(":id", data.id));
      addAlert({
        code: "success",
        message: alertsTexts.success,
        duration: 5000,
      });
    } else {
      if (error.status === 409) {
        setErrorFields({ ...errorFields, email: true });
        setEmailErrorMessage(alertsTexts.emailAlreadyExist);
      } else {
        addAlert({
          code: "danger",
          message: alertsTexts.unexpectedError,
          duration: 5000,
        });
      }
    }
    setIsLoading(false);
  };

  const propsButtonSave = {
    disabled: isLoading,
    text: "Guardar",
    onAction: () => updateUser(),
  };

  const cancelAction = {
    text: "Cancelar",
    onAction: () => {
      history.replace(Paths.Users);
    },
  };

  return (
    <>
      <div className="main-content">
        <Snackbar
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowNotice(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          className={snackBarClasses.success}
          open={showNotice}
          autoHideDuration={6000}
          message={
            errorMessage
              ? "Verifica los campos con errores"
              : "Los cambios han sido guardados"
          }
          onClose={() => setShowNotice(false)}
        ></Snackbar>
        <PageSection
          title="Usuario"
          primaryAction={propsButtonSave}
          secondaryActions={[cancelAction]}
        >
          <PageSection.Item
            className="field"
            title="Usuario"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                error={errorFields ? errorFields.email : false}
                errorText={emailErrorMessage}
                disabled={routerParams.id || isLoading}
                id="email"
                value={userData ? userData.email : ""}
                onChange={(e) => handleOnChangeUser("email", e.target.value)}
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            size={1}
            length={3}
          ></PageSection.Item>
          <PageSection.Item
            className="field"
            size={1}
            length={3}
          ></PageSection.Item>

          <PageSection.Item
            className="field"
            title="Primer Nombre"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                error={errorFields ? errorFields.primerNombre : false}
                errorText="El campo no puede estar vacio"
                disabled={isLoading}
                id="primerNombre"
                value={userData ? userData.primerNombre : ""}
                onChange={(e) =>
                  handleOnChangeUser("primerNombre", e.target.value)
                }
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            title="Segundo Nombre"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                error={""}
                disabled={isLoading}
                id="segundoNombre"
                value={userData ? userData.segundoNombre : ""}
                onChange={(e) =>
                  handleOnChangeUser("segundoNombre", e.target.value)
                }
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            size={1}
            length={3}
          ></PageSection.Item>
          <PageSection.Item
            className="field"
            title="Apellido Paterno"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                error={errorFields ? errorFields.apellidoPaterno : false}
                errorText="El campo no puede estar vacio"
                disabled={isLoading}
                id="apellidoPaterno"
                value={userData ? userData.apellidoPaterno : ""}
                onChange={(e) =>
                  handleOnChangeUser("apellidoPaterno", e.target.value)
                }
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            title="Apellido Materno"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                error={""}
                disabled={isLoading}
                id="apellidoMaterno"
                value={userData ? userData.apellidoMaterno : ""}
                onChange={(e) =>
                  handleOnChangeUser("apellidoMaterno", e.target.value)
                }
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            size={1}
            length={3}
          ></PageSection.Item>
          <PageSection.Item
            className="field"
            title="Contraseña Workfloo"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                disabled={isLoading}
                error={errorFields ? errorFields.password : false}
                errorText={passwordErrorMessage}
                helperText="La contraseña debe tener de 8 a 32 caracteres, una mayúscula, un caracter especial y un número"
                id="password"
                onChange={(e) => handleOnChangeUser("password", e.target.value)}
                type="password"
                value={userData ? userData.password : ""}
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            title="Confirmar contraseña Workfloo"
            size={1}
            length={3}
          >
            <InputGroup.Section>
              <TextField
                disabled={isLoading}
                error={errorFields ? errorFields.confirmPassword : false}
                errorText={passwordConfirmErrorMessage}
                helperText="La contraseña debe tener de 8 a 32 caracteres, una mayúscula, un caracter especial y un número"
                id="confirmPassword"
                onChange={(e) =>
                  handleOnChangeUser("confirmPassword", e.target.value)
                }
                type="password"
                value={userData ? userData.confirmPassword : ""}
              />
            </InputGroup.Section>
          </PageSection.Item>
          <PageSection.Item
            className="field"
            size={1}
            length={3}
          ></PageSection.Item>
          {accountCountry !== "CO" && (
            <>
              <div class="solid">
                ACCESOS
                <hr></hr>
              </div>
              <PageSection.Item
                className="field"
                title="Usuario Buró de crédito"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    error={errorFields ? errorFields.userBC : false}
                    errorText={
                      userData.userBC !== ""
                        ? msgValidatePassword?.PF
                        : "El campo no puede estar vacio"
                    }
                    disabled={isLoading}
                    id="userBC"
                    value={userData ? userData.userBC : ""}
                    onChange={(e) =>
                      handleOnChangeUser("userBC", e.target.value)
                    }
                  />
                  {showAlertValidatePassword &&
                    typeValidatePassword === "PF" && (
                      <Alert code="success" text="Credenciales válidas" />
                    )}
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Contraseña"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    type="password"
                    error={errorFields ? errorFields.claveBC : false}
                    errorText={
                      userData.claveBC !== ""
                        ? msgValidatePassword?.PF
                        : "El campo no puede estar vacio"
                    }
                    disabled={isLoading}
                    id="claveBC"
                    value={userData ? userData.claveBC : ""}
                    onChange={(e) =>
                      handleOnChangeUser("claveBC", e.target.value)
                    }
                    showPass={true}
                  />
                  {showAlertValidatePassword &&
                    typeValidatePassword === "PF" && (
                      <Alert code="success" text="Credenciales válidas" />
                    )}
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item className="field" title=" " size={1} length={3}>
                <InputGroup.Section className="user_button__validate">
                  <Button
                    disabled={userData.userBC === "" || userData.claveBC === ""}
                    onClick={() => {
                      validateBC("PF", userData.userBC, userData.claveBC);
                    }}
                    style="primary"
                    text="Validar Contraseña"
                  />
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Usuario Buró de crédito PM"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    error={errorFields ? errorFields.userBC_PM : false}
                    errorText={
                      userData.userBC_PM !== ""
                        ? msgValidatePassword?.PM
                        : "El campo no puede estar vacio"
                    }
                    disabled={isLoading}
                    id="userBC_PM"
                    value={userData ? userData.userBC_PM : ""}
                    onChange={(e) =>
                      handleOnChangeUser("userBC_PM", e.target.value)
                    }
                  />
                  {showAlertValidatePassword &&
                    typeValidatePassword === "PM" && (
                      <Alert code="success" text="Credenciales válidas" />
                    )}
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Contraseña"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    type="password"
                    error={errorFields ? errorFields.claveBC_PM : false}
                    errorText={
                      userData.claveBC_PM !== ""
                        ? msgValidatePassword?.PM
                        : "El campo no puede estar vacio"
                    }
                    disabled={isLoading}
                    id="claveBC_PM"
                    value={userData ? userData.claveBC_PM : ""}
                    onChange={(e) =>
                      handleOnChangeUser("claveBC_PM", e.target.value)
                    }
                    showPass={true}
                  />
                  {showAlertValidatePassword &&
                    typeValidatePassword === "PM" && (
                      <Alert code="success" text="Credenciales válidas" />
                    )}
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item className="field" title=" " size={1} length={3}>
                <InputGroup.Section className="user_button__validate">
                  <Button
                    disabled={
                      userData.userBC_PM === "" || userData.claveBC_PM === ""
                    }
                    onClick={() => {
                      validateBC("PM", userData.userBC_PM, userData.claveBC_PM);
                    }}
                    style="primary"
                    text="Validar Contraseña"
                  />
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Usuario Círculo de crédito"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    error={errorFields ? errorFields.userCC : false}
                    errorText="El campo no puede estar vacio"
                    disabled={isLoading}
                    id="userCC"
                    value={userData ? userData.userCC : ""}
                    onChange={(e) =>
                      handleOnChangeUser("userCC", e.target.value)
                    }
                  />
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Contraseña"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    type="password"
                    error={errorFields ? errorFields.claveCC : false}
                    errorText="El campo no puede estar vacio"
                    disabled={isLoading}
                    id="claveCC"
                    value={userData ? userData.claveCC : ""}
                    onChange={(e) =>
                      handleOnChangeUser("claveCC", e.target.value)
                    }
                  />
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                size={1}
                length={3}
              ></PageSection.Item>
              <PageSection.Item
                className="field"
                title="Usuario Círculo de crédito PM"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    error={errorFields ? errorFields.userCC_PM : false}
                    errorText="El campo no puede estar vacio"
                    disabled={isLoading}
                    id="userCC_PM"
                    value={userData ? userData.userCC_PM : ""}
                    onChange={(e) =>
                      handleOnChangeUser("userCC_PM", e.target.value)
                    }
                  />
                </InputGroup.Section>
              </PageSection.Item>
              <PageSection.Item
                className="field"
                title="Contraseña"
                size={1}
                length={3}
              >
                <InputGroup.Section>
                  <TextField
                    type="password"
                    error={errorFields ? errorFields.claveCC_PM : false}
                    errorText="El campo no puede estar vacio"
                    disabled={isLoading}
                    id="claveCC_PM"
                    value={userData ? userData.claveCC_PM : ""}
                    onChange={(e) =>
                      handleOnChangeUser("claveCC_PM", e.target.value)
                    }
                  />
                </InputGroup.Section>
              </PageSection.Item>
            </>
          )}
          <div class="solid">
            PERMISOS
            <hr></hr>
          </div>
          <PageSection.Item
            className="field"
            title="¿Definir como Admnistrador?"
            size={1}
            length={2}
          >
            <RadioButton
              label="Si"
              name="admin"
              value={true}
              checked={userData && userData.admin}
              onChange={() => handleOnChangeUser("admin", true)}
            />
            <RadioButton
              label="No"
              name="admin"
              value={false}
              checked={userData && !userData.admin}
              onChange={() => handleOnChangeUser("admin", false)}
            />
          </PageSection.Item>

          {userData.admin === false && (
            <PageSection.Item
              className="field"
              title="Puede ver todas las consultas"
              size={1}
              length={2}
            >
              <RadioButton
                label="Si"
                name="canSeeAllQueries"
                value={true}
                checked={userData && userData.canSeeAllQueries}
                onChange={() => handleOnChangeUser("canSeeAllQueries", true)}
              />
              <RadioButton
                label="No"
                name="canSeeAllQueries"
                value={false}
                checked={userData && !userData.canSeeAllQueries}
                onChange={() => handleOnChangeUser("canSeeAllQueries", false)}
              />
            </PageSection.Item>
          )}
        </PageSection>
      </div>
    </>
  );
}
