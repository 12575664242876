import React from "react";
import { PlusIcon } from "@bit/kiban-design-system.kiban.icons";

import Variable from "./Variable";
import VariableTypes from "../../../../utils/customVariablesTypeOptions";
import Panel from "../../../../shared/Panel";
import VariablesSectionHeaders from "./VariablesSectionHeaders";

const VariablesSection = ({ variables, onAddNew, onEditVariable }) => {
  const renderVariables = (data) => {
    const index = variables.findIndex((item) => data.name === item.name);
    const type = VariableTypes.find((type) => type.value === data.vartype);

    return (
      <li>
        <Variable
          index={data.id}
          name={data.name}
          type={type.content}
          onEdit={() => onEditVariable && onEditVariable(index)}
        />
      </li>
    );
  };
  return (
    <Panel.Section
      title="Variables"
      primaryAction={{
        text: "Añadir",
        icon: PlusIcon,
        onAction: onAddNew,
      }}
    >
      <div className="variables-list">
        <div className="headers">
          <VariablesSectionHeaders />
        </div>
        <ul>
          {variables.length > 0 && (
            <>{variables.map((variable, i) => renderVariables(variable, i))}</>
          )}
        </ul>
      </div>
    </Panel.Section>
  );
};

export default VariablesSection;
