import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import {
  normalizeDataTypes,
  AGGREGATION_TYPES,
  operatorsTypes,
  getStepTypesServices,
  createEditStep,
  getUniverses,
  getCatalog,
} from "./utils";
import Filters from "./Filters";
import alertsTexts from "../../../../utils/alerts-texts";
import sortCatalog from "../../../../utils/sort-catalogs";

import "./index.css";

const StepsModal = ({ open, onClose, onSave, stepToEdit }) => {
  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);
  const [servicesTypes, setServicesTypes] = useState({});
  const [sectionTypes, setSectionTypes] = useState({});
  const [fieldsTypes, setFieldsTypes] = useState({});
  const [catalogsTypes, setCatalogsTypes] = useState({});
  const [catalogsNamesTypes, setCatalogsNamesTypes] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [universesTypes, setUniversesTypes] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedUniverse, setSelectedUniverse] = useState("");
  const [enableFilter, setEnableFilter] = useState(false);
  const [agg, setAgg] = useState("");
  const [ope, setOpe] = useState("");
  const [selectedConditionField, setSelectedConditionField] = useState("");
  const [value, setValue] = useState("");
  const [universe, setUniverse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [conditionFieldSearch, setConditionFieldSearch] = useState("");
  const [conditionFieldOptions, setConditionFieldOptions] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    service: false,
    section: false,
    agg: false,
    ope: false,
    conditionField: false,
    value: false,
    filters: [],
  });

  const { addAlert } = useAlert();
  const routeParams = useParams();

  const getTypes = async () => {
    setIsLoading(true);
    const { types, services, sections, fields, catalogs, catalogsNames } =
      normalizeDataTypes(
        await getStepTypesServices({
          onError: (err) => {
            addAlert({
              code: "danger",
              message: err.message || alertsTexts.unexpectedError,
              duration: 5000,
            });
          },
        })
      );
    setTypes(types);
    setServicesTypes(services);
    setSectionTypes(sections);
    setFieldsTypes(fields);
    setCatalogsTypes(catalogs);
    setCatalogsNamesTypes(catalogsNames);
    setIsLoading(false);
  };

  const getUniversesTypes = async () => {
    const universes = await getUniverses({
      id: routeParams.id,
      onError: (err) => {
        addAlert({
          code: "danger",
          message: err.message || alertsTexts.unexpectedError,
          duration: 5000,
        });
      },
    });
    if (universes) {
      setUniversesTypes(
        universes.map((universe) => ({
          value: universe,
          content: universe,
        }))
      );
    }
  };

  useEffect(() => {
    if (!isFirstLoad) {
      setSelectedService("");
      setSelectedSection("");
    }
  }, [selectedType]);

  useEffect(() => {
    if (!isFirstLoad) {
      setSelectedSection("");
    }
  }, [selectedService]);

  useEffect(() => {
    if (!isFirstLoad) {
      setFilters([]);
      setEnableFilter(false);
      setAgg("");
      setOpe("");
      setSelectedConditionField("");
      setValue("");
    }
  }, [selectedType, selectedService, selectedSection]);

  useEffect(() => {
    if (!isFirstLoad) {
      setSelectedConditionField("");
      setOpe("");
      setValue("");
    }
  }, [agg]);

  useEffect(() => {
    if (open) {
      getUniversesTypes();
    }
    if (!open) {
      setName("");
      setSelectedType("");
      setSelectedService("");
      setSelectedSection("");
      setSelectedUniverse("");
      setUniverse("");
    }
  }, [open]);

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  }, [name, selectedType, selectedService, selectedSection, agg, isFirstLoad]);

  useEffect(() => {
    if (stepToEdit && types) {
      setIsFirstLoad(true);
      setName(stepToEdit.name);
      const selectedType = types.find((type) => type.value === stepToEdit.type);
      if (!selectedType) {
        addAlert({
          code: "danger",
          message: alertsTexts.unexpectedError,
          duration: 5000,
        });
        return;
      }
      setSelectedType(stepToEdit.type);
      setSelectedService(stepToEdit.service);
      setSelectedSection(stepToEdit.pathToSection);
      setFilters(stepToEdit.conditions || []);
      if (stepToEdit.conditions) {
        setEnableFilter(true);
      }
      setAgg(stepToEdit.agg);
      setOpe(stepToEdit.ope);
      setSelectedConditionField(stepToEdit.pathToField);
      setValue(stepToEdit.value);
      setUniverse(stepToEdit.universe);
      setSelectedUniverse(stepToEdit.filterFromUniverse);
    }
  }, [stepToEdit, types]);

  const handleEnableFilterChange = (value) => {
    setEnableFilter(value);
  };

  const handleAddFilter = (operatorType) => {
    setFilters([
      ...filters,
      {
        id: filters.length,
        operatorType,
        operator: "",
        value: "",
        pathToField: "",
      },
    ]);
  };

  const saveStep = async () => {
    setIsLoading(true);
    if (enableFilter && !filters.length) {
      setEnableFilter(false);
    }
    const step = {
      name,
      type: selectedType,
      service: selectedService,
      pathToSection: selectedSection,
      pathToField: selectedConditionField,
      agg,
      ope,
      value,
      universe,
      filterFromUniverse: selectedUniverse,
      conditions: filters,
    };

    const stepId = await createEditStep({
      isEdit: stepToEdit ? true : false,
      step: {
        ...step,
        id: stepToEdit ? stepToEdit.id : null,
      },
      id: routeParams.id,
      onError: (err) => {
        let errMessage = err.message || alertsTexts.unexpectedError;
        if (err.status === 409) {
          errMessage = alertsTexts.nameAlreadyExist;
        }
        addAlert({
          code: "danger",
          message: errMessage,
          duration: 5000,
        });
      },
    });

    if (onSave) {
      if (stepToEdit || (!stepToEdit && stepId)) {
        if (universe) {
          let universes = universesTypes.map((universe) => ({ ...universe }));
          universes.push({
            value: universe,
            content: universe,
          });
          setUniversesTypes(universes);
        }
        step.id = stepToEdit ? stepToEdit.id : stepId.id || stepId;
        onSave(step);
      }
    }
    setIsLoading(false);
  };

  const handleSave = () => {
    if (validateStep()) {
      saveStep();
    }
  };

  const validateStep = () => {
    const formErrors = { ...errors };
    const filtersErrors = [];
    if (!name) {
      formErrors.name = true;
    } else {
      formErrors.name = false;
    }
    if (!selectedType) {
      formErrors.type = true;
    } else {
      formErrors.type = false;
    }
    if (servicesTypes[selectedType] && !selectedService) {
      formErrors.service = true;
    } else {
      formErrors.service = false;
    }
    if (!selectedSection) {
      formErrors.section = true;
    } else {
      formErrors.section = false;
    }
    if (!agg) {
      formErrors.agg = true;
    } else {
      formErrors.agg = false;
    }
    if (!ope) {
      formErrors.ope = true;
    } else {
      formErrors.ope = false;
    }
    if (agg && agg === "sum" && !selectedConditionField) {
      formErrors.selectedConditionField = true;
    } else {
      formErrors.selectedConditionField = false;
    }
    if (!value) {
      formErrors.value = true;
    } else {
      formErrors.value = false;
    }
    if (filters.length) {
      filters.forEach((filter, index) => {
        if (
          !filter.pathToField ||
          !filter.operator ||
          (filter.operator !== "IS_NULL" &&
            filter.operator !== "IS_NOT_NULL" &&
            !filter.value)
        ) {
          let error = { id: filter.id };
          if (!filter.pathToField) {
            error.pathToField = true;
          }
          if (!filter.operator) {
            error.operator = true;
          }
          if (!filter.value) {
            error.value = true;
          }
          filtersErrors.push(error);
        }
      });
      formErrors.filters = filtersErrors;
    }
    setErrors(formErrors);
    return (
      !formErrors.name &&
      !formErrors.type &&
      !formErrors.service &&
      !formErrors.section &&
      !formErrors.agg &&
      !formErrors.ope &&
      !formErrors.selectedConditionField &&
      !formErrors.value &&
      !formErrors.filters.length
    );
  };

  const handleChangeFilters = (filters) => {
    setFilters(filters);
  };

  const handleAggregationChange = (value) => {
    setAgg(value);
  };

  const primaryAction = {
    text: "Guardar",
    onAction: handleSave,
    loading: isLoading,
    disabled: isLoading,
  };
  const secondaryAction = {
    text: "Cancelar",
    onAction: () => onClose && onClose(),
    disabled: isLoading,
  };

  useEffect(() => {
    if (agg && agg === "sum")
      setConditionFieldOptions(
        fieldsTypes[selectedType][selectedService || "default"][selectedSection]
      );
  }, [agg]);

  const handleConditionFieldSearch = (search) => {
    setConditionFieldSearch(search);
    if (search === "") {
      setConditionFieldOptions(
        fieldsTypes[selectedType][selectedService || "default"][selectedSection]
      );
    }
    const regex = new RegExp(search, "i");
    const filteredOptions = fieldsTypes[selectedType][
      selectedService || "default"
    ][selectedSection].filter((option) => option.content.match(regex));
    setConditionFieldOptions(filteredOptions);
  };

  return (
    <ModalScreen
      open={open}
      onClose={onClose}
      title="Paso"
      primaryAction={primaryAction}
      secondaryActions={[secondaryAction]}
    >
      <div className="modal-content" id="StepModalContent">
        <Title type="heading">Paso de árbol de decisión</Title>
        <div className="form-group" id="StepInfo">
          <TextField
            label="Nombre"
            placeholder="Nombre del paso"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
            errorText="El nombre es requerido"
            disabled={isLoading}
          />
          <SelectField
            label="Tipo"
            placeholder="Selecciona un tipo"
            options={types}
            value={selectedType}
            onChange={setSelectedType}
            error={errors.type}
            errorText="Debes seleccionar un tipo"
            disabled={isLoading}
          />
          {servicesTypes[selectedType] && (
            <SelectField
              label="Servicio"
              placeholder="Selecciona un servicio"
              options={servicesTypes[selectedType]}
              value={selectedService}
              onChange={setSelectedService}
              error={errors.service}
              errorText="Debes seleccionar un servicio"
              disabled={isLoading}
            />
          )}
          {selectedType &&
            sectionTypes[selectedType][selectedService || "default"] && (
              <SelectField
                label="Sección"
                placeholder="Selecciona una sección"
                options={
                  sectionTypes[selectedType][selectedService || "default"]
                }
                value={selectedSection}
                onChange={setSelectedSection}
                error={errors.section}
                errorText="Debes seleccionar una sección"
                disabled={isLoading}
              />
            )}
          {universesTypes.length ? (
            <SelectField
              label="A partir del universo"
              placeholder="Seleccionar universo"
              options={universesTypes}
              value={selectedUniverse}
              onChange={setSelectedUniverse}
              disabled={isLoading}
            />
          ) : (
            <span></span>
          )}
        </div>
        {fieldsTypes[selectedType] &&
          fieldsTypes[selectedType][selectedService || "default"] &&
          fieldsTypes[selectedType][selectedService || "default"][
            selectedSection
          ] && (
            <>
              <Title type="heading">Filtro</Title>
              <div className="form-group" id="Filter">
                <label>Activar filtro</label>
                <div className="radio-group">
                  <RadioButton
                    label="Sí"
                    value="Si"
                    checked={enableFilter}
                    onChange={() => handleEnableFilterChange(true)}
                    disabled={isLoading}
                  />
                  <RadioButton
                    label="No"
                    value="No"
                    checked={!enableFilter}
                    onChange={() => handleEnableFilterChange(false)}
                    disabled={isLoading}
                  />
                </div>
                {enableFilter && (
                  <Filters
                    onAddFilter={handleAddFilter}
                    andFilters={
                      filters
                        ? filters.filter(
                            (filter) => filter.operatorType === "AND"
                          )
                        : []
                    }
                    orFilters={
                      filters
                        ? filters.filter(
                            (filter) => filter.operatorType === "OR"
                          )
                        : []
                    }
                    onChange={handleChangeFilters}
                    catalogs={
                      catalogsTypes[selectedType] &&
                      catalogsTypes[selectedType][selectedService || "default"]
                        ? catalogsTypes[selectedType][
                            selectedService || "default"
                          ][selectedSection]
                        : undefined
                    }
                    catalogsNames={
                      catalogsNamesTypes[selectedType] &&
                      catalogsNamesTypes[selectedType][
                        selectedService || "default"
                      ]
                        ? catalogsNamesTypes[selectedType][
                            selectedService || "default"
                          ][selectedSection]
                        : undefined
                    }
                    fields={
                      fieldsTypes[selectedType][selectedService || "default"][
                        selectedSection
                      ]
                    }
                    errors={errors.filters}
                  />
                )}
              </div>
            </>
          )}
        {selectedSection && (
          <>
            <Title type="heading">Condición</Title>
            <div
              className={`form-group${agg === "sum" ? " has-sum" : ""}`}
              id="Condition"
            >
              <SelectField
                label="Condición"
                placeholder="Elegir"
                options={AGGREGATION_TYPES}
                value={agg}
                onChange={handleAggregationChange}
                error={errors.agg}
                errorText="Debes seleccionar una condición"
                disabled={isLoading}
              />
              {agg && agg === "sum" && (
                <SelectField
                  label="Campo"
                  placeholder="Elegir"
                  options={conditionFieldOptions}
                  value={selectedConditionField}
                  onChange={setSelectedConditionField}
                  error={errors.selectedConditionField}
                  errorText="Debes seleccionar un campo"
                  disabled={isLoading}
                  search={conditionFieldSearch}
                  onSearch={handleConditionFieldSearch}
                />
              )}
              <SelectField
                label="Operador"
                placeholder="Elegir"
                options={operatorsTypes()}
                value={ope}
                onChange={setOpe}
                error={errors.ope}
                errorText="Debes seleccionar un operador"
                disabled={isLoading}
              />
              <TextField
                label="Valor"
                placeholder="Condición"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                error={errors.value}
                errorText="Debes ingresar un valor"
                disabled={isLoading}
              />
              <TextField
                label="(Opcional) Guardar paso de política como universo"
                placeholder="Nombre del universo"
                value={universe}
                onChange={(e) => setUniverse(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </>
        )}
      </div>
    </ModalScreen>
  );
};

export default StepsModal;
