import React from "react";
import Dropdown from "@bit/kiban-design-system.kiban.dropdown";
import { TrashIcon } from "@bit/kiban-design-system.kiban.icons";

const ConnectorActionsDropdown = ({ index, onDuplicate, onDelete }) => {
  return (
    <Dropdown text="Acciones">
      <Dropdown.Section
        options={[
          {
            content: "Duplicar",
            onAction: (e) => {
              onDuplicate && onDuplicate(index);
            },
          },
        ]}
      />
      <Dropdown.Section
        options={[
          {
            content: "Eliminar",
            icon: TrashIcon,
            onAction: (e) => {
              onDelete && onDelete(index);
            },
          },
        ]}
      />
    </Dropdown>
  );
};

export default ConnectorActionsDropdown;
