import React from "react";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import { PlusIcon } from "@bit/kiban-design-system.kiban.icons";

const PanelHeader = ({ title, onAddNew }) => {
  return (
    <div className="files-panel-header">
      <span className="files-panel-title">{title}</span>
      <IconButton icon={PlusIcon} style="secondary" onClick={onAddNew} />
    </div>
  );
};

export default PanelHeader;
