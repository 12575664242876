import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import Texts from "../../../utils/app-texts.json";

const AddEditKyc = () => {
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [name, setName] = useState("");
  const [flowId, setFlowId] = useState("");
  const [flowIdTest, setFlowIdTest] = useState("");
  // Expediente azul
  const [templateId, setTemplateId] = useState("");
  const [parentFolder, setParentFolder] = useState("");
  const [notifications, setNotifications] = useState(false);
  const [notifyFrequency, setNotifyFrequency] = useState("");
  const [errorsFolder, setErrorsFolder] = useState({
    templateId: false,
    parentFolder: false,
    notifyFrequency: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [services, setServices] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getCatalogTypesServices = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectorTypeServices.replace(":connector", "IDENTIDAD"),
    });
    if (!error) {
      let optionsSelect = [];
      let servicesOptions = {};
      data.forEach((opt) => {
        optionsSelect.push({
          content: opt.type.langs.es,
          value: opt.type.const,
        });
        if (opt.services)
          servicesOptions[opt.type.const] = opt.services.map((service) => ({
            content: service.langs.es,
            value: service.const,
          }));
      });
      setOptions(optionsSelect);
      setServices(servicesOptions);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const validateFields = () => {
    let anyError = false;
    const updateErrorsFolder = {...errorsFolder};
    if (selected === "EXPEDIENTE_AZUL" && selectedService === "CREATE_FOLDER") {
      updateErrorsFolder.templateId = templateId === "";
      anyError = anyError || templateId === "";
      updateErrorsFolder.parentFolder = parentFolder === "";
      anyError = anyError || parentFolder === "";
      if (notifications) {
        updateErrorsFolder.notifyFrequency = notifyFrequency === "";
        anyError = anyError || notifyFrequency === "";
      }
      setErrorsFolder(updateErrorsFolder);
    }
    if (!anyError) {
      saveConnector();
    }
  }

  const saveConnector = async () => {
    setIsLoading(true);
    let data = { name };
    if (selectedService) {
      data["service"] = selectedService;
    }
    if (selected === "MATI") {
      data = { ...data, flowId, flowIdTest };
    }
    if (selected === "EXPEDIENTE_AZUL" && selectedService === "EXPEDIENTE_AZUL_CREATE_FOLDER") {
      data = {
        ...data,
        templateId: parseInt(templateId),
        parentFolder: parseInt(parentFolder),
        notifications: notifications ? 1 : 0
      }
      if (notifications) {
        data = { ...data, notifyFrequency: parseInt(notifyFrequency) }
      }
    }
    const [error, response] = await apiRequest({
      method: isEdit ? "put" : "post",
      url: isEdit
        ? apiRoutes.editConnector
            .replace(":connector", selected)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", selected),
      data,
    });
    if (!error) {
      addAlert({
        code: "success",
        message: AlertTexts.success,
        duration: 5000,
      });
      if (!isEdit) {
        setName(response.name);
        setSelected(response.type);
        if (response.type === "MATI") {
          setFlowId(response.flowId);
          setFlowIdTest(response.flowIdTest);
        } else {
          setSelectedService(response.service);
        }
        setIsEdit(true);
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", response.id)
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDataConnector = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (!error) {
      setName(data.name);
      setSelected(data.type);
      setSelectedService(data.service || "");
      if (data.type === "MATI") {
        setFlowId(data.flowId);
        setFlowIdTest(data.flowIdTest);
      }
      if (data.type === "EXPEDIENTE_AZUL") {
        setTemplateId(data.templateId);
        setParentFolder(data.parentFolder);
        setNotifications(data.notifications === 1);
        if (data.notifications === 1) {
          setNotifyFrequency(data.notifyFrequency);
        }
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCatalogTypesServices();
    setIsEdit(routeParams.hasOwnProperty("id"));
    if (routeParams.hasOwnProperty("id")) {
      getDataConnector();
    }
  }, []);

  useEffect(() => {
    setErrorsFolder({
      templateId: false,
      parentFolder: false,
      notifyFrequency: false,
    });
    setNotifications(false);
  }, [selectedService]);

  return isLoading ? <Skeleton/> : (
    <div className="main-content">
      <PageSection title="KYC">
        <PageSection.Item size={1} length={3}>
          <TextField
            label="Nombre"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={3}>
          <SelectField
            label={Texts.connectors.fields.connectorType}
            placeholder={Texts.connectors.fields.connectorTypePlaceholder}
            value={selected}
            onChange={(value) => {
              setSelectedService("");
              setSelected(value);
            }}
            options={options}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {services[selected] && (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label={Texts.connectors.fields.serviceType}
                placeholder={Texts.connectors.fields.serviceTypePlaceholder}
                value={selectedService}
                options={services[selected]}
                onChange={(service) => setSelectedService(service)}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {selected === "MATI" && (
          <>
            <PageSection.Item size={1} length={3}>
              <TextField
                label="ID de flujo"
                value={flowId}
                onChange={(e) => {
                  setFlowId(e.target.value);
                }}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <TextField
                label="ID de flujo test"
                value={flowIdTest}
                onChange={(e) => {
                  setFlowIdTest(e.target.value);
                }}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {selected === "EXPEDIENTE_AZUL" && selectedService === "EXPEDIENTE_AZUL_CREATE_FOLDER" && (
          <>
            <PageSection.Item size={1} length={3}>
              <TextField
                label="Template Id"
                value={templateId}
                onChange={(e) => {
                  setTemplateId(e.target.value);
                }}
                type="number"
                error={errorsFolder.templateId}
                errorText={"El campo no debe ir vacio"}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {selected !== "EXPEDIENTE_AZUL" && <PageSection.Item size={1} length={3}>
              <TextField
                label="Folder Padre"
                value={parentFolder}
                onChange={(e) => {
                  setParentFolder(e.target.value);
                }}
                type="number"
                error={errorsFolder.parentFolder}
                errorText={"El campo no debe ir vacio"}
              />
            </PageSection.Item>}
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={2} length={3}>
              <Title type="heading">Activar notificaciones:</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="expediente"
                  value={true}
                  checked={notifications}
                  onChange={(_checked, value) => {
                    setErrorsFolder({...errorsFolder, notifyFrequency: false})
                    setNotifications(
                      value === "true"
                    )
                  }}
                />
                <RadioButton
                  label="No"
                  name="expediente"
                  value={false}
                  checked={!notifications}
                  onChange={(_checked, value) => {
                    setErrorsFolder({...errorsFolder, notifyFrequency: false})
                    setNotifications(
                      value === "true"
                    )
                  }}
                />
              </div>
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {notifications && <>
              <PageSection.Item size={1} length={3}>
                <TextField
                  label="Frecuencia (en días)"
                  value={notifyFrequency}
                  onChange={(e) => {
                    setNotifyFrequency(e.target.value);
                  }}
                  type="number"
                  error={errorsFolder.notifyFrequency}
                  errorText={"El campo no debe ir vacio"}
                />
              </PageSection.Item>
              <PageSection.Item size={2} length={3} />
            </>}
          </>
        )}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button
              style="primary"
              text="Guardar"
              onClick={validateFields}
              disabled={
                selected === "" ||
                name === "" ||
                (services[selected] && selectedService === "") ||
                (selected === "MATI" && flowId === "")
              }
            />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => {
                history.push(
                  Paths.Connectors.replace(
                    ":connectorType",
                    routeParams.connectorType
                  )
                );
              }}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
};

export default AddEditKyc;
