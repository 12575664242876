import React, { useState } from "react";
import Alert from "@bit/kiban-design-system.kiban.alert";
import Badges from "@bit/kiban-design-system.kiban.badges";
import Button from "@bit/kiban-design-system.kiban.button";
import {
  ClipIcon,
  FormIcon,
  GenericConectorIcon,
  DecisionTreeIcon,
  NipIcon,
} from "@bit/kiban-design-system.kiban.icons";
import { JsonViewer } from "@bit/kiban-design-system.kiban.json-viewer";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import StepsBar from "@bit/kiban-design-system.kiban.steps-bar";
import Tag from "@bit/kiban-design-system.kiban.tag";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";

export default function CenterPanel({ dataStep, indexTab, downloadFile }) {
  const [stepSelected, setStepSelected] = useState(0);

  const getLabelStep = (step, index) => {
    const indexLabel = `${index < 9 ? "0" : ""}${index + 1}`;
    return `${indexLabel}. ${step.stepName}`;
  };

  const isNotEmpty = (value) => value !== null && value !== undefined;

  const renderPolicy = () => {
    const isDataJsonValid =
      isNotEmpty(dataStep) &&
      isNotEmpty(dataStep.json) &&
      isNotEmpty(dataStep.json[stepSelected]);
    return (
      <div className="historic__policy__content">
        <div className="historic__policy__content--steps">
          <Title type="displaySmall">Lista de pasos</Title>
          <div className="historic__policy__content--steps-list">
            {dataStep?.json?.map((step, index) => {
              const classStepBox = `historic__step-box${
                index === stepSelected ? " isSelected" : ""
              }`;
              return (
                <div
                  className={classStepBox}
                  onClick={() => setStepSelected(index)}
                >
                  <label className="historic__policy__content__item">
                    {`${getLabelStep(step, index)}`}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="historic__policy__content--result">
          {isDataJsonValid && !dataStep?.json[stepSelected]?.message && (
            <TextField
              value={dataStep?.json[stepSelected]?.result}
              label="Estado"
            />
          )}
          {isDataJsonValid && dataStep?.json[stepSelected]?.message && (
            <TextField
              value={dataStep?.json[stepSelected]?.message}
              label="Mensaje"
            />
          )}
        </div>
      </div>
    );
  };

  const renderNip = () => {
    console.log(dataStep);
    let dataStepsBar = [
      {
        name: "NIP",
        status: true,
        isFullBar: true,
      },
      // {
      //   name: "Confirmación 1",
      //   status: dataStep?.json.length >= 1,
      //   isFullBar: dataStep?.json.length >= 2,
      // },
      // {
      //   name: "Confirmación 2",
      //   status: dataStep?.json.length >= 2,
      //   isFullBar: dataStep?.json.length === 3,
      // },
    ];
    if (dataStep?.json.length >= 1) {
      dataStepsBar.push({
        name: "Confirmación 1",
        status: dataStep?.json.length >= 1,
        isFullBar: dataStep?.json.length >= 2,
      });
    }
    if (dataStep?.json.length > 2) {
      dataStepsBar.push({
        name: "Confirmación 2",
        status: dataStep?.json.length > 2,
        isFullBar: dataStep?.json.length >= 3,
      });
    }
    const message = dataStep.json
      ? dataStep?.json.length === 1
        ? "La confirmación 1 y 2 están pendientes."
        : dataStep?.json.length === 2
        ? "La confirmación 2 sigue pendiente."
        : ""
      : "";
    return (
      <div className="historic__nip__container">
        <div className="historic__nip__message">{message}</div>
        <div className="historic__nip__stepBar">
          <StepsBar steps={dataStepsBar} />
        </div>
        <div className="historic__nip__labels__container">
          <div className="historic__nip__labels">
            {dataStep.json.length > 2 &&
              dataStep?.json[0]?.nip !== dataStep?.json[2]?.nip && (
                <span>NIP Confirmación 1</span>
              )}
            <label>{dataStep.json && dataStep?.json[0]?.nip}</label>
          </div>
          {dataStep.json.length > 2 &&
            dataStep?.json[0]?.nip !== dataStep?.json[2]?.nip && (
              <div className="historic__nip__labels">
                <span>NIP Confirmación 2</span>
                <label>{dataStep.json && dataStep?.json[2]?.nip}</label>
              </div>
            )}
        </div>
      </div>
    );
  };

  const renderConnector = () => {
    return (
      <JsonViewer
        jsonData={dataStep.json ? dataStep.json : {}}
        downloadBtnLAbel="Descargar"
        downloadBtnType="primary"
        downloadBtnIcon={<FormIcon />}
        downloadFunction={(value) => downloadFile(value, dataStep)}
      />
    );
  };

  const renderDetails = () => {
    // TODO correct key for responseError
    const dataDetails = [
      { key: "createdBy", label: "Creado por" },
      { key: "origin", label: "Origen" },
      { key: "status", label: "Estado del proceso" },
      { key: "messageError", label: "Mensaje de Error" },
      { key: "metadata", keyDeep: "responseHttpCode", label: "Código HTTP" },
      {
        key: "metadata",
        keyDeep: "responseTime",
        label: "Tiempo de Respuesta",
      },
    ];
    const keysFiles = ["requestFiles", "responseFiles"];
    return (
      <>
        <div className="historic__details-container">
          <div className="historic__details-item">
            <span className="historic__details--title">Generales</span>
          </div>
          {dataDetails.map((detail) => {
            const status =
              detail.key === "status" && dataStep.hasOwnProperty(detail.key)
                ? dataStep[detail.key].toLowerCase().includes("error")
                  ? "danger"
                  : dataStep[detail.key].toLowerCase()
                : "";
            const label =
              detail.key === "status"
                ? `${dataStep[detail.key].charAt(0)}${dataStep[detail.key]
                    .slice(1)
                    .toLowerCase()}`
                : "";
            return (
              <div className="historic__details-item">
                <div className="historic__details--label">{detail.label}</div>
                <div className="historic__details--content">
                  {detail.key === "createdBy" && (
                    <Tag>{dataStep[detail.key]}</Tag>
                  )}
                  {detail.key === "status" && (
                    <Badges label={label} state={status} type="state" />
                  )}
                  {detail.key === "origin" && dataStep[detail.key]}
                  {detail.key === "messageError" &&
                    dataStep.hasOwnProperty(detail.key) && (
                      <Alert code="danger" text={dataStep[detail.key]} />
                    )}
                  {detail.key === "metadata"
                    ? dataStep.hasOwnProperty(detail.key) &&
                      dataStep[detail.key].hasOwnProperty(detail.keyDeep)
                      ? dataStep[detail.key][detail.keyDeep]
                      : "-"
                    : ""}
                </div>
              </div>
            );
          })}
          {dataStep?.files &&
            keysFiles.map((keyFile) => {
              return (
                dataStep.files.hasOwnProperty(keyFile) && (
                  <>
                    <div className="historic__details-item--file">
                      <span className="historic__details--title">
                        {`Archivos de ${
                          keyFile === "requestFiles" ? "consulta" : "respuesta"
                        }`}
                      </span>
                      <PageSection>
                        {dataStep.files[keyFile].map((file) => {
                          return (
                            <PageSection.Item size={1} length={4}>
                              <Button
                                icon={ClipIcon}
                                onClick={() =>
                                  downloadFile(
                                    file.file.split(":")[1],
                                    dataStep
                                  )
                                }
                                style="dark"
                                text={file.name}
                              />
                            </PageSection.Item>
                          );
                        })}
                        {dataStep.files[keyFile].length % 4 !== 0 && (
                          <PageSection.Item
                            size={4 - (dataStep.files[keyFile].length % 4)}
                            length={4}
                          />
                        )}
                      </PageSection>
                    </div>
                  </>
                )
              );
            })}
        </div>
      </>
    );
  };

  let render = null;

  if (indexTab === 0) {
    switch (dataStep?.connectorType) {
      case "POLICY":
        render = renderPolicy();
        break;
      case "NIP":
      case "NIP_UNYKOO":
        render = renderNip();
        break;
      default:
        render = renderConnector();
        break;
    }
  } else {
    render = renderDetails();
  }

  const renderIcon = () => {
    const color = "#0047FF";
    switch (dataStep?.connectorType) {
      case "FORMULARIO":
        return <FormIcon color={color} />;
      case "POLICY":
        return <DecisionTreeIcon color={color} />;
      case "NIP_UNYKOO":
        return <NipIcon color={color} />;
      default:
        return <GenericConectorIcon color={color} />;
    }
  };

  return (
    <div className="historic__content">
      <div className="historic__content__container">
        {indexTab === 0 && (
          <div className="historic__content__header">
            {renderIcon()}
            <Title type="displaySmall">{dataStep?.connectorName}</Title>
          </div>
        )}
        <div className="historic__content__data">{render}</div>
      </div>
    </div>
  );
}
