import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import PageSection from "@bit/kiban-design-system.layout.page-section";

import { personTypeCatalog } from "../utils";

const SIC = ({ data, onChange, connectorsCatalog, errors, isLoading }) => {
  const handleChange = (value, key) => {
    if (onChange) {
      onChange({
        ...data,
        [key]: value,
      });
    }
  };
  return (
    <>
      <PageSection.Item title="Términos y condiciones" size={1} length={3}>
        <TextField
          placeholder="ej. https://www.kiban.mx/terminos-y-condiciones"
          value={data.urlTermsConditions || ""}
          onChange={(e) => handleChange(e.target.value, "urlTermsConditions")}
          error={errors.urlTermsConditions}
          errorText="Debes ingresar una URL válida"
          disabled={isLoading}
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item title="NIP SMS / WhatsApp" size={1} length={3}>
        <SelectField
          placeholder="Escoge un conector"
          options={
            connectorsCatalog
              ? [].concat(
                  connectorsCatalog.QUIUBAS || [],
                  connectorsCatalog.TWILIO || []
                )
              : []
          }
          helperText="{{nombres}} {{paterno}} {{materno}} {{nip}} se reemplazan con los datos del cliente"
          value={data.messagingSicConnector || ""}
          onChange={(value) => handleChange(value, "messagingSicConnector")}
          error={errors.messagingSicConnector}
          errorText="Debes elegir un conector SMS / WhatsApp"
          disabled={isLoading}
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item title="NIP Email Connector" size={1} length={3}>
        <SelectField
          placeholder="Escoge un conector"
          options={
            connectorsCatalog && connectorsCatalog.SENDGRID
              ? connectorsCatalog.SENDGRID
              : []
          }
          helperText="{{nombres}} {{paterno}} {{materno}} {{nip}} se reemplazan con los datos del cliente"
          value={data.emailSicConnector || ""}
          onChange={(value) => handleChange(value, "emailSicConnector")}
          error={errors.emailSicConnector}
          errorText="Debes elegir un conector Email"
          disabled={isLoading}
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item title="Tipo de persona" size={1} length={3}>
        <SelectField
          placeholder="Escoge un tipo de persona"
          options={personTypeCatalog}
          value={data.personType || ""}
          onChange={(value) => handleChange(value, "personType")}
          error={errors.personType}
          errorText="Debes elegir un tipo de persona"
          disabled={isLoading}
        />
      </PageSection.Item>
    </>
  );
};

export default SIC;
