import React from "react";
import Grid from "@bit/kiban-design-system.layout.grid";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import { MoreVerticalIcon } from "@bit/kiban-design-system.kiban.icons";

const VariablesSectionHeaders = () => {
  return (
    <Grid alignItems="center" noGap className="variable-grid">
      <Grid.Item size="content-width">ID</Grid.Item>
      <Grid.Item size="auto">NOMBRE</Grid.Item>
      <Grid.Item size="auto">TIPO</Grid.Item>
      <Grid.Item size="content-width">
        <IconButton disabled icon={MoreVerticalIcon} style="secondary" />
      </Grid.Item>
    </Grid>
  );
};

export default VariablesSectionHeaders;
