import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "22px",
      marginTop: "10px",
      marginBottom: "22px",
    },
    label: {
      fontSize: "13px",
    },
  })
);
