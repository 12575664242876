import React, { useEffect, useState } from "react";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import "../ConfigurationPage.css";
import AlertTexts from "../../../utils/alerts-texts";

export default function ConfigurationMessaging(props) {
  const [dataConfig, setDataConfig] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const { addAlert } = useAlert();

  const getConfigMessaging = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "MESSAGING"),
    });
    if (!err) {
      const responseDataConfig = data.hasOwnProperty("messagingMXResponse")
        ? data.messagingMXResponse
        : data.hasOwnProperty("messagingCOResponse")
        ? data.messagingCOResponse
        : null;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig);
    let updateConfig = { ...dataConfig };
    keys.forEach((key) => {
      if (!updateConfig[key].Activate) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !["Activate"].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          updateConfig[key][keyInternal] = "";
        });
      }
    });
    setDataConfig(updateConfig);
    return { messagingRequest: { ...updateConfig } };
  };

  const updateConfigMessaging = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "MESSAGING"),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  useEffect(() => {
    if (!Jwt.validateToken()) {
      window.location.href = "/login";
    } else {
      getConfigMessaging();
    }
  }, []);

  const handleOnChangeConfig = (key, attr, value) => {
    const updateConfig = { ...dataConfig };
    const updateObj = { ...updateConfig[key] };
    updateObj[attr] = value;
    updateConfig[key] = updateObj;
    const updateErrors = errorFields.includes(`${key}-${attr}`)
      ? errorFields.filter((keyError) => keyError !== `${key}-${attr}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    const keys = Object.keys(dataConfig);
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (updateConfig[key].Activate) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) => !["Activate"].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          if (updateConfig[key][keyInternal] === "") {
            objErrors.push(`${key}-${keyInternal}`);
          }
        });
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigMessaging();
    }
  };

  return loading ? (
    <Skeleton />
  ) : dataConfig && (
    <div className="main-content">
      <PageSection>
        {props.type === "Email" && dataConfig.Sendgrid && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Sendgrid:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="sendgrid"
                    value={true}
                    checked={dataConfig.Sendgrid.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Sendgrid",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="sendgrid"
                    value={false}
                    checked={!dataConfig.Sendgrid.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Sendgrid",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.Sendgrid.Activate && (
                <>
                  <TextField
                    error={errorFields.includes("Sendgrid-ApiKey")}
                    errorText={"El campo no debe ir vacio"}
                    label="Sendgrid API Key"
                    type="password"
                    value={dataConfig.Sendgrid.ApiKey}
                    onChange={(e) =>
                      handleOnChangeConfig("Sendgrid", "ApiKey", e.target.value)
                    }
                  />
                </>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        <PageSection.Item size={1} length={1}>
          <Button onClick={saveConfig} style="primary" text="Guardar" />
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
