export default {
  "credit-policies": "POLICY",
  documents: "DOCUMENT",
  forms: "FORM",
  vp: "VP",
  identity: "IDENTIDAD",
  digitalsign: "DIGITAL_SIGN",
  bankingcore: "BANKING_CORE",
  buro: "CONSULTA",
  messaging: "MESSAGING",
  cleandata: "CAMARA_COMERCIO_BOGOTA",
  openbanking: "OPEN_BANKING",
  score: "SCORE",
  payments: "PAYMENTS",
  api: "API",
  nip: "NIP",
  rainao: "RAINAO",
  timer: "TIMER",
};
