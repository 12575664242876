export default {
  success: "Los cambios han sido guardados correctamente",
  unexpectedError: "Ocurrió un error inesperado",
  tecnicalError: "Un error técnico ocurrió",
  forbidden: "No permitido",
  nameAlreadyExist: "El nombre ya existe, intente con otro.",
  emailAlreadyExist: "El correo ya fue usado en otra cuenta, intente con otro correo.",
  connectors: {
    creditPolicies: {
      error: {
        deleteStep: {
          STEP: "Hubo un error al eliminar el paso.",
          MESSAGE: "Hubo un error al eliminar el mensaje.",
        },
      },
      success: {
        deleteStep: {
          STEP: "Se eliminó el paso correctamente.",
          MESSAGE: "Se eliminó el mensaje correctamente.",
        },
        test: "test text",
      },
    },
  },
  creditPoliciesAlreadyExist:
    "Ya existe un árbol de desición con el nombre por default 'Nuevo árbol de decisión'. Tiene que editarlo o eliminarlo.",
  invalidCredentials: "Credenciales inválidas",
};
