import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Table from "@bit/kiban-design-system.kiban.table";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import { TrashIcon } from "@bit/kiban-design-system.kiban.icons";
import Dropdown from "@bit/kiban-design-system.kiban.dropdown";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import COLOR_DANGER_BASE from "@bit/kiban-design-system.styles.design-tokens-json";
import { AlertIcon, PlusIcon } from "@bit/kiban-design-system.kiban.icons";

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";
import useTablePagination from "../../utils/useTablePagination";
import Paths from "../../paths";

import useSearch from "../../utils/useTableSearch";

const Workfloos = () => {
  const limit = 10;
  const [workfloos, setWorkfloos] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [totalWorkfloos, setTotalWorkfloos] = useState(0);
  const [search, setSearch] = useState("");
  const history = useHistory();

  const {
    from,
    goBack,
    goForward,
    hasNext,
    hasPrevious,
    page,
    skipBack,
    skipForward,
    to,
  } = useTablePagination({
    initialPage: 1,
    rowsPerPage: limit,
    totalItems: totalWorkfloos,
  });

  const { filteredData } = useSearch({ data: workfloos, search });

  useEffect(() => {
    setTotalWorkfloos(filteredData.length);
  }, [filteredData]);

  const getWorkfloos = async () => {
    const [error, workfloos] = await apiRequest({
      method: "get",
      url: apiRoutes.workfloos,
    });
    if (workfloos) {
      setWorkfloos(workfloos);
    }
    if (error) {
      console.log(error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getWorkfloos();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const workfloosAction = async (id, type) => {
    const [error, data] = await apiRequest({
      method: type === "DUPLICATE" ? "post" : "delete",
      url:
        type === "DUPLICATE"
          ? apiRoutes.duplicateWorkfloos.replace(":id", id)
          : `${apiRoutes.workfloos}/${id}`,
    });
    if (data !== null) {
      if (type === "DUPLICATE") {
        getWorkfloos();
      } else {
        const item = workfloos.findIndex((workfloo) => workfloo.id === id);
        const nWorkfloos = [...workfloos];
        nWorkfloos.splice(item, 1);
        setWorkfloos(nWorkfloos);
      }
    }
    if (error) {
      setError(error);
    }
  };

  const onSelectWorkfloo = (selected) => {
    // const cryptId = Jwt.signToken({
    //   id: workfloos[limit * (page - 1) + selected].id.toString(),
    // });

    // const link = `${getHostName()}/auth/workfloo.xhtml?token=${Jwt.getToken()}&id=${cryptId}`;

    // window.location.href = link;
    history.push(
      Paths.EditWorkfloo.replace(
        ":id",
        filteredData[limit * (page - 1) + selected].id
      )
    );
  };

  const tablePagination = {
    current: from,
    results: to,
    total: totalWorkfloos,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: skipBack,
    onSkipForward: skipForward,
  };

  const DropdownMarkup = ({ index }) => (
    <Dropdown text="Acciones" align="right">
      <Dropdown.Section
        options={[
          {
            content: "Duplicar",
            onAction: (e) => {
              workfloosAction(
                workfloos[limit * (page - 1) + index].id,
                "DUPLICATE"
              );
            },
          },
        ]}
      />
      <Dropdown.Section
        options={[
          {
            content: "Eliminar",
            icon: TrashIcon,
            onAction: (e) => {
              setItemToDelete(limit * (page - 1) + index);
              setShowDeleteAlert(true);
            },
          },
        ]}
      />
    </Dropdown>
  );

  const headers = ["Nombre", "Fecha de creación", "Fecha de modificación", ""];
  const rows = workfloos
    ? filteredData
        .slice(limit * (page - 1), limit * page)
        .map((workfloo, i) => [
          workfloo.name,
          moment(workfloo.createdDate).format("DD-MM-YYYY hh:mm"),
          moment(workfloo.modifiedDate).format("DD-MM-YYYY hh:mm"),
          <DropdownMarkup index={i} />,
        ])
    : [];

  const alertPrimaryAction = {
    text: "Sí, eliminar",
    onAction: (e) => {
      workfloosAction(workfloos[itemToDelete].id, "DELETE");
      closeAlert();
    },
  };

  const alertCancelAction = {
    text: "No, cancelar",
    onAction: (e) => {
      closeAlert();
    },
  };

  const closeAlert = () => {
    setShowDeleteAlert(false);
    setItemToDelete(-1);
  };

  const onAddNew = () => {
    // window.location.href = `${getHostName()}/auth/workfloo.xhtml?token=${Jwt.getToken()}`;
    history.push(Paths.AddWorkfloo);
  };

  const tablePrimaryAction = {
    icon: PlusIcon,
    label: "Nuevo workfloo",
    onAction: onAddNew,
  };

  return (
    <div className="main-content">
      {error && (
        <PageSection.Item size={1} length={1}>
          <p
            style={{
              color: COLOR_DANGER_BASE,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AlertIcon />
            <span style={{ marginLeft: "10px" }}>
              {error.message ? error.message : "Hubo un error inesperado"}
            </span>
          </p>
        </PageSection.Item>
      )}
      {!isLoading ? (
        <Table
          search={search}
          onSearch={setSearch}
          searchTitle="Buscar"
          headings={headers}
          primaryAction={tablePrimaryAction}
          pagination={tablePagination}
          title="Workfloos"
          rows={rows}
          textEmpty={"No se encontraron resultados"}
          onSelectItem={onSelectWorkfloo}
        />
      ) : (
        <SkeletonTable headers title primaryAction pagination />
      )}
      <ModalScreen
        open={showDeleteAlert}
        title="¿Está seguro de querer eliminar el workfloo seleccionado?"
        primaryAction={alertPrimaryAction}
        secondaryActions={[alertCancelAction]}
        onClose={closeAlert}
      >
        Al presionar el botón de eliminar el workfloo que seleccionaste será
        borrado de forma permanente, esta acción no se puede deshacer.
      </ModalScreen>
    </div>
  );
};

export default Workfloos;
