import { apiRequest, apiRoutes } from "../../../../../services";

export const createEditStep = async ({ isEdit, step, onError, id }) => {
  const [error, data] = await apiRequest({
    method: isEdit ? "put" : "post",
    url: !isEdit
      ? apiRoutes.createPolicyStep.replace(":id", id)
      : apiRoutes.editPolicyStep.replace(":id", id).replace(":stepId", step.id),
    data: step,
  });
  if (data !== null) {
    if (data) {
      return data.id;
    }
  }
  if (error) {
    onError(error);
  }
};
