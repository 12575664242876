import React, { useEffect, useRef, useState } from "react";
import { Sortable, Plugins } from "@shopify/draggable";
import PageSection from "@bit/kiban-design-system.layout.page-section";

import Section from "../Section";
import { getSectionName, sort } from "../utils";

const Form = ({
  form,
  sectionTypes,
  onSort,
  onClickEditButtonField,
  onChange,
}) => {
  const draggableRef = useRef(null); // Ref to the draggable container
  const sortable = useRef(); // Ref to the sortable instance
  const [focusId, setFocusId] = useState(null); // Id of the focused field

  /**
   * UseEffect used to initialize the sortable instance
   */
  useEffect(() => {
    if (draggableRef.current) {
      // If the draggable container exists
      const containers = draggableRef.current.querySelectorAll(".form-section"); // Get all the sections
      if (containers.length) {
        // If there are sections
        sortable.current = new Sortable(containers, {
          // Initialize the sortable instance
          draggable: ".FormField",
          handle: ".FormField__drag-handler, .FormField__id, FormField__name",
          distance: 1000,
          mirror: {
            constrainDimensions: true,
          },
          plugins: [Plugins.ResizeMirror],
        });
      }
    }
  });

  /**
   *  Event handler to handle the sortable events
   * @param {Object} evt Sortable event object
   */
  const sortEvent = (evt) => {
    if (onSort) {
      // If the onSort prop is defined
      const newForm = sort(draggableRef.current, evt, form); // Sort the form
      onSort(newForm); // Call the onSort prop
    }
  };

  /**
   *  UseEffect used to add the sortable event listener to the sortable instance
   */
  useEffect(() => {
    if (form && sortable.current) {
      sortable.current.on("sortable:stop", sortEvent);
    }
    return () => {
      sortable.current && sortable.current.off("sortable:stop", sortEvent);
    };
  });

  /**
   *  Event handler to handle every field value change
   * @param {String} sectionId Section id
   * @param {String} fieldId  Field id
   * @param {String} value  Field value
   */
  const handleChangeSectionField = (sectionId, fieldId, value) => {
    if (onChange) {
      // If the onChange prop is defined
      const newForm = form.map((s) => ({
        ...s,
        fields: s.fields.map((f) => ({
          ...f,
        })),
      })); // Clone the form
      const section = newForm.findIndex((s) => s.section === sectionId); // Get the section index
      const field = newForm[section].fields.findIndex((f) => f.id === fieldId); // Get the field index
      newForm[section].fields[field].defaultValue = value; // Change the field value
      onChange(newForm); // Call the onChange prop
    }
  };

  return (
    <PageSection.Item size={1} length={1}>
      <div key={Date.now()} id="DraggableCanvas" ref={draggableRef}>
        {form.map(
          (section, index) =>
            section.fields && (
              <Section
                key={`form-section-${index}`}
                id={section.section}
                name={getSectionName(section.section, sectionTypes)}
                fields={section.fields || []}
                onClickEditButtonField={onClickEditButtonField}
                onChange={(fieldId, value) =>
                  handleChangeSectionField(section.section, fieldId, value)
                }
                onFocusField={(fieldId) => setFocusId(fieldId)}
                onBlurField={() => setFocusId(null)}
                focusId={focusId}
              />
            )
        )}
      </div>
    </PageSection.Item>
  );
};

export default Form;
