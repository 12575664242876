import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Paths from "./paths";
import Login from "./pages/Login/Login";
import RecoveryPassword from "./pages/RecoveryPassword/RecoveryPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Layout from "./components/Layout";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute component={Login} path={Paths.Login} exact />
        <PublicRoute path={Paths.RecoveryPassword} component={RecoveryPassword} exact />
        <PublicRoute path={Paths.ResetPassword} component={ResetPassword} exact />
        <PrivateRoute component={Layout} path={Paths.Base} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
