const validateNIPGEN = ({
  formErrors,
  firstVerificationData,
  secondVerificationData,
}) => {
  formErrors.firstVerification.mean = Boolean(!firstVerificationData.mean);
  if (firstVerificationData.mean)
    formErrors.firstVerification.meanConnector = Boolean(
      !firstVerificationData.meanConnector
    );
  if (firstVerificationData.hasBackUp) {
    formErrors.firstVerification.meanBackUp.mean =
      !firstVerificationData.meanBackUp ||
      !firstVerificationData.meanBackUp.mean;
    if (
      firstVerificationData.meanBackUp &&
      firstVerificationData.meanBackUp.mean
    )
      formErrors.firstVerification.meanBackUp.meanConnector =
        !firstVerificationData.meanBackUp ||
        !firstVerificationData.meanBackUp.meanConnector;
    formErrors.firstVerification.meanBackUp.attempts =
      firstVerificationData.attemps <= 0;
  }
  if (secondVerificationData.mean)
    formErrors.secondVerification.meanConnector = Boolean(
      !secondVerificationData.meanConnector
    );
  if (secondVerificationData.hasBackUp) {
    formErrors.secondVerification.meanBackUp.mean =
      !secondVerificationData.meanBackUp ||
      !secondVerificationData.meanBackUp.mean;
    if (
      secondVerificationData.meanBackUp &&
      secondVerificationData.meanBackUp.mean
    )
      formErrors.secondVerification.meanBackUp.meanConnector =
        !secondVerificationData.meanBackUp ||
        !secondVerificationData.meanBackUp.meanConnector;
    formErrors.secondVerification.meanBackUp.attempts =
      secondVerificationData.attemps <= 0;
  }
  return (
    !formErrors.firstVerification.mean &&
    !formErrors.secondVerification.mean &&
    !formErrors.firstVerification.meanConnector &&
    !formErrors.secondVerification.meanConnector &&
    !formErrors.firstVerification.meanBackUp.mean &&
    !formErrors.secondVerification.meanBackUp.mean &&
    !formErrors.firstVerification.meanBackUp.meanConnector &&
    !formErrors.secondVerification.meanBackUp.meanConnector &&
    !formErrors.firstVerification.meanBackUp.attempts &&
    !formErrors.secondVerification.meanBackUp.attempts
  );
};
export default validateNIPGEN;
