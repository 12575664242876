const FIELD_TYPES = {
  TEXT: "TEXT",
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  SELECT: "SELECT",
};

const LOGICAL_OPERATORS = {
  EQUAL: { value: "EQUAL", content: "Igual a" },
  NOT_EQUAL: { value: "NOT_EQUAL", content: "No es igual a" },
  CONTAINS: { value: "CONTAINS", content: "Contiene" },
  NOT_CONTAINS: { value: "NOT_CONTAINS", content: "No contiene" },
  IS_NULL: { value: "IS_NULL", content: "Está vacío" },
  IS_NOT_NULL: { value: "IS_NOT_NULL", content: "No está vacío" },
  GREATER: { value: "GREATER", content: "Mayor que" },
  LESS: { value: "LESS", content: "Menor que" },
  GREATER_EQUAL: { value: "GREATER_EQUAL", content: "Mayor o igual que" },
  LESS_EQUAL: { value: "LESS_EQUAL", content: "Menor o igual que" },
  IN: { value: "IN", content: "In (ej: 2;4;6)" },
  NOT_IN: { value: "NOT_IN", content: "Not in (ej: 2;4;6)" },
  REGEX: { value: "REGEX", content: "Expresión regular" },
};

export const operatorsTypes = (fieldType) => {
  switch (fieldType) {
    case FIELD_TYPES.TEXT:
    case FIELD_TYPES.STRING:
      return [
        LOGICAL_OPERATORS.EQUAL,
        LOGICAL_OPERATORS.NOT_EQUAL,
        LOGICAL_OPERATORS.CONTAINS,
        LOGICAL_OPERATORS.NOT_CONTAINS,
        LOGICAL_OPERATORS.IS_NULL,
        LOGICAL_OPERATORS.IS_NOT_NULL,
        LOGICAL_OPERATORS.REGEX,
      ];
    case FIELD_TYPES.DATE:
    case FIELD_TYPES.NUMBER:
      return [
        LOGICAL_OPERATORS.EQUAL,
        LOGICAL_OPERATORS.NOT_EQUAL,
        LOGICAL_OPERATORS.IS_NULL,
        LOGICAL_OPERATORS.IS_NOT_NULL,
        LOGICAL_OPERATORS.GREATER,
        LOGICAL_OPERATORS.GREATER_EQUAL,
        LOGICAL_OPERATORS.LESS,
        LOGICAL_OPERATORS.LESS_EQUAL,
        LOGICAL_OPERATORS.IN,
        LOGICAL_OPERATORS.NOT_IN,
      ];
    case FIELD_TYPES.SELECT:
      return [
        LOGICAL_OPERATORS.EQUAL,
        LOGICAL_OPERATORS.NOT_EQUAL,
        LOGICAL_OPERATORS.IS_NULL,
        LOGICAL_OPERATORS.IS_NOT_NULL,
      ];
    default:
      return [
        LOGICAL_OPERATORS.LESS,
        LOGICAL_OPERATORS.LESS_EQUAL,
        LOGICAL_OPERATORS.GREATER,
        LOGICAL_OPERATORS.GREATER_EQUAL,
        LOGICAL_OPERATORS.EQUAL,
        LOGICAL_OPERATORS.NOT_EQUAL,
      ];
  }
};

export const AGGREGATION_TYPES = [
  {
    value: "sum",
    content: "Si la suma de los valores encontrados en",
  },
  { value: "count", content: "Si el número de resultados" },
];
