import PageSection from "@bit/kiban-design-system.layout.page-section";
import Grid from "@bit/kiban-design-system.layout.grid";
import Radio from "@bit/kiban-design-system.kiban.radio-button";
import TextField from "@bit/kiban-design-system.kiban.text-field";

const SPEI = ({ data, onChange, errors }) => (
  <>
    <PageSection title="Configuración SPEI">
      <PageSection.Item title="Activo" size={1} length={1}>
        <Grid>
          <Grid.Item size="contentWidth">
            <Radio
              label="Sí"
              value={1}
              name="SPEIActivate"
              checked={data.Activate}
              onChange={() => onChange("Activate", true)}
            />
          </Grid.Item>
          <Grid.Item size="contentWidth">
            <Radio
              label="No"
              value={0}
              name="SPEIActivate"
              checked={!data.Activate}
              onChange={() => onChange("Activate", false)}
            />
          </Grid.Item>
        </Grid>
      </PageSection.Item>
      {data.Activate && (
        <>
          <PageSection.Item size={1} length={3} title="Nombre ordenante *">
            <TextField
              value={data?.NombreOrdenante}
              onChange={(e) => onChange("NombreOrdenante", e.target.value)}
              error={errors?.NombreOrdenante || false}
              errorText="Este campo es requerido"
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={3} title="Cuenta ordenante *">
            <TextField
              value={data?.CuentaOrdenante}
              maxLength={20}
              onChange={(e) => onChange("CuentaOrdenante", e.target.value)}
              error={errors?.CuentaOrdenante || false}
              errorText="Este campo es requerido"
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={3} />
          {data.Saldo && (
            <PageSection.Item size={1} length={3} title="Saldo">
              <TextField value={data?.Saldo} disabled />
            </PageSection.Item>
          )}
        </>
      )}
    </PageSection>
  </>
);

export default SPEI;
