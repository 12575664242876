import React from "react";
import "./forbidden.css";

const Forbidden = () => {
  // const history = useHistory();
  return (
    <div className='error403-container'>
      <div className='error403__header'>
        <label>Página no permitida</label>
      </div>
      <div className='error403__body'>
        <div className="error403__img">
          <img src='https://storage.googleapis.com/alpha.public.kiban.cloud/images/users.png'></img>
        </div>
        <div className='error403__title'>
          <label>¡Alto ahí!</label>
        </div>
        <div className='error403__description'>
          <label >Parece que no tienes los permisos necesarios para acceder a esta información.</label>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
