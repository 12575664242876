import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { apiRequest, apiRoutes } from "../../../services";
import getCatalogs from "../../../utils/catalogs";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import FormLegalario from "./FormLegalario";

import Texts from "../../../utils/app-texts.json";

const AddEditDigitalSign = () => {
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [templates, setTemplates] = useState(null);
  const [services, setServices] = useState({});
  const [catalogs, setCatalogs] = useState({});
  const [selected, setSelected] = useState("");
  const [dataConnector, setDataConnector] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedCatalogs, setSelectedCatalogs] = useState({});
  // LEGALARIO
  const [formTemplate, setFormTemplate] = useState([]);
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [radioSelected, setRadioSelected] = useState({
    userRequiredSignature: false,
    workflow: false,
    fillDocument: false,
  });
  const [templatePdfId, setTemplatePdfId] = useState("");
  const [dataFormTemplate, setDataFormTemplate] = useState([]);
  const [mappingTemplate, setMappingTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCatalogTypesServices = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectorTypeServices.replace(
        ":connector",
        "DIGITAL_SIGN"
      ),
    });
    if (!error) {
      const [optTypes, optServices, optCatalogs, optTemplates] =
        getCatalogs(data);
      setOptions(optTypes);
      setServices(optServices);
      setCatalogs(optCatalogs);
      setTemplates(optTemplates);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const saveConnector = async () => {
    setIsLoading(true);
    let data = { name };
    if (selected === "LEGALARIO") {
      const mapping = dataFormTemplate.map((dataMapping) => {
        const [connectorId, fieldName, fieldId] =
          dataMapping.fieldMapping.split("&");
        return {
          connectorId,
          fieldName,
          fieldId,
          connectorType: dataMapping.mappingSelected,
          legalarioId: dataMapping.idLegalario,
        };
      });
      Object.assign(data, radioSelected);
      data = {
        ...data,
        templatePdfId,
        mapping,
        whatsApp: radioSelected.whatsApp || false,
      };
    }
    if (selected === "LLEIDA") {
      const [templateId, templateName] = selectedTemplate.split("&");
      data = {
        ...data,
        templateId,
        templateName,
      };
    }
    if (selected === "DECEVAL") {
      data = {
        ...data,
        service: selectedService,
      };
      if (
        selectedCatalogs.hasOwnProperty(selected) &&
        selectedCatalogs[selected].hasOwnProperty(selectedService)
      ) {
        data = {
          ...data,
          ...selectedCatalogs[selected][selectedService],
        };
      }
    }
    const [error, respose] = await apiRequest({
      method: isEdit ? "put" : "post",
      url: isEdit
        ? apiRoutes.editConnector
            .replace(":connector", selected)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", selected),
      data,
    });
    if (!error) {
      addAlert({
        code: "success",
        message: AlertTexts.success,
        duration: 5000,
      });
      if (!isEdit) {
        setName(respose.name);
        setSelected(respose.type);
        setIsEdit(true);
        setDataConnector({});
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", respose.id)
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDataConnector = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (!error) {
      setDataConnector(data);
      setName(data.name);
      setSelected(data.type);
      if (data.type === "LLEIDA") {
        setSelectedTemplate(`${data.templateId}&${data.templateName}`);
      }
      if (data.type === "DECEVAL") {
        setSelectedService(data.service);
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
  };

  const handleSelectCatalogs = (key, value) => {
    let updateSelectedCatalogs = { ...selectedCatalogs };
    if (!updateSelectedCatalogs.hasOwnProperty(selected))
      updateSelectedCatalogs[selected] = {};
    if (!updateSelectedCatalogs[selected].hasOwnProperty(selectedService))
      updateSelectedCatalogs[selected][selectedService] = {};
    updateSelectedCatalogs[selected][selectedService][key] = value;
    setSelectedCatalogs(updateSelectedCatalogs);
  };

  useEffect(() => {
    getCatalogTypesServices();
    setIsEdit(routeParams.hasOwnProperty("id"));
    if (routeParams.hasOwnProperty("id")) {
      getDataConnector();
    }
  }, []);

  useEffect(() => {
    if (routeParams.hasOwnProperty("id")) {
      const optionsSelectsIsLoading =
        options.length === 0 ||
        templates === null ||
        services === null ||
        catalogs === null;
      // validation for MiFiel
      setIsLoading(
        optionsSelectsIsLoading ||
          dataConnector === null ||
          name === "" ||
          selected === ""
      );
      if (
        selected !== "" &&
        dataConnector !== null &&
        Object.keys(dataConnector).length > 0
      ) {
        if (dataConnector.type === "LEGALARIO") {
          if (mappingTemplate === null) {
            setRadioSelected({
              fillDocument: dataConnector.fillDocument,
              userRequiredSignature: dataConnector.userRequiredSignature,
              workflow: dataConnector.workflow,
              whatsApp: dataConnector.whatsApp,
            });
            setTemplatePdfId(dataConnector.templatePdfId);
            setMappingTemplate(dataConnector.mapping);
          }
          setIsLoading(mappingTemplate === null && formTemplate.length === 0);
          if (mappingTemplate !== null && formTemplate.length > 0)
            setDataConnector({});
        }
        if (dataConnector.type === "DECEVAL") {
          const updateSelectedCatalogs = { ...selectedCatalogs };
          setIsLoading(Object.keys(selectedCatalogs).length === 0);
          if (
            selected !== "" &&
            selectedService !== "" &&
            Object.keys(catalogs).length > 0 &&
            Object.keys(selectedCatalogs).length === 0
          ) {
            if (!updateSelectedCatalogs.hasOwnProperty(selected))
              updateSelectedCatalogs[selected] = {};
            if (
              !updateSelectedCatalogs[selected].hasOwnProperty(selectedService)
            )
              updateSelectedCatalogs[selected][selectedService] = {};
            if (catalogs[selected].hasOwnProperty(selectedService)) {
              catalogs[selected][selectedService].forEach((catalog) => {
                updateSelectedCatalogs[selected][selectedService][catalog.key] =
                  dataConnector[catalog.key];
              });
            }
            setSelectedCatalogs(updateSelectedCatalogs);
          }
        }
      }
    }
  }, [
    name,
    selected,
    routeParams,
    dataConnector,
    options,
    mappingTemplate,
    formTemplate,
    services,
    templates,
    catalogs,
    selectedCatalogs,
    selectedService,
  ]);

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      <PageSection title="Firma Electrónica">
        <PageSection.Item
          size={1}
          length={3}
          title={Texts.connectors.fields.name}
        >
          <TextField
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item
          size={1}
          length={3}
          title={Texts.connectors.fields.connectorType}
        >
          <SelectField
            placeholder={Texts.connectors.fields.connectorTypePlaceholder}
            value={selected}
            onChange={(value) => {
              setSelected(value);
              setSelectedService("");
              setSelectedTemplate("");
            }}
            options={options}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {selected === "LEGALARIO" && (
          <FormLegalario
            radioSelected={radioSelected}
            templatePdfId={templatePdfId}
            setTemplatePdfId={setTemplatePdfId}
            addAlert={addAlert}
            dataFormTemplate={dataFormTemplate}
            setDataFormTemplate={setDataFormTemplate}
            setRadioSelected={setRadioSelected}
            mappingTemplate={mappingTemplate}
            formTemplate={formTemplate}
            setFormTemplate={setFormTemplate}
          />
        )}
        {templates && templates.hasOwnProperty(selected) && (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label="Plantilla"
                placeholder="Selecciona una plantilla"
                value={selectedTemplate}
                onChange={(value) => {
                  setSelectedTemplate(value);
                }}
                options={templates[selected]}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {services && services.hasOwnProperty(selected) && (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label="Servicio"
                placeholder="Selecciona una servicio"
                value={selectedService}
                onChange={(value) => {
                  setSelectedService(value);
                  setSelectedCatalogs({});
                }}
                options={services[selected]}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {catalogs &&
          catalogs.hasOwnProperty(selected) &&
          catalogs[selected].hasOwnProperty(selectedService) &&
          catalogs[selected][selectedService].map((catalog) => (
            <>
              <PageSection.Item size={1} length={3}>
                <SelectField
                  label={catalog.name}
                  placeholder={`Selecciona ${catalog.name}`}
                  value={
                    selectedCatalogs.hasOwnProperty(selected) &&
                    selectedCatalogs[selected].hasOwnProperty(
                      selectedService
                    ) &&
                    selectedCatalogs[selected][selectedService].hasOwnProperty(
                      catalog.key
                    )
                      ? selectedCatalogs[selected][selectedService][catalog.key]
                      : ""
                  }
                  onChange={(value) => handleSelectCatalogs(catalog.key, value)}
                  options={catalog.options}
                />
              </PageSection.Item>
              <PageSection.Item size={2} length={3} />
            </>
          ))}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button
              style="primary"
              text="Guardar"
              onClick={saveConnector}
              disabled={
                selected === "" ||
                name === "" ||
                (selected === "LLEIDA" && selectedTemplate === "")
              }
            />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => {
                history.push(
                  Paths.Connectors.replace(
                    ":connectorType",
                    routeParams.connectorType
                  )
                );
              }}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
};

export default AddEditDigitalSign;
