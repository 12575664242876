import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Grid from "@bit/kiban-design-system.layout.grid";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";

const ConfigBuroCL = ({ data, onChange, onSave, errorFields }) => {
  const handleOnChange = (values) => {
    onChange &&
      onChange({
        ...data,
        ...values,
      });
  };

  return (
    <>
      <PageSection title="Equifax">
        <PageSection.Item title="Activar Equifax" size={1} length={1}>
          <Grid>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="Sí"
                value={1}
                checked={data.Equifax?.Activated}
                name="EquifaxActivated"
                onChange={() =>
                  handleOnChange({
                    Equifax: {
                      ...data.Equifax,
                      Activated: true,
                    },
                  })
                }
              />
            </Grid.Item>
            <Grid.Item size="contentWidth">
              <RadioButton
                label="No"
                value={1}
                checked={!data.Equifax?.Activated}
                name="EquifaxActivated"
                onChange={() =>
                  handleOnChange({
                    Equifax: {
                      ...data.Equifax,
                      Activated: false,
                    },
                  })
                }
              />
            </Grid.Item>
          </Grid>
        </PageSection.Item>
        {data.Equifax.Activated && (
          <>
            <PageSection.Item title="Usuario" size={1} length={3}>
              <TextField
                value={data.Equifax?.User}
                onChange={(e) =>
                  handleOnChange({
                    Equifax: {
                      ...data.Equifax,
                      User: e.target.value,
                    },
                  })
                }
                error={errorFields.Equifax?.User}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item title="Contraseña" size={1} length={3}>
              <TextField
                type="password"
                value={data.Equifax?.Password}
                onChange={(e) =>
                  handleOnChange({
                    Equifax: {
                      ...data.Equifax,
                      Password: e.target.value,
                    },
                  })
                }
                error={errorFields.Equifax?.Password}
                errorText="Debes completar este campo"
              />
            </PageSection.Item>
            <PageSection.Item size={1} length={3} />
          </>
        )}
      </PageSection>
      <div style={{ textAlign: "center" }}>
        <Button text="Guardar" onClick={onSave} style={`primary`} />
      </div>
    </>
  );
};

export default ConfigBuroCL;
