import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import OptionField from "@bit/kiban-design-system.kiban.option-field";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import Texts from "../../../utils/app-texts.json";
import Paths from "../../../paths";
import ArusForm from "./ArusForm";
import { isValidURL } from "../../../utils/validators";
import FintocForm from "./FintocForm";
import Jwt from "../../../utils/jwt";
import base64DecodeUnicode from "../../../utils/b64DecodeUnicode";

const AddEditOpenBanking = () => {
  const routeParams = useParams();
  const { addAlert } = useAlert();
  const history = useHistory();

  const [options, setOptions] = useState([]);
  const [services, setServices] = useState(null);
  const [selected, setSelected] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [invoices, setInvoices] = useState(null);
  const [invoiceSelected, setInvoiceSelected] = useState("");
  const [types, setTypes] = useState(null);
  const [typeSelected, setTypeSelected] = useState("");
  const [selectedTypesInvoices, setSelectedTypesInvoices] = useState([]);
  const [optionsTypesInvoices, setOptionsTypesInvoices] = useState(null);
  const [optionsTypesInvoicesFilter, setOptionsTypesInvoicesFilter] = useState(
    []
  );
  const [labelType, setLabelType] = useState("");
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [arusData, setArusData] = useState({});
  const [serviceFields, setServiceFields] = useState({});
  const [selectedFieldsValues, setSelectedFieldsValues] = useState({});
  const [fintocWidget, setFintocWidget] = useState(null);
  const [fintocWidgetTest, setFintocWidgetTest] = useState(null);
  const keysShowInvoices = ["INVOICE", "TAX_RETENTIONS"];
  const keysShowTypes = [
    "INVOICE",
    "TAX_RETURN",
    "CASHFLOW",
    "INVOICING_CONCENTRATION",
  ];
  const keysTypes = [
    "invoicesTypes",
    "taxReturnType",
    "cashFlowType",
    "invoicingConcentrationType",
  ];

  const getCatalogTypesServices = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.connectorTypeServices.replace(
        ":connector",
        "OPEN_BANKING"
      ),
    });
    if (!error) {
      setOptions(
        data.map((opt) => {
          return {
            content:
              opt.type && opt.type.langs && opt.type.langs.es
                ? opt.type.langs.es
                : "",
            value: opt.type && opt.type.const ? opt.type.const : "",
          };
        })
      );
      let servicesOption = {};
      let invoicesOption = {};
      let typesOption = {};
      let typesInvoicesOption = [];
      let serviceFieldsOption = {};
      data.forEach((opt) => {
        if (opt.services) {
          servicesOption[opt.type.const] = opt.services.map((service) => {
            if (service.invoices) {
              invoicesOption[service.const] = service.invoices.map(
                (invoice) => {
                  return {
                    content:
                      invoice.langs && invoice.langs.es ? invoice.langs.es : "",
                    value: invoice.const ? invoice.const : "",
                  };
                }
              );
            }
            const keyType = Object.keys(service).find((key) =>
              keysTypes.includes(key)
            );
            if (keyType) {
              if (service.const !== "INVOICE") {
                typesOption[service.const] = service[keyType].map((type) => {
                  return {
                    content: type.langs && type.langs.es ? type.langs.es : "",
                    value: type.const ? type.const : "",
                  };
                });
              } else {
                typesInvoicesOption = service[keyType].map((type) => {
                  return {
                    label: type.langs && type.langs.es ? type.langs.es : "",
                    value: type.const ? type.const : "",
                  };
                });
              }
            }
            if (opt.type.const === "FINTOC") {
              const serviceFieldsKeys = Object.keys(service).filter(
                (key) => key !== "const" && key !== "langs"
              );
              if (!serviceFieldsOption[opt.type.const]) {
                serviceFieldsOption[opt.type.const] = {};
              }
              if (
                !serviceFieldsOption[opt.type.const][service.const] &&
                Object.keys(service).length > 2
              ) {
                serviceFieldsOption[opt.type.const][service.const] = [];
              }
              serviceFieldsKeys.forEach((key) => {
                let field = {
                  label: Texts.connectors.fields[key],
                  name: key === "invoices" ? "invoiceType" : key,
                  type: "select",
                  options: service[key].map((field) => ({
                    content: field.langs.es,
                    value: field.const,
                  })),
                };
                serviceFieldsOption[opt.type.const][service.const].push(field);
              });
              if (service.const === "MOVEMENTS") {
                const fields = [
                  {
                    label: Texts.connectors.fields.since,
                    name: "since",
                    type: "text",
                    helperText:
                      "El formato debe coincidir con +/-DDDy,+/-DDDm,+/-DDDd",
                  },
                  {
                    label: Texts.connectors.fields.until,
                    name: "until",
                    type: "text",
                    helperText:
                      "El formato debe coincidir con +/-DDDy,+/-DDDm,+/-DDDd",
                  },
                ];
                serviceFieldsOption[opt.type.const][service.const].push(
                  ...fields
                );
              }
              if (service.const === "INVOICES") {
                const fields = [
                  {
                    label: Texts.connectors.fields.since,
                    name: "since",
                    type: "text",
                    helperText:
                      "El formato debe coincidir con +/-DDDy,+/-DDDm,+/-DDDd",
                  },
                  {
                    label: Texts.connectors.fields.until,
                    name: "until",
                    type: "text",
                    helperText:
                      "El formato debe coincidir con +/-DDDy,+/-DDDm,+/-DDDd",
                  },
                  {
                    label: Texts.connectors.fields.updatedSince,
                    name: "updatedSince",
                    type: "text",
                    helperText:
                      "El formato debe coincidir con +/-DDDy,+/-DDDm,+/-DDDd",
                  },
                ];
                serviceFieldsOption[opt.type.const][service.const].push(
                  ...fields
                );
              }
            }
            return {
              content:
                service.langs && service.langs.es ? service.langs.es : "",
              value: service.const ? service.const : "",
            };
          });
        }
      });
      if (Object.keys(serviceFieldsOption).length > 0) {
        setServiceFields(serviceFieldsOption);
      }
      setOptionsTypesInvoices(typesInvoicesOption);
      setServices(servicesOption);
      setInvoices(invoicesOption);
      setTypes(typesOption);
      if (routeParams.hasOwnProperty("id")) {
        getDataConnector();
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const saveConnector = async () => {
    setIsLoading(true);
    let bodyRequest = { name };
    if (selected === "PROMETEO" || selected === "SATWS") {
      bodyRequest["service"] = selectedService;
    }
    if (selected === "SATWS") {
      switch (selectedService) {
        case "INVOICE":
          bodyRequest["invoices"] = invoiceSelected;
          bodyRequest["invoicesTypes"] = selectedTypesInvoices;
          break;
        case "TAX_RETURN":
          bodyRequest["taxReturnType"] = typeSelected;
          break;
        case "TAX_RETENTIONS":
          bodyRequest["invoices"] = invoiceSelected;
          break;
        case "CASHFLOW":
          bodyRequest["cashFlowType"] = typeSelected;
          break;
        case "INVOICING_CONCENTRATION":
          bodyRequest["invoicingConcentrationType"] = typeSelected;
          break;
        default:
          break;
      }
    }
    if (selected === "ARUS") {
      bodyRequest = {
        ...bodyRequest,
        ...arusData,
      };
    }
    if (selected === "FINTOC") {
      bodyRequest = {
        ...bodyRequest,
        service: selectedService,
        ...selectedFieldsValues,
      };
    }
    const [error, data] = await apiRequest({
      method: isEdit ? "put" : "post",
      url: isEdit
        ? apiRoutes.editConnector
            .replace(":connector", selected)
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(":typeConnector", selected),
      data: bodyRequest,
    });
    if (!error) {
      if (selected === "FINTOC") {
        getDataConnector(routeParams.id || data.id);
      }
      addAlert({
        code: "success",
        message: AlertTexts.success,
        duration: 5000,
      });
      if (!isEdit) {
        setName(data.name);
        setSelected(data.type);
        setIsEdit(true);
        if (data.type === "PROMETEO") {
          setSelectedService(data.service);
        }
        history.push(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
      }
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getDataConnector = async (id) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id || id),
    });
    if (!error) {
      setName(data.name);
      setSelected(data.type);
      setDataUpdate(data);
    } else {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const searchInvoices = (search) => {
    if (search !== "") {
      const options = optionsTypesInvoices.filter((opt) =>
        opt.label.includes(search)
      );
      setOptionsTypesInvoicesFilter(options);
    } else {
      setOptionsTypesInvoicesFilter(optionsTypesInvoices);
    }
  };

  const handleOnRemoveSelection = (value) => {
    const allSelectedItems = selectedTypesInvoices.filter(
      (item) => item !== value
    );
    setSelectedTypesInvoices(allSelectedItems);
  };

  useEffect(() => {
    getCatalogTypesServices();
    setIsEdit(routeParams.hasOwnProperty("id"));
  }, []);

  useEffect(() => {
    if (selected) {
      setSelectedService("");
    }
  }, [selected]);

  useEffect(() => {
    if (optionsTypesInvoices) {
      setOptionsTypesInvoicesFilter(optionsTypesInvoices);
    }
  }, [optionsTypesInvoices]);

  useEffect(() => {
    if (selectedService) {
      const option = services[selected].find(
        (service) => service.value === selectedService
      );
      setLabelType(option.content.toLowerCase());
    }
  }, [selectedService]);

  useEffect(() => {
    if (services && types && invoices && optionsTypesInvoices && dataUpdate) {
      if (dataUpdate.type === "PROMETEO" || dataUpdate.type === "SATWS") {
        setSelectedService(dataUpdate.service);
      }
      if (dataUpdate.type === "SATWS") {
        switch (dataUpdate.service) {
          case "INVOICE":
            setInvoiceSelected(dataUpdate.invoices);
            setSelectedTypesInvoices(dataUpdate.invoicesTypes);
            break;
          case "TAX_RETURN":
            setTypeSelected(dataUpdate.taxReturnType);
            break;
          case "TAX_RETENTIONS":
            setInvoiceSelected(dataUpdate.invoices);
            break;
          case "CASHFLOW":
            setTypeSelected(dataUpdate.cashFlowType);
            break;
          case "INVOICING_CONCENTRATION":
            setTypeSelected(dataUpdate.invoicingConcentrationType);
            break;
          default:
            break;
        }
      }
      if (dataUpdate.type === "ARUS") {
        setArusData({
          url: dataUpdate.url,
        });
      }
      if (dataUpdate.type === "FINTOC") {
        let fields = {};
        for (let field in dataUpdate) {
          if (
            field !== "id" &&
            field !== "name" &&
            field !== "type" &&
            field !== "service" &&
            field !== "created" &&
            field !== "modified" &&
            field !== "widgetBase64"
          ) {
            fields[field] = dataUpdate[field];
          }
        }
        setSelectedService(dataUpdate.service);
        setSelectedFieldsValues(fields);
        let widget = base64DecodeUnicode(dataUpdate.widgetBase64);
        let widgetTest = base64DecodeUnicode(dataUpdate.widgetSandBoxBase64);
        // Add attr country to widget
        widget = widget.replace(
          "})",
          `    country:"${Jwt.getItem("country").toLowerCase()}",\n})`
        );
        setFintocWidget(widget);
        widgetTest = widgetTest.replace(
          "})",
          `    country:"${Jwt.getItem("country").toLowerCase()}",\n})`
        );
        setFintocWidgetTest(widgetTest);
      }
    }
  }, [
    services,
    types,
    invoices,
    optionsTypesInvoices,
    dataUpdate,
    serviceFields,
  ]);

  const handleArusDataChange = (field, value) => {
    setArusData({ ...arusData, [field]: value });
  };
  const handleOnFieldChange = (field, value) => {
    setSelectedFieldsValues({
      ...selectedFieldsValues,
      [field]: value,
    });
  };

  const validateFintoc = () => {
    const fields = serviceFields[selected][selectedService];
    const validations = [];
    if (fields)
      fields.forEach((field) => {
        if (field.type !== "text") {
          validations.push(
            selectedFieldsValues[field.name] &&
              selectedFieldsValues[field.name] !== ""
          );
        } else {
          validations.push(
            selectedFieldsValues[field.name] &&
              selectedFieldsValues[field.name] !== ""
          );
        }
      });
    return validations.every((validation) => validation === true);
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content" id="AddEditOpenBanking">
      <PageSection title="Open Banking">
        <PageSection.Item size={1} length={3}>
          <TextField
            label={Texts.connectors.fields.name}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={3}>
          <SelectField
            label={Texts.connectors.fields.connectorType}
            placeholder={Texts.connectors.fields.connectorType}
            value={selected}
            onChange={(value) => {
              setSelected(value);
            }}
            options={options}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {selected !== "" && services[selected] && (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label={Texts.connectors.fields.serviceType}
                placeholder={Texts.connectors.fields.serviceTypePlaceholder}
                value={selectedService}
                onChange={(value) => {
                  if (Object.keys(selectedFieldsValues).length > 0) {
                    setSelectedFieldsValues({});
                  }
                  setSelectedService(value);
                }}
                options={services ? services[selected] : []}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {(selectedService === "BALANCE_SHEET" ||
          selectedService === "INCOME_STATEMENT") && (
          <>
            <PageSection.Item size={1} length={3}>
              <p style={{ fontWeight: "700" }}>
                Para utilizar este servicio se debió haber agregado el servicio
                de "Declaraciones" previamente en el workfloo
              </p>
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {selectedService !== "" && keysShowInvoices.includes(selectedService) && (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label={Texts.connectors.fields.invoices}
                placeholder={Texts.connectors.fields.invoicesPlaceholder}
                value={invoiceSelected}
                onChange={(value) => {
                  setInvoiceSelected(value);
                }}
                options={invoices[selectedService]}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
          </>
        )}
        {selected !== "FINTOC" &&
          selectedService !== "" &&
          keysShowTypes.includes(selectedService) && (
            <>
              <PageSection.Item
                title={
                  selectedService === "INVOICE"
                    ? Texts.connectors.fields.typeServiceSelected.replace(
                        ":service",
                        labelType
                      )
                    : ""
                }
                size={1}
                length={3}
              >
                {selectedService === "INVOICE" ? (
                  <OptionField
                    name={Texts.connectors.fields.typeServiceSelected.replace(
                      ":service",
                      labelType
                    )}
                    items={optionsTypesInvoicesFilter}
                    searchPlaceHolder={Texts.connectors.fields.searchPlaceholder.replace(
                      ":service",
                      labelType
                    )}
                    selectedItems={selectedTypesInvoices}
                    onSearchChange={searchInvoices}
                    onChange={(selected) => setSelectedTypesInvoices(selected)}
                    onRemoveSelection={handleOnRemoveSelection}
                  />
                ) : (
                  <SelectField
                    label={Texts.connectors.fields.typeServiceSelected.replace(
                      ":service",
                      labelType
                    )}
                    placeholder={Texts.connectors.fields.typeServiceSelectedPlaceholder.replace(
                      ":service",
                      labelType
                    )}
                    value={typeSelected}
                    onChange={(value) => {
                      setTypeSelected(value);
                    }}
                    options={types[selectedService]}
                  />
                )}
              </PageSection.Item>
              <PageSection.Item size={2} length={3} />
            </>
          )}
        {((Object.keys(serviceFields).length > 0 &&
          serviceFields[selected] &&
          serviceFields[selected][selectedService]) ||
          (fintocWidget && fintocWidgetTest)) && (
          <FintocForm
            fields={serviceFields}
            type={selected}
            serviceType={selectedService}
            formValues={selectedFieldsValues}
            onFieldChange={handleOnFieldChange}
            widget={fintocWidget}
            widgetTest={fintocWidgetTest}
          />
        )}
        {selected === "ARUS" && (
          <ArusForm data={arusData} onChange={handleArusDataChange} />
        )}
        <PageSection.Item size={1} length={3} />
        <PageSection.Item size={1} length={3}>
          <ButtonGroup>
            <Button
              style="primary"
              text="Guardar"
              onClick={saveConnector}
              disabled={
                selected === "" ||
                name === "" ||
                (selected === "PROMETEO" && selectedService === "") ||
                (selected === "ARUS" &&
                  (!arusData.url || !isValidURL(arusData.url))) ||
                (selected === "FINTOC" && !validateFintoc()) ||
                (selectedService === "INVOICE" &&
                  selectedTypesInvoices.length === 0)
              }
            />
            <Button
              style="secondary"
              text="Cancelar"
              onClick={() => {
                history.push(
                  Paths.Connectors.replace(":connectorType", "openbanking")
                );
              }}
            />
          </ButtonGroup>
        </PageSection.Item>
        <PageSection.Item size={1} length={3} />
      </PageSection>
    </div>
  );
};

export default AddEditOpenBanking;
