import { apiRequest, apiRoutes } from "../../../../../services";

export const getUniverses = async ({ onError, id }) => {
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getPolicyUniversesCatalog.replace(":id", id),
  });
  if (data) {
    return data;
  }
  if (error) {
    onError(error);
  }
};
