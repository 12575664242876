const getCatalogs = (responseTypesServices) => {
  let types = [];
  let services = {};
  let catalogs = {};
  let templates = {}
  responseTypesServices.forEach((opt) => {
    types.push({
      value: opt.type.const,
      content: opt.type.langs.es,
    });
    if (opt.services) {
      services[opt.type.const] = [];
      opt.services.forEach((service) => {
        services[opt.type.const].push({
          value: service.const,
          content: service.langs.es,
        });
        if (service.catalogs) {
          service.catalogs.forEach((catalog) => {
            if (!catalogs.hasOwnProperty(opt.type.const))
              catalogs[opt.type.const] = {};
            if (!catalogs[opt.type.const].hasOwnProperty(service.const))
              catalogs[opt.type.const][service.const] = [];
            catalogs[opt.type.const][service.const].push({
              name: catalog.type.langs.es,
              key: catalog.type.const,
              options: catalog.catalog.map((optCatalog) => ({
                value: optCatalog.const,
                content: optCatalog.langs.es,
              })),
            });
          });
        }
      });
    }
    if (opt.templates) {
      templates[opt.type.const] = [];
      opt.templates.forEach((template) => {
        if (template.name && template.id) {
          templates[opt.type.const].push({
            content: template.name,
            value: `${template.id}&${template.name}`,
          })
        }
      });
    }
  });
  return [types, services, catalogs, templates];
};

export default getCatalogs;