import { apiRequest, apiRoutes } from "../../services";

export const sdkCodeSnippet = `//<![CDATA[
      (window.mifiel = window.mifiel || []),
        (function () {
          "use strict";
          for (
            var e = ["widget"],
              i = function (e) {
                return function () {
                  window.mifiel.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0))
                  );
                };
              },
              t = 0;
            t < e.length;
            t++
          ) {
            var n = e[t];
            window.mifiel[n] || (window.mifiel[n] = i(n));
          }
          if (!document.getElementById("mifiel-js")) {
            var r = document.createElement("script"),
              o = document.getElementsByTagName("script")[0];
            (r.type = "text/javascript"),
              (r.id = "mifiel-js"),
              (r.async = !0),
              (r.src = "https://sandbox.mifiel.com/sign-widget-v1.0.0.js"),
              o.parentNode.insertBefore(r, o);
          }
        })();
      //]]>`;
export const widgetCode = `window.mifiel
			.widget({
				widgetId : "{{widgetId}}",
				appendTo : '{{appendTo}}',
				successBtnText : 'Continuar',
			});`;

export const wsEvent = `
						window
								.addEventListener(
										'message',
										function(e) {
											console.log(e);
											// accept messages from known hosts only
											// change to https://stageex.mifiel.com if necessary
											if (e.origin !== 'https://app.mifiel.com'
													&& e.origin !== 'https://app-sandbox.mifiel.com') {
												return;
											}
											// We will always send an object
											if (typeof e.data !== 'object') {
												return;
											}
											// document signed succesfully
											if (e.data.eventType === 'mifiel.widget.success.callback-cta') {
                        window.onMiFielCaptured();
											}
											// Event errors
											if (e.data.eventType === 'mifiel.widget.error.callback-cta') {
												var error = e.data.error;
												console.log(error);
												// error.code => 1001
												// error.message => 'Invalid PDF, the pdf param has no data'
											}
										}, false);`;

export const getWidgetData = async (workflooId, onError) => {
  const [error, data] = await apiRequest({
    url: apiRoutes.getMiFielWidgetData.replace("{workflooId}", workflooId),
    method: "GET",
  });
  if (data) {
    return data;
  }
  if (error) {
    onError(error);
  }
};

export const setWidgetStatus = async (workflooId, onError) => {
  const [error, data] = await apiRequest({
    url: apiRoutes.setMiFielWidgetStatus.replace("{workflooId}", workflooId),
    method: "POST",
    data: {
      status: "CAPTURED",
    },
  });
  if (data !== null) {
    return data;
  }
  if (error) {
    onError(error);
  }
};
