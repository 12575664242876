import React, { useEffect, useState } from "react";

import Alert from "@bit/kiban-design-system.kiban.alert";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import TableSms from "./TableSms/TableSms";

import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";

export default function ConfigPlans({ type }) {
  const [dataConfig, setDataConfig] = useState(null);
  const [dataPlan, setDataPlan] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataTableConnector, setDataTableConnector] = useState([]);
  const [planSelected, setPlanSelected] = useState(null);
  const keysDataConnector = ["dataByConnectorProd", "dataByConnectorTest"];

  const getDataPlan = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getPlans,
    });
    if (!err) {
      setDataPlan(data);
      if (planSelected) {
        updateDataTablePlans();
      }
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrio un error, vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const getConfigMessaging = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "MESSAGING"),
    });
    if (!err) {
      const responseDataConfig = data.hasOwnProperty("messagingMXResponse")
        ? data.messagingMXResponse
        : data.hasOwnProperty("messagingCOResponse")
        ? data.messagingCOResponse
        : null;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrio un error, vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const updatePlan = async () => {
    setLoading(true);
    const response = await apiRequest({
      method: "put",
      url: apiRoutes.updatePlans.replace(":typePlan", type.toUpperCase()),
      data: {
        planNextMonth: planSelected,
      },
    });
    if (!response[0]) {
      getDataPlan();
      setStatus({
        code: "success",
        text: "Se guardó correctamente el plan",
      });
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrio un error, vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const updateDataTablePlans = () => {
    const plans =
      type === "SMS" ? dataConfig.SmsPlans.smsPlans : dataConfig.WhatsappPlans;
    plans.sort(function (a, b) {
      if (a.packageCostUsd > b.packageCostUsd) {
        return 1;
      }
      if (a.packageCostUsd < b.packageCostUsd) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setPlanSelected(null);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getDataPlan();
      getConfigMessaging();
    } else {
      window.location.href = "/login";
    }
  }, [type]);

  useEffect(() => {
    if (dataConfig && dataPlan) {
      updateDataTablePlans();
      const tableConnector = {};
      keysDataConnector.forEach((key) => {
        const config =
          type === "SMS" ? dataConfig.SmsData : dataConfig.WhatsappData;
        const anyDataConnector =
          key !== "total" ? (config[key] !== null ? config[key] : []) : config;
        tableConnector[key] = key !== "total" && [
          {
            send: anyDataConnector.total,
            aproved: anyDataConnector.success,
            fail: anyDataConnector.failed,
          },
        ];
      });
      setDataTableConnector(tableConnector);
    }
  }, [dataConfig, dataPlan]);

  useEffect(() => {
    if (planSelected) {
      updatePlan();
    }
  }, planSelected);

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  const headersTableProd = ["Enviados", "Aprobados", "Rechazados"];

  const headersTableTotal = ["", "", `${type} Incluidos`, "Costo Extra"];

  return loading ? (
    <>
      <SkeletonTable />
      <SkeletonTable />
      <SkeletonTable />
      <SkeletonTable />
    </>
  ) : (
    <div className="main-content">
      {status && <Alert {...status} />}
      <PageSection>
        <PageSection.Item size={1} length={1}>
          <p>
            El servicio de {type.toLowerCase()} está activado. Se facturan bajo
            uso en pospago. Todos los {type.toLowerCase()} usados durante un
            mes, se facturan el siguiente mes. Puedes revisar los costos{" "}
            <a
              target="_blank"
              href="https://docs.kiban.mx/terminos-y-condiciones/pricing"
            >
              aquí
            </a>
          </p>
          {keysDataConnector.map((key) => (
            <TableSms
              title={
                key === "total"
                  ? ""
                  : `${type === "SMS" ? "SMS" : "Whatsapp"} Mensajes ${
                      key.includes("Prod") ? "Producción" : "Test"
                    }`
              }
              headers={key === "total" ? headersTableTotal : headersTableProd}
              data={
                dataTableConnector.hasOwnProperty(key)
                  ? dataTableConnector[key]
                  : []
              }
              isTotal={key === "total"}
            />
          ))}
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
