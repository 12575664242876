import { apiRequest, apiRoutes } from "../../../../services";

const getNipMeansCatalog = async () => {
  let meansCatalog = null;
  let error = null;
  const [err, res] = await apiRequest({
    method: "get",
    url: apiRoutes.getCatalog.replace(":catalogName", "CONNECTOR_NIP_MEANS"),
  });
  if (res)
    meansCatalog = res.map((meanType) => ({
      value: meanType.key,
      content: meanType.value,
    }));
  if (err) error = err;
  return {
    meansCatalog,
    error,
  };
};

export default getNipMeansCatalog;
