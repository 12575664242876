import { useState, useEffect } from "react";

/**
 * A set of elements that represents every state of the pagination
 * @typedef {Object} UseTablePaginationResults
 * @property {number} page Updated page - current value of each change
 * @property {Function} skipBack Skip back event
 * @property {Function} goBack Go back event which should be triggered when user clicks onPrevious Table event
 * @property {Function} goForward Go forward event which should be triggered when user clicks onNext Table event
 * @property {Function} skipForward Skip forward event
 * @property {boolean} hasNext Used to know if pagination has next page
 * @property {boolean} hasPrevious Used to know if pagination has previous page
 * @property {Number} from Initial element in current pagination
 * @property {Number} to Last element in current pagination
 */
/**
 * This function is used to control the pagination property of Table component from Kiban design system.
 * @function useTablePagination
 * @param {Object} params
 * @param {number} params.initialPage Where pagination starts
 * @param {number} params.rowsPerPage Limit of items per page
 * @param {number} params.totalItems Total number of elements
 * @returns {UseTablePaginationResults}
 */
const useTablePagination = ({ initialPage, rowsPerPage, totalItems }) => {
  /**
   * Limit of items per page
   * @type {number}
   */
  const limit = rowsPerPage;

  /**
   * The current page displayed in the table
   * @type {[number, Function]}
   */
  const [page, setPage] = useState(initialPage);

  const [total, setTotal] = useState(0);

  /**
   * Updates the total param when totalItems argument changes
   */
  useEffect(() => {
    setTotal(totalItems);
    if (totalItems > 0 && limit * page >= totalItems) {
      setPage(Math.ceil(totalItems / limit));
    }
  }, [totalItems]);

  const skipBack = () => {
    setPage(1); // Sets the current page 1 when skipBack Function is triggered
  };

  const goBack = () => {
    if (page > 1) {
      setPage(page - 1); // Reduce the page if it is greater than one
    }
  };

  const goForward = () => {
    if (limit * page < total) {
      setPage(page + 1); // Increments the page if this is lower than total items
    }
  };

  const skipForward = () => {
    setPage(Math.ceil(total / limit)); // Sets current page to total calculated pages
  };
  return {
    page,
    skipBack,
    goBack,
    goForward,
    skipForward,
    hasNext: limit * page < total,
    hasPrevious: page > 1,
    from: limit * page - (limit - 1),
    to: limit * page > total ? total : limit * page,
  };
};

export default useTablePagination;
