import React from "react";
import { Switch } from "react-router-dom";
import {
  Api,
  Company,
  Documentation,
  Home,
  Nip,
  Users,
  AddEditUser,
  Workfloos,
  Connectors,
  Documents,
  Authorization,
  Synchronization,
  Historic,
  Variables,
  EditConnectors,
  CasosFicticios,
  AddEditCasosFicticios,
  AuthNip,
  AuthNipSic,
  AddEditWorkfloo,
  FintocWidget,
  DetailHistoricWorkfloo,
  StartWorkfloo,
  Forbidden,
  MatiWidget,
  MiFielWidget,
  Rainao,
} from "../pages";

import PrivateRoute from "../PrivateRoute";
import Paths from "../paths";

export const Navigation = () => {
  return (
    <Switch>
      <PrivateRoute exact path={Paths.Home} component={Home} />
      <PrivateRoute
        exact
        path={Paths.Documentation}
        component={Documentation}
      />
      <PrivateRoute exact path={Paths.Api} component={Api} />
      <PrivateRoute exact path={Paths.Users} component={Users} />
      <PrivateRoute exact path={Paths.AddUser} component={AddEditUser} />
      <PrivateRoute exact path={Paths.EditUser} component={AddEditUser} />
      <PrivateRoute exact path={Paths.Connectors} component={Connectors} />
      <PrivateRoute exact path={Paths.Nip} component={Nip} />
      <PrivateRoute exact path={Paths.pdfDocument} component={Documents} />
      <PrivateRoute
        exact
        path={Paths.Authorization}
        component={Authorization}
      />
      <PrivateRoute exact path={Paths.Company} component={Company} />
      <PrivateRoute exact path={Paths.Workfloos} component={Workfloos} />
      <PrivateRoute exact path={Paths.Variables} component={Variables} />
      <PrivateRoute
        exact
        path={Paths.AddConnectors}
        component={EditConnectors}
      />
      <PrivateRoute
        exact
        path={Paths.EditConnectors}
        component={EditConnectors}
      />
      <PrivateRoute exact path={Paths.Historic} component={Historic} />
      <PrivateRoute
        exact
        path={Paths.Synchronization}
        component={Synchronization}
      />
      <PrivateRoute
        exact
        path={Paths.CasosFicticios}
        component={CasosFicticios}
      />
      <PrivateRoute
        exact
        path={Paths.AddCasosFicticios}
        component={AddEditCasosFicticios}
      />
      <PrivateRoute
        exact
        path={Paths.EditCasosFicticios}
        component={AddEditCasosFicticios}
      />
      <PrivateRoute exact path={Paths.AuthNip} component={AuthNip} />
      <PrivateRoute exact path={Paths.AuthNipSic} component={AuthNipSic} />
      <PrivateRoute
        exact
        path={Paths.AddWorkfloo}
        component={AddEditWorkfloo}
      />
      <PrivateRoute
        exact
        path={Paths.EditWorkfloo}
        component={AddEditWorkfloo}
      />
      <PrivateRoute exact path={Paths.FintocWidget} component={FintocWidget} />
      <PrivateRoute
        exact
        path={Paths.DetailHistoricWorkfloo}
        component={DetailHistoricWorkfloo}
      />
      <PrivateRoute
        exact
        path={Paths.StartWorkfloo}
        component={StartWorkfloo}
      />
      <PrivateRoute exact path={Paths.Forbidden} component={Forbidden} />
      <PrivateRoute exact path={Paths.Mati} component={MatiWidget} />
      <PrivateRoute exact path={Paths.MiFiel} component={MiFielWidget} />
      <PrivateRoute exact path={Paths.Rainao} component={Rainao} />
    </Switch>
  );
};
