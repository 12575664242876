import React, { useEffect, useState } from "react";
import Table from "@bit/kiban-design-system.kiban.table";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import COLOR_DANGER_BASE from "@bit/kiban-design-system.styles.design-tokens-json";
import { AlertIcon } from "@bit/kiban-design-system.kiban.icons";
import "./VariablesPersonalizadas.css";

import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";

const Variables = () => {
  const headers = Jwt.getItem("all");
  const [variables, setVariables] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getRows = (fields) => {
    return fields.map((field) => [
      <span>{field.id}</span>,
      <span>{field.name}</span>,
      <span>{getType(field.type)}</span>,
    ]);
  };

  const getType = (fieldType) => {
    const type = {
      NUMERIC: "Númerico",
      ALPHANUMERIC: "Alphanumérico",
      LIST: "Lista de valores",
      DATE: "Fecha",
    };
    return type[fieldType];
  };

  const getVariables = async () => {
    const [error, variables] = await apiRequest({
      method: "get",
      url: apiRoutes.legacyVariables,
      headers: {
        company_code: headers.company_code,
        api_key: headers.sessionId,
      },
    });
    if (variables) {
      setVariables(variables.vprs);
    }
    if (error) {
      console.log(error);
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getVariables();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const tableHeaders = ["ID", "Nombre", "Tipo"];

  return (
    <div className="main-content">
      {error && (
        <PageSection.Item size={1} length={1}>
          <p
            style={{
              color: COLOR_DANGER_BASE,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AlertIcon />
            <span style={{ marginLeft: "10px" }}>
              {error.message ? error.message : "Hubo un error inesperado"}
            </span>
          </p>
        </PageSection.Item>
      )}
      {!isLoading ? (
        variables.length > 0 ? (
          variables.map((variable) => {
            // eslint-disable-next-line no-unused-expressions
            return (
              <div className="table-custom">
                <Table
                  headings={tableHeaders}
                  title={variable.name}
                  rows={variable.fields ? getRows(variable.fields) : []}
                />
              </div>
            );
          })
        ) : (
          <p>No hay variables en este momento</p>
        )
      ) : (
        <Skeleton size="medium" />
      )}
    </div>
  );
};

export default Variables;
