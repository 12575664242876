import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import "./Historic.css";

import Alert from "@bit/kiban-design-system.kiban.alert";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Select from "@bit/kiban-design-system.kiban.select-field";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import Table from "@bit/kiban-design-system.kiban.table";

import TextField from "@bit/kiban-design-system.kiban.text-field";

import { apiRequest, apiRoutes } from "../../services";
import Paths from "../../paths";
import { getMessageError } from "./HistoricErrors";
import Jwt from "../../utils/jwt";

export default function HistoricWorkfloo() {
  const limit = 50;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historic, setHistoric] = useState([]);

  // end data table
  // filters config
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [idWorkfloo, setIdWorkfloo] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [workfloo, setWorkfloo] = useState("");
  const [user, setUser] = useState("");
  const [catalogWorkfloos, setCatalogWorkfloos] = useState([]);
  const [catalogUsers, setCatalogUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  registerLocale("es", es);
  const history = useHistory();

  const getHistoricWorkfloos = async (params) => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.historicWorkfloo}?limit=${limit + 1}&skip=${
        page * limit
      }${params.length > 0 ? `&${params.join("&")}` : ""}`,
    });
    if (data) {
      setHasNextPage(data.workflooHistory.length > limit);
      setHistoric(data.workflooHistory ? data.workflooHistory : []);
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  const downloadHistoricWorkfloos = async () => {
    const dataAppliedFilters = filters.filter((filter) =>
      appliedFilters.includes(filter.key)
    );
    const params = dataAppliedFilters.map((filter) => {
      return `${
        filter.key.includes("date")
          ? filter.key.replace("date_", "")
          : filter.key
      }=${
        filter.key.includes("date")
          ? moment(filter.value.toJSON()).format("YYYY-MM-DD")
          : filter.value
      }`;
    });
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.downloadHistoricWorkfloo}?limit=100000&skip=0${
        params.length > 0 ? `&${params.join("&")}` : ""
      }`,
    });
    if (data) {
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = "Historial.csv";
      download.href = "data:text/csv;charset=utf-8," + escape(data);
      download.click();
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  // Start configuration table
  // Pagination table

  const goForward = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  const goBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const tablePagination = {
    hasNext: hasNextPage,
    hasPrevious: page > 0,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: () => setPage(0),
  };
  // end pagination table

  const rows =
    historic.length > 0
      ? historic
          .slice(0, limit)
          .map((historicItem, i) => [
            historicItem.idUnykoo.toString(),
            historicItem.workflooName,
            moment.utc(historicItem.created).format("DD-MM-YYYY"),
            moment.utc(historicItem.created).format("HH:mm"),
            historicItem.createdBy,
            historicItem.status,
            historicItem.origin,
          ])
      : [];

  const primaryAction = {
    label: "Exportar CSV",
    text: "Exportar CSV",
    onAction: downloadHistoricWorkfloos,
    disabled: isLoading,
  };

  const tableHeaders = [
    "ID",
    "Workfloo",
    "Fecha",
    "Hora",
    "Usuario",
    "Estatus",
    "Origin",
  ];

  const getWorkfloos = async () => {
    setIsLoading(true);
    const [error, workfloos] = await apiRequest({
      method: "get",
      url: apiRoutes.workfloos,
    });
    if (workfloos) {
      const options = workfloos.map((workflooItem) => {
        return {
          content: workflooItem.name,
          value: workflooItem.name,
        };
      });
      setCatalogWorkfloos(options);
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  const getUsers = async () => {
    setIsLoading(true);
    const [error, users] = await apiRequest({
      method: "get",
      url: apiRoutes.users,
    });

    if (users) {
      const options = users.users.map((userItem) => {
        return {
          content: userItem.login,
          value: userItem.userId,
        };
      });
      setCatalogUsers(options);
    }
    if (error) {
      const messageError = getMessageError(error.status, error.message);
      console.error(error);
      setError(messageError);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getWorkfloos();
      getUsers();
      getHistoricWorkfloos(filterParams);
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleIdWorkfloo = (event) => {
    setIdWorkfloo(event.target.value);
  };

  const handleRemoveIdWorkfloo = () => {
    setIdWorkfloo("");
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleRemoveStartDate = () => {
    setStartDate(null);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleRemoveEndDate = () => {
    setEndDate(null);
  };

  const handleSelectWorkfloo = (name) => {
    setWorkfloo(name);
  };

  const handleRemoveWorkfloo = () => {
    setWorkfloo("");
  };

  const handleSelectUser = (selected) => {
    setUser(selected);
  };

  const handleRemoveUser = () => {
    setUser("");
  };

  const filters = [
    {
      key: "workflooId",
      label: "ID Workfloo",
      value: idWorkfloo,
      filter: (
        <TextField
          placeholder="Id Workfloo"
          onChange={handleIdWorkfloo}
          value={idWorkfloo}
        />
      ),
      onRemove: handleRemoveIdWorkfloo,
    },
    {
      key: "date_from",
      label: "Fecha de principio",
      value: startDate,
      filter: (
        <DatePicker
          locale="es"
          className="input-date"
          selected={startDate}
          onChange={handleStartDate}
          placeholderText="Fecha de principio"
          dateFormat="dd/MM/yyyy"
        />
      ),
      onRemove: handleRemoveStartDate,
    },
    {
      key: "date_to",
      label: "Fecha de fin",
      value: endDate,
      filter: (
        <DatePicker
          locale="es"
          className="input-date"
          selected={endDate}
          onChange={handleEndDate}
          placeholderText="Fecha de fin"
          dateFormat="dd/MM/yyyy"
        />
      ),
      onRemove: handleRemoveEndDate,
    },
    {
      key: "userId",
      label: "Usuario",
      value: user,
      filter: (
        <Select
          placeholder={"Selecciona un usuario"}
          value={user}
          options={catalogUsers}
          onChange={handleSelectUser}
        />
      ),
      onRemove: handleRemoveUser,
    },
    {
      key: "workflooName",
      label: "Workfloos",
      value: workfloo,
      filter: (
        <Select
          placeholder={"Selecciona un workfloo"}
          value={workfloo}
          options={catalogWorkfloos}
          onChange={handleSelectWorkfloo}
        />
      ),
      onRemove: handleRemoveWorkfloo,
    },
  ];

  const resetValues = (filtersReset) => {
    filtersReset.forEach((filter) => {
      filter.onRemove && filter.onRemove();
    });
  };

  const isEmpty = (value) => {
    return value === "" || value === null || value === undefined;
  };

  const onFilter = (selectedFilters) => {
    const resetFilters = filters.filter(
      (filter) => !selectedFilters.includes(filter.key)
    );
    resetValues(resetFilters);
    const filtersNoEmpty = filters
      .filter(
        (filter) =>
          selectedFilters.includes(filter.key) && !isEmpty(filter.value)
      )
      .map((filter) => filter.key);
    setAppliedFilters(filtersNoEmpty);
  };

  const listFilters = filters
    .filter((filter) => appliedFilters.includes(filter.key))
    .map((filter) => {
      const value = filter.key.includes("date")
        ? [
            filter.value.getDate(),
            filter.value.getMonth() + 1,
            filter.value.getUTCFullYear(),
          ].join("/")
        : filter.key === "userId"
        ? catalogUsers.find((item) => item.value === user).content
        : filter.value;
      return {
        key: filter.key,
        value: (
          <span>
            {filter.label}: <strong>{value}</strong>
          </span>
        ),
      };
    });

  // Apply filters
  useEffect(() => {
    const params = filters
      .filter((filter) => appliedFilters.includes(filter.key))
      .map((filter) => {
        return `${
          filter.key.includes("date")
            ? filter.key.replace("date_", "")
            : filter.key
        }=${
          filter.key.includes("date")
            ? moment(filter.value.toJSON()).format("YYYY-MM-DD")
            : encodeURIComponent(filter.value)
        }`;
      });
    getHistoricWorkfloos(params);
    setFilterParams(params);
  }, [appliedFilters]);
  // end filters config

  useEffect(() => {
    getHistoricWorkfloos(filterParams);
  }, [page]);

  const onSelectItem = (selected) => {
    const itemSelected = historic[selected];
    const path = Paths.DetailHistoricWorkfloo.replace(
      ":idHistoryWorkfloo",
      itemSelected.idUnykoo,
    );
    const url = `${window.location.origin}${path}`;
    window.open(url, "_blank");
  };
  // End configuration table

  return (
    <div className="main-content">
      {isLoading ? (
        <SkeletonTable columns={7} primaryAction pagination />
      ) : (
        <PageSection>
          <PageSection.Item size={1} length={1}>
            {error && (
              <Alert key={`alert-error-historic`} code="danger" text={error} />
            )}
            <Table
              headings={tableHeaders}
              rows={rows}
              primaryAction={primaryAction}
              pagination={historic.length > 0 ? tablePagination : null}
              filters={filters}
              appliedFilters={appliedFilters}
              onFilter={onFilter}
              onSelectItem={onSelectItem}
              listFilters={listFilters}
              labelExtraActionFilter={"Agregar filtro"}
              textEmpty={"No se encontraron resultados"}
              filterTitle="Filtrar"
              filterPrimaryActionText="Aplicar"
              filterSecondaryActionText="Cancelar"
              filterTagsLabel="Filtrado por:"
              filterTagsListEditButtonText="Editar filtro"
            />
          </PageSection.Item>
        </PageSection>
      )}
    </div>
  );
}
