import { apiRequest, apiRoutes } from "../../../../services";

const saveConnector = async (connector, id, onFail) => {
  const [error, data] = await apiRequest({
    method: id ? "put" : "post",
    url: id
      ? apiRoutes.editConnector.replace(":connector", "NIP").replace(":id", id)
      : apiRoutes.addConnector.replace(":typeConnector", "NIP"),
    data: connector,
  });
  if (data !== null) {
    return data;
  }
  if (onFail) {
    onFail(error);
  }
};

export default saveConnector;
