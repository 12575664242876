import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Button from "@bit/kiban-design-system.kiban.button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import { apiRequest, apiRoutes } from "../../../services";
import AlertTexts from "../../../utils/alerts-texts";
import "./AddEditSTP.css";
import Paths from "../../../paths";
import Texts from "../../../utils/app-texts.json";

import connectorsTypes from "../connectorsTypes";

const AddEditSTP = () => {
  const [connector, setConnector] = useState(null);
  const [connectorTypes, setConnectorTypes] = useState(null);
  const [filteredConnectorTypes, setFilteredConnectorTypes] = useState(null);
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    service: false,
    mappingType: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSTP, setIsSTP] = useState(false);

  const routeParams = useParams();
  const history = useHistory();
  const { addAlert } = useAlert();

  const getTypes = async () => {
    const [error, types] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnectorTypeServices.replace(
        ":connectorType",
        connectorsTypes[routeParams.connectorType]
      ),
    });
    if (types) {
      const typesServices = {
        type: [],
        services: {},
        mappingTypes: {},
      };
      types.forEach((type) => {
        typesServices.type.push({
          content: type.type.langs.es,
          value: type.type.const,
        });
        if (type.services) {
          if (!typesServices.services[type.type.const])
            typesServices.services[type.type.const] = [];
          type.services.forEach((service) => {
            typesServices.services[type.type.const].push({
              content: service.langs.es,
              value: service.const,
            });
            if (service.mappingType) {
              if (!typesServices.mappingTypes[service.const])
                typesServices.mappingTypes[service.const] = [];
              typesServices.mappingTypes[service.const] =
                service.mappingType.map((mappingType) => ({
                  content: mappingType.langs.es,
                  value: mappingType.const,
                }));
            }
          });
        }
      });
      setConnectorTypes(typesServices);
      if (!routeParams.id) {
        setConnector({
          ...connector,
          type: typesServices.type.find((type) => type.value === "PAYMENTS")
            .value,
          service:
            typesServices.services[
              typesServices.type.find((type) => type.value === "PAYMENTS").value
            ][0].value,
        });
      }
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.tecnicalError,
      });
    }
    if (!routeParams.id) {
      setIsLoading(false);
    }
  };

  const getConnector = async () => {
    const [error, connector] = await apiRequest({
      method: "get",
      url: apiRoutes.getEditConnector
        .replace("/:typeConnector", "")
        .replace(":id", routeParams.id),
    });
    if (connector) {
      setIsSTP(connector.type === "STP");
      setConnector(connector);
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };

  const saveForm = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: routeParams.id ? "put" : "post",
      data: connector,
      url: routeParams.id
        ? apiRoutes.getEditConnector
            .replace(
              ":typeConnector",
              connectorsTypes[routeParams.connectorType]
            )
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(
            ":typeConnector",
            connectorsTypes[routeParams.connectorType]
          ),
    });
    if (data !== null) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
      if (!routeParams.id)
        history.replace(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (connector && connectorTypes) {
      let valueToFilter = connector.type;
      let filteredTypes = {
        ...connectorTypes,
        type: connectorTypes.type.filter(
          (type) => type.value === valueToFilter
        ),
      };
      setFilteredConnectorTypes(filteredTypes);
    }
  }, [connector, connectorTypes]);

  useEffect(() => {
    getTypes();
    if (routeParams.id) getConnector();
  }, []);

  const handleConnectorChange = (key, value) => {
    setConnector({
      ...connector,
      [key]: value,
    });
  };

  const validateForm = () => {
    let errors = {
      name: false,
      type: false,
      service: false,
      mappingType: false,
    };
    if (!connector.name) {
      errors.name = true;
    }
    if (!connector.type) {
      errors.type = true;
    }
    if (!connector.service) {
      errors.service = true;
    }
    if (
      connector.service &&
      connectorTypes.mappingTypes[connector.service] &&
      !connector.mappingType
    ) {
      errors.mappingType = true;
    }
    setErrors(errors);
    return (
      !errors.name && !errors.type && !errors.service && !errors.mappingType
    );
  };

  const handleSaveConnector = () => {
    if (validateForm()) {
      saveForm();
    }
  };

  const handleCancelConnector = () =>
    history.push(
      Paths.Connectors.replace(":connectorType", routeParams.connectorType)
    );

  const content = (
    <PageSection title="Pagos">
      <PageSection.Item
        size={1}
        length={3}
        title={Texts.connectors.fields.name}
      >
        <TextField
          value={connector?.name}
          onChange={(e) => handleConnectorChange("name", e.target.value)}
          error={errors.name}
          errorText="Debes completar este campo"
        />
      </PageSection.Item>
      {isSTP &&
        filteredConnectorTypes &&
        filteredConnectorTypes.type.find((type) => type.value === "STP") && (
          <>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item
              size={1}
              length={3}
              title={Texts.connectors.fields.connectorType}
            >
              <SelectField
                value={connector ? connector.type : ""}
                options={
                  filteredConnectorTypes ? filteredConnectorTypes.type : []
                }
                onChange={(type) => handleConnectorChange("type", type)}
                placeholder={Texts.connectors.fields.connectorTypePlaceholder}
                error={errors.type}
                errorText="Debes seleccionar un tipo de servicio de pagos"
                disabled={!isSTP}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {connector && connector.type && (
              <>
                <PageSection.Item
                  size={1}
                  length={3}
                  title={Texts.connectors.fields.serviceType}
                >
                  <SelectField
                    value={connector ? connector.service : ""}
                    options={
                      connectorTypes
                        ? connectorTypes.services[connector.type]
                        : []
                    }
                    onChange={(service) =>
                      handleConnectorChange("service", service)
                    }
                    disabled={!isSTP}
                    placeholder={Texts.connectors.fields.serviceTypePlaceholder}
                    error={errors.service}
                    errorText={`Debes seleccionar un tipo de servicio ${connector.type}`}
                  />
                </PageSection.Item>
                <PageSection.Item size={2} length={3} />
              </>
            )}
            {connector &&
              connector.service &&
              connectorTypes &&
              connectorTypes.mappingTypes[connector.service] && (
                <>
                  <PageSection.Item
                    size={1}
                    length={3}
                    title={`Configuración consulta ${connector?.service}`}
                  >
                    <SelectField
                      value={connector ? connector.mappingType : ""}
                      options={
                        connectorTypes
                          ? connectorTypes.mappingTypes[connector.service]
                          : []
                      }
                      onChange={(mappingType) =>
                        handleConnectorChange("mappingType", mappingType)
                      }
                      placeholder="Selecciona un tipo de mapeo"
                      error={errors.mappingType}
                      errorText={`Debes seleccionar un tipo de consulta ${connector.service}`}
                      disabled={!isSTP}
                    />
                  </PageSection.Item>
                  <PageSection.Item size={2} length={3} />
                </>
              )}
          </>
        )}
      <PageSection.Item size={1} length={1}>
        <div className="actions-container">
          <Button
            text="Guardar"
            style="primary"
            onClick={handleSaveConnector}
          />
          <Button text="Cancelar" onClick={handleCancelConnector} />
        </div>
      </PageSection.Item>
    </PageSection>
  );

  return (
    <div className="main-content" id="AddEditSTP">
      {isLoading ? <Skeleton /> : content}
    </div>
  );
};

export default AddEditSTP;
