import React, { useEffect, useState, useRef } from "react";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Textarea from "@bit/kiban-design-system.kiban.textarea";
import Title from "@bit/kiban-design-system.kiban.title";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import "../ConfigurationPage.css";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import ConfigChile from "./ConfigChile";

export default function ConfigurationOpenBAnking() {
  const [dataConfig, setDataConfig] = useState({});
  const [error, setError] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { addAlert } = useAlert();

  const getConfigOpenBanking = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "OPEN_BANKING"
      ),
    });
    if (!err) {
      const responseDataConfig =
        data.openBankingCOResponse ||
        data.openBankingMXResponse ||
        data.openBankingCLResponse;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig);
    let updateConfig = { ...dataConfig };
    keys.forEach((key) => {
      if (
        updateConfig[key].Activate === false ||
        updateConfig[key].ActivateFintoc === false
      ) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) =>
            ![
              "Activate",
              "WebhookUrl",
              "WebhookSecret",
              "ActivateFintoc",
            ].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          updateConfig[key][keyInternal] = "";
        });
      }
    });
    setDataConfig(updateConfig);
    return { openBankingRequest: { ...updateConfig } };
  };

  const updateConfigOpen = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "OPEN_BANKING"
      ),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigOpenBanking();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const handleOnChangeConfig = (key, attr, value) => {
    const updateConfig = { ...dataConfig };
    const updateObj = { ...updateConfig[key] };
    updateObj[attr] = value;
    updateConfig[key] = updateObj;
    const updateErrors = errorFields.includes(`${key}-${attr}`)
      ? errorFields.filter((keyError) => keyError !== `${key}-${attr}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    if (dataConfig.Finerio) {
      dataConfig.Finerio.FinerioPublicKey
        ? (dataConfig.Finerio.FinerioPublicKey = btoa(
            dataConfig.Finerio.FinerioPublicKey
          ))
        : (dataConfig.Finerio.FinerioPublicKey = "");
    }
    const keys = Object.keys(dataConfig);
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (updateConfig[key].Activate || updateConfig[key].ActivateFintoc) {
        const keysInternal = Object.keys(updateConfig[key]).filter(
          (k) =>
            !["Activate", "Prometeo", "Finerio", "ActivateFintoc"].includes(k)
        );
        keysInternal.forEach((keyInternal) => {
          if (
            updateConfig[key][keyInternal] === "" &&
            keyInternal !== "SandboxApiKey" &&
            key === "Arus" &&
            keyInternal !== "Url"
          ) {
            objErrors.push(`${key}-${keyInternal}`);
          }
        });
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigOpen();
    }
  };

  return loading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      <PageSection>
        {dataConfig.Prometeo && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Prometeo:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="prometeo"
                    value={true}
                    checked={dataConfig.Prometeo.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Prometeo",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="prometeo"
                    value={false}
                    checked={!dataConfig.Prometeo.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Prometeo",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.Prometeo.Activate && (
                <>
                  <TextField
                    error={errorFields.includes("Prometeo-ApiKey")}
                    errorText={"El campo no debe ir vacio"}
                    label="Prometeo Api Key"
                    value={dataConfig.Prometeo.ApiKey}
                    onChange={(e) =>
                      handleOnChangeConfig("Prometeo", "ApiKey", e.target.value)
                    }
                  />
                  <TextField
                    label="Prometeo API Key Sandbox:"
                    value={dataConfig.Prometeo.SandboxApiKey}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Prometeo",
                        "SandboxApiKey",
                        e.target.value
                      )
                    }
                  />
                </>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Arus && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Arus:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="arus"
                    value={true}
                    checked={dataConfig.Arus.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig("Arus", "Activate", value === "true")
                    }
                  />
                  <RadioButton
                    label="No"
                    name="arus"
                    value={false}
                    checked={!dataConfig.Arus.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig("Arus", "Activate", value === "true")
                    }
                  />
                </div>
              </div>
              {dataConfig.Arus.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("Arus-ApiKey")}
                    errorText={"El campo no debe ir vacio"}
                    label="Api key"
                    value={dataConfig.Arus.ApiKey}
                    onChange={(e) =>
                      handleOnChangeConfig("Arus", "ApiKey", e.target.value)
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Finerio && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Finerio:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="finerio"
                    value={true}
                    checked={dataConfig.Finerio.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="finerio"
                    value={false}
                    checked={!dataConfig.Finerio.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.Finerio.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("Finerio-Username")}
                    errorText={"El campo no debe ir vacio"}
                    label="Username"
                    value={dataConfig.Finerio.Username}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "Username",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("Finerio-Password")}
                    errorText={"El campo no debe ir vacio"}
                    label="Password"
                    value={dataConfig.Finerio.Password}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "Password",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("Finerio-ClientId")}
                    errorText={"El campo no debe ir vacio"}
                    label="id de Cliente"
                    value={dataConfig.Finerio.ClientId}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "ClientId",
                        e.target.value
                      )
                    }
                  />
                  <TextField
                    error={errorFields.includes("Finerio-ClientSecret")}
                    errorText={"El campo no debe ir vacio"}
                    label="Clave secreta"
                    value={dataConfig.Finerio.ClientSecret}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "ClientSecret",
                        e.target.value
                      )
                    }
                  />
                  <Textarea
                    error={errorFields.includes("Finerio-FinerioPublicKey")}
                    errorText={"El campo no debe ir vacio"}
                    label="Finerio Connect API Public Key"
                    value={dataConfig.Finerio.FinerioPublicKey}
                    onChange={(e) =>
                      handleOnChangeConfig(
                        "Finerio",
                        "FinerioPublicKey",
                        e.target.value
                      )
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Satws && (
          <>
            <PageSection.Item size={1} length={2}>
              <div className="rows">
                <Title type="heading">Activar Configuración sat.ws:</Title>
                <div className="container-inputs">
                  <RadioButton
                    label="Si"
                    name="satws"
                    value={true}
                    checked={dataConfig.Satws.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Satws",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                  <RadioButton
                    label="No"
                    name="satws"
                    value={false}
                    checked={!dataConfig.Satws.Activate}
                    onChange={(checked, value) =>
                      handleOnChangeConfig(
                        "Satws",
                        "Activate",
                        value === "true"
                      )
                    }
                  />
                </div>
              </div>
              {dataConfig.Satws.Activate && (
                <div>
                  <TextField
                    error={errorFields.includes("Satws-ApiKey")}
                    errorText={"El campo no debe ir vacio"}
                    label="sat.ws API Key"
                    value={dataConfig.Satws.ApiKey}
                    onChange={(e) =>
                      handleOnChangeConfig("Satws", "ApiKey", e.target.value)
                    }
                  />
                </div>
              )}
            </PageSection.Item>
            <PageSection.Item size={1} length={2} />
          </>
        )}
        {dataConfig.Fintoc && (
          <ConfigChile
            onChange={handleOnChangeConfig}
            dataConfig={dataConfig}
            errors={errorFields}
          />
        )}
        <PageSection.Item size={1} length={1}>
          <Button onClick={saveConfig} style="primary" text="Guardar" />
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
