import Verification from "./Verification/Verification";

const GenericNIP = ({
  messagingCatalog,
  connectorsCatalog,
  firstVerificationData,
  secondVerificationData,
  onChangteFirstVerification,
  onChangteSecondVerification,
  firstVerificationErrors,
  secondVerificationErrors,
  isLoading,
}) => {
  const secondverificationMessagingCatalog = [
    {
      value: null,
      content: "-- Selecciona un medio --",
    },
    ...messagingCatalog,
  ];
  return (
    <>
      <Verification
        title="Primera Verificación"
        messagingCatalog={messagingCatalog}
        connectorsCatalog={connectorsCatalog}
        data={firstVerificationData || {}}
        onChange={onChangteFirstVerification}
        errors={firstVerificationErrors}
        isLoading={isLoading}
        meanFilter={secondVerificationData.mean}
      />
      <Verification
        title="Segunda Verificación"
        messagingCatalog={secondverificationMessagingCatalog}
        connectorsCatalog={connectorsCatalog}
        data={secondVerificationData || {}}
        onChange={onChangteSecondVerification}
        errors={secondVerificationErrors}
        isLoading={isLoading}
        meanFilter={firstVerificationData.mean}
      />
    </>
  );
};

export default GenericNIP;
