import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Textarea from "@bit/kiban-design-system.kiban.textarea";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { createEditMessage } from "./utils";

import alertsTexts from "../../../../utils/alerts-texts";

import "./index.css";

const MessageModal = ({
  open,
  onClose,
  onApply,
  editMessage,
  isEdit,
  messageToEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [nameError, setNameError] = useState("");
  const { addAlert } = useAlert();
  const [errorsFields, setErrorFields] = useState({
    name: false,
    message: false,
  });

  const routeParams = useParams();

  useEffect(() => {
    if (isEdit) {
      setMessage(editMessage);
    } else {
      setMessage(null);
    }
  }, [open]);

  useEffect(() => {
    if (messageToEdit) {
      setMessage(messageToEdit);
    }
  }, [messageToEdit]);

  const saveMessage = async () => {
    setIsLoading(true);

    const stepId = await createEditMessage({
      stepId: message.id,
      message,
      policyId: routeParams.id,
      onError: (err) => {
        let errMessage = err.message || alertsTexts.unexpectedError;
        if (err.status === 409) {
          errMessage = alertsTexts.nameAlreadyExist;
        }
        addAlert({
          code: "danger",
          message: errMessage,
          duration: 5000,
        });
      },
    });
    if (onApply) {
      if (messageToEdit || (!messageToEdit && stepId)) {
        message.id = messageToEdit ? messageToEdit.id : stepId.id || stepId;
        onApply(message);
      }
    }
    setIsLoading(false);
  };

  const handleOnApply = () => {
    const updateErrors = {
      name: message ? !message.name || message.name === "" : true,
      message: message ? !message.message || message.messsage === "" : true,
    };
    if (updateErrors.name) {
      setNameError("El campo no puede estar vacio");
    }
    setErrorFields(updateErrors);
    if (!updateErrors.name && !updateErrors.message) {
      saveMessage();
    }
  };

  const primaryAction = {
    text: "Guardar",
    onAction: handleOnApply,
    disabled: isLoading,
    loading: isLoading,
  };

  const handleClose = () => {
    setMessage(null);
    onClose && onClose();
  };

  const cancelAction = {
    text: "Cancelar",
    onAction: handleClose,
    disabled: isLoading,
  };

  const handleChangeInput = (event) => {
    let updateMessage = { ...message };
    updateMessage[event.target.id] = event.target.value;
    setMessage(updateMessage);
  };

  return (
    <ModalScreen
      open={open}
      title="Mensaje"
      primaryAction={primaryAction}
      secondaryActions={[cancelAction]}
      onClose={handleClose}
    >
      <div
        key={`formulario-modal-predefined-variable`}
        className="ModalScreen_Variable--Container"
      >
        <PageSection.Item className="field" title="Ayuda" size={3} length={3}>
          <div className="help">
            <p>
              El mensaje que quieres recibir cuando la árbol de decisión llega a
              este resultado. Puedes usar :
            </p>
            <ul>
              <li>
                {
                  "{FORM:3}} : para poner el valor del campo de un formulario, el cual tiene el id 3"
                }
              </li>
              <li>
                {
                  "{{VPR:1}} : para poner el valor de la variable personalizada de respuesta número 1"
                }
              </li>
            </ul>

            <p>Ejemplo :</p>
            <ul>
              <li>
                {"Rechazado por edad mayor a 80 años. La edad es : {{VPR:1}}"}
              </li>
              <li>
                {
                  "Rechazado por número de hijos mayor a 2. Número de hijos: {{FORM:3}}"
                }
              </li>
            </ul>
          </div>
        </PageSection.Item>
        <PageSection.Item className="field" title="Nombre" size={1} length={3}>
          <TextField
            error={errorsFields.name}
            errorText={nameError}
            id="name"
            value={message ? message.name : ""}
            onChange={(event) => handleChangeInput(event)}
            disabled={isLoading}
          />
        </PageSection.Item>
        <PageSection.Item className="field" title="Mensaje" size={3} length={3}>
          <Textarea
            error={errorsFields.message}
            errorText="El campo no puede estar vacio"
            id="message"
            value={message ? message.message : ""}
            onChange={(event) => handleChangeInput(event)}
            disabled={isLoading}
          />
        </PageSection.Item>
      </div>
    </ModalScreen>
  );
};

export default MessageModal;
