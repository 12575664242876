import React, { useEffect } from "react";
import Routes from "./routes";
import ResetStyles from "@bit/kiban-design-system.styles.styles-reset";

import loadScreenRecorder from "./utils/screenRecorder";

/**
 *  Main App component
 * @returns {React.Component}
 */
function App() {
  useEffect(() => {
    loadScreenRecorder();
  }, []);
  return (
    <>
      <ResetStyles />
      <Routes />
    </>
  );
}

export default App;
