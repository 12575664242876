import React from "react";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";

const DeleteConfirmationModal = ({ onConfrmDelete, onCancelDelete, open }) => {
  const primaryAction = {
    text: "Eliminar",
    onAction: onConfrmDelete,
  };
  const secondaryAction = {
    text: "Cancelar",
    onAction: onCancelDelete,
  };
  return (
    <ModalScreen
      open={open}
      title="¿Estás seguro de eliminar este campo?"
      primaryAction={primaryAction}
      secondaryActions={[secondaryAction]}
      onClose={onCancelDelete}
    >
      <p>
        Al presionar 'Eliminar' se eliminará el campo de forma irreversible.
        Esta acción no se puede deshacer.
      </p>
    </ModalScreen>
  );
};

export default DeleteConfirmationModal;
