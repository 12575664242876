import React, { useState } from "react";
import "./Login.css";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";
import { validateEmail } from "../../utils/validators";

export default function VerifyEmail({
  sendVerifyEmail,
}) {
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const handleSendEmail = () => {
    if (validateEmail(email)) {
      setErrorEmail(false);
      sendVerifyEmail(email)
    } else {
      setErrorEmail(true);
    }
  };

  return (
    <div className="verifyEmail__container">
      <div className="verifyEmail__notice">
        <label>A partir de ahora Workfloo usará un correo electrónico para tu Login.</label>
      </div>
      <div className="verifyEmail__label">
        <label>Ayudanos a verificar tu correo y enviarémos un código de seguridad para continuar con tu acceso:</label>
      </div>
      <TextField
        label="Correo electrónico"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        placeholder="ejemplo@correo.com"
        error={errorEmail}
        errorText="Se debe ingresar un email válido"
      />
      <Button onClick={() => handleSendEmail()} style="light" text="Enviar Código" />
    </div>
  );
}
