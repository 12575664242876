import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import Button from "@bit/kiban-design-system.kiban.button";
import Title from "@bit/kiban-design-system.kiban.title";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import "./Login.css";
import Paths from "../../paths";
import logo from "./../../assets/workfloo-logo.png";
import infoSvg from "./../../assets/info.svg";
import VerifyEmail from "./VerifyEmail";
import VerifyCode from "./VerifyCode";
import { apiRoutes, apiRequest } from "./../../services";
import { useAuth } from "../../services/auth/AuthProvider";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";
import alertsTexts from "../../utils/alerts-texts";

export default function Login() {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { login, logout } = useAuth();
  const [password, setPassword] = useState("");
  const [company_code, setCompanyCode] = useState("");
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isVerifyCode, setIsVerifyCode] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const [loginErrorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [userError, setUserError] = useState(false);
  const [envError, setEnvError] = useState(false);

  const loginService = async () => {
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.login,
      data: {
        login: user,
        password,
      },
      headers: {
        company_code: company_code,
      },
    });
    if (data) {
      if (data.token) {
        login(data.token);
        setIsLoggedIn(true);
      }
      if (data?.status === "CONFIRMAR_EMAIL") {
        setIsVerifyEmail(true);
      }
    }
    if (error) {
      setError(true);
      if (error.message) {
        setErrorMessage("El login se debe hacer por email.");
      } else {
        setErrorMessage("El email o la contraseña son incorrectas.");
      }
    }
  };

  useEffect(() => {
    if (Jwt.getToken() !== null) {
      logout();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 5000);
    }
  }, [error]);

  function handleSubmit(event) {
    event.preventDefault();
    if (company_code === "") {
      setEnvError(true);
    }
    if (password === "") {
      setPasswordError(true);
    }
    if (user === "") {
      setUserError(true);
    } else {
      loginService();
    }
  }

  const handleOnForgoPasswordClick = () => {
    history.push(Paths.RecoveryPassword);
  };

  if (isLoggedIn) {
    history.push(Paths.StartWorkfloo);
  }

  const sendVerifyEmail = async (verifyEmail) => {
    setEmail(verifyEmail);
    setErrorCode(false);
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.validateEmail,
      data: {
        login: user,
        email: verifyEmail,
      },
      headers: {
        company_code: company_code,
      },
    });
    if (!response[0]) {
      setIsVerifyCode(true);
      setIsVerifyEmail(false);
    } else {
      let message = alertsTexts.unexpectedError;
      if (response[0]?.status === 409) {
        message = alertsTexts.emailAlreadyExist;
      }
      setError(true);
      setErrorMessage(message);
    }
  }

  const sendVerifyCode = async (code) => {
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.validateCode,
      data: {
        email,
        code,
      },
      headers: {
        company_code: company_code,
      },
    });
    if (!response[0]) {
      setIsVerifyCode(false);
    } else {
      setErrorCode(true);
    }
  }

  return (
    <div className="login-container">
      <div className="login-form">
        <img className="login-logo" src={logo} alt="logo"></img>
        {error && (
          <div className="login-error-container">
            <img src={infoSvg} alt="info-logo"></img>
            <span className="login-error">{loginErrorMessage}</span>
          </div>
        )}
        {!isVerifyEmail && !isVerifyCode &&
          <div className="form-container">
            <Title type="displaySmall">Iniciar Sesión</Title>
            <TextField
              label="Código de empresa"
              value={company_code}
              error={envError}
              errorText={"El campo es requerido"}
              id="standard-sandbox-input"
              onChange={(e) => setCompanyCode(e.target.value)}
            />
            <TextField
              label="Usuario"
              value={user}
              error={userError}
              errorText={"El campo es requerido"}
              onChange={(e) => setUser(e.target.value)}
            />
            <TextField
              label="Contraseña"
              value={password}
              error={passwordError}
              errorText={"El campo es requerido"}
              type="password"
              showPass={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ButtonGroup>
              <Button style="secondary" onClick={handleOnForgoPasswordClick} text="Olvidé mi contraseña"/>
              <Button style="primary" onClick={handleSubmit} text="Continuar"/>
            </ButtonGroup>
          </div>
        }
        {isVerifyEmail &&
          <VerifyEmail
            sendVerifyEmail={sendVerifyEmail}
          />
        }
        {isVerifyCode &&
          <VerifyCode
            sendVerifyEmail={sendVerifyEmail}
            sendVerifyCode={sendVerifyCode}
            email={email}
            error={errorCode}
          />
        }
      </div>
      <div className="login-side"></div>
    </div>
  );
}
