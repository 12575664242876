export default (files) => {
  const allFiles = [...files];
  const newFiles = allFiles.filter((file) =>
    file.name_file.includes("newFile")
  );
  allFiles.push({
    name_file: `newFile${
      newFiles.length > 0
        ? `_${newFiles.length < 10 ? "0" : ""}${newFiles.length}`
        : ""
    }.js`,
    data: "",
  });
  return allFiles;
};
