import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "./../../assets/workfloo-logo.png";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { apiRoutes, apiRequest } from "./../../services";
import alertsTexts from "../../utils/alerts-texts";
import infoSvg from "./../../assets/info.svg";

import "../Login/Login.css";

export default function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const token = location.search ? new URLSearchParams(location.search).get("token") : "";
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPassword = async () => {
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.resetPassword,
      data: {
        password: newPassword,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response[0]) {
      history.push("/login");
    } else {
      setError(true);
      setErrorMessage(alertsTexts.unexpectedError);
    }
  };

  const handleResetPassword = () => {
    setErrorNewPassword(newPassword === "");
    setErrorConfirmPassword(newPassword !== confirmPassword || confirmPassword === "");
    if (newPassword !== "" && confirmPassword !== "" && newPassword === confirmPassword) {
      resetPassword();
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 5000);
    }
  }, [error]);

  return (
    <div className="login-container">
      <div className="login-form">
        <img className="login-logo" src={logo} alt="logo"/>
        <Title type="heading">Reestablece tu contraseña</Title>
        {error && (
          <div className="login-error-container">
            <img src={infoSvg} alt="info-logo"></img>
            <span className="login-error">{errorMessage}</span>
          </div>
        )}
        <TextField
          label="Nueva contraseña"
          value={newPassword}
          type="password"
          showPass={true}
          onChange={(event) => setNewPassword(event.target.value)}
          error={errorNewPassword}
          errorText="Debes ingresar una contraseña"
        />
        <TextField
          label="Verifica tu nueva contraseña"
          value={confirmPassword}
          type="password"
          onChange={(event) => setConfirmPassword(event.target.value)}
          error={errorConfirmPassword}
          errorText={confirmPassword === "" ? "Debes ingresar una contraaseña" : "Tus contraseñas no coinciden intenta de nuevo."}
        />
        <ButtonGroup>
          <Button style="primary" onClick={handleResetPassword} text="Reestablecer"/>
        </ButtonGroup>
      </div>
      <div className="login-side"></div>
    </div>
  );
}
