import React, { useState, useEffect } from "react";
import Button from "@bit/kiban-design-system.kiban.button";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { PlusIcon, TrashIcon } from "@bit/kiban-design-system.kiban.icons";
import OptionField from "@bit/kiban-design-system.kiban.option-field";
import YMDInput from "@bit/kiban-design-system.kiban.ymd-input";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { operatorsTypes } from "../utils";
import { apiRequest, apiRoutes } from "../../../../../services";

import "./index.css";
import alertsTexts from "../../../../../utils/alerts-texts";

const Filters = ({
  onAddFilter,
  fields = [],
  andFilters,
  orFilters,
  onChange,
  catalogs,
  errors,
  catalogsNames,
}) => {
  const handleOnFilterChange = (value, key, id) => {
    let filters = andFilters.map((filter) => ({ ...filter }));
    if (orFilters && orFilters.length > 0) {
      filters = [...filters, ...orFilters.map((filter) => ({ ...filter }))];
    }
    const fitlerIndex = filters.findIndex((filter) => filter.id === id);
    filters[fitlerIndex][key] = value;
    if (key === "pathToField") {
      const operatorType = fields
        ? fields.find((field) => field.value === value)?.type
        : "";
      filters[fitlerIndex].operator = "";
      if (operatorType === "DATE") {
        filters[fitlerIndex].value = "0y,0m,0d";
      } else filters[fitlerIndex].value = "";
    }
    if (key === "operator") {
      const operatorType = fields
        ? fields.find(
            (field) => field.value === filters[fitlerIndex].pathToField
          )?.type
        : "";
      if (operatorType === "DATE") {
        filters[fitlerIndex].value = "0y,0m,0d";
      } else {
        filters[fitlerIndex].value = "";
      }
    }
    onChange && onChange(filters);
  };

  const handleDeleteFilter = (id) => {
    let filters = andFilters.map((filter) => ({ ...filter }));
    if (orFilters && orFilters.length > 0)
      filters = [...filters, ...orFilters.map((filter) => ({ ...filter }))];
    const fitlerIndex = filters.findIndex((filter) => filter.id === id);
    filters.splice(fitlerIndex, 1);
    onChange && onChange(filters);
  };

  return (
    <div className="filters-container">
      <div className="filters-section">
        <div className="filters-section-header">
          <label>Aplicar filtro en donde cumplan TODAS estas condiciones</label>
          <Button
            text="Añadir condición"
            style="light"
            icon={PlusIcon}
            onClick={() => onAddFilter && onAddFilter("AND")}
          />
        </div>
        <div className="filters-section-content">
          {andFilters && andFilters.length
            ? andFilters.map((filter, i) => (
                <FilterField
                  key={`and-filter-${i}`}
                  fields={fields}
                  selectedField={filter.pathToField}
                  selectedRule={filter.operator}
                  value={filter.value}
                  onChange={(value, key) =>
                    handleOnFilterChange(value, key, filter.id)
                  }
                  catalogs={catalogs}
                  onDelete={() => handleDeleteFilter(filter.id)}
                  catalogsNames={catalogsNames}
                  errors={
                    (errors &&
                      errors.find((error) => error.id === filter.id)) ||
                    undefined
                  }
                />
              ))
            : "No hay filtros para mostrar"}
        </div>
      </div>
      <div className="filters-section">
        <div className="filters-section-header">
          <label>Y que cumplan CUALQUIERA de estas condiciones</label>
          <Button
            text="Añadir condición"
            style="light"
            icon={PlusIcon}
            onClick={() => onAddFilter && onAddFilter("OR")}
          />
        </div>
        <div className="filters-section-content">
          {orFilters && orFilters.length
            ? orFilters.map((filter, i) => (
                <FilterField
                  key={`or-filter-${i}`}
                  fields={fields}
                  selectedField={filter.pathToField}
                  selectedRule={filter.operator}
                  value={filter.value}
                  onChange={(value, key) =>
                    handleOnFilterChange(value, key, filter.id)
                  }
                  catalogs={catalogs}
                  catalogsNames={catalogsNames}
                  onDelete={() => handleDeleteFilter(filter.id)}
                  errors={
                    (errors &&
                      errors.find((error) => error.id === filter.id)) ||
                    undefined
                  }
                />
              ))
            : "No hay filtros para mostrar"}
        </div>
      </div>
    </div>
  );
};

const FilterField = ({
  fields,
  selectedField = "",
  selectedRule = "",
  value = "",
  onChange,
  catalogs,
  catalogsNames,
  onDelete,
  errors,
}) => {
  const [options, setOptions] = useState([]);
  const [optionFieldValue, setOptionFieldValue] = useState([]);
  const [filteredFields, setFilteredFields] = useState(fields);
  const [fieldSearch, setFieldSearch] = useState("");

  useEffect(() => {
    setFilteredFields(fields);
  }, [fields]);

  const { addAlert } = useAlert();

  const operatorType =
    selectedField && fields
      ? fields.find((field) => field.value === selectedField)?.type
      : "";

  const getCatalogs = () => {
    return catalogs
      ? catalogs[selectedField].map((catalog) => ({
          label: catalog.content,
          value: catalog.value,
        }))
      : [];
  };

  const getCatalogsFromName = async () => {
    const [error, catalog] = await apiRequest({
      method: "GET",
      url: apiRoutes.getCatalog.replace(
        ":catalogName",
        catalogsNames[selectedField]
      ),
    });
    if (catalog) {
      return catalog.map((catalog) => ({
        label: catalog.value,
        value: catalog.key,
      }));
    }
    if (error) {
      addAlert({
        code: "danger",
        message: error.message || alertsTexts.unexpectedError,
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (operatorType !== "DATE") {
        if (
          selectedField &&
          ((catalogs && catalogs[selectedField]) ||
            (catalogsNames && catalogsNames[selectedField]))
        ) {
          setOptions(
            catalogs && catalogs[selectedField]
              ? getCatalogs()
              : catalogsNames && catalogsNames[selectedField]
              ? await getCatalogsFromName()
              : []
          );
        }
      }
    })();
  }, [operatorType, selectedField, catalogs]);

  useEffect(() => {
    if (operatorType === "SELECT" && value !== optionFieldValue.join(";")) {
      setOptionFieldValue(value.split(";"));
    }
  }, [value]);

  const handleOptionFieldSearchChange = async (value) => {
    if (value === "") {
      if (catalogs && catalogs[selectedField]) {
        setOptions(getCatalogs());
      }
      if (catalogsNames && catalogsNames[selectedField]) {
        setOptions(await getCatalogsFromName());
      }
      return;
    }
    const filterRegex = new RegExp(value, "i");
    const filteredOptions = (
      catalogs && catalogs[selectedField]
        ? getCatalogs()
        : catalogsNames &&
          catalogsNames[selectedField] &&
          (await getCatalogsFromName())
    ).filter((option) => option.label.match(filterRegex));
    setOptions(filteredOptions);
  };

  const getYMDValue = (value) => {
    if (value) {
      let [year, month, day] = value.split(",");
      if (!year) {
        year = "0y";
      }
      if (!month) {
        month = "0m";
      }
      if (!day) {
        day = "0d";
      }
      return {
        year: parseInt(year.replace("y", "")),
        month: parseInt(month.replace("m", "")),
        day: parseInt(day.replace("d", "")),
      };
    }
    return { year: 0, month: 0, day: 0 };
  };

  const handleOnOptionFieldRemoveSelection = (val) => {
    const allValues = optionFieldValue.filter((item) => item !== val);
    setOptionFieldValue(allValues);
    onChange && onChange(allValues.join(";"), "value");
  };

  const handleOptionFieldChange = (value) =>
    onChange && onChange(value.join(";"), "value");

  const handleFieldsSearch = (search) => {
    setFieldSearch(search);
    if (search === "") {
      setFilteredFields(fields);
    }
    const regex = new RegExp(search, "i");
    const filteredOptions = fields.filter((option) =>
      option.content.match(regex)
    );
    setFilteredFields(filteredOptions);
  };

  return (
    <div className="filter-field">
      <SelectField
        label="Campo"
        options={filteredFields}
        value={selectedField}
        placeholder="Selecciona un campo"
        onChange={(value) => onChange && onChange(value, "pathToField")}
        error={(errors && errors.pathToField) || undefined}
        errorText="Debes seleccionar un campo"
        search={fieldSearch}
        onSearch={handleFieldsSearch}
      />
      <SelectField
        label="Operador"
        options={operatorsTypes(operatorType)}
        value={selectedRule}
        placeholder="Selecciona un operador"
        onChange={(value) => onChange && onChange(value, "operator")}
        error={(errors && errors.operator) || undefined}
        errorText="Debes seleccionar un operador"
      />
      {selectedRule !== "IS_NULL" && selectedRule !== "IS_NOT_NULL" ? (
        operatorType !== "DATE" ? (
          selectedField &&
          ((catalogs && catalogs[selectedField]) ||
            (catalogsNames && catalogsNames[selectedField])) ? (
            <div className="option-field-container">
              <label>Valor</label>
              <OptionField
                label="Catalogo"
                items={options}
                selectedItems={optionFieldValue}
                onChange={handleOptionFieldChange}
                name={selectedField}
                searchPlaceHolder="Buscar"
                onSearchChange={handleOptionFieldSearchChange}
                onRemoveSelection={handleOnOptionFieldRemoveSelection}
                error={(errors && errors.value) || undefined}
                errorText="Debes seleccionar un valor"
              />
            </div>
          ) : (
            <TextField
              label="Valor"
              placeholder="Escribe el valor del filtro"
              value={value}
              onChange={(e) => onChange && onChange(e.target.value, "value")}
              error={(errors && errors.value) || undefined}
              errorText="Debes escribir un valor"
            />
          )
        ) : (
          <YMDInput
            label="Valor"
            value={getYMDValue(value)}
            onChangeFn={(e) => {
              onChange &&
                onChange(
                  `${e.year < 0 ? "" : "+"}${e.year}y,${
                    e.month < 0 ? "" : "+"
                  }${e.month}m,${e.day < 0 ? "" : "+"}${e.day}d`,
                  "value"
                );
            }}
            negative
          />
        )
      ) : (
        <div />
      )}
      <IconButton icon={TrashIcon} style="primary" onClick={onDelete} />
    </div>
  );
};

export default Filters;
