import React from "react";

import EditorPanel from "./EditorPanel";
import OutputPanel from "./OutputPanel";

const CenterPanel = ({ file, output, onCodeChange, onSave, isLoading }) => {
  return (
    <>
      <EditorPanel
        file={file}
        onCodeChange={onCodeChange}
        loading={isLoading}
      />
      <OutputPanel output={output} />
    </>
  );
};

export default CenterPanel;
