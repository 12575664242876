import { apiRequest, apiRoutes } from "../../../services";

/**
 *  primary action object
 * @param {Function} onAction On click event
 */
export const pagePrimaryAction = (onAction) => ({
  text: "Guardar",
  onAction,
});

/**
 *  secondary action object
 * @param {String} onAction On click event
 */
export const pageCancelAction = (onAction) => ({
  text: "Cancelar",
  onAction,
});

/**
 *  Function to get predefined fields types from API
 * @param {Function} onError On error event
 */
export const getPredefinedFieldsTypes = async ({ onError }) => {
  let types = [];
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getPredefinedTypes,
  });
  if (data) {
    types = data.map((type) => ({
      // if data is not empty then map it and convert the format to use in select fields
      value: type.const,
      content: type.langs.es,
    }));
  }
  if (error) {
    onError && onError(error);
  }
  return types;
};

/**
 *  Function to get predefined fields from API
 * @param {Object} form Sections and fields from the form
 * @param {String} type Predifined field type
 * @param {Function} onError On error event
 */
export const getPredefinedFields = async ({ form, type, onError }) => {
  const [error, data] = await apiRequest({
    method: "patch",
    url: apiRoutes.getPredefinedFields.replace(":id", type),
    data: form,
  });
  if (data) {
    return data;
  }
  if (error) {
    onError && onError(error);
  }
};

/**
 *  Function to get sections types from API
 * @param {Function} onError On error event
 */
export const getSectionTypes = async ({ onError }) => {
  let types = [];
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getSections,
  });
  if (data) {
    types = data.map((type) => ({
      // if data is not empty then map it and convert the format to use in select fields
      value: type.const,
      content: type.langs.es,
    }));
  }
  if (error) {
    onError && onError(error);
  }
  return types;
};

/**
 *  Function to get section name from the section types catalog
 * @param {String} sectionValue Section type value
 * @param {Object} sections  Sections types catalog
 */
export const getSectionName = (sectionValue, sections) => {
  if (!sectionValue) return;
  return sections.find((section) => section.value === sectionValue)?.content;
};

/**
 *  Event handler to manage the DnD event of the fields
 * @param {Object} draggableContainer DOM element
 * @param {Object} evt Event object
 * @param {Object} form Form object
 */
export const sort = (draggableContainer, evt, form) => {
  let newContainerIndex = Array.from(draggableContainer.children).findIndex(
    (item) => {
      return item.id === evt.data.newContainer.id;
    }
  ); // get the new container index
  let oldContainerIndex = Array.from(draggableContainer.children).findIndex(
    (item) => item.id === evt.data.oldContainer.id
  ); // get the old container index
  let newForm = form.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
    })),
  })); // create a new form with the same sections and fields
  let fieldToChange = {
    ...newForm[oldContainerIndex].fields[evt.data.oldIndex],
  }; // get the field to change
  fieldToChange.section = newForm[newContainerIndex].section; // change the section of the field
  newForm[oldContainerIndex].fields.splice(evt.data.oldIndex, 1); // remove the field from the old container
  newForm[newContainerIndex].fields.splice(evt.data.newIndex, 0, fieldToChange); // add the field to the new container
  if (!newForm[oldContainerIndex].fields.length) {
    // if the old container is empty
    newForm.splice(oldContainerIndex, 1); // remove the section
  }
  return newForm;
};

/**
 * Field types catalog
 */
export const fieldTypes = [
  {
    content: "Alfabético",
    value: "ALPHABETIC",
  },
  {
    content: "Alfanumérico",
    value: "ALPHANUMERIC",
  },
  {
    content: "Archivo",
    value: "PICTURE",
  },
  {
    content: "Botón de opción",
    value: "RADIO",
  },
  {
    content: "Código postal",
    value: "POSTAL_CODE",
  },
  {
    content: "Correo electrónico",
    value: "EMAIL",
  },
  {
    content: "Fecha",
    value: "DATE",
  },
  {
    content: "Menú desplegable",
    value: "LIST",
  },
  {
    content: "Numérico",
    value: "NUMERIC",
  },
  {
    content: "Teléfono",
    value: "PHONE",
  },
  {
    content: "Texto",
    value: "TEXT",
  },
  {
    content: "Texto Informativo",
    value: "INFORMATIVE_TEXT",
  },
  {
    content: "Ubicación GPS",
    value: "GEOLOC",
  },
];

/**
 *  Function to save the form to the API
 * @param {Object} form Form object
 * @param {String} id If the form is an update then the id is required
 * @param {Function} onError On error event
 */
export const saveForm = async ({ form, id, onError }) => {
  const [error, data] = await apiRequest({
    method: id ? "put" : "post",
    url: id ? apiRoutes.editForm.replace(":id", id) : apiRoutes.addForm,
    data: form,
  });
  if (data !== null) {
    return data;
  }
  if (error) {
    onError && onError(error);
  }
};

/**
 *  Function to get the form from the API
 * @param {String} id Form id
 * @param {Function} onError On error event
 */
export const getForm = async ({ id, onError }) => {
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getConnector.replace(":id", id),
  });
  if (data) {
    return data;
  }
  if (error) {
    onError && onError(error);
  }
};

/**
 *  Function to get the field id from the API
 * @param {Function} onError
 */
export const getFieldId = async ({ onError }) => {
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getCustomFieldId,
  });
  if (data) {
    return data.customFormFieldId;
  }
  if (error) {
    onError && onError(error);
  }
};

/**
 *  Function to convert listOptions or catalog or casosFicticios props in catalogs
 * @param {Array} options
 */
export const getFieldOptions = (options) => {
  const opts = options
    ? options.map((option) => {
        let opt = {};
        if (typeof option === "object") {
          opt.value = option.const || option.value;
          opt.label = option.const || option.value;
          opt.content =
            option.langs.es || option.langs["N/T"] || option.content;
        } else {
          opt.value = option;
          opt.label = option;
          opt.content = option;
        }
        return opt;
      })
    : [];
  return opts;
};
