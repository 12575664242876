import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Alert from "@bit/kiban-design-system.kiban.alert";
import Dropdown from "@bit/kiban-design-system.kiban.dropdown";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import Table from "@bit/kiban-design-system.kiban.table";
import { PlusIcon, TrashIcon } from "@bit/kiban-design-system.kiban.icons";

import { apiRequest, apiRoutes } from "../../services";
import Paths from "../../paths";
import Jwt from "../../utils/jwt";
import useTablePagination from "../../utils/useTablePagination";
import useSearch from "../../utils/useTableSearch";

export default function CasosFicticios() {
  const limit = "10000";
  const [itemToDelete, setItemToDelete] = useState(-1);
  const [openAlert, setOpenAlert] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [totalCasos, setTotalCasos] = useState(0);
  const [casosFicticios, setCasosFicticios] = useState([]);
  const [typesServices, setTypesServices] = useState([]);
  const [optionsTypes, setOptionsTypes] = useState([]);
  const [typeSelected, setTypeSelected] = useState(null);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [optionsServices, setOptionsServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState("");

  const history = useHistory();

  const { filteredData } = useSearch({
    data: casosFicticios,
    search,
  });

  const getDatosFicticios = async (params = "") => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.casosFicticios}?limit=${limit}&skip=0${params}`,
    });
    if (!err) {
      setCasosFicticios(data.casosFicticios ? data.casosFicticios : []);
      setTotalCasos(data.casosFicticios ? data.casosFicticios.length : 0);
      if (params !== "") {
        setStatus({
          code: "success",
          text: "Filtraron correctamente los datos.",
        });
      }
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrió un error inesperado, intente de nuevo.",
      });
    }
    setIsLoading(false);
  };

  const deleteDatosFicticios = async (id) => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      method: "delete",
      url: `${apiRoutes.casosFicticios}?id=${id}`,
    });
    if (!err) {
      const allCasos = [...casosFicticios];
      allCasos.splice(itemToDelete, 1);
      setCasosFicticios(allCasos);
      setTotalCasos(allCasos.length);
      setStatus({
        code: "success",
        text: "Se eliminó correctamente el registro.",
      });
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrió un error inesperado, intente de nuevo.",
      });
    }
    setIsLoading(false);
  };

  const getTypesServices = async () => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getCatalogTypesServices,
    });
    if (!err) {
      setTypesServices(data);
      const options = data.map((item) => {
        return {
          content: item.type.langs.es,
          value: item.type.const,
        };
      });
      setOptionsTypes(options);
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrió un error inesperado, intente de nuevo.",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getTypesServices();
      getDatosFicticios();
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    setTotalCasos(filteredData.length);
  }, [filteredData]);

  // Start configuration table
  // Pagination table
  const {
    from,
    goBack,
    goForward,
    hasNext,
    hasPrevious,
    page,
    skipBack,
    skipForward,
    to,
  } = useTablePagination({
    initialPage: 1,
    rowsPerPage: limit,
    totalItems: totalCasos,
  });

  const tablePagination = {
    current: from,
    results: to,
    total: totalCasos,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: skipBack,
    onSkipForward: skipForward,
  };

  const handleOnChangeOptionsTypes = (value) => {
    const typeServiceSelected = typesServices.find(
      (item) => item.type.const === value
    );
    setTypeSelected({
      text: typeServiceSelected.type.langs.es,
      value: typeServiceSelected.type.const,
    });
    const services = typeServiceSelected.services
      ? typeServiceSelected.services.map((service) => {
          return {
            content: service.langs.es,
            value: service.const,
          };
        })
      : [];
    setOptionsServices(services);
    setServiceSelected(null);
  };

  const onRemoveOptionsTypes = () => {
    setTypeSelected(null);
  };

  const handleOnChangeOptionsServices = (value) => {
    const serviceSelected = typesServices
      .find((item) => item.type.const === typeSelected.value)
      .services.find((item) => item.const === value);
    setServiceSelected({
      text: serviceSelected.langs.es,
      value: serviceSelected.const,
    });
  };

  const onRemoveOptionsService = () => {
    setServiceSelected(null);
  };

  const filters = [
    {
      key: "type",
      label: "Tipo",
      value: typeSelected,
      filter: (
        <SelectField
          placeholder="Tipo"
          onChange={handleOnChangeOptionsTypes}
          value={typeSelected && typeSelected.value}
          options={optionsTypes}
        />
      ),
      onRemove: onRemoveOptionsTypes,
    },
    {
      key: "service",
      label: "Servicio",
      value: serviceSelected,
      filter: (
        <SelectField
          placeholder={
            optionsServices.length > 0
              ? "Servicio"
              : typeSelected && typeSelected.text
              ? `${typeSelected.text} no contiene servicios`
              : "Seleccione un servicio"
          }
          disabled={optionsServices.length === 0}
          onChange={handleOnChangeOptionsServices}
          value={serviceSelected && serviceSelected.value}
          options={optionsServices}
        />
      ),
      onRemove: onRemoveOptionsService,
    },
  ];

  const listFilters = filters
    .filter((filter) => appliedFilters.includes(filter.key))
    .map((filter) => {
      return {
        key: filter.key,
        value: (
          <span>
            {filter.label}: <strong>{filter.value.text}</strong>
          </span>
        ),
      };
    });

  const resetValues = (filtersReset) => {
    filtersReset.forEach((filter) => {
      filter.onRemove && filter.onRemove();
    });
  };

  const isEmpty = (value) => {
    return value === "" || value === null || value === undefined;
  };

  const onFilter = (selectedFilters) => {
    const typeDeleted = !selectedFilters.includes("type");
    const resetFilters = typeDeleted
      ? filters
      : filters.filter((filter) => !selectedFilters.includes(filter.key));
    resetValues(resetFilters);
    const filtersNoEmpty = typeDeleted
      ? []
      : filters
          .filter(
            (filter) =>
              selectedFilters.includes(filter.key) &&
              !isEmpty(filter.value.value)
          )
          .map((filter) => filter.key);
    setAppliedFilters(filtersNoEmpty);
    const params = typeDeleted
      ? ""
      : filters
          .filter(
            (filter) =>
              selectedFilters.includes(filter.key) &&
              !isEmpty(filter.value.value)
          )
          .map((filter) => {
            return `&${filter.key}=${filter.value.value}`;
          })
          .join("");
    getDatosFicticios(params);
  };
  // end pagination table

  const onDeleteItem = (index) => {
    setItemToDelete(limit * (page - 1) + index);
    setOpenAlert(true);
  };

  const alertPrimaryAction = {
    text: "Sí, eliminar",
    onAction: (e) => {
      deleteDatosFicticios(casosFicticios[itemToDelete].id);
      closeAlert();
    },
  };

  const alertCancelAction = {
    text: "No, cancelar",
    onAction: (e) => {
      closeAlert();
    },
  };

  const closeAlert = () => {
    setOpenAlert(false);
    setItemToDelete(-1);
  };

  const ActionsDropdown = ({ index, onDuplicate, onDelete }) => {
    return (
      <Dropdown text="Acciones">
        <Dropdown.Section
          options={[
            {
              content: "Duplicar",
              onAction: (e) => {
                onDuplicate && onDuplicate(index);
              },
            },
          ]}
        />
        <Dropdown.Section
          options={[
            {
              content: "Eliminar",
              icon: TrashIcon,
              onAction: (e) => {
                onDelete && onDelete(index);
              },
            },
          ]}
        />
      </Dropdown>
    );
  };

  const tablePrimaryAction = {
    icon: PlusIcon,
    label: "Nuevo caso ficticio",
    text: "Nuevo caso ficticio",
    onAction: () => history.push(Paths.AddCasosFicticios),
  };

  const onDuplicateItem = async (index) => {
    const id = casosFicticios[limit * (page - 1) + index].id;
    setIsLoading(true);
    const response = await apiRequest({
      method: "post",
      url: `${apiRoutes.duplicateCasoFicticio}?id=${id}`,
    });
    if (!response[0]) {
      getDatosFicticios();
      setStatus({
        code: "success",
        text: "Se duplico con éxito el registro.",
      });
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrio un error inesperado, intenta de nuevo.",
      });
    }
    setIsLoading(false);
  };

  const getNameType = (code) => {
    if (typesServices.length > 0 && code !== "") {
      const type = typesServices.find((item) => item.type.const === code);
      return type ? type.type.langs.es : code;
    }
    return code;
  };

  const getNameService = (codeType, code) => {
    if (typesServices.length > 0 && codeType !== "" && code !== "") {
      const type = typesServices.find((item) => item.type.const === codeType);
      return type
        ? type.services.find((service) => service.const === code).langs.es
        : code;
    }
    return code;
  };

  const rows =
    casosFicticios.length > 0
      ? filteredData
          .slice(limit * (page - 1), limit * page)
          .map((item, i) => [
            item.name,
            item.type ? getNameType(item.type) : "",
            item.service ? getNameService(item.type, item.service) : "",
            item.created && item.created !== ""
              ? moment(item.created).format("DD-MM-YYYY HH:mm")
              : "",
            item.modified && item.modified !== ""
              ? moment(item.modified).format("DD-MM-YYYY HH:mm")
              : "",
            <ActionsDropdown
              index={i}
              onDuplicate={() => onDuplicateItem(i)}
              onDelete={() => onDeleteItem(i)}
            />,
          ])
      : [];

  const tableHeaders = [
    "Nombre",
    "Tipo",
    "Servicio",
    "Creación",
    "Modificación",
    "",
  ];

  const onSelectItem = (index) => {
    const itemSelected = filteredData[limit * (page - 1) + index];
    history.push(Paths.EditCasosFicticios.replace(":id", itemSelected.id));
  };

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  const handleSearch = (search) => {
    setSearch(search);
  };

  return isLoading ? (
    <SkeletonTable columns={6} headers title primaryAction pagination />
  ) : (
    <div className="main-content">
      {status && <Alert {...status} />}
      <PageSection title="Casos Ficticios">
        <PageSection.Item size={1} length={1}>
          <ModalScreen
            open={openAlert}
            title={"¿Esta seguro que quiere eliminar este caso ficticio?"}
            primaryAction={alertPrimaryAction}
            secondaryActions={[alertCancelAction]}
            onClose={closeAlert}
          >
            {"Esta acción es irreversible, se eliminará de forma permanente"}
          </ModalScreen>
          <Table
            headings={tableHeaders}
            onSearch={setSearch}
            search={search}
            searchTitle="Buscar"
            rows={rows}
            pagination={casosFicticios.length > 0 ? tablePagination : null}
            primaryAction={tablePrimaryAction}
            textEmpty={"No se encontraron resultados"}
            filters={filters}
            appliedFilters={appliedFilters}
            listFilters={listFilters}
            onFilter={onFilter}
            onSelectItem={onSelectItem}
            labelExtraActionFilter={"Filtrar por"}
            filterTitle="Filtrar"
            filterPrimaryActionText="Aplicar"
            filterSecondaryActionText="Cancelar"
            filterTagsLabel="Filtrado por:"
            filterTagsListEditButtonText="Editar filtro"
          />
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
