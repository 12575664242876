import React, { useEffect, useRef, useState } from "react";
import {
  FileIcon,
  MoreVerticalIcon,
  EditIcon,
  TrashIcon,
} from "@bit/kiban-design-system.kiban.icons";
import Popover from "@bit/kiban-design-system.kiban.popover";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import ActionList from "@bit/kiban-design-system.kiban.action-list";

const File = ({
  name,
  onClick,
  onFileNameChange,
  onDeleteFile,
  error,
  errorText,
  selected
}) => {
  const [tempName, setTempName] = useState(name);
  const [showActionsList, setShowActionsList] = useState(false);
  const [isFileNameEditable, setIsFileNameEditable] = useState(false);

  useEffect(() => {
    if (!error) {
      setTempName(name);
    }
  }, [error, name]);

  const inputRef = useRef();

  const togglePopover = () => {
    setShowActionsList(!showActionsList);
  };

  const toggleIsFileNameEditable = () => {
    if (error) {
      onFileNameChange && onFileNameChange(tempName);
    }
    setIsFileNameEditable(!isFileNameEditable);
  };

  const handleOnBlur = () => {
      toggleIsFileNameEditable();
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      toggleIsFileNameEditable();
    }
  };

  useEffect(() => {
    if (isFileNameEditable) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isFileNameEditable]);

  const activator = (
    <IconButton
      icon={MoreVerticalIcon}
      onClick={togglePopover}
      style="secondary"
    />
  );

  return (
    <div className={`file-container${selected ? ' active':''}`} onClick={onClick}>
      <FileIcon />
      <div className="file-name-container">
        {isFileNameEditable ? (
          <input
            ref={inputRef}
            type="text"
            className={`file-name${error ? " error" : ""}`}
            value={name}
            onKeyUp={handleKeyUp}
            onBlur={handleOnBlur}
            onChange={(e) => {
              onFileNameChange && onFileNameChange(e.target.value);
            }}
          />
        ) : (
          <label>{name}</label>
        )}
        {error && errorText && (
          <div className="tooltip-indicator">{errorText}</div>
        )}
      </div>
      <Popover
        activator={activator}
        position="right"
        active={showActionsList}
        onClose={togglePopover}
      >
        <ActionList
          onActionAnyItem={togglePopover}
          items={[
            [
              {
                content: "Renombrar",
                icon: EditIcon,
                onAction: toggleIsFileNameEditable,
              },
            ],
            [
              {
                content: "Eliminar",
                icon: TrashIcon,
                onAction: onDeleteFile,
              },
            ],
          ]}
        />
      </Popover>
    </div>
  );
};

export default File;
