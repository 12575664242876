import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Button from "@bit/kiban-design-system.kiban.button";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";

import { apiRequest, apiRoutes } from "../../../../services";

import Texts from "../../../../utils/app-texts.json";
import Jwt from "../../../../utils/jwt";
import PFAEField from "./PFAEField";
import AlertTexts from "../../../../utils/alerts-texts";
import connectorsTypes from "../../connectorsTypes";
import PFPMForm from "./PFPMForm";
import ExperianForm from "./ExperianForm";

import "./AddEditBuro.css";
import Paths from "../../../../paths";

const NIP_UNYKOO = "NIP_UNYKOO";
const NIP_UNYKOO_CATALOG_OPTION = {
  content: "Nip generado por Workfloo",
  value: NIP_UNYKOO,
};

const AddEditBuro = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [services, setServices] = useState({});
  const [selectedService, setSelectedService] = useState("");
  const [configs, setConfigs] = useState({});
  const [selectedConfigs, setSelectedConfigs] = useState({});
  const [PFAE, setPFAE] = useState(null);
  const [country, setCountry] = useState("");
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState("");
  const [messagingTypes, setMessagingTypes] = useState({});
  const [selectedSMSType, setSelectedSMSType] = useState("");
  const [selectedEmailType, setSelectedEmailType] = useState("");
  const [user, setUser] = useState("");
  const [userKey, setUserKey] = useState("");
  const [userSandbox, setUserSandbox] = useState("");
  const [userKeySandbox, setUserKeySandbox] = useState("");
  const [politicId, setPoliticId] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    service: false,
    user: false,
    userKey: false,
    politicId: false,
  });

  const routeParams = useParams();
  const history = useHistory();
  const { addAlert } = useAlert();

  const getTypes = async () => {
    const [error, types] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnectorTypeServices.replace(
        ":connectorType",
        "CONSULTA"
      ),
    });
    if (types) {
      let opts = [];
      let services = {};
      let configs = {};
      types.forEach((type) => {
        opts.push({
          value: type.type.const,
          content: type.type.langs.es,
        });
        if (type.services) {
          services[type.type.const] = [];
          type.services.forEach((service) => {
            services[type.type.const].push({
              value: service.const,
              content: service.langs.es,
            });
            if (service.catalogs) {
              service.catalogs.forEach((catalog) => {
                if (!configs.hasOwnProperty(type.type.const))
                  configs[type.type.const] = {};
                if (!configs[type.type.const].hasOwnProperty(service.const))
                  configs[type.type.const][service.const] = [];
                configs[type.type.const][service.const].push({
                  name: catalog.type.langs.es,
                  key: catalog.type.const,
                  pfae: isPFAEField(catalog.type.const),
                  options: catalog.catalog.map((opt) => ({
                    value: opt.const,
                    content: opt.langs.es,
                  })),
                });
              });
            }
          });
        }
        if (type.catalogs) {
          type.catalogs.forEach((catalog) => {
            if (!configs.hasOwnProperty(type.type.const))
              configs[type.type.const] = [];
            configs[type.type.const].push({
              name: catalog.type.langs.es,
              key: catalog.type.const,
              pfae: isPFAEField(catalog.type.const),
              options: catalog.catalog.map((opt) => ({
                value: opt.const,
                content: opt.langs.es,
              })),
            });
          });
        }
      });
      setConfigs(configs);
      setServices(services);
      setTypes(opts);
    }
    if (error) {
      addAlert({
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    if (!routeParams.id) {
      setIsLoading(false);
    }
  };

  const getMessagingTypes = async () => {
    const [error, types] = await apiRequest({
      method: "get",
      url: `${apiRoutes.connectors.replace(
        "{type}",
        connectorsTypes.messaging
      )}&default=true`,
    });
    if (types) {
      let messaging = { sms: [], email: [] };
      types.forEach((type) => {
        if (
          type.type === "QUIUBAS" ||
          type.type === "TWILIO" ||
          type.type === "SENDGRID"
        )
          messaging[
            type.type === "QUIUBAS" || type.type === "TWILIO" ? "sms" : "email"
          ].push({
            value: type.id,
            content: type.name,
          });
      });
      setMessagingTypes(messaging);
    }
    if (error) {
      addAlert({
        code: "danger",
        message:
          "Ocurrió un error intentando obtener los conectores de mensajería",
      });
    }
  };

  const saveConnector = async () => {
    let connector = {
      name,
      type: selectedType,
      service: selectedService,
      ...selectedConfigs,
    };
    if (country === "MX" && selectedType === "BC" && selectedService === "PM") {
      connector.pfae = PFAE;
    }
    if (
      (selectedService === "PM" || selectedService === "PF") &&
      selectedConfigs.authMethod &&
      selectedConfigs.authMethod === "NIP_UNYKOO"
    ) {
      connector = {
        ...connector,
        termsAndConditionsUrl,
        connectorSmsId: selectedSMSType,
        connectorEmailId: selectedEmailType,
      };
    }
    if (selectedType === "EXPERIAN") {
      connector = {
        ...connector,
        usuario: user,
        clave: userKey,
        usuarioSandbox: userSandbox,
        claveSandbox: userKeySandbox,
      };
    }
    if (selectedType === "TRANSUNION" && selectedService === "CREDIT_VISION") {
      connector = {
        ...connector,
        idPolitica: politicId,
      };
    }
    const [error, data] = await apiRequest({
      method: routeParams.id ? "put" : "post",
      url: !routeParams.id
        ? apiRoutes.addConnector.replace(":typeConnector", selectedType)
        : apiRoutes.editConnector
            .replace(":connector", selectedType)
            .replace(":id", routeParams.id),
      data: connector,
    });
    if (data !== null) {
      if (!routeParams.id) {
        history.replace(
          Paths.EditConnectors.replace(
            ":connectorType",
            routeParams.connectorType
          ).replace(":id", data.id)
        );
      }
      addAlert({
        code: "success",
        message: AlertTexts.success,
      });
    }
    if (error) {
      let message = AlertTexts.tecnicalError;
      if (error.status === 409) {
        message = AlertTexts.nameAlreadyExist;
      }
      addAlert({
        code: "danger",
        message: message,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  const getConnector = async () => {
    await getTypes();
    const [error, connector] = await apiRequest({
      method: "get",
      url: apiRoutes.getConnector.replace(":id", routeParams.id),
    });
    if (connector) {
      setName(connector.name);
      setSelectedType(connector.type);
      setSelectedService(connector.service || "");
      setPFAE(connector.pfae || false);
      if (connector.type === "EXPERIAN") {
        setUser(connector.usuario);
        setUserKey(connector.clave);
        setUserSandbox(connector.usuarioSandbox);
        setUserKeySandbox(connector.claveSandbox);
        setSelectedConfigs({
          producto: connector.product,
        });
      }
      if (
        connector.type === "TRANSUNION" &&
        connector.service === "CREDIT_VISION"
      ) {
        setPoliticId(connector.idPolitica);
      }
      if (connector.type === "BC" || connector.type === "CC") {
        if (connector.authMethod === "NIP_UNYKOO") {
          setTermsAndConditionsUrl(connector.termsAndConditionsUrl);
          setSelectedSMSType(connector.connectorSmsId);
          setSelectedEmailType(connector.connectorEmailId);
        }
      }
      let configs = {};
      Object.keys(connector).forEach((key) => {
        if (
          key !== "name" &&
          key !== "type" &&
          key !== "service" &&
          key !== "pfae" &&
          key !== "id" &&
          key !== "created" &&
          key !== "modified"
        ) {
          configs[key] = connector[key];
        }
      });
      setSelectedConfigs(configs);
    }
    if (error) {
      addAlert({
        code: "danger",
        message: AlertTexts.tecnicalError,
      });
    }
    setIsLoading(false);
  };

  const isPFAEField = (field) => {
    return (
      field === "requestScoreShareholder" || field === "typeScoreShareholder"
    );
  };

  useEffect(() => {
    setCountry(Jwt.getItem("country"));
    getTypes();
    getMessagingTypes();
    if (routeParams.id) {
      getConnector();
    }
  }, []);

  const handleChangeType = (val) => {
    setSelectedService("");
    setSelectedType(val);
  };

  const handleChangeService = (val) => {
    setPFAE(false);
    setSelectedConfigs({});
    setSelectedService(val);
  };

  const handlePFAEField = (value) => {
    setPFAE(value);
    setSelectedConfigs({});
  };

  const handleOnChangeCatalog = (key, val) => {
    setSelectedConfigs({
      ...selectedConfigs,
      [key]: val,
    });
  };
  const configsComponents = [];
  if (
    selectedService &&
    Object.keys(configs).length > 0 &&
    (Array.isArray(configs[selectedType][selectedService]) ||
      Array.isArray(configs[selectedType]))
  ) {
    if (Object.keys(configs).length) {
      let selectedConfigsObj = {};
      (configs[selectedType][selectedService] || configs[selectedType]).forEach(
        (conf) => {
          if (PFAE && conf.pfae) return;
          selectedConfigsObj = {
            ...selectedConfigsObj,
            [conf.key]: conf.options[0].value,
          };
          if (conf.options.length > 1) {
            let opts = [...conf.options];
            configsComponents.push(
              <>
                <PageSection.Item size={2} length={3} />
                <PageSection.Item size={1} length={3}>
                  <SelectField
                    label={conf.name}
                    options={opts}
                    onChange={(val) => handleOnChangeCatalog(conf.key, val)}
                    placeholder="Seleccionar"
                    value={selectedConfigs[conf.key] || conf.options[0].value}
                    disabled={isLoading}
                  />
                </PageSection.Item>
                {routeParams.id &&
                  (selectedService === "PM" || selectedService === "PF") &&
                  conf.key === "authMethod" &&
                  selectedConfigs.authMethod === "NIP_UNYKOO" && (
                    <PFPMForm
                      termsAndConditionsUrl={termsAndConditionsUrl}
                      onChangeTermsConditionsUrl={setTermsAndConditionsUrl}
                      termsAndConditionsUrlError={errors.termsAndConditionsUrl}
                      selectedSMSType={selectedSMSType}
                      onChangeSelectedSMSType={setSelectedSMSType}
                      smsTypeError={errors.smsType}
                      selectedEmailType={selectedEmailType}
                      onChangeEmailType={setSelectedEmailType}
                      messagingTypes={messagingTypes}
                      emailTypeError={errors.emailType}
                    />
                  )}
              </>
            );
          }
        }
      );
      if (!Object.keys(selectedConfigs).length)
        setSelectedConfigs(selectedConfigsObj);
    }
  }

  const handleOnSave = () => {
    if (isFormValid()) {
      setIsLoading(true);
      saveConnector();
    }
  };

  const isFormValid = () => {
    let formErrors = { ...errors };
    if (!name) {
      formErrors.name = true;
    } else {
      formErrors.name = false;
    }
    if (!selectedType) {
      formErrors.type = true;
    } else {
      formErrors.type = false;
    }
    if (services && selectedType && !selectedService) {
      formErrors.service = true;
    } else {
      formErrors.service = false;
    }
    if (selectedType === "EXPERIAN") {
      if (!user) {
        formErrors.user = true;
      } else {
        formErrors.user = false;
      }
      if (!userKey) {
        formErrors.userKey = true;
      } else {
        formErrors.userKey = false;
      }
    }
    if (
      selectedType === "TRANSUNION" &&
      selectedService === "CREDIT_VISION" &&
      !politicId
    ) {
      formErrors.politicId = true;
    } else {
      formErrors.politicId = false;
    }
    setErrors(formErrors);
    return (
      !formErrors.name &&
      !formErrors.type &&
      !formErrors.service &&
      !formErrors.user &&
      !formErrors.userKey &&
      !formErrors.politicId
    );
  };

  return (
    <div className="main-content" id="AddEditBuro">
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <PageSection title="Nuevo Perfil">
            <PageSection.Item size={1} length={3}>
              <TextField
                label={Texts.connectors.fields.name}
                placeholder={Texts.connectors.fields.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errors.name}
                errorText="Debes completar este campo"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <SelectField
                placeholder={Texts.connectors.fields.connectorTypePlaceholder}
                options={types}
                value={selectedType}
                onChange={handleChangeType}
                label={Texts.connectors.fields.connectorType}
                error={errors.type}
                errorText="Debes seleccionar un tipo"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {services && selectedType && (
              <PageSection.Item size={1} length={3}>
                <SelectField
                  label={Texts.connectors.fields.serviceType}
                  placeholder={Texts.connectors.fields.serviceTypePlaceholder}
                  options={services[selectedType]}
                  value={selectedService}
                  onChange={handleChangeService}
                  error={errors.service}
                  errorText="Debes seleccionar un servicio"
                  disabled={isLoading}
                />
              </PageSection.Item>
            )}
            {selectedType === "TRANSUNION" &&
              selectedService === "CREDIT_VISION" && (
                <>
                  <PageSection.Item size={2} length={3} />
                  <PageSection.Item size={1} length={3}>
                    <TextField
                      label="Id Política"
                      placeholder="Id Política"
                      value={politicId}
                      onChange={(e) => setPoliticId(e.target.value)}
                      error={errors.politicId}
                      errorText="Debes completar este campo"
                      disabled={isLoading}
                    />
                  </PageSection.Item>
                </>
              )}
            {selectedType === "EXPERIAN" && selectedService && (
              <ExperianForm
                user={user}
                onChangeUser={setUser}
                errorUser={errors.user || false}
                userKey={userKey}
                onChangeUserKey={setUserKey}
                errorUserKey={errors.userKey || false}
                userSandbox={userSandbox}
                userKeySandbox={userKeySandbox}
                onChangeUserSandbox={setUserSandbox}
                onChangeUserKeySandbox={setUserKeySandbox}
              />
            )}
            {country === "MX" &&
              selectedType === "BC" &&
              selectedService === "PM" && (
                <>
                  <PageSection.Item size={2} length={3} />
                  <PageSection.Item size={1} length={3}>
                    <PFAEField
                      value={PFAE}
                      onChange={handlePFAEField}
                      isLoading={isLoading}
                    />
                  </PageSection.Item>
                </>
              )}
            {configsComponents}
          </PageSection>
          <PageSection>
            <PageSection.Item size={1} length={1}>
              <div className="buttons-container">
                <Button
                  text="Cancelar"
                  disabled={isLoading}
                  onClick={() =>
                    history.push(
                      Paths.Connectors.replace(":connectorType", "buro")
                    )
                  }
                />
                <Button
                  text="Guardar"
                  style="primary"
                  onClick={handleOnSave}
                  loading={isLoading}
                  disabled={isLoading}
                />
              </div>
            </PageSection.Item>
          </PageSection>
        </>
      )}
    </div>
  );
};

export default AddEditBuro;
