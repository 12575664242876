import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import Button from "@bit/kiban-design-system.kiban.button";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../services";
import base64DecodeUnicode from "../../utils/b64DecodeUnicode";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";

const FintocWidget = () => {
  const [casosFicticios, setCasosFicticios] = useState([]);
  const [showCasosFicticios, setShowCasosFicticios] = useState(false);
  const [selectedCasoFicticio, setSelectedCasoFicticio] = useState("");
  const [widgetScript, setWidgetScript] = useState("");

  const routeParams = useParams();
  const { addAlert } = useAlert();

  const getFintoc = async () => {
    const [err, res] = await apiRequest({
      method: "get",
      url: apiRoutes.workflooFintoc.replace("{idUnykoo}", routeParams.id),
    });
    if (res) {
      setShowCasosFicticios(res.showCasosFicticios);
      setWidgetScript(
        base64DecodeUnicode(res.widgetScript)
          .replace("{workflooId}", routeParams.id)
          .replace(
            "})",
            `    country:"${Jwt.getItem("country").toLowerCase()}",\n})`
          )
      );
    }
    if (err) {
      addAlert({
        code: "error",
        message: err.message,
        duration: 5000,
      });
    }
  };

  const getCasosFicticios = async () => {
    const [err, res] = await apiRequest({
      method: "get",
      url: `${apiRoutes.casosFicticios}?limit=1000&skip=0&type=FINTOC&includeDefault=true`,
    });
    if (res) {
      if (res.casosFicticios?.length) {
        const casosFicticios = res.casosFicticios.map((caso) => ({
          value: caso.id,
          content: caso.name,
        }));
        setCasosFicticios(casosFicticios);
      }
    }
    if (err) {
      addAlert({
        code: "error",
        message: err.message,
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    const fintocJs = document.createElement("script");
    fintocJs.src = "https://js.fintoc.com/v1";
    document.body.appendChild(fintocJs);
    getFintoc();
  }, []);

  const executeWidget = () => {
    const cryptId = Jwt.signToken({ id: routeParams.id });
    let ws = widgetScript;
    ws = ws
      .replace(
        "{caso_ficticio}",
        selectedCasoFicticio
          ? encodeURI(
              casosFicticios.find((caso) => caso.value === selectedCasoFicticio)
                .content
            )
          : ""
      )
      .replace(
        "onSuccess",
        `onSuccess: function () {
            window.location.href = '${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}';
        }`
      )
      .replace(
        "onExit",
        `onExit: function () {
            window.location.reload();
        }`
      );
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = ws;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (widgetScript) {
      if (showCasosFicticios) {
        getCasosFicticios();
      } else {
        executeWidget();
      }
    }
  }, [widgetScript, showCasosFicticios]);

  const handleContinue = () => {
    executeWidget();
  };

  return (
    <div className="main-content">
      <PageSection title="Fintoc Widget">
        {showCasosFicticios ? (
          <>
            <PageSection.Item size={1} length={3}>
              <SelectField
                label="Casos ficticios"
                options={casosFicticios}
                placeholder="Selecciona un caso ficticio"
                value={selectedCasoFicticio}
                onChange={setSelectedCasoFicticio}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item size={1} length={3}>
              <Button
                text="Continuar"
                style="primary"
                onClick={handleContinue}
                disabled={!selectedCasoFicticio}
              />
            </PageSection.Item>
          </>
        ) : null}
      </PageSection>
    </div>
  );
};

export default FintocWidget;
