import React, { useEffect, useState } from "react";
import Alert from "@bit/kiban-design-system.kiban.alert";
import ExampleSection from "./ExampleSection";
import EditorCode from "./EditorCode";
import FileList from "../../../../shared/FileList";
import OutputSection from "./OutputSection";

import "./ApiCode.css";

const ApiCode = ({
  dataCode,
  setDataCode,
  draft,
  changes,
  setChanges,
  results,
}) => {
  const [indexFile, setIndexFile] = useState(0);

  const addNewFile = () => {
    let updateDataCode = { ...dataCode };
    const files = [...updateDataCode.files];
    const numFile =
      files.length > 9 ? files.length.toString() : `0${files.length}`;
    files.push({
      name_file: `newFile_${numFile}.js`,
      data: "",
    });
    updateDataCode = {
      ...updateDataCode,
      files: files,
    };
    setDataCode(updateDataCode);
  };

  const selectFile = (index) => {
    setIndexFile(index);
  };

  const onDeleteFile = (files) => {
    let updateDataCode = { ...dataCode };
    const defaultFile = [
      {
        name_file: `newFile.js`,
        data: "",
      },
    ];
    updateDataCode = {
      ...updateDataCode,
      files: files.length > 0 ? files : defaultFile,
    };
    setDataCode(updateDataCode);
  };

  const updateFiles = (allFiles) => {
    let updateDataCode = { ...dataCode };
    setDataCode({ ...updateDataCode, files: allFiles });
  };

  const handleChangeCode = (code) => {
    let updateDataCode = { ...dataCode };
    const files = [...dataCode.files];
    const updateFile = files[indexFile];
    files[indexFile] = { ...updateFile, data: code };
    updateDataCode = { ...updateDataCode, files: files };
    setDataCode(updateDataCode);
  };

  const handleChangeExample = (newExample) => {
    setDataCode({ ...dataCode, example: newExample });
  };

  useEffect(() => {
    setChanges(JSON.stringify(dataCode) !== JSON.stringify(draft));
  }, [dataCode, draft]);

  return (
    <>
      {changes && (
        <div className="Api-AlertContainer">
          <Alert code="info" text="Cambios no guardados" />
        </div>
      )}
      <div
        className={`Api-CodeContainer${
          changes ? " Api-CodeContainer-Alert" : ""
        }`}
      >
        <div className="Api-CodeContainer-FileList">
          <FileList
            files={dataCode.files.map((file) => {
              return { ...file };
            })}
            onAddNew={addNewFile}
            onFileNameChange={(allFiles) => updateFiles(allFiles)}
            onDeleteFile={onDeleteFile}
            onSelectFile={selectFile}
            selectedFile={dataCode.files[indexFile]}
            onChangeOrder={(allFiles) => updateFiles(allFiles)}
          />
        </div>
        <div className="Api-CodeContainer-Code">
          <div className="Api-CodeContainer-Code--example">
            <ExampleSection
              example={dataCode.example}
              onExampleChange={handleChangeExample}
            />
          </div>
          <div className="Api-CodeContainer-Code--editor">
            <EditorCode
              file={dataCode.files[indexFile]}
              onCodeChange={handleChangeCode}
            />
          </div>
          <div className="Api-CodeContainer-Code--results">
            <OutputSection
              output={results ? JSON.stringify(results, null, 2) : ""}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiCode;
