import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import "./FormApi.css";

const FormApi = ({
  data,
  onChangeData,
  optionsMethod,
  optionsContentType,
  errors,
}) => {
  const examples = [
    '{{FORM:<id_form>:<api_id_field>}}. Por ejemplo {{FORM:2:rfc}} toma el valor del campo con API id rfc del Formulario con id 2.',
    '{{VP:<id_vp>}}. Por ejemplo {{VP:4}} toma el valor de la Variable dinámica 4.',
    '{{WORKFLOO_ID}}: Id del workfloo.',
    '{{WORKFLOO_ENV}}. Ambiente workfloo, https://test.unykoo.com o https://www.unykoo.com.',
    '{{WORKFLOO_API_KEY}}. API Key del ambiente actual.',
  ]
  return (
    <>
      <PageSection.Item size={1} length={2} title="Nombre">
        <TextField
          onChange={(e) => onChangeData(e.target.value, "name")}
          value={data && data.name}
          error={errors.name}
          errorText="Este campo debe de estar completo"
        />
      </PageSection.Item>
      <PageSection.Item size={1} length={2} title="Url">
        <TextField
          onChange={(e) => {
            onChangeData(e.target.value, "url");
          }}
          value={data && data.url}
          error={errors.url}
          errorText="Este campo debe de estar completo"
        />
      </PageSection.Item>
      <PageSection.Item size={1} length={1}>
        <Title type="heading">
          La siguiente lista de valores se pueden utilizar como variables:
        </Title>
        <ul>
          {examples.map(example => <li className="examples">{example}</li>)}
        </ul>
      </PageSection.Item>
      <PageSection.Item size={1} length={2} title="Request Method">
        <SelectField
          placeholder="Selecciona una opción"
          onChange={(value) => onChangeData(value, "requestMethod")}
          value={data ? data.requestMethod : ""}
          options={optionsMethod}
          error={errors.requestMethod}
          errorText="Este campo debe de estar completo"
        />
      </PageSection.Item>
      <PageSection.Item size={1} length={2} title="Content Type">
        <SelectField
          placeholder="Selecciona una opción"
          onChange={(value) => onChangeData(value, "contentType")}
          value={data ? data.contentType : ""}
          options={optionsContentType}
          error={errors.contentType}
          errorText="Este campo debe de estar completo"
        />
      </PageSection.Item>
    </>
  );
};

export default FormApi;
