import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { apiRequest, apiRoutes } from "../../../services";
import { API_BASE_URL } from "../../../services/api-instance";
import Jwt from "../../../utils/jwt";
import Header from "./header";
import PanelLeft from "./panelLeft";
import CenterPanel from "./centerPanel";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import { getMessageError } from "../HistoricErrors";
import { getHostName } from "../../../utils/menuRights";
import AlertTexts from "../../../utils/alerts-texts";
import Paths from "../../../paths";
import "./DetailHistoricWorkfloo.css";

export default function DetailHistoricWorkfloo() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataWorkfloo, setDataWorkfloo] = useState(null);
  const [stepSelected, setStepSelected] = useState(null);
  const [indexSelected, setIndexSelected] = useState(0);
  const [indexTab, setIndexTab] = useState(0);
  const routerParams = useParams();
  const history = useHistory();
  const { addAlert } = useAlert();

  const getDetailHistoricWorkfloo = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.workflooHistoric}`.replace(
        ":id",
        routerParams.idHistoryWorkfloo
      ),
    });
    if (data) {
      setDataWorkfloo(data);
      setStepSelected(data.steps[0]);
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
    // TODO manage error
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getDetailHistoricWorkfloo();
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (dataWorkfloo) {
      setIsLoading(false);
    }
  }, dataWorkfloo);

  // build data table
  const downloadJson = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.downloadJsonWorkfloo.replace(
        ":id",
        routerParams.idHistoryWorkfloo
      ),
    });
    if (data) {
      let download = document.createElement("a");
      download.textContent = "download";
      download.download = `workfloo_${routerParams.idHistoryWorkfloo}.json`;
      download.href =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(data, undefined, 2));
      download.click();
    }
    if (error) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: getMessageError(error.status, error.message),
      });
    }
    setIsLoading(false);
  };

  const downloadFile = async (file, dataStep) => {
    try {
      const response = await axios({
        method: "get",
        url: `${API_BASE_URL}/${apiRoutes.downloadFileJsonWorkfloo}?connectorType=${dataStep.connectorType}&connectorId=${dataStep.connectorId}&stepId=${dataStep.stepId}&fileValue=@workfloo_file:${file}`.replace(
          ":id",
          routerParams.idHistoryWorkfloo
        ),
        headers: {
          Authorization: `Bearer ${Jwt.getToken()}`,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      addAlert({
        duration: 5000,
        code: "danger",
        message: getMessageError(e.status, e.message),
      });
    }
  };

  const handleCancel = async () => {
    const response = await apiRequest({
      method: "put",
      url: apiRoutes.cancelWorkfloo,
      data: {
        idUnykoo: dataWorkfloo.workflooId,
        login: Jwt.getItem("login"),
      },
    });
    if (!response[0]) {
      history.push(Paths.StartWorkfloo);
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: AlertTexts.unexpectedError,
      });
    }
  };

  const handleContinue = () => {
    const cryptId = Jwt.signToken({ id: routerParams.idHistoryWorkfloo });
    window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
  };

  const handleOnClickStepBox = (index) => {
    setIndexSelected(index);
    setStepSelected(dataWorkfloo.steps[index]);
  };

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="historic__container">
      <div className="historic__content-container">
        <PanelLeft
          steps={dataWorkfloo?.steps}
          onSelectStep={handleOnClickStepBox}
          indexSelected={indexSelected}
        />
        <div className="historic__content-right-container">
          <Header
            dataWorkfloo={dataWorkfloo}
            downloadJson={downloadJson}
            handleCancel={handleCancel}
            handleContinue={handleContinue}
            indexTab={indexTab}
            setIndexTab={setIndexTab}
          />
          {stepSelected && (
            <CenterPanel
              dataStep={stepSelected}
              indexTab={indexTab}
              downloadFile={downloadFile}
            />
          )}
        </div>
      </div>
    </div>
  );
}
