import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SideMenu } from "../shared/SideMenu/SideMenu";
import { Navigation } from "./Navigation";
import { sideMenu } from "../utils/menuRights";
import Jwt from "../utils/jwt";
import { getHostName } from "../utils/menuRights";
import Paths from "../paths";
import { apiRequest, apiRoutes } from "./../services";
import { ReactComponent as Logout } from "../assets/logout-icon.svg";
import "./Layout.css";
import { useAuth } from "./../services/auth/AuthProvider";

const Layout = () => {
  const history = useHistory();
  const { logout } = useAuth();
  const [userName, setUserName] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    setUserName(`${Jwt.getItem("companyCode")} ${Jwt.getItem("login")}`);
  }, []);

  function onProfileClick() {
    setActive(!active);
  }

  const logoutService = async () => {
    const company_code = localStorage.getItem("company_code");
    const [error, data] = await apiRequest({
      method: "post",
      url: apiRoutes.logout,
      headers: {
        company_code: company_code,
        api_key: "3c6847dc-5c72-46ce-8d0c-98c77b3b518e",
      },
    });
    localStorage.clear();
    logout();
    Jwt.removeToken();
    history.push(Paths.Login);
  };
  function handleLogout(event) {
    event.preventDefault();
    logoutService();
  }

  return (
    <div className="Layout">
      <div className="container">
        <SideMenu options={sideMenu()} />
        <div className="page-container">
          <>
            <div className="appbar">
              {userName}
              <img
                src={` https://storage.googleapis.com/assets.kiban.tech/images/avatar.png`}
                className="appbar-profile-icon"
                onClick={onProfileClick}
                alt=""
              ></img>
            </div>
            <div className={`appbar-logout${active ? "-active" : ""}`}>
              <button className="appbar-logout-button" onClick={handleLogout}>
                {" "}
                <Logout className="logout-icon"></Logout> Cerrar Sesión
              </button>
            </div>
          </>
          <div className="page">
            <div className="content">
              <Navigation />
            </div>
            <div className="footer">
              <span>Copyright 2013 - 2022 Kiban</span>
              <span>
                <span></span>
                <span>Todos Derechos Reservados</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
