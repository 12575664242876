import {
  ErrorIcon,
  FormIcon,
  GenericConectorIcon,
  DecisionTreeIcon,
  NipIcon,
  SuccessIcon,
  WarningIcon,
} from "@bit/kiban-design-system.kiban.icons";

export default function PanelLeft({ steps, onSelectStep, indexSelected }) {
  const renderIcon = (type) => {
    const color = "#0047FF";
    switch (type) {
      case "FORMULARIO":
        return <FormIcon color={color} />;
      case "POLICY":
        return <DecisionTreeIcon color={color} />;
      case "NIP_UNYKOO":
        return <NipIcon color={color} />;
      default:
        return <GenericConectorIcon color={color} />;
    }
  };

  const IconBox = (status) => {
    switch (status) {
      case "SUCCESS":
        return <SuccessIcon size={12.8} />;
      case "ERROR":
        return <ErrorIcon size={12.8} />;
      case "ERROR_TECHNICAL":
        return <ErrorIcon size={12.8} />;
      case "PROGRESS":
        return <WarningIcon size={12.8} />;
      default:
        return <WarningIcon size={12.8} />;
    }
  };

  const getIndex = (index) => {
    return index < 9 ? `0${index + 1}` : index + 1;
  };

  const StepBox = ({ step, index }) => {
    const classStepBox = `historic__step-box${
      index === indexSelected ? " isSelected" : ""
    }`;
    return (
      <div className={classStepBox} onClick={() => onSelectStep(index)}>
        <div className="step-box__icon-container">
          {renderIcon(step.connectorType)}
        </div>
        <div className="step-box__labels-container">
          <div className="step-box__labels-container-title">
            <div className="step-box--index">{getIndex(index)}</div>
            <div className="step-box--title">{step.connectorType}</div>
          </div>
          <div className="step-box--description">{step.connectorName}</div>
        </div>
        <div className="step-box--status">{IconBox(step.status)}</div>
      </div>
    );
  };

  return (
    <div className="historic_panel-left-container">
      {steps.map((step, index) => (
        <StepBox step={step} index={index} />
      ))}
    </div>
  );
}
