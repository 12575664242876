import React from "react";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import Panel from "../../../../shared/Panel";

const ExampleSection = ({
  example,
  onExampleChange,
}) => {
  return (
    <Panel className="ExampleSection-panel">
      <Panel.Section title="Ejemplo">
        <AceEditor
          mode="javascript"
          name="Api_example"
          fontSize={12}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={false}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            scrollPastEnd: true,
          }}
          width="100%"
          value={example}
          onChange={onExampleChange}
        />
      </Panel.Section>
    </Panel>
  );
};

export default ExampleSection;

