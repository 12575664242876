import React from "react";

import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import Panel from "../../../../shared/Panel";

const EditorCode = ({ file, onCodeChange }) => {
  return (
    <Panel className="EditorSection-panel" title={file ? file?.name_file : "Editor"}>
      <AceEditor
        name="api_editor_code"
        mode="javascript"
        fontSize={12}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={true}
        onChange={onCodeChange}
        value={file ? file.data : ""}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          cursorStyle: "smooth",
        }}
        width="100%"
      />
    </Panel>
  );
};

export default EditorCode;
