import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Nip from "./Nip";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import AlertTexts from "../../utils/alerts-texts";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";

import "./Rainao.css";

const Rainao = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sentMean, setSentMean] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [currentStep, setCurrentStep] = useState(
    "WAITING_ACCEPT_TERMS_AND_CONDITIONS"
  );
  const [steps, setSteps] = useState([
    {
      code: "WAITING_ACCEPT_TERMS_AND_CONDITIONS",
      name: "Términos y Condiciones",
      status: true,
      isFullBar: false,
    },
    {
      code: "WAITING_FIRST_VERIFICATION",
      name: "Ingrese NIP",
      status: false,
      isFullBar: false,
    },
    {
      code: "WAITING_SECOND_VERIFICATION",
      name: "Autorización",
      status: false,
      isFullBar: false,
    },
  ]);
  const params = useParams();
  const { addAlert } = useAlert();

  const getStatusRainao = async () => {
    setIsLoading(true);
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.statusRainao}?idWorkfloo=${params.id}`,
    });
    if (data && data.statusStep !== "SUCCESS" && data.statusStep !== "WAIT") {
      setCurrentStep(data.statusStep);
      setSentMean(data?.sentMean || "");
      setRazonSocial(data?.razonSocial || "");
      const indexStep = steps.findIndex(
        (step) => step.code === data.statusStep
      );
      const updateSteps = steps.map((step, index) => {
        return { ...step, status: index <= indexStep };
      });
      setSteps(updateSteps);
      setIsLoading(false);
    } else if (data) {
      const cryptId = Jwt.signToken({ id: params.id });
      window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
    }
    if (error) {
      addAlert({
        code: "danger",
        message: AlertTexts.tecnicalError,
        duration: 5000,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getStatusRainao();
    } else {
      window.location.href = "/login";
    }
  }, []);

  return isLoading ? (
    <Skeleton />
  ) : (
    <Nip
      currentStep={currentStep}
      steps={steps}
      sentMean={sentMean}
      razonSocial={razonSocial}
      getStatusRainao={getStatusRainao}
    />
  );
};

export default Rainao;
