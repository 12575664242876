import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import Jwt from "../../utils/jwt";
import { getHostName } from "../../utils/menuRights";

import {
  sdkCodeSnippet,
  widgetCode,
  wsEvent,
  getWidgetData,
  setWidgetStatus,
} from "./utils";
import AlertTexts from "../../utils/alerts-texts";

const MiFielWidget = () => {
  const [mifielWidgetId, setMifielWidgetId] = useState();

  const widgetContainerRef = useRef(null);

  const routeParams = useParams();
  const { addAlert } = useAlert();

  const showAlert = (message, code) => {
    addAlert({
      message,
      code,
      duration: 5000,
    });
  };

  const loadSdk = () => {
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = sdkCodeSnippet;
    document.body.appendChild(script);
  };
  const executeWidget = () => {
    const widgetScript = document.createElement("script");
    const ws = widgetCode
      .replace("{{widgetId}}", mifielWidgetId)
      .replace("{{appendTo}}", widgetContainerRef.current.id);
    widgetScript.innerHTML = ws;
    widgetScript.async = true;
    document.body.appendChild(widgetScript);
  };
  const executeEvent = () => {
    const cryptId = Jwt.signToken({
      id: routeParams.id,
    });
    const widgetScript = document.createElement("script");
    const ws = wsEvent.replace("{{idUnykoo}}", cryptId);
    widgetScript.innerHTML = ws;
    widgetScript.async = true;
    document.body.appendChild(widgetScript);
  };

  const loadWidgetData = async () => {
    const response = await getWidgetData(routeParams.id, (error) => {
      showAlert(error.message || AlertTexts.unexpectedError, "danger");
    });

    if (response && response.status === "SHOW_WIDGET") {
      setMifielWidgetId(response.widgetID);
    }
  };

  const setWidgetStatusToReady = async () => {
    const response = await setWidgetStatus(routeParams.id, (error) => {
      showAlert(error.message || AlertTexts.unexpectedError, "danger");
    });
    if (response !== null || response !== undefined) {
      const cryptId = Jwt.signToken({ id: routeParams.id });
      window.location.href = `${getHostName()}/auth/waitView.xhtml?token=${Jwt.getToken()}&idUnykoo=${cryptId}`;
    }
  };

  useEffect(() => {
    loadWidgetData();
    loadSdk();
  }, []);

  useEffect(() => {
    if (mifielWidgetId) {
      executeWidget();
      window.onMiFielCaptured = setWidgetStatusToReady;
      executeEvent();
    }
    return () => {
      if (window.onMiFielCaptured) {
        window.onMiFielCaptured = null;
      }
    };
  }, [mifielWidgetId]);

  return (
    <div className="main-content">
      <PageSection title="Mifiel Widget">
        <PageSection.Item size={1} length={1}>
          <div ref={widgetContainerRef} id="mifiel-widget-container"></div>
        </PageSection.Item>
      </PageSection>
    </div>
  );
};

export default MiFielWidget;
