import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Dropdown from "@bit/kiban-design-system.kiban.dropdown";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import { PlusIcon, AlertIcon, TrashIcon } from "@bit/kiban-design-system.kiban.icons";
import Table from "@bit/kiban-design-system.kiban.table";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { COLOR_DANGER_BASE } from "@bit/kiban-design-system.styles.design-tokens-es6";

import Jwt from "../../utils/jwt";
import useTablePagination from "../../utils/useTablePagination";
import { getHostName } from "../../utils/menuRights";
import Paths from "./../../paths";
import "./UsersList.css"
import { apiRequest, apiRoutes } from "../../services";

const UsersList = () => {
  const history = useHistory();
  const limit = 10;
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);

  const {
    from,
    goBack,
    goForward,
    hasNext,
    hasPrevious,
    page,
    skipBack,
    skipForward,
    to,
  } = useTablePagination({
    initialPage: 1,
    rowsPerPage: limit,
    totalItems: totalUsers,
  });

  useEffect(() => {
    setTotalUsers(users.length);
  }, [users]);

  useEffect(() => {
    getUsers();
  }, []);

  const onSelectItem = (selected) => {
    const user = users[limit * (page - 1) + selected];
    const cryptId = Jwt.signToken({ id: user.userId.toString() });
    history.push(
      Paths.EditUser.replace(
        ":id", user.userId.toString()
        // filteredData[limit * (page - 1) + selected].id
      )
    );
  };

  const getUsers = async() => {
    const [errorUsers, users] = await apiRequest({
      method: "get",
      url: apiRoutes.users,
    });

    if (users) {
      setUsers(users.users);
    }
    if (errorUsers) {
      setError(errorUsers);
    }
    setIsLoading(false);
  }

  const handleDeleteUser = async (id) => {
    const [error, data] = await apiRequest({
      method: "delete",
      url: apiRoutes.editUser.replace(":id", id),
    });
    if (!error) {
      getUsers();
    }
    setIsLoading(false);
  };


  const tablePagination = {
    current: from,
    results: to,
    total: totalUsers,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: skipBack,
    onSkipForward: skipForward,
  };

  const ActionsDropdown = ({ index, onDuplicate, onDelete }) => {
    return (
      <Dropdown text="Acciones">
        <Dropdown.Section
          options={[
            {
              content: "Eliminar",
              icon: TrashIcon,
              onAction: (e) => {
                onDelete && onDelete(index);
              },
            },
          ]}
        />
      </Dropdown>
    );
  };

  const rows =
    users.length &&
    users
      .slice(limit * (page - 1), limit * page)
      .map((user, i) => [
        user?.login,
        [user.name1, user.name2].join(" "),
        user.lastName1,
        user.lastName2,
        moment(user.created).format("DD-MM-YYYY hh:mm"),
        <ActionsDropdown
          index={i}
          onDelete={() => handleDeleteUser(user.userId)}
        />,
      ]);

  const primaryAction = {
    label: "Nuevo usuario",
    text: "Nuevo usuario",
    icon: PlusIcon,
    disabled: isLoading,
    onAction: () => {
      history.push(Paths.AddUser);
    },
  };

  const tableHeaders = [
    "Usuario",
    "Nombres",
    "Apellido Paterno",
    "Apellido Materno",
    "Fecha de Alta",
    "Acciones"
  ];

  return (
    <PageSection
      title={isLoading || !users.length ? "Usuarios" : ""}
      primaryAction={isLoading || !users.length ? primaryAction : undefined}
    >
      {error && (
        <PageSection.Item size={1} length={1}>
          <p
            style={{
              color: COLOR_DANGER_BASE,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AlertIcon />
            <span style={{ marginLeft: "10px" }}>
              {error.message ? error.message : "Hubo un error inesperado"}
            </span>
          </p>
        </PageSection.Item>
      )}
      {!isLoading ? (
        <PageSection.Item size={1} length={1}>
          {users.length > 0 ? (
            <Table
              title="Usuarios"
              headings={tableHeaders}
              rows={rows}
              primaryAction={primaryAction}
              onSelectItem={onSelectItem}
              pagination={tablePagination}
            />
          ) : (
            <p>No hay usuarios registrados en este momento</p>
          )}
        </PageSection.Item>
      ) : (
        <PageSection.Item>
          <Skeleton size="medium" />
        </PageSection.Item>
      )}
    </PageSection>
  );
};

export default UsersList;
