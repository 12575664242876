import validateNIPGEN from "./validateNIPGEN";
import validateNIPSIC from "./validateNIPSIC";

const isValidForm = ({
  errors,
  name,
  selectedService,
  firstVerificationData,
  secondVerificationData,
  sicData,
  onValidate,
}) => {
  let formErrors = { ...errors };
  formErrors.name = Boolean(!name);
  formErrors.service = Boolean(!selectedService);
  let isValidNIPGEN = true;
  let isValidNIPSIC = true;
  if (selectedService === "NIP_GEN") {
    isValidNIPGEN = validateNIPGEN({
      formErrors: formErrors.nipGeneric,
      firstVerificationData,
      secondVerificationData,
    });
  }
  if (selectedService === "NIP_SIC") {
    isValidNIPSIC = validateNIPSIC({
      formErrors: formErrors.sic,
      sicData,
    });
  }
  if (onValidate) onValidate(formErrors);
  return (
    !formErrors.name && !formErrors.service && isValidNIPGEN && isValidNIPSIC
  );
};

export default isValidForm;
