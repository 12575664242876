import React from "react";
import "./styles.sass";

import Section from "./Section";
import HeaderActions from "./HeaderActions";

const Panel = ({
  children,
  className,
  title,
  primaryAction,
  secondaryActions,
}) => {
  return (
    <div className={`Panel_Main_Container ${className ? ` ${className}` : ""}`}>
      {title && (
        <div className="Panel_Header">
          <h2 className="Panel_Title">{title}</h2>
          {(primaryAction || secondaryActions) && (
            <HeaderActions
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

Panel.Section = Section;

export default Panel;
