import { envs } from "./menuRights";

const srCode = `
  window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/7a7e9d0a-d836-43db-bb63-1581748164dd.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
`;

const getSrCode = () => {
  const hostname = window.location.hostname;
  const env = envs.find((env) => env.url === hostname);
  if (env) {
    const envCode = env.url.split(".")[0];
    if (envCode !== "localhost" && envCode !== "qa" && envCode !== "alpha") {
      const script = document.createElement("script");
      script.async = true;
      script.type = "text/javascript";
      script.innerHTML = srCode;
      document.body.appendChild(script);
    }
  }
};

export default getSrCode;
