import SelectField from "@bit/kiban-design-system.kiban.select-field";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Radio from "@bit/kiban-design-system.kiban.radio-button";
import Title from "@bit/kiban-design-system.kiban.title";
import PageSection from "@bit/kiban-design-system.layout.page-section";

import "./Verification.css";

const Verification = ({
  title,
  data,
  onChange,
  messagingCatalog,
  connectorsCatalog,
  errors,
  isLoading,
  meanFilter,
}) => {
  const handleChange = (value, key) => {
    if (onChange) {
      let newData = {
        ...data,
        [key]: value,
      };
      if (key === "mean") {
        newData.meanConnector = "";
      }
      if (key === "hasBackUp" && value === false) {
        delete newData.meanBackUp;
        delete newData.attemps;
      }
      if (key === "hasBackUp" && value) {
        newData.attemps = 1;
      }
      onChange(newData);
    }
  };

  const handleBackupChange = (value, key) => {
    if (onChange) {
      const meanBackUp = {
        ...(data.meanBackUp || {}),
        [key]: value,
      };
      if (key === "mean") {
        meanBackUp.meanConnector = "";
      }
      onChange({
        ...data,
        meanBackUp,
      });
    }
  };

  return (
    <>
      <PageSection title={title}>
        <PageSection.Item title="Medio" size={1} length={3}>
          <SelectField
            placeholder="Elige un medio"
            options={messagingCatalog.filter(
              (mean) => mean.value !== meanFilter
            )}
            value={data.mean || ""}
            onChange={(value) => handleChange(value, "mean")}
            error={errors.mean}
            errorText="Debes elegir un medio"
            disabled={isLoading}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        {data && data.mean && (
          <PageSection.Item title="Conector" size={1} length={3}>
            <SelectField
              placeholder="Elige un conector"
              options={connectorsCatalog[data.mean]}
              value={data.meanConnector || ""}
              onChange={(value) => handleChange(value, "meanConnector")}
              error={errors.meanConnector}
              errorText="Debes elegir un conector"
              disabled={isLoading}
            />
          </PageSection.Item>
        )}
        <PageSection.Item size={3} length={3}>
          <Title type="displaySmall">Método de respaldo</Title>
          <p>
            En caso de que “{title}” no funcione,{" "}
            <strong>¿Activar método de respaldo?</strong>
          </p>
          <div className="backup-method-container">
            <Radio
              label="No"
              name={`Primera Verificación-backup-method`}
              value="false"
              checked={!data.hasBackUp}
              onChange={() => handleChange(false, "hasBackUp")}
            />
            <Radio
              label="Sí"
              name={`Primera Verificación-backup-method`}
              value="true"
              checked={data.hasBackUp}
              onChange={() => handleChange(true, "hasBackUp")}
            />
          </div>
        </PageSection.Item>
        {data.hasBackUp && (
          <>
            <PageSection.Item title="Número de intentos" size={1} length={3}>
              <TextField
                type="number"
                placeholder="Número de intentos"
                value={data.attemps || 1}
                onChange={(e) =>
                  handleChange(parseInt(e.target.value), "attemps")
                }
                error={errors.meanBackUp.attempts}
                errorText="Debes ingresar un número de intentos mayor a 0"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            <PageSection.Item title="Medio" size={1} length={3}>
              <SelectField
                placeholder="Elige un medio"
                options={messagingCatalog.filter(
                  (type) => type.value !== data.mean
                )}
                value={(data.meanBackUp && data.meanBackUp.mean) || ""}
                onChange={(value) => handleBackupChange(value, "mean")}
                error={errors.meanBackUp && errors.meanBackUp.mean}
                errorText="Debes elegir un medio"
                disabled={isLoading}
              />
            </PageSection.Item>
            <PageSection.Item size={2} length={3} />
            {data.meanBackUp && data.meanBackUp.mean && (
              <PageSection.Item title="Conector" size={1} length={3}>
                <SelectField
                  placeholder="Elige un conector"
                  options={connectorsCatalog[data.meanBackUp.mean] || []}
                  value={
                    (data.meanBackUp && data.meanBackUp.meanConnector) || ""
                  }
                  onChange={(value) =>
                    handleBackupChange(value, "meanConnector")
                  }
                  error={data.meanBackUp && errors.meanBackUp.meanConnector}
                  errorText="Debes elegir un conector"
                  disabled={isLoading}
                />
              </PageSection.Item>
            )}
          </>
        )}
      </PageSection>
    </>
  );
};

export default Verification;
