import { apiRequest, apiRoutes } from "../../../../../services";

export const createEditMessage = async ({
  message,
  onError,
  policyId,
  stepId,
}) => {
  const [error, response] = await apiRequest({
    method: stepId ? "put" : "post",
    url: !stepId
      ? apiRoutes.createPolicyStep.replace(":id", policyId)
      : apiRoutes.editPolicyStep
          .replace(":id", policyId)
          .replace(":stepId", stepId),
    data: message,
  });
  if (response !== null) {
    return response;
  }
  if (error !== null) {
    onError && onError(error);
  }
};
