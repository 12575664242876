import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "./../../assets/workfloo-logo.png";
import Button from "@bit/kiban-design-system.kiban.button";
import ButtonGroup from "@bit/kiban-design-system.kiban.button-group";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import Paths from "../../paths";
import { apiRoutes, apiRequest } from "./../../services";
import infoSvg from "./../../assets/info.svg";
import alertsTexts from "../../utils/alerts-texts";

import "../Login/Login.css";

export default function RecoveryPassword() {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [errorCode, setErrorCode] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sendedEmail, setSendedEmail] = useState(false);

  const recoveryPassword = async () => {
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.recoveryPassword,
      data: {
        login: email,
      },
      headers: {
        company_code: code,
      },
    });
    if (!response[0]) {
      setSendedEmail(true);
    } else {
      setError(true);
      setErrorMessage(alertsTexts.unexpectedError);
    }
  };

  const handleRecoveryPassword = () => {
    setErrorCode(code === "");
    setErrorEmail(email === "");
    if (code !== "" && email !== "") {
      recoveryPassword();
    }
  };

  const handleCancel = () => {
    history.push(Paths.Login);
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 5000);
    }
  }, [error]);

  return (
    <div className="login-container">
      <div className="login-form">
        <img className="login-logo" src={logo} alt="logo"/>
        <Title type="heading">Reestablece tu contraseña</Title>
        {error && (
          <div className="login-error-container">
            <img src={infoSvg} alt="info-logo"></img>
            <span className="login-error">{errorMessage}</span>
          </div>
        )}
        {sendedEmail && (
          <div>
            <div className="recovery-password-label"><span>Enviamos una liga para reestablecer tu contraseña a tu correo.</span></div>
            <Button style="light" onClick={handleRecoveryPassword} text="Reenviar la liga"/>
          </div>
        )}
        {!sendedEmail && (<>
          <TextField
            label="Código de empresa"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            error={errorCode}
            errorText="El campo no puede ir vacio."
          />
          <TextField
            label="Usuario"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={errorEmail}
            errorText="El campo no puede ir vacio"
          />
          <ButtonGroup>
            <Button style="secondary" onClick={handleCancel} text="Cancelar"/>
            <Button style="primary" onClick={handleRecoveryPassword} text="Enviar"/>
          </ButtonGroup>
        </>)}
      </div>
      <div className="login-side"></div>
    </div>
  );
}
