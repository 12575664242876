import React, { useState, useEffect } from "react";
import "./SideMenu.css";
import { ReactComponent as Chevron } from "./../../assets/chevron-down.svg";

import { Link } from "react-router-dom";

export const SideMenu = (props) => {
  const { options } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexSub, setActiveIndexSub] = useState(null);

  useEffect(() => {
    const menuIndex = localStorage.getItem("menuIndex");
    const submenuIndex = localStorage.getItem("submenuIndex");
    if (menuIndex && menuIndex >= 0) {
      setActiveIndex(parseInt(menuIndex, "10"));
    }
    if (submenuIndex && submenuIndex >= 0) {
      setActiveIndexSub(parseInt(submenuIndex, "10"));
    }
  }, []);

  function onItemClick(i, isRedirect) {
    if (!isRedirect) {
      localStorage.setItem("menuIndex", i);
      localStorage.setItem("submenuIndex", null);
    }
    setActiveIndex(i);
    setActiveIndexSub(null);
  }

  function onSubmenuClick(i, isRedirect) {
    if (!isRedirect) {
      localStorage.setItem("submenuIndex", i);
    }
    setActiveIndexSub(i);
  }

  return (
    <div className="side-menu-workfloo">
      <div className="logo-menu-container">
        <img
          className="sideMenu"
          src="https://storage.googleapis.com/assets.kiban.tech/images/workfloo_logo_white.svg"
          alt="logo"
        ></img>
      </div>
      {options.map((option, i) => (
        <div key={i} className="option-container">
          {option.link &&
          (option.link.includes("https://") ||
            option.link.includes("http://")) ? (
            <a
              key={i}
              onClick={() => onItemClick(i, true)}
              className={`menu-item-${
                activeIndex === i ? "active-" : ""
              }workfloo`}
              href={option.link || "#"}
            >
              <span>{option.name}</span>
              <span>
                {option.subMenu && <Chevron className="submenu-icon"></Chevron>}
              </span>
            </a>
          ) : (
            <Link
              key={i}
              onClick={() => onItemClick(i, false)}
              className={`menu-item-${
                activeIndex === i ? "active-" : ""
              }workfloo`}
              to={option.link || "/"}
            >
              <span>{option.name}</span>
              <span>
                {option.subMenu && <Chevron className="submenu-icon"></Chevron>}
              </span>
            </Link>
          )}

          {Array.isArray(option.subMenu) ? (
            <span
              className={`submenu-items${activeIndex === i ? "-active" : ""}`}
            >
              {option.subMenu.map((subOption, j) =>
                subOption.link &&
                (subOption.link.includes("https://") ||
                  subOption.link.includes("http://")) ? (
                  <a
                    key={j}
                    className={`submenu-workfloo-item${
                      activeIndexSub === j ? "-active" : ""
                    }`}
                    onClick={() => onSubmenuClick(j, true)}
                    href={subOption.link || "#"}
                  >
                    {subOption.name}
                  </a>
                ) : (
                  <Link
                    key={j}
                    onClick={() => onSubmenuClick(j, false)}
                    className={`submenu-workfloo-item${
                      activeIndexSub === j ? "-active" : ""
                    }`}
                    to={subOption.link || "/"}
                  >
                    {subOption.name}
                  </Link>
                )
              )}
            </span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

SideMenu.defaultValues = {
  required: false,
};
