import React from "react";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";

import "./PFAEField.css";

const PFAEField = ({ onChange, value, isLoading }) => {
  const handleOnChange = (_, value) => {
    onChange && onChange(Boolean(parseInt(value)));
  };
  return (
    <div className="PFAE--Field">
      <p>¿PFAE?</p>
      <div className="PFAE--Field-Options">
        <RadioButton
          label="Sí"
          value={1}
          name="PFAE"
          onChange={handleOnChange}
          checked={value}
          disabled={isLoading}
        />
        <RadioButton
          label="No"
          value={0}
          name="PFAE"
          onChange={handleOnChange}
          checked={!value}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default PFAEField;
