import React, { useEffect, useState } from "react";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import SelectField from "@bit/kiban-design-system.kiban.select-field";

const PredefinedFieldsModal = ({
  open,
  onClose,
  types,
  onApply,
  isLoading,
}) => {
  const [selectedType, setSelectedType] = useState(""); // Predefined field type selected

  /**
   * UseEffect used to reset the selected type when the modal is closed or opened
   */
  useEffect(() => {
    setSelectedType("");
  }, [open]);

  /**
   * Event handler to apply the selected type
   */
  const handleApply = () => {
    onApply && onApply(selectedType);
  };
  const modalActions = [
    {
      text: "Aplicar",
      onAction: handleApply,
      disabled: !selectedType,
    },
    {
      text: "Cancelar",
      onAction: onClose,
    },
  ];
  return (
    <ModalScreen
      open={open}
      title="Campos predefinidos"
      primaryAction={modalActions[0]}
      secondaryActions={[modalActions[1]]}
      onClose={onClose}
    >
      <SelectField
        label="Tipos predefinidos para conector"
        options={types}
        placeholder="Selecciona un tipo de conector"
        onChange={setSelectedType}
        value={selectedType}
        disabled={isLoading}
      />
    </ModalScreen>
  );
};

export default PredefinedFieldsModal;
