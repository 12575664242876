import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Table from "@bit/kiban-design-system.kiban.table";
import Dropdown from "@bit/kiban-design-system.kiban.dropdown";
import { TrashIcon } from "@bit/kiban-design-system.kiban.icons";
import FlowChart from "@bit/kiban-design-system.kiban.flow-chart";
import WorkflooNode from "@bit/kiban-design-system.kiban.workfloo-node";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
import ModalScreen from "@bit/kiban-design-system.kiban.modal-screen";
import { PlusIcon } from "@bit/kiban-design-system.kiban.icons";

import generateID from "../../../utils/generateID";
import "./AddEditCreditPolicies.css";
import Panel from "../../../shared/Panel";
import Paths from "../../../paths";
import { apiRequest, apiRoutes } from "../../../services";
import MessageModal from "./MessageModal";
import StepsModal from "./StepsModal";

import {
  createConnector,
  getConnector,
  getLastCreatedNode,
  saveConnector,
  duplicateStep,
  parseNodes,
  deleteStep as deleteStepApi,
} from "./utils";
import alertsTexts from "../../../utils/alerts-texts";
import appTexts from "../../../utils/app-texts.json";

const AddEditCreditPolicies = () => {
  const [lastCreatedNode, setLastCreatedNode] = useState(null); // State to manage the node when it is dropped over canvas
  const [selectedItem, setSelectedItem] = useState(null); // Step or message type selected
  const [creditPolicyStep, setCreditPolicyStep] = useState([]); // Steps
  const [messageModal, setOpenMessageModal] = useState(false);
  const [openStepModal, setOpenStepModal] = useState(false); // State to manage the step modal opening
  const [messages, setMessages] = useState([]); // Messages
  const [nodes, setNodes] = useState([]); // Flowchart nodes
  const [name, setName] = useState(""); // Name of the connector
  const [auxName, setAuxName] = useState(""); // Name of the connector auxiliar
  const [errors, setErrors] = useState({
    // Errors object
    name: false,
    nodes: false,
  });
  const [modalOpen, setModalOpen] = useState(false); // Modal open state
  const [stepEdit, setStepEdit] = useState(null); // Step to edit
  const [stepDelete, setStepDelete] = useState(null); // Step to delete
  const [messageEdit, setMessageEdit] = useState(null); // Message to edit
  const stepTypeRef = useRef(null); // Step type selected ref
  const [showDeleteAlert, setShowDeleteAlert] = useState(false); // Modal open state

  const history = useHistory();
  const routeParams = useParams();
  const { addAlert } = useAlert();

  /**
   * Function to set all connector states
   * @param {string} Name of the connector
   * @param {array} Steps of the connector
   * @param {array} Messages of the connector
   * @param {array} Not parsed Nodes of the connector
   */
  const setData = ({ name, messages, steps, nodes }) => {
    setName(name);
    setAuxName(name);
    setMessages(messages);
    setCreditPolicyStep(steps);
    const parsedNodes = parseNodes({
      messages,
      steps,
      nodes,
    });
    setNodes(parsedNodes);
  };

  /**
   * Function to get the connector data
   */
  const getConnectorHandler = async () => {
    const connector = await getConnector({
      addAlert,
      history,
      id: routeParams.id,
    });
    if (connector) {
      setConnectorData(connector);
    }
  };

  /**
   *  Function to set the connector data
   * @param {Object} connector Connector data
   */
  const setConnectorData = (connector) => {
    setData({
      name: connector.name,
      messages: connector.messages,
      steps: connector.steps,
      nodes: connector.nodes,
    });
  };

  useEffect(() => {
    /**
     * If route params connector id does not exists then a connector will be created
     */
    if (!routeParams.id) {
      createConnector({
        addAlert,
        history,
        onCreate: setConnectorData,
      });
    } else {
      getConnectorHandler();
    }
  }, []);

  useEffect(() => {
    /**
     * If last created node and selected item change then the node will be added to the flowchart
     */
    if (lastCreatedNode && selectedItem) {
      setNodes([
        ...nodes,
        {
          ...getLastCreatedNode({
            node: lastCreatedNode,
            selectedItem,
            steps: creditPolicyStep,
            messages,
          }),
        },
      ]);
    }
  }, [lastCreatedNode, selectedItem]);

  /**
   * When nodes change then last created node and selected item will be reset
   */
  useEffect(() => {
    setLastCreatedNode(null);
    setSelectedItem(null);
  }, [nodes]);

  useEffect(() => {
    if (!showDeleteAlert) {
      setStepDelete(null);
    }
  }, [showDeleteAlert]);

  useEffect(() => {
    if (!openStepModal) {
      setStepEdit(null);
    }
  }, [openStepModal]);
  useEffect(() => {
    if (!messageModal) {
      setMessageEdit(null);
    }
  }, [messageModal]);

  /**
   *  Event to handle when a node has been deleted
   * @param {string} id
   */
  const handleDeleteNode = (id) => {
    let allNodes = nodes.filter((node) => node.id !== id); // Filter all nodes except the one that has been deleted
    allNodes.forEach((node) => {
      if (node.sourceEndpoints) {
        // If node has source endpoints
        node.sourceEndpoints.forEach((ep) => {
          if (ep.children && ep.children.indexOf(id) !== -1) {
            // if source endpoint has children and the deleted node is one of them
            ep.children = ep.children.filter((child) => child !== id); // Remove the deleted node from the children
          }
        });
      } else if (node.children && node.children.indexOf(id) !== -1) {
        // If node has children and the deleted node is one of them
        node.children = node.children.filter((child) => child !== id); // Remove the deleted node from the children
      }
    });
    setNodes(allNodes);
  };

  const renderNode = (node) => {
    return (
      <div
        className={`node-item ${node.type === "MESSAGE" ? "message" : "step"}`}
      >
        <WorkflooNode
          title={node.title}
          onDelete={() => handleDeleteNode(node.id)}
        />
      </div>
    );
  };

  /**
   *  Event to handle when a node changes its position
   * @param {string} node id
   * @param {Object} props Props that changed
   */
  const handleNodeChange = (node, props) => {
    let allNodes = nodes.map((node) => ({ ...node }));
    let nodeIndex = allNodes.findIndex((nd) => nd.id === node);

    allNodes[nodeIndex] = { ...allNodes[nodeIndex], ...props };
    setNodes(allNodes);
  };

  /**
   *  Event to handle when node connection changed
   * @param {string} sourceId  Source node id
   * @param {string} sourceEndpoint Source endpoint id
   * @param {string} targetId Target node id
   */
  const handleConnectionsChange = (sourceId, sourceEndpoint, targetId) => {
    let allNodes = nodes.map((node) => ({ ...node }));
    let sourceNodeIndex = allNodes.findIndex((nd) => nd.id === sourceId);
    if (sourceNodeIndex > -1) {
      // If source node exists
      if (allNodes[sourceNodeIndex].sourceEndpoints) {
        // If source node has source endpoints
        let sourceEndpointIndex = allNodes[
          sourceNodeIndex
        ].sourceEndpoints.findIndex((sep) => sep.id === sourceEndpoint); // Find the source endpoint index
        allNodes[sourceNodeIndex].sourceEndpoints[
          sourceEndpointIndex
        ].children[0] = targetId;
        setNodes(allNodes);
      } else {
        if (allNodes[sourceNodeIndex].children) {
          allNodes[sourceNodeIndex].children = [
            ...allNodes[sourceNodeIndex].children,
            sourceEndpoint,
          ]; // Add the source endpoint to the children
        } else {
          allNodes[sourceNodeIndex].children = [sourceEndpoint]; // Add the source endpoint to the children
        }
        setNodes(allNodes);
      }
    }
  };

  /**
   *  Event to handle when node connection is removed
   * @param {string} sourceId  Source node id
   * @param {string} sourceEndpoint  Source endpoint id
   * @param {string} targetId  Target node id
   */
  const handleDeleteConnection = (sourceId, sourceEndpoint, targetId) => {
    let allNodes = nodes.map((node) => ({ ...node }));
    let sourceNodeIndex = allNodes.findIndex((nd) => nd.id === sourceId);
    if (sourceNodeIndex > -1) {
      // If source node exists
      if (allNodes[sourceNodeIndex].sourceEndpoints) {
        // If source node has source endpoints
        let sourceEndpointIndex = allNodes[
          sourceNodeIndex
        ].sourceEndpoints.findIndex((sep) => sep.id === sourceEndpoint); // Find the source endpoint index
        if (
          allNodes[sourceNodeIndex].sourceEndpoints[sourceEndpointIndex]
            .children // If source endpoint has children
        ) {
          allNodes[sourceNodeIndex].sourceEndpoints[
            sourceEndpointIndex
          ].children = allNodes[sourceNodeIndex].sourceEndpoints[
            sourceEndpointIndex
          ].children.filter((child) => child !== targetId); // Remove the target node from the children
          setNodes(allNodes);
        }
      } else {
        allNodes[sourceNodeIndex].children.splice(
          allNodes[sourceNodeIndex].children.indexOf(targetId),
          1
        ); // Remove the target node from the children
        setNodes(allNodes);
      }
    }
  };

  const handleOnDropOver = (e) => {
    const node = {
      posX: e.clientX - e.currentTarget.getBoundingClientRect().left - 44.5, // left position calculated by cursor position and canvas position
      posY: e.clientY - e.currentTarget.getBoundingClientRect().top - 44.5, // top position calculated by cursor position and canvas position
      id: generateID(), // unique id
    };
    setLastCreatedNode(node);
  };

  const handleDragItemEnd = (item) => {
    setSelectedItem(item);
  };

  /**
   * This functions is used to validate the form
   */
  const validateForm = () => {
    let formErrors = { ...errors };
    formErrors.name = !name;
    if (nodes) {
      // if there are nodes
      const stepsNoConnections = nodes.filter(
        (node) =>
          node.type === "STEP" &&
          (!node.sourceEndpoints[0].children ||
            !node.sourceEndpoints[1].children ||
            !node.sourceEndpoints[0].children.length ||
            !node.sourceEndpoints[1].children.length)
      ); // Find Steps with no connections
      if (stepsNoConnections.length) {
        // If there are steps with no connections
        formErrors.nodes = true;
        addAlert({
          code: "danger",
          message: "Es necesario conectar todos los pasos",
          duration: 5000,
        });
      } else {
        formErrors.nodes = false;
      }
    }
    setErrors(formErrors);
    return !formErrors.name && !formErrors.nodes;
  };

  /**
   * Event handler to save the connector
   */
  const handleSaveConnector = () => {
    if (validateForm()) {
      // If form is valid
      saveConnector({
        id: routeParams.id,
        name,
        auxName,
        nodes,
        messages,
        method: "put",
        steps: creditPolicyStep,
        onSuccess: () => {
          setAuxName(name);
          addAlert({
            code: "success",
            message: alertsTexts.success,
            duration: 5000,
          });
        },
        onError: (error) => {
          let message = alertsTexts.unexpectedError;
          if (error.message) {
            // If error has a message
            message = error.message; // Set the message
          }
          if (error.status === 409) {
            message = alertsTexts.nameAlreadyExist;
          }
          addAlert({
            code: "danger",
            message: message,
            duration: 5000,
          });
        },
      });
    }
  };

  const pageActions = [
    {
      text: "Guardar",
      onAction: handleSaveConnector,
    },
    {
      text: "Cancelar",
      onAction: () =>
        history.push(
          Paths.Connectors.replace(":connectorType", routeParams.connectorType)
        ),
    },
  ];

  const listsAddNewAction = (type) => ({
    label: "Agregar nuevo",
    icon: PlusIcon,
    onAction: () => {
      handleCreateStep(type);
    },
  });

  /**
   *  Event handler to delete steps
   * @param {string} type  Type of the step
   * @param {string} id  Id of the step
   * @returns
   */
  const handleDeleteStep = (type, id) => {
    const filteredNodes = nodes.filter((node) => node.itemId === id);
    if (filteredNodes.length) {
      addAlert({
        code: "danger",
        message: appTexts.connectors.creditPolicies.deleteStep[type],
        duration: 5000,
      });
    } else {
      setStepDelete({
        type,
        id,
      });
      setShowDeleteAlert(true);
    }
  };

  // const AddMessage = () => {
  //   getConnectorHandler()
  // }

  const deleteStep = () => {
    if (stepDelete) {
      deleteStepApi({
        id: stepDelete.id,
        connectorId: routeParams.id,
        onSuccess: () => {
          addAlert({
            code: "success",
            message:
              alertsTexts.connectors.creditPolicies.success.deleteStep[
                stepDelete.type
              ],
            duration: 5000,
          });
          let steps = [];
          let msgs = [];
          switch (stepDelete.type) {
            case "STEP":
              steps = creditPolicyStep.filter(
                (step) => step.id !== stepDelete.id
              ); // Remove the step from the steps
              msgs = messages.map((msg) => ({ ...msg }));
              setCreditPolicyStep(steps);
              break;
            case "MESSAGE":
              msgs = messages.filter((step) => step.id !== stepDelete.id); // Remove the step from the steps
              steps = creditPolicyStep.map((step) => ({ ...step }));
              setMessages(msgs);
              break;
            default:
              break;
          }
          saveConnector({
            id: routeParams.id,
            name,
            nodes,
            method: "put",
            steps,
            messages: msgs,
            onSuccess: () => {
              setTimeout(() => {
                addAlert({
                  code: "success",
                  message: alertsTexts.success,
                  duration: 5000,
                });
              }, 3000);
            },
            onError: (error) => {
              setTimeout(() => {
                let message = alertsTexts.unexpectedError;
                if (error.status === 409) {
                  message = alertsTexts.nameAlreadyExist;
                }
                addAlert({
                  code: "danger",
                  message: message,
                  duration: 5000,
                });
              }, 3000);
            },
          });
          setShowDeleteAlert(false);
        },
        onError: (error) => {
          addAlert({
            code: "danger",
            message:
              alertsTexts.connectors.creditPolicies.error.deleteStep[
                stepDelete.type
              ],
            duration: 5000,
          });
          setShowDeleteAlert(false);
        },
      });
    }
  };

  /**
   *  Event handler to duplicate steps and messages
   * @param {string} id Id of the step or message
   */
  const handleDuplicateStep = (id) => {
    duplicateStep({
      id,
      connectorId: routeParams.id,
      onSuccess: (data) =>
        setData({
          ...data,
        }),
      onError: () =>
        addAlert({
          code: "danger",
          message: alertsTexts.unexpectedError,
          duration: 5000,
        }),
    });
  };

  /**
   *  Event handler to edit steps and messages
   * @param {string} type Type of the step or message
   * @param {string} id  Id of the step or message
   */
  const handleEditStep = (type, id, item) => {
    if (type === "MESSAGE") {
      if (item) {
        setMessageEdit(item);
      }
      openMessageModal();
    } else {
      // const cryptId = Jwt.signToken({ id: routeParams.id });
      // const cryptIdStep = Jwt.signToken({ id });
      // let url = `${getHostName()}/auth/profil/creditPolicyEditStep_ultima_v2.xhtml?token=${Jwt.getToken()}&policy_id=${cryptId}&step_id=${cryptIdStep}`;
      // window.location.href = url;
      setStepEdit(creditPolicyStep.find((step) => step.id === id));
      toggleStepModal();
    }
  };

  /**
   *  Event handler to create a new step
   * @param {string} type Step type
   */
  const handleCreateStep = (type) => {
    if (type === "MESSAGE") {
      openMessageModal();
    } else {
      // const cryptId = Jwt.signToken({ id: routeParams.id });
      // let url = `${getHostName()}{path}?token={token}&policy_id=${cryptId}`;
      // window.location.href = url
      //   .replace(
      //     "{path}",
      //     type === "STEPS"
      //       ? "/auth/profil/creditPolicyEditStep_ultima_v2.xhtml"
      //       : "/auth/profil/creditPolicyEditMessage_ultima.xhtml"
      //   )
      //   .replace("{token}", Jwt.getToken());
      toggleStepModal();
    }
  };
  const toggleStepModal = () => {
    setOpenStepModal(!openStepModal);
  };
  const openMessageModal = () => {
    setOpenMessageModal(!messageModal);
  };

  const dropdownMarkup = (type, id, item) => (
    <Dropdown text="Acciones" align={type === "MESSAGE" ? "right" : "left"}>
      <Dropdown.Section
        options={[
          {
            content: "Duplicar",
            onAction: () => handleDuplicateStep(id),
          },
          {
            content: "Editar",
            onAction: () => {
              handleEditStep(type, id, item);
            },
          },
        ]}
      />
      <Dropdown.Section
        options={[
          {
            content: "Eliminar",
            onAction: () => handleDeleteStep(type, id),
            icon: TrashIcon,
          },
        ]}
      />
    </Dropdown>
  );
  const creditPoliciesRows = creditPolicyStep.map((step) => [
    <div
      className="node-item step"
      draggable
      onDragStart={() =>
        handleDragItemEnd({
          id: step.id,
          type: "STEP",
        })
      }
    >
      <WorkflooNode title={step.name} />
    </div>,
    dropdownMarkup("STEP", step.id),
  ]);

  const messageRows = messages.map((message) => [
    <div
      className="node-item message"
      draggable
      onDragStart={() =>
        handleDragItemEnd({
          id: message.id,
          type: "MESSAGE",
        })
      }
    >
      <WorkflooNode title={message.name} />
    </div>,
    dropdownMarkup("MESSAGE", message.id, message),
  ]);

  const handleSaveStep = (step) => {
    const steps = creditPolicyStep.map((s) => ({
      ...s,
      conditions: s.conditions ? s.conditions.map((c) => ({ ...c })) : [],
    }));
    const stepIndex = steps.findIndex((s) => s.id === step.id);
    if (stepIndex !== -1) {
      const allNodes = nodes.map((n) => ({ ...n }));
      const node = allNodes.findIndex((n) => n.itemId === step.id);
      if (node !== -1) {
        if (allNodes[node].title !== step.name) {
          allNodes[node].title = step.name;
          setNodes(allNodes);
        }
      }
      steps[stepIndex] = step;
    } else {
      steps.push(step);
    }
    setCreditPolicyStep(steps);
    toggleStepModal();
  };

  const handleApplyMessage = (message) => {
    const steps = messages.map((s) => ({
      ...s,
      conditions: s.conditions ? s.conditions.map((c) => ({ ...c })) : [],
    }));
    const stepIndex = steps.findIndex((s) => s.id === message.id);
    if (stepIndex !== -1) {
      const allNodes = nodes.map((n) => ({ ...n }));
      const node = allNodes.findIndex((n) => n.itemId === message.id);
      if (node !== -1) {
        if (allNodes[node].title !== message.name) {
          allNodes[node].title = message.name;
          setNodes(allNodes);
        }
      }
      steps[stepIndex] = message;
    } else {
      steps.push(message);
    }
    setMessages(steps);
    openMessageModal();
  };

  const modalAlertActions = [
    {
      text: "Aceptar",
      onAction: () => {
        if (stepTypeRef.current)
          if (stepTypeRef.current.action === "edit") {
            handleEditStep(stepTypeRef.current.type, stepTypeRef.current.id);
          } else {
            handleCreateStep(stepTypeRef.current.type);
          }
      },
    },
    {
      text: "Cancelar",
      onAction: () => {
        setModalOpen(false);
        stepTypeRef.current = null;
      },
    },
  ];

  const modalDeleteStepAlertActions = [
    {
      text: "Aceptar",
      onAction: () => deleteStep(),
    },
    {
      text: "Cancelar",
      onAction: () => setShowDeleteAlert(false),
    },
  ];

  return (
    <div className="main-content" id="AddEditCreditPolicies">
      <PageSection
        title="Árbol de decisión"
        primaryAction={pageActions[0]}
        secondaryActions={[pageActions[1]]}
      >
        <PageSection.Item size={1} length={3}>
          <TextField
            label="Nombre"
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
            errorText={"Debes completar este campo"}
          />
        </PageSection.Item>
        <PageSection.Item size={2} length={3} />
        <PageSection.Item size={1} length={1}>
          <div className="TablesContainer">
            <Table
              primaryAction={listsAddNewAction("STEPS")}
              title="Pasos"
              rows={creditPoliciesRows}
              textEmpty="No hay pasos"
              maxHeight={450}
            />
            <Table
              primaryAction={listsAddNewAction("MESSAGE")}
              title="Mensajes"
              rows={messageRows}
              textEmpty="No hay mensajes"
              maxHeight={450}
            />
          </div>
        </PageSection.Item>
        <PageSection.Item size={1} length={1}>
          <Panel className="FlowChart--Panel">
            <Panel.Section>
              <FlowChart
                graphHeight={800}
                graphId="creditpolicies-flow-chart"
                nodes={nodes}
                nodeClassName="credit-policy-flow-chart-node"
                renderNode={renderNode}
                onNodeChange={handleNodeChange}
                onConnectionsChange={handleConnectionsChange}
                onDeleteConnection={handleDeleteConnection}
                onDropOver={handleOnDropOver}
                sourceEndpoints={["BottomLeft", "BottomRight"]}
              />
            </Panel.Section>
          </Panel>
        </PageSection.Item>
      </PageSection>
      <ModalScreen
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="¿Seguro que deseas realizar esta operación?"
        description="Si realizas esta acción es necesario que guardes tus cambios o se perderán."
        primaryAction={modalAlertActions[0]}
        secondaryActions={[modalAlertActions[1]]}
      />
      <ModalScreen
        open={showDeleteAlert}
        onClose={() => setShowDeleteAlert(false)}
        title="¿Seguro que deseas eliminar este elemento?"
        description="Esta acción es irreversible, si realizas esta acción se eliminará el elemento de forma permanente."
        primaryAction={modalDeleteStepAlertActions[0]}
        secondaryActions={[modalDeleteStepAlertActions[1]]}
      />
      <MessageModal
        open={messageModal}
        onClose={openMessageModal}
        onApply={handleApplyMessage}
        messageToEdit={messageEdit}
      />
      <StepsModal
        open={openStepModal}
        onClose={toggleStepModal}
        onSave={handleSaveStep}
        stepToEdit={stepEdit}
      />
    </div>
  );
};

export default AddEditCreditPolicies;
