import { isValidURL } from "../../../../utils/validators";

const validateNIPSIC = ({ formErrors, sicData }) => {
  formErrors.urlTermsConditions =
    Boolean(!sicData.urlTermsConditions) ||
    !isValidURL(sicData.urlTermsConditions);
  formErrors.messagingSicConnector = Boolean(!sicData.messagingSicConnector);
  formErrors.emailSicConnector = Boolean(!sicData.emailSicConnector);
  formErrors.personType = Boolean(!sicData.personType);
  return (
    !formErrors.urlTermsConditions &&
    !formErrors.messagingSicConnector &&
    !formErrors.emailSicConnector &&
    !formErrors.personType
  );
};

export default validateNIPSIC;
