import { isValidIdentifier } from "../../../../utils/validators";

const onVariableChange = ({
  modalVariables,
  variableErrors,
  name,
  type,
  i,
}) => {
  const repeated = modalVariables.filter(
    (variable, index) => index !== i && variable.name.trim() === name.trim()
  );
  let variables = [...modalVariables];
  let errors = [...variableErrors];
  let error = errors.findIndex((error) => error.index === i);
  if (error === -1) {
    errors.push({ index: i });
    error = errors.length - 1;
  }
  if (!name) {
    errors[error].type = "EMPTY_NAME";
  } else if (repeated.length) {
    errors[error].type = "REPEATED_NAME";
  } else {
    errors.splice(error, 1);
  }
  variables[i].name = name;
  variables[i].vartype = type;
  return { variables, errors };
};

export default onVariableChange;
