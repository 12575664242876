import React, { useEffect, useState, useRef } from "react";
// start design-system
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Title from "@bit/kiban-design-system.kiban.title";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";
// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import "../ConfigurationPage.css";

export default function ConfigurationBankingCore() {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataConfig, setDataConfig] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const { addAlert } = useAlert();

  const getConfigBankingCore = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "BANKING_CORE"
      ),
    });
    if (!err) {
      const responseDataConfig =
        data.hasOwnProperty("bankingCoreResponse") && data.bankingCoreResponse;
      if (responseDataConfig) {
        setDataConfig(responseDataConfig);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigBankingCore();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const getObjRequest = () => {
    const keys = Object.keys(dataConfig.MambuConfig);
    let updateConfig = { ...dataConfig };
    keys.forEach((key) => {
      if (!updateConfig.Activated) {
        const keysInternal = Object.keys(updateConfig.MambuConfig[key]);
        keysInternal.forEach((keyInternal) => {
          updateConfig.MambuConfig[key][keyInternal] = "";
        });
      }
    });
    setDataConfig(updateConfig);
    return { bankingCoreRequest: { ...updateConfig } };
  };

  const updateConfigBankingCore = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: { ...getObjRequest() },
      method: "put",
      url: apiRoutes.getConfigConnector.replace(
        ":typeConnector",
        "BANKING_CORE"
      ),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  const handleOnChangeConfig = (key, attr, value) => {
    let updateConfig = { ...dataConfig };
    const updateObj =
      key === "Activated"
        ? { ...updateConfig }
        : { ...updateConfig.MambuConfig[key] };
    updateObj[key === "Activated" ? key : attr] = value;
    if (key === "Activated") {
      updateConfig = { ...updateObj };
    } else {
      updateConfig.MambuConfig[key] = updateObj;
    }
    const updateErrors = errorFields.includes(`${key}-${attr}`)
      ? errorFields.filter((keyError) => keyError !== `${key}-${attr}`)
      : [...errorFields];
    setErrorFields(updateErrors);
    setDataConfig(updateConfig);
  };

  const saveConfig = () => {
    const keys = Object.keys(dataConfig.MambuConfig);
    const updateConfig = { ...dataConfig };
    let objErrors = [];
    keys.forEach((key) => {
      if (updateConfig.Activated) {
        const keysInternal = Object.keys(updateConfig.MambuConfig[key]);
        keysInternal.forEach((keyInternal) => {
          if (
            key.includes("Sandbox") &&
            updateConfig.MambuConfig[key][keyInternal] === ""
          ) {
            objErrors.push(`${key}-${keyInternal}`);
          } else if (key.includes("Prod")) {
            const values = Object.values(updateConfig.MambuConfig[key]).join(
              ""
            );
            if (
              values !== "" &&
              updateConfig.MambuConfig[key][keyInternal] === ""
            ) {
              objErrors.push(`${key}-${keyInternal}`);
            }
          }
        });
      }
    });
    setErrorFields(objErrors);
    if (objErrors.length === 0) {
      updateConfigBankingCore();
    }
  };

  return loading ? (
    <Skeleton />
  ) : dataConfig && (
    <div className="main-content">
      <PageSection>
        <PageSection.Item size={1} length={2}>
          <div className="rows">
            <Title type="heading">Activar Mambu</Title>
            <div className="container-inputs">
              <RadioButton
                label="Si"
                name="mambu"
                value={true}
                checked={dataConfig && dataConfig.Activated}
                onChange={(checked, value) =>
                  handleOnChangeConfig("Activated", null, value === "true")
                }
              />
              <RadioButton
                label="No"
                name="mambu"
                value={false}
                checked={dataConfig && !dataConfig.Activated}
                onChange={(checked, value) =>
                  handleOnChangeConfig("Activated", null, value === "true")
                }
              />
            </div>
          </div>
          {dataConfig.Activated && (
            <>
              <TextField
                error={errorFields.includes("SandboxConfig-Endpoint")}
                errorText={"El campo no debe ir vacio"}
                label="Enpoint Sandbox"
                value={dataConfig?.MambuConfig?.SandboxConfig?.Endpoint}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "SandboxConfig",
                    "Endpoint",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.includes("SandboxConfig-ApiUsername")}
                errorText={"El campo no debe ir vacio"}
                label="Usuario API Sandbox"
                value={dataConfig?.MambuConfig?.SandboxConfig?.ApiUsername}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "SandboxConfig",
                    "ApiUsername",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.includes("SandboxConfig-ApiPassword")}
                errorText={"El campo no debe ir vacio"}
                label="Contraseña API Sandbox"
                type="password"
                value={dataConfig?.MambuConfig?.SandboxConfig?.ApiPassword}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "SandboxConfig",
                    "ApiPassword",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.includes("ProdConfig-Endpoint")}
                errorText={"El campo no debe ir vacio"}
                label="Enpoint producción"
                value={dataConfig?.MambuConfig?.ProdConfig?.Endpoint}
                onChange={(e) =>
                  handleOnChangeConfig("ProdConfig", "Endpoint", e.target.value)
                }
              />
              <TextField
                error={errorFields.includes("ProdConfig-ApiUsername")}
                errorText={"El campo no debe ir vacio"}
                label="Usuario API producción"
                value={dataConfig?.MambuConfig?.ProdConfig?.ApiUsername}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "ProdConfig",
                    "ApiUsername",
                    e.target.value
                  )
                }
              />
              <TextField
                error={errorFields.includes("ProdConfig-ApiPassword")}
                errorText={"El campo no debe ir vacio"}
                label="Contraseña API producción"
                type="password"
                value={dataConfig?.MambuConfig?.ProdConfig?.ApiPassword}
                onChange={(e) =>
                  handleOnChangeConfig(
                    "ProdConfig",
                    "ApiPassword",
                    e.target.value
                  )
                }
              />
              Usuario y contraseña de usuario que se deberá crear en Mambu
              dashboard, sección Administration -> Access -> Users con permisos
              API.
            </>
          )}
        </PageSection.Item>
        <PageSection.Item size={1} length={1}>
          <Button onClick={saveConfig} style="primary" text="Guardar" />
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
