import React, { useState, useRef, useEffect } from "react";
import Button from "@bit/kiban-design-system.kiban.button";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";

import { getFieldOptions } from "../utils";
import { containsOnlyNumbers } from "../../../../utils/validators";

import "./Section.css";

const Section = ({
  name,
  fields,
  id,
  onClickEditButtonField,
  onChange,
  onFocusField,
  onBlurField,
  focusId,
}) => {
  return (
    <div className="form-section" id={id}>
      <label className="section-name">{name}</label>
      {fields.map((field, index) => (
        <Field
          key={`Section-FormField-${field.id}`}
          name={field.name}
          id={field.langs ? field.langs.es : field.id}
          type={field.type}
          options={getFieldOptions(
            field.catalogs ||
              field.casosFicticios ||
              (field.listOptions && field.listOptions.split(";"))
          )}
          onClickEditButton={(fieldId) => onClickEditButtonField(id, fieldId)}
          defaultValue={field.defaultValue}
          onChange={(value) => onChange && onChange(field.id, value)}
          onFocus={() => onFocusField && onFocusField(field.id)}
          onBlur={() => onBlurField && onBlurField(field.id)}
          isFocused={field.id === focusId}
        />
      ))}
    </div>
  );
};

const Field = ({
  name,
  id,
  type,
  options,
  defaultValue,
  onClickEditButton,
  onChange,
  isFocused,
  onFocus,
  onBlur,
}) => {
  const textFieldRef = useRef();
  useEffect(() => {
    if (textFieldRef.current) {
      if (isFocused) {
        textFieldRef.current.querySelector("input") &&
          textFieldRef.current.querySelector("input").focus();
      } else {
        textFieldRef.current.querySelector("input") &&
          textFieldRef.current.querySelector("input").blur();
      }
    }
  }, [isFocused]);

  const getDefaultValue = (value, options) => {
    let defaultValue = "";
    if (options) {
      const valIndex = options.findIndex((opt) => opt.value === value);
      if (valIndex > -1) {
        defaultValue = value;
      }
    }
    return defaultValue;
  };

  return (
    <div className="FormField" ref={textFieldRef}>
      <div className="FormField__content">
        <div className="FormField__info">
          <div className="FormField__drag-handler"></div>
          <p className="FormField__name">{name}</p>
          <p className="FormField__id">
            ID API: <strong>{id}</strong>
          </p>
          <Button
            text="Modificar"
            onClick={() => onClickEditButton && onClickEditButton(id)}
          />
        </div>
        <div className="FormField__Field">
          <div className="FormField__drag-handler"></div>
          <div className="FormField__Field-Container">
            {(type === "ALPHABETIC" ||
              type === "ALPHANUMERIC" ||
              type === "TEXT" ||
              type === "POSTAL_CODE" ||
              type === "EMAIL" ||
              type === "PHONE" ||
              type === "INFORMATIVE_TEXT") && (
              <TextField
                placeholder={name}
                value={defaultValue}
                onChange={(e) => onChange && onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
            {type === "DATE" && (
              <TextField
                type="date"
                placeholder={name}
                value={defaultValue}
                onChange={(e) => onChange && onChange(e.target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
            {type === "NUMERIC" && (
              <TextField
                placeholder={name}
                value={defaultValue}
                onChange={(e) => {
                  if (
                    e.target.value === "" ||
                    containsOnlyNumbers(e.target.value)
                  ) {
                    onChange && onChange(e.target.value);
                  }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            )}
            {(type === "LIST" ||
              type === "CATALOG" ||
              type === "CASO_FICTICIO") && (
              <SelectField
                options={options}
                placeholder={`Selecciona una opción`}
                value={getDefaultValue(defaultValue, options)}
                onChange={onChange}
              />
            )}
            {type === "RADIO" &&
              options.map((option) => (
                <RadioButton
                  label={option.content}
                  checked={option.value === defaultValue}
                  onChange={() => onChange(option.value)}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
