import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Jwt from "../../utils/jwt";

import UsersList from "./UsersList";

const Users = () => {
  const history = useHistory();

  useEffect(() => {
    if (!Jwt.validateToken()) {
      history.push("/login");
    }
  }, []);

  return (
    <div className="main-content">
      <UsersList />
    </div>
  );
};

export default Users;
