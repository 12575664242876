import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  root: {
    borderCollapse: "collapse",
    "& .MuiTableCell-root": {
      border: "solid 1px #bdbdbd !important",
      fontFamily: '"Roboto","Helvetica Neue",sans-serif !important',
      fontSize: "13px",
      padding: "6px 10px",
      lineHeight: "18px",
      textAlign: "center",
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        letterSpacing: "0",
        backgroundColor: "#FFF !important",
      },
    },
    "& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)": {
      backgroundColor: "#f4f4f4",
    },
  },
  container: {
    maxHeight: "370px",
  },
});
