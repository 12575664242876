import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Alert from "@bit/kiban-design-system.kiban.alert";
import FooterActions from "@bit/kiban-design-system.kiban.footer-actions";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import Tabs from "@bit/kiban-design-system.kiban.tabs";

import HeaderQueryParams from "./HeadersQueryParams";
import Body from "./Body";
import FormApi from "./FormApi";
import ApiCode from "./ApiCode/ApiCode";

import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import b64DecodeUnicode from "../../../utils/b64DecodeUnicode";
import Paths from "../../../paths";

const API = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [optionsMethod, setOptionsMethod] = useState([]);
  const [optionsContentType, setOptionsContentType] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [queryParameters, setQueryParameters] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    url: false,
    requestMethod: false,
    contentType: false,
  });
  const [tabSelected, setTabSelected] = useState(0);
  const [exampleApi, setExampleApi] = useState(null);
  const [draft, setDraft] = useState({
    example: "",
    files: [],
  });
  const [dataCode, setDataCode] = useState({
    example: "",
    files: [],
  });
  const [changes, setChanges] = useState(false);
  const [results, setResults] = useState(null);

  const routeParams = useParams();
  const history = useHistory();

  const getExample = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getApiExample,
    });
    if (!error) {
      if (!routeParams.id) {
        const updateDraft = { ...draft };
        const updateDataCode = { ...dataCode };
        updateDraft.example = b64DecodeUnicode(data.example);
        updateDataCode.example = b64DecodeUnicode(data.example);
        const filesJs = data.js_files.map((fileJs) => {
          return {
            ...fileJs,
            data: b64DecodeUnicode(fileJs.data),
          };
        });
        updateDraft.files = filesJs;
        updateDataCode.files = filesJs;
        setDataCode(updateDataCode);
        setDraft(updateDraft);
      } else {
        setExampleApi(data);
      }
    }
  };

  const getCatalogs = async () => {
    const [err, dataCatalogs] = await apiRequest({
      method: "get",
      url: apiRoutes.getCatalogsApi,
    });
    if (!err) {
      setOptionsMethod(
        dataCatalogs.httpMethods.map((item) => {
          return {
            content: item.const,
            value: item.const,
          };
        })
      );
      setOptionsContentType(
        dataCatalogs.contentTypes.map((item) => {
          return {
            content: item.const,
            value: item.const,
          };
        })
      );
    } else {
      setStatus({ code: "danger", text: "Ocurrio un error" });
    }
    setIsLoading(false);
  };

  const getConnector = async (idConnector) => {
    const [error, data] = await apiRequest({
      method: "GET",
      url: apiRoutes.getConnector.replace(
        ":id",
        idConnector ? idConnector : routeParams.id
      ),
    });
    if (data) {
      if (data.headers) {
        const headers = Object.keys(data.headers).map((key) => [
          [{ value: key }],
          [{ value: data.headers[key] }],
        ]);
        setHeaders(headers);
      }
      if (data.queryParameters) {
        const params = Object.keys(data.queryParameters).map((key) => [
          [{ value: key }],
          [{ value: data.queryParameters[key] }],
        ]);
        setQueryParameters(params);
      }
      setData({ ...data });
      if (data.hasOwnProperty("postProcessingResult")) {
        setResults(data.postProcessingResult);
      }
    }
    if (error) {
      setStatus({
        code: "danger",
        text:
          error.status !== 404
            ? "Ocurrió un error inesperado al obtener el conector"
            : "Conector no encontrado",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getCatalogs();
      getExample();
      if (routeParams.id) {
        getConnector();
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    const updateDraft = { ...draft };
    const updateDataCode = { ...dataCode };
    if (data && exampleApi) {
      const source = data.hasOwnProperty("postProcessing")
        ? data.postProcessing
        : exampleApi;
      updateDraft.example = b64DecodeUnicode(source.example);
      updateDataCode.example = b64DecodeUnicode(source.example);
      const filesJs = source.js_files.map((fileJs) => {
        return {
          ...fileJs,
          data: b64DecodeUnicode(fileJs.data),
        };
      });
      updateDraft.files = filesJs;
      updateDataCode.files = filesJs;
      setDataCode(updateDataCode);
      setDraft(updateDraft);
    }
  }, [data, exampleApi]);

  const saveConnector = async () => {
    const apiHeaders = {};
    const apiQueryParameters = {};
    headers.forEach((header, i) => {
      if (!header[0][0].value || !header[1][0].value) {
        const nHeaders = getDynamicTableRow(headers);
        nHeaders.splice(i, 1);
        setHeaders(nHeaders);
      } else {
        apiHeaders[header[0][0].value] = header[1][0].value;
      }
    });
    queryParameters.forEach((param, i) => {
      if (!param[0][0].value || !param[1][0].value) {
        const nParams = getDynamicTableRow(queryParameters);
        nParams.splice(i, 1);
        setQueryParameters(nParams);
      } else {
        apiQueryParameters[param[0][0].value] = param[1][0].value;
      }
    });
    let postProcessing = null;
    let dataApi = { ...data };
    if (changes) {
      postProcessing = {};
      postProcessing.example = btoa(
        unescape(encodeURIComponent(dataCode.example))
      );
      postProcessing.js_files = dataCode.files.map((file) => {
        return {
          ...file,
          data: btoa(unescape(encodeURIComponent(file.data))),
        };
      });
      dataApi = { ...dataApi, postProcessing: postProcessing };
    }
    const [error, connectorData] = await apiRequest({
      method: routeParams.id ? "put" : "post",
      url: routeParams.id
        ? apiRoutes.getEditConnector
            .replace(":typeConnector", routeParams.connectorType.toUpperCase())
            .replace(":id", routeParams.id)
        : apiRoutes.addConnector.replace(
            ":typeConnector",
            routeParams.connectorType.toUpperCase()
          ),
      data: {
        ...dataApi,
        headers: apiHeaders,
        queryParameters: apiQueryParameters,
      },
    });
    if (connectorData !== null) {
      setStatus({ code: "success", text: AlertTexts.success });
      setTimeout(() => setStatus(null), 5000);
      setDraft(dataCode);
      setChanges(false);
    }
    if (error) {
      setStatus({
        code: "danger",
        text: error.message ? error.message : "Ocurrió un error",
      });
    }
    setIsLoading(false);
    if (!routeParams.id) {
      history.push(
        Paths.EditConnectors.replace(
          ":connectorType",
          routeParams.connectorType
        ).replace(":id", connectorData.id)
      );
    }
    if (dataApi.hasOwnProperty("postProcessing")) {
      getConnector(!routeParams.id ? connectorData.id : null);
    }
  };

  const isFormValid = () => {
    let formErrors = {
      name: false,
      url: false,
      requestMethod: false,
      contentType: false,
    };
    if (!data?.name) {
      formErrors.name = true;
    }
    if (!data?.url) {
      formErrors.url = true;
    }
    if (!data?.requestMethod) {
      formErrors.requestMethod = true;
    }
    if (!data?.contentType) {
      formErrors.contentType = true;
    }
    setErrors(formErrors);
    return (
      !formErrors.name &&
      !formErrors.url &&
      !formErrors.requestMethod &&
      !formErrors.contentType
    );
  };

  const onChangeData = (value, key) => {
    const updateData = { ...data };
    updateData[key] = value;
    setData(updateData);
  };

  const onCodeChange = (code) => {
    setData({
      ...data,
      bodyBase64: btoa(unescape(encodeURIComponent(code))),
    });
  };

  const onSaveConnector = () => {
    setIsLoading(true);
    if (isFormValid()) {
      saveConnector();
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  };

  const getDynamicTableRow = (array) => {
    const nArray = array.map((row) =>
      row.map((cell) => cell.map((value) => ({ ...value })))
    );
    return nArray;
  };

  const onChangeRow = (key, action, item, row, value) => {
    switch (key) {
      case "headers":
        const nHeaders = [...headers];
        if (action === "ADD") nHeaders.push([[{ value: "" }], [{ value: "" }]]);
        if (action === "REMOVE") nHeaders.splice(item, 1);
        if (action === "CHANGE") {
          nHeaders[row][item] = [{ value }];
        }
        setHeaders(nHeaders);
        break;
      case "queryParameters":
        const nQueryParameters = getDynamicTableRow(queryParameters);
        if (action === "ADD") {
          nQueryParameters.push([[{ value: "" }], [{ value: "" }]]);
        }
        if (action === "REMOVE") nQueryParameters.splice(item, 1);
        if (action === "CHANGE") {
          nQueryParameters[row][item] = [{ value }];
        }
        setQueryParameters(nQueryParameters);
        break;
      default:
        return;
    }
  };

  const headerQueryParamsData = [
    {
      title: "Headers",
      sectionKey: "headers",
      data: headers,
    },
    {
      title: "Query Parameters",
      sectionKey: "queryParameters",
      data: queryParameters,
    },
  ];

  const saveAction = {
    text: "Guardar",
    onAction: onSaveConnector,
  };
  const cancelAction = {
    text: "Cancelar",
    onAction: () => {
      history.replace(
        Paths.Connectors.replace(":connectorType", routeParams.connectorType)
      );
    },
  };

  return (
    <div className="main-content">
      {status && <Alert {...status} />}
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          <Tabs
            indexSelected={tabSelected}
            onChange={(index) => setTabSelected(index)}
            title="API"
            primaryAction={saveAction}
            secondaryActions={[cancelAction]}
          >
            <Tabs.Item>Detalles</Tabs.Item>
            <Tabs.Item>Pos procesamiento</Tabs.Item>
          </Tabs>
          {tabSelected === 0 && (
            <>
              <PageSection>
                <FormApi
                  data={data}
                  onChangeData={onChangeData}
                  optionsMethod={optionsMethod}
                  optionsContentType={optionsContentType}
                  errors={errors}
                />
              </PageSection>
              {headerQueryParamsData.map((data, i) => {
                return (
                  <HeaderQueryParams
                    onAdd={(key) => onChangeRow(key, "ADD")}
                    onRemove={(key, item) => onChangeRow(key, "REMOVE", item)}
                    onChange={(key, value, row, item) =>
                      onChangeRow(key, "CHANGE", item, row, value)
                    }
                    {...data}
                  />
                );
              })}
              <Body onChange={onCodeChange} code={data?.bodyBase64} />
              <FooterActions
                primaryAction={saveAction}
                secondaryActions={[cancelAction]}
              />
            </>
          )}
          {tabSelected === 1 && (
            <ApiCode
              dataCode={dataCode}
              draft={draft}
              setDataCode={setDataCode}
              changes={changes}
              setChanges={setChanges}
              results={results}
            />
          )}
        </>
      )}
    </div>
  );
};

export default API;
