import { useEffect, useState } from "react";
// start design-system
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

// end design-system
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";
import AlertTexts from "../../../utils/alerts-texts";
import "./ConfigurationBuro.css";

import ConfigBuroMX from "./ConfigBuroMX";
import ConfigBuroCO from "./ConfigBuroCO";
import ConfigBuroCL from "./ConfigBuroCL";

export default function ConfigurationBuro() {
  const [loading, setLoading] = useState(true);
  const [dataConfig, setDataConfig] = useState({});
  const [requestKey, setRequestKey] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const { addAlert } = useAlert();

  const getConfigBuro = async () => {
    const [err, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "CONSULTA"),
    });
    if (!err) {
      const responseDataConfig =
        data.buroMXResponse || data.buroCOResponse || data.buroCLResponse;
      if (responseDataConfig) {
        const requestKey = Object.keys(data)[0];
        setDataConfig(responseDataConfig);
        setRequestKey(requestKey);
      }
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
      getConfigBuro();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const getObjRequest = () => {
    let updateConfig = { ...dataConfig };
    setDataConfig(updateConfig);
    return { buroRequest: { ...updateConfig } };
  };

  const updateConfigBuro = async () => {
    setLoading(true);
    const [err, data] = await apiRequest({
      data: getObjRequest(),
      method: "put",
      url: apiRoutes.getConfigConnector.replace(":typeConnector", "CONSULTA"),
    });
    if (!err) {
      addAlert({
        duration: 5000,
        code: "success",
        message: AlertTexts.success,
      });
    } else {
      addAlert({
        duration: 5000,
        code: "danger",
        message: "Ocurrio un error vuelve a intentar",
      });
    }
    setLoading(false);
  };

  const handleOnChangeConfig = (obj, key, attr, value) => {
    const updateConfig = { ...dataConfig };
    const updateObj = { ...updateConfig[obj][key] };
    updateObj[attr] = value;
    updateConfig[obj][key] = updateObj;
    setDataConfig(updateConfig);
  };

  const handleOnChangeCO = (data) => {
    setDataConfig(data);
  };

  const handleOnChangeCL = (data) => {
    setDataConfig(data);
  };

  const saveConfig = () => {
    setLoading(true);
    if (
      (requestKey === "buroMXResponse" && formValidMX()) ||
      (requestKey === "buroCOResponse" && formValidCO()) ||
      (requestKey === "buroCLResponse" && formValidCL())
    ) {
      updateConfigBuro();
    } else {
      setTimeout(() => setLoading(false), 500);
    }
  };

  const formValidMX = () => {
    let errors = {
      buroCredito: {
        PersonaMoral: {},
      },
      circuloCredito: {
        PersonaMoral: {},
        PersonaFisica: {},
        Pld: {},
        Lae: {},
      },
    };
    if (dataConfig.CirculoCredito.PersonaFisica.Activated) {
      const claveOtorgante =
        dataConfig.CirculoCredito.PersonaFisica.ClaveOtorgante;
      if (
        !claveOtorgante ||
        claveOtorgante.length < 6 ||
        claveOtorgante.length > 10
      ) {
        errors["circuloCredito"]["PersonaFisica"]["ClaveOtorgante"] = true;
      }
    }
    if (dataConfig.CirculoCredito.PersonaMoral.Activated) {
      const codigoInstitucion =
        dataConfig.CirculoCredito.PersonaMoral.CodigoInstitucion;
      if (!codigoInstitucion || codigoInstitucion.length !== 4) {
        errors["circuloCredito"]["PersonaMoral"]["CodigoInstitucion"] = true;
      }
    }
    if (dataConfig.BuroCredito.PersonaMoral.Activated) {
      if (
        !dataConfig.BuroCredito.PersonaMoral.CodigoInstitucion ||
        dataConfig.BuroCredito.PersonaMoral.CodigoInstitucion.length !== 4 ||
        isNaN(
          parseInt(dataConfig.BuroCredito.PersonaMoral.CodigoInstitucion, 10)
        )
      ) {
        errors["buroCredito"]["PersonaMoral"]["CodigoInstitucion"] = true;
      }
    }
    if (
      dataConfig.CirculoCredito.Pld.ActivatedCCPldCheck ||
      dataConfig.CirculoCredito.Lae.ActivatedCCLae
    ) {
      if (!dataConfig.CirculoCredito.Pld.ConsumerKey) {
        errors["circuloCredito"]["Pld"]["ConsumerKey"] = true;
      }
      if (!dataConfig.CirculoCredito.Pld.User) {
        errors["circuloCredito"]["Pld"]["User"] = true;
      }
      if (!dataConfig.CirculoCredito.Pld.Password) {
        errors["circuloCredito"]["Pld"]["Password"] = true;
      }
      if (!dataConfig.CirculoCredito.Pld.Signature) {
        errors["circuloCredito"]["Pld"]["Signature"] = true;
      }
    }
    setErrorFields(errors);
    return (
      !errors.circuloCredito.PersonaFisica.ClaveOtorgante &&
      !errors.circuloCredito.PersonaMoral.CodigoInstitucion &&
      !errors.circuloCredito.Pld.Signature &&
      !errors.circuloCredito.Pld.Password &&
      !errors.circuloCredito.Pld.User &&
      !errors.circuloCredito.Pld.ConsumerKey &&
      !errors.buroCredito.PersonaMoral.CodigoInstitucion &&
      !errors.circuloCredito.PersonaMoral.CodigoInstitucion &&
      !errors.circuloCredito.PersonaFisica.ClaveOtorgante
    );
  };

  const formValidCO = () => {
    let errors = {
      TransUnion: {
        IdCifin: false,
        PasswordCifin: false,
      },
      Experian: {
        User: false,
        Password: false,
      },
    };
    if (dataConfig.Transunion.Activated) {
      if (!dataConfig.Transunion.IdCifin) {
        errors.TransUnion.IdCifin = true;
      }
      if (!dataConfig.Transunion.PasswordCifin) {
        errors.TransUnion.PasswordCifin = true;
      }
    }
    if (dataConfig.Experian.Activated) {
      if (!dataConfig.Experian.User) {
        errors.Experian.User = true;
      }
      if (!dataConfig.Experian.Password) {
        errors.Experian.Password = true;
      }
    }
    setErrorFields(errors);
    return (
      !errors.TransUnion.IdCifin &&
      !errors.TransUnion.PasswordCifin &&
      !errors.Experian.User &&
      !errors.Experian.Password
    );
  };

  const formValidCL = () => {
    let errors = {
      Equifax: {
        User: false,
        Password: false,
      },
    };
    if (dataConfig.Equifax.Activated) {
      if (!dataConfig.Equifax.User) {
        errors.Equifax.User = true;
      }
      if (!dataConfig.Equifax.Password) {
        errors.Equifax.Password = true;
      }
    }
    setErrorFields(errors);
    return !errors.Equifax.User && !errors.Equifax.Password;
  };

  const renderBuro = () => {
    if (requestKey === "buroCOResponse")
      return (
        <ConfigBuroCO
          data={dataConfig}
          onChange={handleOnChangeCO}
          onSave={saveConfig}
          errorFields={errorFields}
        />
      );
    if (requestKey === "buroMXResponse")
      return (
        <ConfigBuroMX
          dataConfig={dataConfig}
          errorFields={errorFields}
          handleOnChangeConfig={handleOnChangeConfig}
          saveConfig={saveConfig}
        />
      );
    if (requestKey === "buroCLResponse")
      return (
        <ConfigBuroCL
          data={dataConfig}
          errorFields={errorFields}
          onChange={handleOnChangeCL}
          onSave={saveConfig}
        />
      );
  };

  return loading ? (
    <Skeleton />
  ) : (
    <div className="main-content">{renderBuro()}</div>
  );
}
