import React from "react";
import { getHostName } from "../../utils/menuRights";
import ConfigurationKyc from "./Kyc/ConfigurationKyc";
import ConfigurationDigitalSign from "./DigitalSign/ConfigurationDigitalSign";
import ConfigurationBankingCore from "./BankingCore/ConfigurationBankingCore";
import ConfigurationBuro from "./Buro/ConfigurationBuro";
import ConfigurationMessaging from "./Messaging/ConfigMessaging";
import ConfigPlans from "./Messaging/ConfigPlans";
import ConfigurationCleanData from "./CleanData/ConfigurationCleanData";
import ConfigurationOpenBanking from "./OpenBanking/ConfigurationOpenBanking";
import ConfigurationScores from "./Scores/Scores";
import ConfigurationPayments from "./Payments/ConfigView";

const hostname = getHostName();

export default {
  "credit-policies": {
    title: "Árboles de decisión",
    primaryAction: "Nuevo árbol de decisión",
    emptyState:
      "Este conector permite generar Árboles de decisión. Puedes comenzar dando clic en Nuevo árbol de decisión.",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar el árbol de decisión seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    link: "/connectors/credit-policies/add",
    editLink: `/connectors/credit-policies/edit/:id`,
    tabs: [],
    pagesTabs: [],
  },
  documents: {
    title: "Documentos",
    primaryAction: "Nuevo documento",
    emptyState:
      "Este conector permite generar Documentos. Puedes comenzar dando clic en Nuevo documento.",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar el documento seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    // link: `${hostname}/auth/document/documentConnectorEdit.xhtml?token={token}`,
    link: `/connectors/documents/add`,
    editLink: "/connectors/documents/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
  forms: {
    title: "Formularios",
    primaryAction: "Nuevo formulario",
    emptyState:
      "Este conector permite generar Formularios. Puedes comenzar dando clic en Nuevo formulario.",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar el formulario seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    // link: `${hostname}/auth/app/formEdit.xhtml?token={token}`,
    // editLink: `${hostname}/auth/app/formEdit.xhtml?id={id}&token={token}`,
    link: `/connectors/forms/add`,
    editLink: `/connectors/forms/edit/:id`,
    tabs: [],
    pagesTabs: [],
  },
  vp: {
    title: "Variables dinámicas",
    primaryAction: "Nuevo proyecto",
    emptyState:
      "Este conector perimte generar Variables personalizadas por medio de la creación de código JavaScript",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar este proyecto de variables personalizadas?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/vp/add",
    editLink: "/connectors/vp/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
  identity: {
    title: "KYC",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/identity/add",
    editLink: "/connectors/identity/edit/:id",
    tabs: ["KYC", "Configuración"],
    pagesTabs: [<ConfigurationKyc />],
  },
  digitalsign: {
    title: "Firma Electrónica",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/digitalsign/add",
    editLink: "/connectors/digitalsign/edit/:id",
    tabs: ["Firma Electrónica", "Configuración"],
    pagesTabs: [<ConfigurationDigitalSign />],
  },
  buro: {
    title: "Buró",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: `/connectors/buro/add`,
    editLink: `/connectors/buro/edit/:id`,
    tabs: ["Burós", "Configuración"],
    pagesTabs: [<ConfigurationBuro />],
  },
  bankingcore: {
    title: "Core Bancario",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: `${hostname}/auth/bankingcore/bankingCoreConnectorNew.xhtml?token={token}`,
    editLink: `${hostname}/auth/bankingcore/bankingCoreConnectorEditMAMBU.xhtml?id={id}&token={token}`,
    tabs: ["Core Bancario", "Configuración"],
    pagesTabs: [<ConfigurationBankingCore />],
  },
  cleandata: {
    title: "Clean Data",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/cleandata/add",
    editLink: "/connectors/cleandata/edit/:id",
    tabs: ["Clean Data", "Configuración"],
    pagesTabs: [<ConfigurationCleanData />],
  },
  openbanking: {
    title: "Open Banking",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/openbanking/add",
    editLink: "/connectors/openbanking/edit/:id",
    tabs: ["Open Banking", "Configuración"],
    pagesTabs: [<ConfigurationOpenBanking />],
  },
  score: {
    title: "Scores",
    primaryAction: "Crear Nuevo",
    emptyState: "No se econtraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente.",
    link: "/connectors/score/add",
    editLink: "/connectors/score/edit/:id",
    tabs: ["Scores", "Configuración"],
    pagesTabs: [<ConfigurationScores />],
  },
  messaging: {
    title: "Mensajeria",
    primaryAction: "Crear Nuevo",
    emptyState: "No se encontraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará este elemento de forma permanente",
    link: "/connectors/messaging/add",
    editLink: "/connectors/messaging/edit/:id",
    tabs: ["Mensajeria", "SMS", "Email", "WhatsApp"],
    pagesTabs: [
      <ConfigPlans type="SMS" />,
      <ConfigurationMessaging type="Email" />,
      <ConfigPlans type="WhatsApp" />,
    ],
  },
  payments: {
    title: "Pagos",
    primaryAction: "Crear Nuevo",
    emptyState: "No se econtraron registros",
    deleteAlertTitle: "¿Está seguro de querer eliminar este elemento?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente.",
    link: `/connectors/payments/add`,
    editLink: `/connectors/payments/edit/:id`,
    tabs: ["Pagos", "Configuración"],
    pagesTabs: [<ConfigurationPayments />],
  },
  api: {
    title: "API",
    primaryAction: "Nueva API",
    emptyState:
      "Este conector permite generar Apis. Puedes comenzar dando clic en Nueva API.",
    deleteAlertTitle: "¿Está seguro de querer eliminar la API seleccionada?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    link: "/connectors/api/add",
    editLink: "/connectors/api/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
  nip: {
    title: "NIP",
    primaryAction: "Nuevo NIP",
    emptyState:
      "Este conector permite generar conectores NIP. Puedes comenzar dando clic en Nuevo NIP.",
    deleteAlertTitle: "¿Está seguro de querer eliminar el NIP seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    link: "/connectors/nip/add",
    editLink: "/connectors/nip/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
  rainao: {
    title: "Rainao",
    primaryAction: "Crear nuevo",
    emptyState:
      "Este conector permite generar conectores Rainao. Puedes comenzar dando clic en Crear nuevo.",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar el conector de Rainao seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    link: "/connectors/rainao/add",
    editLink: "/connectors/rainao/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
  timer: {
    title: "Timer",
    primaryAction: "Crear nuevo",
    emptyState:
      "Este conector permite generar conectores Timer. Puedes comenzar dando clic en Crear nuevo.",
    deleteAlertTitle:
      "¿Está seguro de querer eliminar el conector Timer seleccionado?",
    deleteAlertDescription:
      "Esta acción es irreversible, se eliminará de forma permanente",
    link: "/connectors/timer/add",
    editLink: "/connectors/timer/edit/:id",
    tabs: [],
    pagesTabs: [],
  },
};
