import React, { useState, useEffect } from "react";
// start design-system
import Alert from "@bit/kiban-design-system.kiban.alert";
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import SkeletonTable from "@bit/kiban-design-system.kiban.skeleton-table";
import Table from "@bit/kiban-design-system.kiban.table";
import Loading from "@bit/kiban-design-system.kiban.loading";
// end design-system

import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";
import useTablePagination from "../../utils/useTablePagination";

// TODO actions and connect with API
export default function Synchronization() {
  const limit = 1000;
  const [totalSynchs, setTotalSynchs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSynch, setIsLoadingSynch] = useState(false);
  const [synchs, setSynchs] = useState([]);
  const [status, setStatus] = useState(null);

  const b64DecodeUnicode = (str) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const b64ToJson = (strB64) => {
    return JSON.stringify(JSON.parse(b64DecodeUnicode(strB64)));
  };

  const getSynchs = async () => {
    setIsLoading(true);
    const [err, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getLogActivity}?limit=${limit}&skip=0`,
    });
    if (!err) {
      setSynchs(data.logActivities ? data.logActivities : []);
      setTotalSynchs(data.logActivities ? data.logActivities.length : 0);
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrió un error, vuelve a intentarlo",
      });
    }
    setIsLoading(false);
  };

  const synchronization = async () => {
    setIsLoadingSynch(true);
    const [err, data] = await apiRequest({
      method: "post",
      url: apiRoutes.synchronization,
    });
    if (!err) {
      getSynchs();
      setStatus({
        code: "success",
        text: "Se sincronizaron los datos con éxito",
      });
    } else {
      setStatus({
        code: "danger",
        text: "Ocurrió un error, vuelve a intentarlo",
      });
    }
    setIsLoadingSynch(false);
  };

  const {
    from,
    goBack,
    goForward,
    hasNext,
    hasPrevious,
    page,
    skipBack,
    skipForward,
    to,
  } = useTablePagination({
    initialPage: 1,
    rowsPerPage: limit,
    totalItems: totalSynchs,
  });

  const tablePagination = {
    current: from,
    results: to,
    total: totalSynchs,
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    onNext: goForward,
    onPrevious: goBack,
    onSkipBack: skipBack,
    onSkipForward: skipForward,
  };

  const rows =
    synchs.length > 0
      ? synchs
          .slice(limit * (page - 1), limit * page)
          .map((sync) => [
            sync.date,
            sync.time,
            sync.status,
            b64ToJson(sync.data),
            ,
          ])
      : [];

  // TODO for next release
  // const primaryAction = {
  //   label: "Exportar a un excel",
  //   text: "Exportar a un excel",
  //   disabled: isLoading,
  //   onAction: () => {},
  // };

  const tableHeaders = ["Fecha", "Hora", "Estatus", "Datos"];

  useEffect(() => {
    if (status) {
      var timeoutAlert = setTimeout(function () {
        setStatus(null);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutAlert);
    };
  }, [status]);

  useEffect(() => {
    if (Jwt.validateToken()) {
      getSynchs();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const stylesLoader = {
    display: "flex",
    justifyContent: "center",
  };

  return isLoading ? (
    <SkeletonTable />
  ) : (
    <>
      <div className="main-content">
        {status && <Alert {...status} />}
        <PageSection title="Copiar la configuración en el ambiente de producción">
          <PageSection.Item size={1} length={1}>
            <Button
              text="Sincronizar"
              style="primary"
              disabled={isLoadingSynch}
              onClick={() => synchronization()}
            />
            <p style={{ color: "red" }}>
              Todos los workfloos y conectores del ambiente de producción serán
              reemplazados con la configuración del ambiente de pruebas.
            </p>
          </PageSection.Item>
          <PageSection.Item size={1} length={1}>
            {isLoadingSynch ? (
              <div style={stylesLoader}>
                <Loading />
              </div>
            ) : (
              <Table
                title="Últimas sincronizaciones"
                headings={tableHeaders}
                rows={rows}
                // primaryAction={primaryAction} TODO for next realease
                pagination={tablePagination}
                textEmpty="No se encontraron resultados"
              />
            )}
          </PageSection.Item>
        </PageSection>
      </div>
    </>
  );
}
