import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@bit/kiban-design-system.kiban.button";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Title from "@bit/kiban-design-system.kiban.title";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { apiRequest, apiRoutes } from "../../services";
import Jwt from "../../utils/jwt";

export default function Authorization() {
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const styles = {
    display: "flex",
    justifyContent: "space-between",
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  useEffect(() => {
    if (Jwt.validateToken()) {
    } else {
      history.push("/login");
    }
  }, []);

  async function handleSave() {
    setIsLoading(true);
    const response = await apiRequest({
      method: "post",
      url: apiRoutes.authorization,
      data: {
        type: "BC",
        start: selectedStartDate,
        end: selectedEndDate,
      },
    });
    if (response) {
      let blob = new Blob([response], { type: "content-type text/csv" }),
        url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Auditoria_autenticador_bc.csv");
      document.body.appendChild(link);
      link.click();
    }
    setIsLoading(false);
  }

  const download = () => {
    setIsLoading(true);
    const url =
      "https://storage.googleapis.com/assets.kiban.tech/files/carta_autenticacion_bc.doc";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Carta_autenticacion_bc.csv");
    document.body.appendChild(link);
    link.click();
    setIsLoading(false);
  };

  return (
    <>
      <div className="main-content">
        <PageSection title="Auditoría">
          <PageSection.Item size={1} length={3}>
            <div style={styles}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="start-date"
                  label="Fecha de principio"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="end-date"
                  label="Fecha de fin"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading}
                />
              </MuiPickersUtilsProvider>
            </div>
          </PageSection.Item>
          <PageSection.Item size={1} length={3}>
            <Button
              onClick={() => handleSave()}
              style="primary"
              text="Auditoría Autenticador BC"
              loading={isLoading}
              disabled={isLoading}
            />
          </PageSection.Item>
          <PageSection.Item size={1} length={3} />
          <PageSection.Item size={1} length={3}>
            <Title type="heading">
              Carta autorización a adjuntar al envío de auditoría
            </Title>
          </PageSection.Item>
          <PageSection.Item size={1} length={3}>
            <Button
              onClick={() => download()}
              style="primary"
              text="Descargar"
              loading={isLoading}
              disabled={isLoading}
            />
          </PageSection.Item>
        </PageSection>
      </div>
    </>
  );
}
