const isAnyFileEmpty = (files) => {
  const emptyFiles = files.filter(
    (file) => file.data === "" || file.data === null
  );
  return emptyFiles.length > 0;
};

const isFormValid = ({ name, variables, files, example }) => {
  let formErrors = [];
  if (name === "" || name === null) {
    formErrors.push({
      type: "name",
      message: "Debes introducir un nombre para el proyecto",
    });
  }
  if (variables.length === 0) {
    formErrors.push({
      type: "variables",
      message: "Debes agregar al menos una variable",
    });
  }
  if (files.length === 0) {
    formErrors.push({
      type: "files",
      message: "Debes agregar al menos un archivo",
    });
  }
  if (isAnyFileEmpty(files)) {
    formErrors.push({
      type: "file_empty",
      message: "Todos los archivos deben contener al menos una línea de código",
    });
  }
  if (example === "" || example === null) {
    formErrors.push({
      typer: "example",
      message: "Debes incluir la estructura de ejemplo",
    });
  }

  return { isValid: formErrors.length === 0, formErrors };
};

export default isFormValid;
