import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import InputFile from "@bit/kiban-design-system.kiban.input-file";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import Skeleton from "@bit/kiban-design-system.kiban.skeleton";
import Textarea from "@bit/kiban-design-system.kiban.textarea";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import { FileIcon, AlertIcon } from "@bit/kiban-design-system.kiban.icons";
import {
  COLOR_BRAND_PRIMARY_BASE,
  COLOR_DANGER_BASE,
} from "@bit/kiban-design-system.styles.design-tokens-es6";
import { useAlert } from "@bit/kiban-design-system.kiban.alert-provider";

import { apiRequest, apiRoutes } from "../../../services";
import connectorsTypes from "../connectorsTypes";
import Jwt from "../../../utils/jwt";
import Paths from "../../../paths";
import "./AddEditDocument.css";
import alertTexts from "../../../utils/alerts-texts";

export default function AddEditDocument() {
  const [nameConnector, setNameConnector] = useState("");
  const [plan, setPlan] = useState(null);
  const [encrypt, setEncrypt] = useState(false);
  const [publicKey, setPublicKey] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [pdfBase64, setPdfBase64] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const routerParams = useParams();
  const history = useHistory();
  const [auxName, setAuxName] = useState("");
  const [auxPdfName, setAuxPdfName] = useState("");
  const [errors, setErrors] = useState({
    name: false,
  });
  const [isPreLoading, setIsPreLoading] = useState(false);

  const { addAlert } = useAlert();

  const showAlert = (type, message) => {
    addAlert({
      code: type,
      message,
      duration: 5000,
    });
  };

  async function addEditDataDocument(method, url, data) {
    const [err] = await apiRequest({
      method,
      url,
      data,
    });
    if (!err) {
      showAlert("success", alertTexts.success);
      history.push(
        Paths.Connectors.replace(":connectorType", routerParams.connectorType)
      );
    } else {
      showAlert("error", err.message || alertTexts.unexpectedError);
      setError(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    async function getPlan() {
      const [err, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getPlans,
      });
      setError(err);
      if (!err) {
        setPlan(data);
      } else {
        showAlert("error", alertTexts.unexpectedError);
      }
      setIsLoading(false);
    }

    async function getDataDocument(typeConnector, id) {
      const [err, data] = await apiRequest({
        method: "get",
        url: apiRoutes.getConnector.replace(":id", id),
      });
      if (!err) {
        setNameConnector(data.name);
        setAuxName(data.name);
        setEncrypt(data.encrypt);
        setPdfName(data.pdfName);
        setPublicKey(data.publicKeyToEncrypt);
        setPdfBase64(data.pdfBase64 || null);
        setAuxPdfName(data.pdfName);
      } else {
        showAlert("error", alertTexts.unexpectedError);
        setError(err);
      }
      setIsPreLoading(false);
    }

    if (Jwt.validateToken()) {
      if (routerParams.id) {
        setIsPreLoading(true);
        getDataDocument(
          connectorsTypes[routerParams.connectorType],
          routerParams.id
        );
      }
      getPlan();
    } else {
      window.location.href = "/login";
    }
  }, []);

  const onCancel = () => {
    history.push(
      Paths.Connectors.replace(":connectorType", routerParams.connectorType)
    );
  };

  const handleOnRadioChange = (value) => {
    setEncrypt(value);
    if (!value) {
      setPublicKey("");
    }
  };

  const handleNameConnector = (event) => {
    setNameConnector(event.target.value);
  };

  const handlePulicKey = (event) => {
    setPublicKey(event.target.value);
  };

  const handleChange = (pdf) => {
    if (pdf && pdf.length > 0) {
      setPdfName(pdf[0].filename);
      setPdfBase64(pdf[0].content);
    }
  };

  const handleClick = () => {};

  const submitDocument = () => {
    setIsLoading(true);
    const dataBody = {
      encrypt,
      publicKeyToEncrypt: publicKey,
      pdfName,
    };
    if (pdfName !== "" && pdfBase64 !== null) {
      dataBody["pdfBase64"] = pdfBase64;
    }
    if (auxName !== nameConnector) {
      dataBody["name"] = nameConnector;
    } else {
      dataBody["name"] = auxName;
    }
    const typeConnector = connectorsTypes[routerParams.connectorType];
    const url = !routerParams.hasOwnProperty("id")
      ? apiRoutes.addConnector.replace(":typeConnector", typeConnector)
      : apiRoutes.getEditConnector
          .replace(":typeConnector", typeConnector)
          .replace(":id", routerParams.id);
    addEditDataDocument(
      routerParams.hasOwnProperty("id") ? "put" : "post",
      url,
      dataBody
    );
  };

  const isFormValid = () => {
    let formErrors = { ...errors };
    formErrors.name = Boolean(!nameConnector);
    setErrors(formErrors);
    return !formErrors.name;
  };

  const primaryAction = {
    text: "Guardar",
    onAction: () => {
      if (isFormValid()) submitDocument();
    },
    disabled: isLoading,
    loading: isLoading,
  };
  const cancelAction = {
    text: "Cancelar",
    onAction: onCancel,
    disabled: isLoading,
  };

  const fileSecondaryActions = [
    {
      text: "Reemplazar archivo",
      onAction() {
        setPdfName("");
      },
      disabled: isLoading,
    },
  ];

  if (routerParams.hasOwnProperty("id") && pdfName === auxPdfName)
    fileSecondaryActions.push({
      text: "Editar",
      onAction() {
        history.push(`/connectors/${routerParams.id}/pdfTemplate`);
      },
      disabled: isLoading,
    });

  return isPreLoading ? (
    <Skeleton />
  ) : (
    <div className="main-content">
      {error && (
        <div>
          <p
            style={{
              color: COLOR_DANGER_BASE,
              display: "flex",
              alignItems: "center",
            }}
          >
            <AlertIcon />
            <span style={{ marginLeft: "10px" }}>
              {error.message ? error.message : "Hubo un error inesperado"}
            </span>
          </p>
        </div>
      )}
      <PageSection
        title="Documento"
        primaryAction={primaryAction}
        secondaryActions={[cancelAction]}
      >
        <PageSection.Item size={1} length={2} title="Nombre">
          <TextField
            value={nameConnector}
            onChange={(event) => handleNameConnector(event)}
            error={errors.name}
            errorText="El nombre es requerido"
            disabled={isLoading}
          />
        </PageSection.Item>
      </PageSection>
      <PageSection
        title="Archivo"
        secondaryActions={
          !routerParams.id || !pdfName ? undefined : fileSecondaryActions
        }
      >
        {!routerParams.id || !pdfName ? (
          <PageSection.Item size={1} length={1} title="Cargar archivo">
            <InputFile
              encodeBase64
              handleChange={handleChange}
              handleClick={handleClick}
              extensionsFiles={[".pdf"]}
            />
          </PageSection.Item>
        ) : (
          <PageSection.Item size={1} length={3} title="Archivo">
            <div className="file-component">
              <FileIcon color={COLOR_BRAND_PRIMARY_BASE} />
              <div className="file-name">
                <span>{pdfName}</span>
                <span className="legend">
                  Archivo {pdfName.split(".")[1].toUpperCase()}
                </span>
              </div>
            </div>
          </PageSection.Item>
        )}
        <PageSection.Item
          size={1}
          length={3}
          title="Encriptar archivo (Disponible a partir del plan de 1,500 USD)"
        >
          <div className="radio-buttons-encrypt-file">
            <RadioButton
              disabled={plan && plan.workfloo && plan.workfloo.tier < 1500}
              label="Si"
              name="encript"
              value={true}
              checked={encrypt}
              onChange={() => handleOnRadioChange(true)}
            />
            <RadioButton
              disabled={plan && plan.workfloo && plan.workfloo.tier < 1500}
              label="No"
              name="encript"
              value={false}
              checked={!encrypt}
              onChange={() => handleOnRadioChange(false)}
            />
            {encrypt && (
              <div className="public-key-container">
                <Textarea
                  label="Llave pública"
                  onChange={(event) => handlePulicKey(event)}
                  value={publicKey}
                />
              </div>
            )}
          </div>
        </PageSection.Item>
      </PageSection>
    </div>
  );
}
