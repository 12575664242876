import {
  DeviceHub,
  Extension,
  AccountBalance,
  Receipt,
  MonetizationOn,
  Assessment,
  VerifiedUser,
  Description,
  Assignment,
  Person,
  Message,
  MoneyOff,
  TrendingUp,
  AttachMoney,
  SettingsInputComponent,
  Settings,
  TextFormat,
  Dialpad,
  AccessTime,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { apiRequest, apiRoutes } from "../../../services";
import Jwt from "../../../utils/jwt";

export const boxOptions = [
  {
    label: "API",
    value: "API",
  },
  {
    label: "Árbol de decisión",
    value: "POLICY",
  },
  {
    label: "Burós",
    value: "CONSULTA",
  },
  {
    label: "Cfdi",
    value: "CFDI",
  },
  {
    label: "Core Bancario",
    value: "BANKING_CORE",
  },
  {
    label: "Clean Data",
    value: "CAMARA_COMERCIO_BOGOTA",
  },
  {
    label: "Firma Electrónica",
    value: "DIGITAL_SIGN",
  },
  {
    label: "Documento",
    value: "DOCUMENT",
  },
  {
    label: "Formulario",
    value: "FORM",
  },
  {
    label: "KYC",
    value: "IDENTIDAD",
  },
  {
    label: "Mensajería",
    value: "MESSAGING",
  },
  {
    label: "NIP",
    value: "NIP",
  },
  {
    label: "Open Banking",
    value: "OPEN_BANKING",
  },
  {
    label: "Score",
    value: "SCORE",
  },
  {
    label: "Pagos",
    value: "PAYMENTS",
  },
  {
    label: "Variables Dinámicas",
    value: "VP",
  },
  {
    label: "Variables Personalizadas",
    value: "VPR",
  },
  {
    label: "Timer",
    value: "TIMER",
  },
].filter(Boolean);

export const nodesIcons = [
  {
    value: "API",
    icon: Extension,
  },
  {
    value: "POLICY",
    icon: DeviceHub,
  },
  {
    value: "CONSULTA",
    icon: AccountBalance,
  },
  {
    value: "CFDI",
    icon: Receipt,
  },
  {
    value: "BANKING_CORE",
    icon: MonetizationOn,
  },
  {
    value: "CAMARA_COMERCIO_BOGOTA",
    icon: Assessment,
  },
  {
    value: "DIGITAL_SIGN",
    icon: VerifiedUser,
  },
  {
    value: "DOCUMENT",
    icon: Description,
  },
  {
    value: "FORM",
    icon: Assignment,
  },
  {
    value: "IDENTIDAD",
    icon: Person,
  },
  {
    value: "MESSAGING",
    icon: Message,
  },
  {
    value: "NIP",
    icon: Dialpad,
  },
  {
    value: "OPEN_BANKING",
    icon: MoneyOff,
  },
  {
    value: "SCORE",
    icon: TrendingUp,
  },
  {
    value: "PAYMENTS",
    icon: AttachMoney,
  },
  {
    value: "VP",
    icon: SettingsInputComponent,
  },
  {
    value: "VPR",
    icon: Settings,
  },
  {
    value: "MESSAGE",
    icon: TextFormat,
  },
  {
    value: "TIMER",
    icon: AccessTime,
  },
].filter(Boolean);

export const useConnector = (type) => {
  const [connectors, setConnectors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    setConnectors(null);
    setError(null);
    (async () => {
      if (type) {
        const [error, connectors] = await apiRequest({
          method: "get",
          url: apiRoutes.connectors.replace("{type}", type),
        });
        if (connectors) {
          setConnectors(connectors);
        }
        if (error) {
          setError(error);
        }
        setLoading(false);
      }
    })();
  }, [type]);
  return { connectors, loading, error };
};

export const getBoxOpotions = async () => {
  let options = [];
  const [error, bxopts] = await apiRequest({
    method: "get",
    url: apiRoutes.getWorkflooAvailableTypes,
  });
  if (bxopts) {
    options = boxOptions.map((bx) =>
      !bxopts[bx.value] ? { ...bx, disabled: true } : bx
    );
  }
  return { options, error };
};

export const generateID = () => {
  return `Element-${Math.floor(Math.random() * Date.now() + 1)}`;
};

export const nodesGap = 40;

/**
 * This function creates a parent node children for workfloos nodes
 * @function policyNodes
 * @param {Object} connector The selected connector
 * @param {string} connectorType The selected connector type
 * @param {Object} parentNode The node which will have children
 * @returns {ObjectOf(newConnections, newNodes)} The new connections and nodes generated
 */
export const policyNodes = ({ connector, connectorType, parentNode }) => {
  let newNodes = [];
  /**
   * gets all Nodes which dont have any children
   */
  let finalNodes = connector.nodes.filter(
    (node) => !node.childTrueNodeId && !node.childFalseNodeId
  );

  /**
   * Computes the summed size of all child nodes excluding the last one
   */
  const rowSize = 89 * (finalNodes.length - 1);
  finalNodes.forEach((node, i) => {
    const id = generateID();
    newNodes.push({
      title: connector.messages.find((msg) => msg.id === node.stepId).name,
      idConnector: parentNode.idConnector,
      idMessage: node.stepId,
      connectorType: "MESSAGE",
      parent: parentNode.id,
      id,
      children: [],
      posY: parentNode.posY + (89 + nodesGap),
      posX:
        parentNode.posX - rowSize > 0
          ? parentNode.posX + // Parent node left position: ;
            (i > 0 ? 89 + nodesGap / 2 : 89) * i - // Multiplies the Ui node size by the node index, if the index is greater than 0 a gap will be added to separate then to each other
            (rowSize + (nodesGap / 2) * (finalNodes.length - 1)) / 2
          : i * (nodesGap / 2 + 89), // The half of the row Size with gaps added will be substracted to the nodes final size
    });
  });
  return newNodes;
};

/**
 *  Api request to get connector by id
 * @param {string} id The connector id
 * @param {Function} onError The function to be called when an error occurs
 * @returns {Object} The connector
 */
export const getConnector = async ({ id, onError }) => {
  const [error, connector] = await apiRequest({
    method: "get",
    url: apiRoutes.getConnector.replace(":id", id),
  });
  if (connector) {
    return connector;
  }
  if (error) {
    onError(error);
  }
};
