export default [
  {
    content: "Persona Moral",
    value: "PM",
  },
  {
    content: "Persona Física",
    value: "PF",
  },
];
