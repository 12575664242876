import React, { useEffect } from "react";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import SelectField from "@bit/kiban-design-system.kiban.select-field";
import InputGroup from "@bit/kiban-design-system.kiban.input-group";
import IconButton from "@bit/kiban-design-system.kiban.icon-button";
import { XIcon } from "@bit/kiban-design-system.kiban.icons";
import Grid from "@bit/kiban-design-system.layout.grid";

import VariableTypes from "../../../../utils/customVariablesTypeOptions";

const VariableForm = ({
  name,
  type,
  onChange,
  nameError,
  typeError,
  onRemove,
  id,
}) => {
  return (
    <Grid alignItems="flex-end">
      <Grid.Item size="content-width">
        <span>{id}</span>
      </Grid.Item>
      <Grid.Item size="auto">
        <div className="Modal-Screen--Variable-Form">
          <div className="Modal-Screen--Variable-Form--Name var-form-item">
            <TextField
              label="Nombre"
              type="text"
              placeholder="Nombre"
              value={name}
              onChange={(e) => onChange && onChange(e.target.value, type)}
              error={!!nameError}
              errorText={nameError}
            />
          </div>
          <div className="Modal-Screen--Variable-Form--Type var-form-item">
            <SelectField
              label="Tipo"
              placeholder="Nombre"
              options={VariableTypes}
              value={type}
              onChange={(type) => onChange && onChange(name, type)}
              error={!!typeError}
              errorText={typeError}
            />
          </div>
        </div>
      </Grid.Item>
      <Grid.Item size="content-width">
        <IconButton icon={XIcon} onClick={onRemove} style="secondary" />
      </Grid.Item>
    </Grid>
  );
};

export default VariableForm;
