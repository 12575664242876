import { apiRequest, apiRoutes } from "../../../../services";

const getConnector = async (id, onFail) => {
  const [error, data] = await apiRequest({
    method: "get",
    url: apiRoutes.getConnector.replace(":id", id),
  });
  if (data) {
    return data;
  }
  if (onFail) {
    onFail(error);
  }
};

export default getConnector;
