import { useEffect, useState } from "react";
const useSearch = ({ data, search }) => {
  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    if (search === "") {
      setFilteredData(data);
      return;
    }
    const filterRegex = new RegExp(search, "i");
    const result = data.filter((connector) => {
      return filterRegex.test(connector.name);
    });
    setFilteredData(result);
  }, [search, data]);
  return { filteredData };
};

export default useSearch;
