import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import Title from "@bit/kiban-design-system.kiban.title";
import RadioButton from "@bit/kiban-design-system.kiban.radio-button";
import TextField from "@bit/kiban-design-system.kiban.text-field";
import Button from "@bit/kiban-design-system.kiban.button";

const ConfigDigitalSignMX = (
  { dataConfig, handleOnChangeConfig, errorFields, saveConfig, refs }
) => {
  const {
    passwordWebhookReference,
    userWebhookReference,
    urlWebhookReference,
  } = refs;
  return (
    <PageSection>
      {dataConfig?.Mifiel && (
        <>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar MiFiel</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="identidad-digital"
                  value={true}
                  checked={dataConfig.Mifiel.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "Mifiel",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="identidad-digital"
                  value={false}
                  checked={!dataConfig.Mifiel.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "Mifiel",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            {dataConfig.Mifiel.Activated && (
              <>
                <TextField
                  error={errorFields.includes("Mifiel-AppIdSandbox")}
                  errorText={"El campo no debe ir vacio"}
                  label="APP ID Sandbox"
                  value={dataConfig.Mifiel.AppIdSandbox}
                  onChange={(e) =>
                    handleOnChangeConfig(
                      "Mifiel",
                      "AppIdSandbox",
                      e.target.value
                    )
                  }
                />
                <TextField
                  error={errorFields.includes("Mifiel-AppSecretSandbox")}
                  errorText={"El campo no debe ir vacio"}
                  label="APP SECRET Sandbox"
                  type="password"
                  value={dataConfig.Mifiel.AppSecretSandbox}
                  onChange={(e) =>
                    handleOnChangeConfig(
                      "Mifiel",
                      "AppSecretSandbox",
                      e.target.value
                    )
                  }
                />
                <TextField
                  error={errorFields.includes("Mifiel-AppId")}
                  errorText={"El campo no debe ir vacio"}
                  label="APP ID"
                  value={dataConfig.Mifiel.AppId}
                  onChange={(e) =>
                    handleOnChangeConfig("Mifiel", "AppId", e.target.value)
                  }
                />
                <TextField
                  error={errorFields.includes("Mifiel-AppSecret")}
                  errorText={"El campo no debe ir vacio"}
                  label="APP SECRET"
                  type="password"
                  value={dataConfig.Mifiel.AppSecret}
                  onChange={(e) =>
                    handleOnChangeConfig("Mifiel", "AppSecret", e.target.value)
                  }
                />
                Consulte la Documentación Mifiel para más información,{" "}
                <a target="_blank" href="https://docs.mifiel.com/">
                  ir a documentación
                </a>
              </>
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={2} />
        </>
      )}
      {dataConfig?.Legalario && (
        <>
          <PageSection.Item size={1} length={2}>
            <div className="rows">
              <Title type="heading">Activar Legalario:</Title>
              <div className="container-inputs">
                <RadioButton
                  label="Si"
                  name="cumplo-360"
                  value={true}
                  checked={dataConfig.Legalario.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "Legalario",
                      "Activated",
                      value === "true"
                    )
                  }
                />
                <RadioButton
                  label="No"
                  name="cumplo-360"
                  value={false}
                  checked={!dataConfig.Legalario.Activated}
                  onChange={(checked, value) =>
                    handleOnChangeConfig(
                      "Legalario",
                      "Activated",
                      value === "true"
                    )
                  }
                />
              </div>
            </div>
            {dataConfig.Legalario.Activated && (
              <div>
                <TextField
                  error={errorFields.includes("Legalario-ClientId")}
                  errorText={"El campo no debe ir vacio"}
                  label="Client Id"
                  value={dataConfig.Legalario.ClientId}
                  onChange={(e) =>
                    handleOnChangeConfig(
                      "Legalario",
                      "ClientId",
                      e.target.value
                    )
                  }
                />
                <TextField
                  error={errorFields.includes("Legalario-ClientSecret")}
                  errorText={"El campo no debe ir vacio"}
                  label="Client Secret"
                  type="password"
                  value={dataConfig.Legalario.ClientSecret}
                  onChange={(e) =>
                    handleOnChangeConfig(
                      "Legalario",
                      "ClientSecret",
                      e.target.value
                    )
                  }
                />
                <TextField
                  connectedRight={
                    <Button
                      onClick={() => {
                        urlWebhookReference.current.focus();
                        urlWebhookReference.current.select();
                        navigator.clipboard.writeText(
                          dataConfig.Legalario.WebhookUrl
                        );
                      }}
                      style="primary"
                      text="Copiar"
                    />
                  }
                  readOnly={true}
                  id="urlWebhook"
                  prefix="URL webhook"
                  reference={urlWebhookReference}
                  value={dataConfig.Legalario.WebhookUrl}
                />
                <TextField
                  connectedRight={
                    <Button
                      onClick={() => {
                        userWebhookReference.current.focus();
                        userWebhookReference.current.select();
                        navigator.clipboard.writeText(
                          dataConfig.Legalario.WebhookUser
                        );
                      }}
                      style="primary"
                      text="Copiar"
                    />
                  }
                  readOnly={true}
                  id="userWebhook"
                  prefix="Usuario Webhook"
                  reference={userWebhookReference}
                  value={dataConfig.Legalario.WebhookUser}
                />
                <TextField
                  connectedRight={
                    <Button
                      onClick={() => {
                        passwordWebhookReference.current.focus();
                        passwordWebhookReference.current.select();
                        navigator.clipboard.writeText(
                          dataConfig.Legalario.WebhookPassword
                        );
                      }}
                      style="primary"
                      text="Copiar"
                    />
                  }
                  readOnly={true}
                  id="passwordWebhook"
                  prefix="contraseña Webhook"
                  reference={passwordWebhookReference}
                  value={dataConfig.Legalario.WebhookPassword}
                />
              </div>
            )}
          </PageSection.Item>
          <PageSection.Item size={1} length={2} />
        </>
      )}
      <PageSection.Item size={1} length={1}>
        <Button onClick={saveConfig} style="primary" text="Guardar" />
      </PageSection.Item>
    </PageSection>
  );
};

export default ConfigDigitalSignMX;
