import React from "react";
import "./Home.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#fff",
    backgroundColor: "transparent",
    justifyContent: "flex-start",
    flexDirection: "row",
    padding: 20,
    boxShadow: "0 0 black",
  },
}));

export default function Home() {
  return <div></div>;
}
