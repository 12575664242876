const parseOptions = (options) => {
  const parsedTypes = [];
  const parsedServices = {};
  options.forEach((option) => {
    parsedTypes.push({
      content: option.type.langs.es,
      value: option.type.const,
    });
    if (option.services) {
      option.services.forEach((service) => {
        if (service.const) {
          if (!parsedServices[option.type.const]) {
            parsedServices[option.type.const] = [];
          }
          parsedServices[option.type.const].push({
            content: service.langs.es,
            value: service.const,
          });
        }
      });
    }
  });
  return {
    parsedTypes,
    parsedServices,
  };
};

export default parseOptions;
