import React from "react";
import PageSection from "@bit/kiban-design-system.layout.page-section";
import TextField from "@bit/kiban-design-system.kiban.text-field";

const ExperianForm = ({
  user,
  onChangeUser,
  errorUser,
  userKey,
  onChangeUserKey,
  errorUserKey,
  userSandbox,
  userKeySandbox,
  onChangeUserSandbox,
  onChangeUserKeySandbox,
}) => {
  return (
    <>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item size={1} length={3}>
        <TextField
          label="Usuario"
          placeholder="Usuario"
          value={user}
          onChange={(e) => onChangeUser(e.target.value)}
          error={errorUser}
          errorText="Debes completar este campo"
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item size={1} length={3}>
        <TextField
          label="Clave"
          placeholder="Clave"
          value={userKey}
          onChange={(e) => onChangeUserKey(e.target.value)}
          error={errorUserKey}
          errorText="Debes completar este campo"
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item size={1} length={3}>
        <TextField
          label="Usuario Sandbox"
          placeholder="Usuario Sanbox"
          value={userSandbox}
          onChange={(e) => onChangeUserSandbox(e.target.value)}
        />
      </PageSection.Item>
      <PageSection.Item size={2} length={3} />
      <PageSection.Item size={1} length={3}>
        <TextField
          label="Clave Sandbox"
          placeholder="Clave Sandbox"
          value={userKeySandbox}
          onChange={(e) => onChangeUserKeySandbox(e.target.value)}
        />
      </PageSection.Item>
    </>
  );
};

export default ExperianForm;
